import { RouterStore as BaseRouterStore } from "mobx-react-router";
import { computed, decorate } from "mobx";
import { RouteConstants } from "../constants/RouteConstants";
import { matchPath } from "react-router";

export default class RouterStore extends BaseRouterStore {
  stripSlash = str => str.replace(/\/$/gi, "");

  isActive = href => {
    return href === "/"
      ? this.location.pathname === href
      : matchPath(this.location.pathname, href) !== null;
  };

  getPathParams = path => {
    const pathSplit = path.split("/");
    const urlSplit = this.location.pathname.split("/");

    const values = {};
    pathSplit.forEach((i, index) => {
      if (i.includes(":")) {
        const key = i.substr(1).replace("(\\d+)", "");
        values[key] = urlSplit[index];
      }
    });
    return values;
  };

  pushWithPathParams = (route, params) => {
    this.history.push(this.getPathParamsRoute(route, params));
  };

  getQueryParams = () => {
    const urlSplit = this.location.search.split("?");
    if (urlSplit.length === 2) {
      const queryParamsSplit = urlSplit[1].split("&");

      return queryParamsSplit.reduce((acc, cur) => {
        const paramSplit = cur.split("=");
        acc[paramSplit[0]] = paramSplit[1];
        return acc;
      }, {});
    }

    return {};
  };

  pushWithQueryParams = (route, params) => {
    this.history.push(this.getQueryParamsRoute(route, params));
  };

  pushWithAllParams = (route, allParams) => {
    if (allParams.path) {
      route = this.getPathParamsRoute(route, allParams.path);
    }

    if (allParams.query) {
      route = this.getQueryParamsRoute(route, allParams.query);
    }

    this.history.push(route);
  };

  getPathParamsRoute = (route, params) => {
    for (const p in params) {
      if (Object.prototype.hasOwnProperty.call(params, p)) {
        route = route.replace(`:${p}`, params[p]);
      }
    }
    return route;
  };

  getQueryParamsRoute = (route, params) => {
    let queryParams = "";
    for (const p in params) {
      if (Object.prototype.hasOwnProperty.call(params, p)) {
        queryParams +=
          queryParams.length > 0 ? `&${p}=${params[p]}` : `?${p}=${params[p]}`;
      }
    }
    return queryParams.length > 0 ? `${route}${queryParams}` : route;
  };

  get isLoginPath() {
    return this.stripSlash(this.location.pathname) === RouteConstants.LOGIN;
  }

  get isAccountPath() {
    return this.stripSlash(this.location.pathname) === RouteConstants.ACCOUNT;
  }

  get isEventSelectionPath() {
    return this.location.pathname === RouteConstants.BASE;
  }

  get isProgramsPath() {
    return this.stripSlash(this.location.pathname) === RouteConstants.PROGRAMS;
  }

  get isEventsPath() {
    return this.stripSlash(this.location.pathname) === RouteConstants.EVENTS;
  }

  get isEditEventPath() {
    return this.location.pathname.startsWith("/events/") && 
      this.location.pathname.endsWith("/edit");
  }

  get isOktaPath() {
    return this.stripSlash(this.location.pathname) === RouteConstants.OKTA;
  }

  get isEventFlowPath() {
    return this.location.pathname.startsWith("/event/");
  }

  get isOpportunitiesPath() {
    return (
      this.stripSlash(this.location.pathname) ===
      RouteConstants.EVENT_OPPORTUNITIES
    );
  }
}

decorate(RouterStore, {
  isLoginPath: computed,
  isAccountPath: computed,
  isEventSelectionPath: computed,
  isProgramsPath: computed,
  isEventsPath: computed,
  isEditEventPath: computed,
  isOktaPath: computed,
  isEventFlowPath: computed,
  isOpportunitiesPath: computed
});

import { action, extendObservable } from "mobx";

export default class BlockingStore {
  constructor(history) {
    this.history = history;

    this.defaults = {
      currentFormDirty: false,
      currentFormSaveDisabledFn: undefined,
      showUnsavedChangesModal: false,
      pendingLocation: null,
      formBlockingSaveCallback: null,
      formBlockingSaveArgs: null,
      stayAfterBlockingDiscarded: false,
      formBlockingDiscardCallback: null
    };

    // eslint-disable-next-line no-unused-vars
    this.history.block((location, action) => {
      // The location and action arguments indicate the location
      // we're transitioning to and how we're getting there.
      if (this.currentFormDirty) {
        this.showUnsavedChangesModal = true;
        this.pendingLocation = location;
        return "Are you sure?"; // note this isn't used but needs to return a string
      }
    });

    extendObservable(this, {
      // properties
      currentFormDirty: this.defaults.currentFormDirty,
      currentFormSaveDisabledFn: this.defaults.currentFormSaveDisabledFn,
      showUnsavedChangesModal: this.defaults.showUnsavedChangesModal,
      pendingLocation: this.defaults.pendingLocation,
      formBlockingSaveCallback: this.defaults.formBlockingSaveCallback,
      formBlockingSaveArgs: this.defaults.formBlockingSaveArgs,
      stayAfterBlockingDiscarded: this.defaults.stayAfterBlockingDiscarded,
      formBlockingDiscardCallback: this.defaults.formBlockingDiscardCallback,

      // functions
      clearAttributes: action(() => {
        this.currentFormDirty = this.defaults.currentFormDirty;
        this.showUnsavedChangesModal = this.defaults.showUnsavedChangesModal;
        this.pendingLocation = this.defaults.pendingLocation;
        this.formBlockingSaveCallback = this.defaults.formBlockingSaveCallback;
        this.currentFormSaveDisabledFn = this.defaults.currentFormSaveDisabledFn;
        this.formBlockingSaveArgs = this.defaults.formBlockingSaveArgs;
        this.formBlockingDiscardCallback = this.defaults.formBlockingDiscardCallback;
      }),
      setCurrentFormDirty: action(value => {
        this.currentFormDirty = value;
      }),
      setShowUnsavedChangesModal: action(value => {
        this.showUnsavedChangesModal = value;
      }),
      setFormBlockingSaveCallback: action((value, args) => {
        this.formBlockingSaveCallback = value;
        this.formBlockingSaveArgs = args;
      }),
      setCurrentFormSaveDisabledFn: action(value => {
        this.currentFormSaveDisabledFn = value;
      }),
      setStayAfterBlockingDiscarded: action(value => {
        this.stayAfterBlockingDiscarded = value;
      }),
      setFormBlockingDiscardCallback: action(value => {
        this.formBlockingDiscardCallback = value;
      }),
      onBlockingDiscarded: action(() => {
        const location = this.pendingLocation;
        if (!this.stayAfterBlockingDiscarded) {
          this.clearAttributes();
          this.history.push(location);
        }
        if (
          this.formBlockingDiscardCallback &&
          typeof this.formBlockingDiscardCallback === "function"
        ) {
          this.formBlockingDiscardCallback();
        }
        if (this.stayAfterBlockingDiscarded) {
          this.clearAttributes();
        }
      }),
      onBlockingSaved: action(() => {
        if (
          this.formBlockingSaveCallback &&
          typeof this.formBlockingSaveCallback === "function"
        ) {
          if (this.formBlockingSaveArgs) {
            this.formBlockingSaveCallback(
              ...this.formBlockingSaveArgs,
              this.onBlockingDiscarded
            );
          } else {
            this.formBlockingSaveCallback(this.onBlockingDiscarded);
          }
        }
      })
    });
  }
}

import { extendObservable, action } from "mobx";
import { NotificationConstants } from "../constants/NotificationConstants";

class NotificationStore {
  constructor() {
    this.defaults = {
      isOpen: false,
      timeout: 3000,
      timeoutObj: null,
      message: "",
      messages: [],
      messageType: NotificationConstants.SUCCESS
    };

    extendObservable(this, {
      isOpen: this.defaults.isOpen,
      message: this.defaults.message,
      messages: this.defaults.messages,
      messageType: this.defaults.messageType,
      timeout: this.defaults.timeout,
      timeoutObj: this.defaults.timeoutObj,
      setMessage: action(
        (
          value,
          type = this.defaults.messageType,
          timeout = this.defaults.timeout
        ) => {
          this.messages = this.messages.concat({
            value,
            type,
            timeout
          });

          if (this.messages.length === 1) {
            this.messageBroker();
          }
        }
      ),
      messageBroker: action(() => {
        const message = this.messages.length ? this.messages[0] : null;
        if (message) {
          this.isOpen = true;
          this.message = message.value;
          this.messageType = message.type;
          if (this.messageType !== NotificationConstants.ERROR) {
            this.timeoutObj = window.setTimeout(() => {
              this.isOpen = false;
              this.messages.shift();
              setTimeout(() => this.messageBroker(), 100);
            }, message.timeout);
          }
        }
      }),
      onClose: action(() => {
        if (this.timeoutObj) {
          window.clearTimeout(this.timeoutObj);
        }

        this.isOpen = false;
        this.messages.shift();
        setTimeout(() => this.messageBroker(), 200);
      })
    });
  }
}

export default NotificationStore;

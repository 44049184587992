import React from "react";
import { Router } from "react-router";
import { Provider } from "mobx-react";
import PropTypes from "prop-types";
import { AuthContextProvider, oktaAuth } from "./AuthContext";
import { LoadingContextProvider } from "./LoadingContext";
import RootStore from "../stores/RootStore";
import HttpInterceptor from "../httpClients/HttpInterceptor";
import { Security } from "@okta/okta-react";

const rootStore = new RootStore();

const WrapperProvider = ({ children }) => {
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(originalUri);
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Provider rootStore={rootStore}>
        <Router history={rootStore.history}>
          <AuthContextProvider>
            <LoadingContextProvider>
              <HttpInterceptor>{children}</HttpInterceptor>
            </LoadingContextProvider>
          </AuthContextProvider>
        </Router>
      </Provider>
    </Security>
  );
};

WrapperProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default WrapperProvider;

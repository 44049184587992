import {
  extendObservable,
  action,
  autorun,
  decorate,
  computed,
  isObservableArray,
} from "mobx";
import { RouteConstants } from "../constants/RouteConstants";
import { FormConstants } from "../constants/FormConstants";
import { NotificationConstants } from "../constants/NotificationConstants";
import { StoreUtilities } from "../utilities/StoreUtilities";
import { StringUtilities } from "../utilities/StringUtilities";
import { DateUtilities } from "../utilities/DateUtilities";
import moment from "moment";

export default class VolunteerStore {
  constructor(
    programApi,
    importApi,
    authApi,
    emailApi,
    routerStore,
    authStore,
    gridStore,
    notificationStore,
    eventStore,
    eventInfoStore,
    volunteerScreensStore,
    blockingStore,
    history,
    i18n
  ) {
    this.programApi = programApi;
    this.importApi = importApi;
    this.authApi = authApi;
    this.emailApi = emailApi;
    this.routerStore = routerStore;
    this.authStore = authStore;
    this.gridStore = gridStore;
    this.notificationStore = notificationStore;
    this.eventStore = eventStore;
    this.eventInfoStore = eventInfoStore;
    this.volunteerScreensStore = volunteerScreensStore;
    this.blockingStore = blockingStore;
    this.history = history;
    this.i18n = i18n;
    this.fileReader = new FileReader();

    this.defaults = {
      volunteer: {},
      volunteers: [],
      volunteersPendingDelete: [],
      sortFilters: {
        key: "name",
        direction: "ASC",
        initial: [],
      },
      savingVolunteer: false,
      isNewVolunteerOpen: false,
      searchVolunteers: "",
      volunteerFilters: {
        waiver: "",
        tier: [],
        alumni: "",
        score: [],
        consent: [],
        orientation: "",
        opportunity: "",
        standby: "",
        infoSession: [],
        shiftsAttended: [],
        photo: [],
      },
      selectedVolunteers: [],
      selectedVolunteersSet: new Set(),
      selectAll: false,
      selectAllOverride: false,
      consentBoxes: {},
      orientationBoxes: {},
      showVolunteerInfo: true,
      showEventInfo: true,
      showOpportunities: false,
      volunteerId: undefined,
      email: "",
      lastName: "",
      firstName: "",
      middleInitial: "",
      gender: "",
      birthDate: null,
      birthCountry: "",
      language: "",
      selectedDates: [],
      selectedLanguages: [],
      selectedOpportunities: [],
      languageOptions: FormConstants.LANGUAGES,
      notes: "",
      country: "United States",
      street1: "",
      street2: "",
      street3: "",
      city: "",
      region: "",
      zipCode: "",
      alumni: false,
      attendedOrientation: true,
      waiverDate: null,
      backgroundCheckStarted: false,
      orientationDate: null,
      backgroundDate: null,
      waiverBox: false,
      volunteerPhoto: null,
      attendedOnlineSessionBox: false,
      attendedOnlineSessionDate: null,
      photoUploaded: false,
      photoSubmissionDate: null,
      photoStatus: null,
      photoInfoChanged: false,
      newPhotoUploaded: false,
      selectedRejectionReason: FormConstants.PHOTO_REJECTION_REASONS[0],
      photoId: null,
      photoBase64Encoded: "",
      backgroundBox: false,
      selections: [],
      standbySelections: [],
      interviewScore: "N/A",
      interviewComments: "",
      backgroundCheckScore: "",
      backgroundCheckComments: "",
      phoneNumbersList: [],
      volunteerFormDirty: false,
      volunteerTableDirty: false,
      validationErrorMessages: [],
      failedVolunteerLoad: false,
      validEmail: false,
      isVerifyingEmail: false,
      emailTimeout: null,
      existingUser: {},
      selectedEmailTemplate: null,
      isEmailVolunteersOpen: false,
      emailAllSelected: false,
      emailNotPreviouslyReceivedSelected: false,
      volunteersPageSize: 50,
      failedImportsPageSize: 20,
      loadingVolunteers: false,
      searchingVolunteers: false,
      loadingFailedImports: false,
      numVolunteerPages: 1,
      numFailedImportPages: 1,
      previousNumVolunteerPages: 1,
      previousNumFailedImportPages: 1,
      totalVolunteers: 0,

      totalImport: 0,
      totalImportPostValidation: 0,
      duplicate: 0,
      dupSameNameAccepted: 0,
      totalImportRejected: 0,
      totalImportAccepted: 0,
      dupSameNameRejected: 0,
      dupDiffNameRejected: 0,
      disallowedCountryRejected: 0,
      totalImportNotProcessed: 0,

      failedImports: [],
      importResultFilter: FormConstants.IMPORT_RESULT_FILTERS[0],
      importResultFilterDropDownValue: "",
      importResultFilterTextBoxValue: "",
      importResultTableFilter: [
        {
          id: "processed",
          value: "",
        },
        {
          id: "allFields",
          value: "",
        },
      ],
      filteredTotalVolunteers: null,
      currentVolunteersPage: 0,
      currentFailedImportsPage: 0,
      previousVolunteersPage: null,
      failedImportsExpanded: false,
      importExceptions: [],
      uploadingImportExceptions: false,
      importInfoExpanded: false,
      importValidationExpanded: false,
      searchTimeout: null,
      isWarnOnDeleteOpen: false,
      currentSort: null,
      currentFailedImportsSort: null,
      showConfirmImport: false,
      showGenerateVolunteers: false,
      showFailedReasonModal: false,
      failedReasonModalVolunteerId: null,
      failedReasonModalEvent: null,
      failedReasonValue: {},
      modalFailedReasonValue: {},
      isRejectionEmailModalOpen: false,
      selectedRejectionEmailTemplate: null,
      sendingRejectionEmail: false,
      generatePrefix: "",
      generateNumVolunteers: "",
      generateTwoTiers: true,
      exporting: false,
      downloadingReport: false,
      downloadingWaiver: false,
      filterDrawerOpen: false,
      disableSearchInput: false,
      completedInfoSessionVideo: false,
      passedInfoSessionQuestionnaire: false,
    };

    extendObservable(this, {
      ...StoreUtilities.initialize(this),

      volunteerPageChanged: action((page, sorted) => {
        this.currentSort = sorted;
        if (!this.searchVolunteers && !this.areFiltersApplied()) {
          if (this.eventStore.eventId) {
            this.loadVolunteersForEvent(
              this.eventStore.eventId,
              page,
              this.currentSort
            );
          }
        } else {
          // filtered down already
          this.setLoadingVolunteers(true);
          this.search(page, this.currentSort);
        }
      }),
      // functions
      setDate: action((date, event, propName) => {
        if (!(date instanceof Date)) {
          // onSelect from onChange passes in date and an event
          event = date;
        }

        if (!event) {
          this[propName] = null;
        } else if (event.type === "click") {
          this[propName] = date;
        } else {
          const d = moment(event.currentTarget.value, "MM/DD/YYYY", true);
          this[propName] = d.isValid() ? d.toDate() : null;
        }
      }),
      setSearchVolunteers: action((event) => {
        this.searchVolunteers = event.target.value;

        if (!this.searchVolunteers && !this.areFiltersApplied()) {
          this.volunteers = this.sortFilters.initial;
          this.currentVolunteersPage = this.previousVolunteersPage;
          this.numVolunteerPages = this.previousNumVolunteerPages;
          this.previousVolunteersPage = null;
          this.filteredTotalVolunteers = null;
        } else {
          if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
          }
          this.searchTimeout = setTimeout(() => {
            if (this.previousVolunteersPage === null) {
              this.previousVolunteersPage = this.currentVolunteersPage;
              this.previousNumVolunteerPages = this.numVolunteerPages;
            }
            this.search(0, this.currentSort);
          }, 500);
        }
      }),
      search: action((page, sorted) => {
        if (sorted) {
          sorted = sorted[0]; // TODO: Currently only allowing sort by 1 column at a time
          if (
            sorted.id === "acceptedWaiverDate" ||
            sorted.id === "backgroundCheckConsentDate"
          ) {
            sorted.desc = !sorted.desc;
          }
        }
        this.setSearchingVolunteers(true);
        this.programApi
          .findVolunteers({
            eventId: eventStore.eventId,
            limit: this.volunteersPageSize,
            page: page,
            sort: sorted.id,
            direction: sorted.desc ? "DESC" : "ASC",
            filters: this.searchFilters(),
          })
          .then((data) => {
            this.setVolunteers(
              data
                ? data.volunteers.map((volunteer) => {
                    if (volunteer.attendedOnlineSessionDate) {
                      this.setupInterviewScore(
                        volunteer,
                        volunteer,
                        "Pass - Online"
                      );
                    }
                    return volunteer;
                  })
                : []
            );
            this.currentVolunteersPage = page;
            this.setLoadingVolunteers(false);
            this.setSearchingVolunteers(false);
            this.searchTimeout = null;

            if (data) {
              this.totalVolunteers = data.totalVolunteers;
              this.filteredTotalVolunteers = data.totalFilteredVolunteers;
              this.numVolunteerPages = Math.ceil(
                this.filteredTotalVolunteers / this.volunteersPageSize
              );
            }

            this.volunteers.forEach((volunteer) => {
              const { backgroundCheckConsentDate } = volunteer;
              this.consentBoxes[volunteer.id] = Boolean(
                backgroundCheckConsentDate
              );
            });
          })
          .catch(() => {
            this.searchTimeout = null;
          });
      }),
      setVolunteers: action((value) => {
        this.volunteers = value;
      }),
      setNameSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      clickVolunteerBox: action((volunteerId) => {
        if (this.selectedVolunteersSet.has(volunteerId)) {
          this.selectedVolunteersSet.delete(volunteerId);
          this.selectAll = false;
          this.selectAllOverride = false;
        } else {
          this.selectedVolunteersSet.add(volunteerId);
          this.selectAll =
            this.selectedVolunteersSet.size ===
            (!this.searchVolunteers
              ? this.totalVolunteers
              : this.filteredTotalVolunteers);
        }
        this.selectedVolunteers = [...this.selectedVolunteersSet];
      }),
      toggleSelectAll: action(() => {
        this.selectedVolunteersSet =
          this.selectAllOverride || this.selectAll
            ? new Set()
            : new Set(this.volunteers.map((i) => i.id));
        this.selectAll = !this.selectAll;
        if (this.selectAll) {
          if (
            !this.searchVolunteers &&
            this.filteredTotalVolunteers > this.volunteersPageSize
          ) {
            this.selectAllOverride = true;
          } else if (this.totalVolunteers > this.volunteersPageSize) {
            this.selectAllOverride = true;
          }
        } else {
          this.selectAllOverride = false;
        }
        this.selectedVolunteers = [...this.selectedVolunteersSet];
      }),
      setSelectedEmailTemplate: action((emailId) => {
        this.selectedEmailTemplate = emailId;
      }),
      emailVolunteers: action(() => {
        if (this.selectAll) {
          const filters = {
            ...this.searchFilters(),
            onlyUnsentEmails: this.emailNotPreviouslyReceivedSelected,
            //onlyUnsentEmails: false  // Set it to false so emails will always be sent.
          };
          this.programApi
            .emailAllVolunteers(
              this.selectedEmailTemplate,
              this.eventStore.eventId,
              filters
            )
            .then(() => {
              this.displayEmailNotification();
              this.hideEmailVolunteers();
            });
        } else {
          const emails = [];
          this.selectedVolunteers.map((vId) => {
            const volunteer = this.volunteers.find((v) => v.id === vId);
            emails.push({
              toAddresses: [{ address: volunteer.email }],
              placeholders: [
                { key: "first_name", value: volunteer.firstName },
                { key: "email", value: volunteer.email.toLowerCase() },
                { key: "password", value: "" },
                {
                  key: "unsubscribe_address",
                  value: this.eventStore.contactEmailAddress,
                },
              ],
            });
          });

          this.emailApi
            .sendEmailsWithTemplate(this.selectedEmailTemplate, {
              emailInfos: emails,
              onlyUnsentEmails: this.emailNotPreviouslyReceivedSelected,
              //onlyUnsentEmails: false  // Set it to false so emails will always be sent.
            })
            .then(() => {
              this.displayEmailNotification();
              this.hideEmailVolunteers();
            });
        }
      }),
      showEmailVolunteers: action(() => {
        this.isEmailVolunteersOpen = true;
      }),
      hideEmailVolunteers: action(() => {
        this.isEmailVolunteersOpen = false;
        this.selectedEmailTemplate = null;
        this.emailAllSelected = false;
        this.emailNotPreviouslyReceivedSelected = false;
      }),
      displayEmailNotification: action(() => {
        this.notificationStore.setMessage("Emails Sent");
      }),
      setEmailAllSelected: action(() => {
        this.emailAllSelected = true;
        this.emailNotPreviouslyReceivedSelected = false;
      }),
      setEmailNotPreviouslyReceivedSelected: action(() => {
        this.emailAllSelected = false;
        this.emailNotPreviouslyReceivedSelected = true;
      }),
      clickConsentBox: action((volunteerId) => {
        this.consentBoxes = Object.assign({}, this.consentBoxes, {
          [volunteerId]: !this.consentBoxes[volunteerId],
        });
      }),
      clickOrientationBox: action((volunteerId) => {
        this.orientationBoxes = Object.assign({}, this.orientationBoxes, {
          [volunteerId]: !this.orientationBoxes[volunteerId],
        });
      }),
      changeInterviewScore: action((volunteerId, e) => {
        if (e.target.value === "Fail") {
          this.showFailedReasonModal = true;
          this.failedReasonModalVolunteerId = volunteerId;
          this.failedReasonModalEvent = { target: { value: e.target.value } };
        } else {
          this.saveInterviewScore(volunteerId, e);
        }
      }),
      saveInterviewScore: action((volunteerId, e) => {
        this.showFailedReasonModal = false;

        // have to iterate to force re-render
        this.volunteers = this.volunteers.map((v) => {
          if (v.id === volunteerId) {
            v.interviewScore = e.target.value === "N/A" ? null : e.target.value;
          }

          return v;
        });

        const volunteer = this.volunteers.find((v) => v.id === volunteerId);
        volunteer.loading = true;

        if (volunteer.interviewScore === "Fail") {
          volunteer.failedReason = this.modalFailedReasonValue.value;
        } else {
          volunteer.failedReason = null;
        }

        // this really should already be set at this point, but just making sure
        if (
          volunteer.onlineInfoSession === null ||
          volunteer.onlineInfoSession === undefined
        ) {
          volunteer.onlineInfoSession =
            volunteer.interviewScore &&
            volunteer.interviewScore.indexOf("Online") > -1;
        }

        this.saveVolunteerFromList(this.eventStore.event.id, volunteer)
          .then(() => {
            this.modalFailedReasonValue = this.defaults.modalFailedReasonValue;

            this.volunteers = this.volunteers.map((v) => {
              if (v.id === volunteerId) {
                v.savedMessage = "Saved!";
              }
              return v;
            });

            setTimeout(() => {
              this.volunteers = this.volunteers.map((v) => {
                if (v.id === volunteerId) {
                  v.loading = undefined;
                  v.loading = undefined;
                  v.savedMessage = undefined;

                  if (v.attendedOnlineSessionDate) {
                    this.setupInterviewScore(v, v, "Pass - Online");
                  }
                }
                return v;
              });
            }, 1000);
          })
          .catch(() => {
            this.modalFailedReasonValue = this.defaults.modalFailedReasonValue;
            this.volunteers = this.volunteers.map((v) => {
              if (v.id === volunteerId) {
                v.loading = undefined;
                v.savedMessage = undefined;
              }
              return v;
            });
            this.notificationStore.setMessage(
              "Failed Saving Volunteer",
              NotificationConstants.ERROR
            );
          });
      }),
      toggleNewVolunteer: action(() => {
        this.isNewVolunteerOpen = !this.isNewVolunteerOpen;
        this.clearVolunteerAttributes();
      }),
      showVolunteerDetails: action(() => {
        this.isNewVolunteerOpen = false;
        this.volunteerFormDirty = true; // have to do this here because no access to dirty checker
        this.routerStore.pushWithQueryParams(RouteConstants.NEW_VOLUNTEER, {
          eventId: eventStore.eventId,
        });
      }),
      toggleVolunteerInfo: action(() => {
        this.showVolunteerInfo = !this.showVolunteerInfo;
      }),
      toggleEventInfo: action(() => {
        this.showEventInfo = !this.showEventInfo;
      }),
      toggleOpportunitiesBox: action(() => {
        this.showOpportunities = !this.showOpportunities;
      }),
      verifyEmail: action((event) => {
        this.email = event.target.value;
        this.validEmail = FormConstants.REGEX.EMAIL.test(this.email);

        if (this.emailTimeout) {
          window.clearTimeout(this.emailTimeout);
        }

        if (this.email && this.validEmail) {
          this.isVerifyingEmail = true;
          this.emailTimeout = window.setTimeout(() => {
            // NOTE: on back end, create one function that does both
            const requests = [
              this.authApi.searchUsers(this.email, 1),
              this.programApi.findVolunteers({
                email: this.email,
                eventId: this.eventStore.eventId,
              }),
            ];

            Promise.all(requests)
              .then((data) => {
                this.isVerifyingEmail = false;
                const [users, volunteers] = data;
                const user = users ? users.result[0] || {} : {};
                const vol = volunteers ? volunteers.volunteers[0] || {} : {};

                if (vol.id) {
                  //Volunteer found
                  this.setVolunteerAttributes(vol);
                } else if (user.userId) {
                  //No volunteer found, but found user
                  this.setVolunteerAttributesFromUser(user);
                  this.existingUser = user;
                }
              })
              .catch(() => {
                this.isVerifyingEmail = false;
              });
          }, 300);
        }
      }),
      setGender: action((item) => {
        this.gender = item.name;
      }),
      setBirthDate: action((date, event) => {
        this.setDate(date, event, "birthDate");
      }),
      setBirthCountry: action((item) => {
        this.birthCountry = item.name;
      }),
      setLanguage: action((event) => {
        if (typeof event === "string") {
          this.language = event;
        } else {
          this.language = event.target.value;

          // do basic grepping
          if (this.language) {
            this.languageOptions = FormConstants.LANGUAGES.filter((i) =>
              i.name.toLowerCase().startsWith(this.language.toLowerCase())
            );
          }
        }
      }),
      setVolunteerLanguages: action((value) => {
        this.selectedLanguages = this.selectedLanguages.concat([value]);
      }),
      removeVolunteerLanguages: action((item) => {
        this.selectedLanguages = this.selectedLanguages.filter(
          (val) => val.key !== item.key
        );
      }),
      setSelectionDates: action((value) => {
        this.selectedDates = this.selectedDates.concat([value]);
      }),
      removeSelectionDates: action((item) => {
        this.selectedDates = this.selectedDates.filter(
          (val) => val.key !== item.key
        );
      }),
      setSelectedOpportunities: action((value) => {
        this.selectedOpportunities = this.selectedOpportunities.concat([value]);
      }),
      removeSelectedOpportunities: action((item) => {
        this.selectedOpportunities = this.selectedOpportunities.filter(
          (val) => val.key !== item.key
        );
      }),
      setCountry: action((item) => {
        this.country = item.name;
      }),
      setRegion: action((item) => {
        this.region = item.name;
      }),
      setAlumni: action(() => {
        this.alumni = !this.alumni;

        if (this.alumni) {
          this.attendedOnlineSessionDate = null;
          this.interviewScore = null;
        }
      }),
      setAttendedOrientation: action(() => {
        this.attendedOrientation = !this.attendedOrientation;
      }),
      setWaiverDate: action((date, event) => {
        this.setDate(date, event, "waiverDate");
      }),
      setOrientationDate: action((date, event) => {
        this.setDate(date, event, "orientationDate");
      }),
      setBackgroundDate: action((date, event) => {
        this.setDate(date, event, "backgroundDate");
      }),
      setWaiverBox: action(() => {
        this.waiverBox = !this.waiverBox;
      }),
      setAttendedOnlineSessionBox: action(() => {
        this.attendedOnlineSessionBox = !this.attendedOnlineSessionBox;
        if (!this.attendedOnlineSessionBox) {
          this.attendedOnlineSessionDate = null;
          this.interviewScore = null;
          this.completedInfoSessionVideo = false;
          this.passedInfoSessionQuestionnaire = false;
          this.failedReason = null;
        } else {
          this.attendedOnlineSessionDate = this.orientationDate
            ? this.orientationDate
            : new Date();
          this.completedInfoSessionVideo = true;
          this.passedInfoSessionQuestionnaire = true;
          this.failedReason = null;
        }
      }),
      setAttendedOnlineSessionDate: action((date, event) => {
        this.setDate(date, event, "attendedOnlineSessionDate");
      }),
      setOrientationBox: action(() => {
        this.orientationBox = !this.orientationBox;
      }),
      setBackgroundBox: action(() => {
        this.backgroundBox = !this.backgroundBox;
      }),
      setInterviewScore: action((item) => {
        this.interviewScore = item.value;

        if (this.interviewScore !== "Fail") {
          this.failedReasonValue = this.defaults.failedReasonValue;
        }

        if (!this.interviewScore) {
          this.setOrientationDate(null);
        }
      }),
      setPhotoStatus: action((item) => {
        this.photoStatus = item.value;
        this.photoInfoChanged = true;
      }),
      setBackgroundCheckScore: action((item) => {
        this.backgroundCheckScore = item.name;
      }),
      setStandbyTime: action((date, tod, selectAll) => {
        if (selectAll) {
          const val = this.standbySelections[date] || {};
          if (val.morning && val.afternoon && val.evening) {
            this.standbySelections = Object.assign({}, this.standbySelections, {
              [date]: {
                ...val,
                morning: false,
                afternoon: false,
                evening: false,
              },
            });
          } else {
            this.standbySelections = Object.assign({}, this.standbySelections, {
              [date]: { ...val, morning: true, afternoon: true, evening: true },
            });
          }
        } else {
          const val = this.standbySelections[date] || {};
          val[tod] = !val[tod];

          this.standbySelections = Object.assign({}, this.standbySelections, {
            [date]: val,
          });
        }
      }),
      setIsWarnOnDeleteOpen: action((value) => {
        this.isWarnOnDeleteOpen = value;
      }),
      addSelection: action((newShiftDate) => {
        const { shiftDate } = newShiftDate;
        let opportunityShiftId;
        const shiftTier = shiftDate.shiftTiers.find((st) => {
          return st.tierId === this.volunteer.assignedTierId;
        });
        if (!opportunityShiftId) {
          opportunityShiftId = shiftTier.opportunityShiftId;
        }
        const p = this.volunteer.selections.find((s) => {
          return s.opportunityShift.id === shiftTier.opportunityShiftId;
        });
        const opportunityShift = {
          id: opportunityShiftId,
          zone: {
            number: newShiftDate.zoneNumber,
            outside: newShiftDate.outside,
          },
        };
        this.selections.push(p || { opportunityShift });
      }),
      removeSelection: action((oppShiftId) => {
        this.selections = this.selections.filter(
          (s) => s.opportunityShift.id !== oppShiftId
        );
      }),
      updateAttendance: action((selectionId) => {
        const selection = this.selections.find(
          (s) => s.opportunityShift.id === selectionId
        );
        selection.attendedShift = !selection.attendedShift;
      }),
      addPhoneNumber: action(() => {
        this.phoneNumbersList.push({
          key: StringUtilities.random(),
          type: "",
          number: "",
        });
      }),
      removeNumber: action((idx) => {
        this.phoneNumbersList.splice(idx, 1);
        if (!this.phoneNumbersList.length) {
          this.addPhoneNumber();
        }
      }),
      setPhoneType: action((idx, item) => {
        this.phoneNumbersList[idx].type = item.name;
      }),
      setPhoneNumber: action((idx, event) => {
        this.phoneNumbersList[idx].number = event.target.value;
      }),
      setPhoneNumbersList: action((list) => {
        this.phoneNumbersList = list;
      }),
      clearVolunteerAttributes: action(() => {
        this.sortFilters = this.defaults.sortFilters;
        this.showEventInfo = this.defaults.showEventInfo;
        this.showVolunteerInfo = this.defaults.showVolunteerInfo;
        this.showOpportunities = this.defaults.showOpportunities;
        this.failedImports = this.defaults.failedImports;

        this.volunteer = this.defaults.volunteer;
        this.volunteerId = this.defaults.volunteerId;
        this.email = this.defaults.email;
        this.lastName = this.defaults.lastName;
        this.firstName = this.defaults.firstName;
        this.middleInitial = this.defaults.middleInitial;
        this.gender = this.defaults.gender;
        this.birthDate = this.defaults.birthDate;
        this.birthCountry = this.defaults.birthCountry;
        this.language = this.defaults.language;
        this.languageOptions = this.defaults.languageOptions;
        this.selectedDates = this.defaults.selectedDates;
        this.selectedLanguages = this.defaults.selectedLanguages;
        this.selectedOpportunities = this.defaults.selectedOpportunities;
        this.notes = this.defaults.notes;
        this.country = this.defaults.country;
        this.street1 = this.defaults.street1;
        this.street2 = this.defaults.street2;
        this.street3 = this.defaults.street3;
        this.city = this.defaults.city;
        this.region = this.defaults.region;
        this.zipCode = this.defaults.zipCode;
        this.alumni = this.defaults.alumni;
        this.attendedOrientation = this.defaults.attendedOrientation;
        this.waiverDate = this.defaults.waiverDate;
        this.backgroundCheckStarted = this.defaults.backgroundCheckStarted;
        this.photoSubmissionDate = this.defaults.photoSubmissionDate;
        this.orientationDate = this.defaults.orientationDate;
        this.backgroundDate = this.defaults.backgroundDate;
        this.waiverBox = this.defaults.waiverBox;
        this.photoUploaded = this.defaults.photoUploaded;
        this.photoBase64Encoded = this.defaults.photoBase64Encoded;
        this.photoStatus = this.defaults.photoStatus;
        this.photoInfoChanged = this.defaults.photoInfoChanged;
        this.newPhotoUploaded = this.defaults.newPhotoUploaded;
        this.photoId = this.defaults.photoId;
        this.backgroundBox = this.defaults.backgroundBox;
        this.interviewScore = this.defaults.interviewScore;
        this.interviewComments = this.defaults.interviewComments;
        this.backgroundCheckScore = this.defaults.backgroundCheckScore;
        this.backgroundCheckComments = this.defaults.backgroundCheckComments;
        this.selections = this.defaults.selections;
        this.standbySelections = this.defaults.standbySelections;
        this.phoneNumbersList = this.defaults.phoneNumbersList;
        this.existingUser = this.defaults.existingUser;
        this.selectedEmailTemplate = this.defaults.selectedEmailTemplate;
        this.selectedRejectionEmailTemplate =
          this.defaults.selectedRejectionEmailTemplate;
        this.emailAllSelected = this.defaults.emailAllSelected;
        this.emailNotPreviouslyReceivedSelected =
          this.defaults.emailNotPreviouslyReceivedSelected;
        this.showFailedReasonModal = this.defaults.showFailedReasonModal;
        this.failedReasonModalVolunteerId =
          this.defaults.failedReasonModalVolunteerId;
        this.failedReasonModalEvent = this.defaults.failedReasonModalEvent;
        this.failedReasonValue = this.defaults.failedReasonValue;
        this.modalFailedReasonValue = this.defaults.modalFailedReasonValue;
        this.generatePrefix = this.defaults.generatePrefix;
        this.generateNumVolunteers = this.defaults.generateNumVolunteers;

        this.currentSort = this.defaults.currentSort;
        this.filterDrawerOpen = this.defaults.filterDrawerOpen;
      }),
      setVolunteerAttributesFromUser: action((item) => {
        this.email = item.email;
        this.firstName = item.firstName;
        this.lastName = item.lastName;
        this.middleInitial = item.middleInitial;
        this.notes = item.notes;
        this.birthDate = DateUtilities.parseString(item.dob);
        this.gender = item.gender;
        this.zipCode = item.zipcode;
        this.region = item.state;
        this.city = item.city;
        this.country = item.country;
        this.birthCountry = item.countryOfBirth;
        this.selectedLanguages = item.languages
          ? FormConstants.LANGUAGES.filter(
              (l) => item.languages.indexOf(l.key) !== -1
            )
          : [];
        this.phoneNumbersList = (item.phoneNumbers || [])
          .sort((a, b) => a.id - b.id)
          .map((p) => ({ ...p, key: StringUtilities.random() }));
        if (!this.phoneNumbersList.length) {
          this.addPhoneNumber();
        }
        const [street1, street2, street3] = item.streetAddress.split(",");
        this.street1 = street1;
        this.street2 = street2 || "";
        this.street3 = street3 || "";
      }),
      setVolunteerAttributes: action((item) => {
        // clear expanders
        this.showEventInfo = this.defaults.showEventInfo;
        this.showVolunteerInfo = this.defaults.showVolunteerInfo;
        this.showOpportunities = this.defaults.showOpportunities;

        // set volunteer profile info
        this.volunteerId = item.id;
        this.email = item.email;
        this.firstName = item.firstName;
        this.lastName = item.lastName;
        this.middleInitial = item.middleInitial;
        this.notes = item.notes;
        this.birthDate = DateUtilities.parseString(item.dob);
        this.gender = item.gender;
        this.zipCode = item.zipcode;
        this.region = item.state;
        this.city = item.city;
        this.country = item.country;
        this.birthCountry = item.countryOfBirth;
        this.selectedLanguages = item.languages
          ? FormConstants.LANGUAGES.filter(
              (l) => item.languages.indexOf(l.key) !== -1
            )
          : [];
        this.phoneNumbersList = (item.phoneNumbers || [])
          .sort((a, b) => a.id - b.id)
          .map((p) => ({ ...p, key: StringUtilities.random() }));
        if (!this.phoneNumbersList.length) {
          this.addPhoneNumber();
        }

        this.street1 = "";
        this.street2 = "";
        this.street3 = "";

        if (item.streetAddress) {
          const [street1, street2, street3] = item.streetAddress?.split(",");
          this.street1 = street1;
          this.street2 = street2 || "";
          this.street3 = street3 || "";
        }

        // set the volunteer event status data
        this.alumni = item.priorExp;
        this.attendedOrientation = item.attendedOrientation;
        this.waiverBox = Boolean(item.acceptedWaiverDate);
        this.waiverDate = DateUtilities.parseString(item.acceptedWaiverDate);
        this.attendedOnlineSessionBox = Boolean(
          item.completedInfoSessionVideo && item.passedInfoSessionQuestionnaire
        );
        this.attendedOnlineSessionDate = DateUtilities.parseString(
          item.attendedOnlineSessionDate
        );
        (this.backgroundCheckStarted = Boolean(item.backgroundCheckStarted)),
          (this.backgroundBox = Boolean(item.backgroundCheckConsentDate));
        this.backgroundDate = DateUtilities.parseString(
          item.backgroundCheckConsentDate
        );

        if (item.backgroundCheckFailed === true) {
          this.backgroundCheckScore = "Fail";
        } else if (item.backgroundCheckFailed === false) {
          this.backgroundCheckScore = "Pass";
        } else {
          this.backgroundCheckScore = "N/A";
        }

        if (item.attendedOnlineSessionDate) {
          this.orientationDate = DateUtilities.parseString(
            item.attendedOnlineSessionDate
          );
          this.setupInterviewScore(this, item, "Pass - Online");
        } else {
          this.interviewScore = null;
        }

        this.selections = item.selections;

        this.failedReasonValue =
          FormConstants.FAILED_REASONS.find(
            (f) => f.value === item.failedReason
          ) || {};

        this.standbySelections = {};
        item.standbySelections.forEach((ss) => {
          this.standbySelections[moment(ss.date).format("YYYY-MM-DD")] = ss;
        });

        this.completedInfoSessionVideo = item.completedInfoSessionVideo;
        this.passedInfoSessionQuestionnaire =
          item.passedInfoSessionQuestionnaire;

        this.volunteer = item;
      }),
      setLoadingVolunteers: action((value) => {
        this.loadingVolunteers = value;
      }),
      setDisableSearchInput: action((value) => {
        this.disableSearchInput = value;
      }),
      setSearchingVolunteers: action((value) => {
        this.searchingVolunteers = value;
      }),
      setLoadingFailedImports: action((value) => {
        this.loadingFailedImports = value;
      }),
      setVolunteerFormDirty: action((value) => {
        this.volunteerFormDirty = value;
      }),
      setVolunteerTableDirty: action((value) => {
        this.volunteerTableDirty = value;
      }),
      setValidationErrorMessages: action((messages) => {
        this.validationErrorMessages = messages;
      }),
      clearValidationErrorMessages: action(() => {
        this.validationErrorMessages = [];
      }),
      toggleConfirmImportModal: action(() => {
        this.showConfirmImport = !this.showConfirmImport;
      }),
      toggleGenerateVolunteersModal: action(() => {
        this.showGenerateVolunteers = !this.showGenerateVolunteers;

        if (!this.showGenerateVolunteers) {
          this.generateNumVolunteers = this.defaults.generateNumVolunteers;
          this.generatePrefix = this.defaults.generatePrefix;
          this.generateTwoTiers = this.defaults.generateTwoTiers;
        }
      }),
      setGenerateTwoTiers: action(() => {
        this.generateTwoTiers = !this.generateTwoTiers;
      }),
      generateVolunteers: action((eventId) => {
        this.programApi
          .generateTestVolunteers(eventId, {
            prefix: this.generatePrefix,
            numUsers: this.generateNumVolunteers,
            twoTiers: this.generateTwoTiers,
          })
          .then(() => {
            this.showGenerateVolunteers = false;
            this.generatePrefix = this.defaults.generatePrefix;
            this.generateNumVolunteers = this.defaults.generateNumVolunteers;
            this.generateTwoTiers = this.defaults.generateTwoTiers;
            this.notificationStore.setMessage("Generating Volunteers");
          })
          .catch(() => {
            this.notificationStore.setMessage(
              "Error Generating Volunteers",
              NotificationConstants.ERROR
            );
          });
      }),
      toggleFailedReasonModal: action(() => {
        this.showFailedReasonModal = !this.showFailedReasonModal;
        this.modalFailedReasonValue = this.defaults.modalFailedReasonValue;
      }),
      importVolunteers: action((eventId) => {
        this.toggleConfirmImportModal();
        this.importApi
          .importVolunteers(eventId)
          .then(() => {
            this.loadFailedImports(eventId);
            this.notificationStore.setMessage("Accepted");
          })
          .catch(() => {
            this.notificationStore.setMessage(
              "Uh-oh",
              NotificationConstants.ERROR
            );
          });
      }),
      setVolunteerPhoto: action((files) => {
        this.newPhotoUploaded = true;
        this.setPhotoStatus({
          key: "Approved",
          name: "Approved",
          value: "Approved",
        });

        this.fileReader.onloadend = () => {
          this.photoBase64Encoded = this.fileReader.result;
        };
        this.fileReader.readAsDataURL(files[0]);
      }),
      sendToAccessBadges: action((volunteerId) => {
        this.programApi
          .sendPhotoToAccessBadge(volunteerId)
          .then(() => {
            this.notificationStore.setMessage("Photo sent to Access Badges");
          })
          .catch(() => {
            this.notificationStore.setMessage(
              "Photo was not sent because the volunteer has no opportunity selections",
              NotificationConstants.ERROR
            );
          });
      }),
      loadVolunteer: action((volunteerId, eventId) => {
        this.programApi
          .loadVolunteer(volunteerId, eventId)
          .then((volunteer) => {
            this.failedVolunteerLoad = false;
            this.setVolunteerAttributes(volunteer);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e);
            this.failedVolunteerLoad = true;
          });
      }),
      saveVolunteer: action(
        (eventId, blockingCallback, isVolunteerSave, isRejectPhotoSave) => {
          const scores = this.reduceScores();

          const volunteer = {
            // profile information
            eventId,
            id: this.volunteerId,
            email: this.email,
            userId: this.volunteer.userId || this.existingUser.userId,
            firstName: this.firstName,
            lastName: this.lastName,
            streetAddress: [this.street1, this.street2, this.street3]
              .join(", ")
              .trim()
              .replace(/,+$/gi, ""),
            city: this.city,
            state: this.region,
            country: this.country,
            zipcode: this.zipCode,
            countryOfBirth: this.birthCountry,
            gender: this.gender,
            dob: this.birthDate,
            priorExp: this.alumni || this.existingUser.priorExp,
            attendedOrientation: this.attendedOrientation,
            notes: this.notes,
            languages: this.selectedLanguages.map((i) => i.key).join(","),
            middleInitial: this.middleInitial,
            phoneNumbers: this.phoneNumbersList.filter(
              (p) => p.type && p.number
            ),

            // event information
            acceptedWaiverDate: this.waiverBox ? this.waiverDate : null,
            attendedOnlineSessionDate: this.attendedOnlineSessionBox
              ? this.attendedOnlineSessionDate
              : null,
            backgroundCheckConsentDate: this.backgroundBox
              ? this.backgroundDate
              : null,
            backgroundCheckFailed: null,
            backgroundCheckStarted: this.backgroundCheckStarted,
            selections: this.selections,
            standbySelections: Object.keys(this.standbySelections).map((k) => {
              return { ...this.standbySelections[k], date: k };
            }),
            failedReason: this.failedReasonValue.value,
            completedInfoSessionVideo: this.completedInfoSessionVideo,
            passedInfoSessionQuestionnaire: this.passedInfoSessionQuestionnaire,
          };

          if (this.photoInfoChanged) {
            volunteer.photoId = this.photoId;
            volunteer.photoStatus = this.photoStatus.toUpperCase();
            volunteer.photoRejectionReason =
              this.photoStatus.toUpperCase() === "REJECTED"
                ? this.selectedRejectionReason.value
                : null;
          }

          if (this.newPhotoUploaded) {
            volunteer.photo = this.photoBase64Encoded;
          }

          if (this.backgroundCheckScore === "Fail") {
            volunteer.backgroundCheckFailed = true;
          } else if (this.backgroundCheckScore === "Pass") {
            volunteer.backgroundCheckFailed = false;
          }

          if (this.interviewScore && scores[this.interviewScore]) {
            const interviewScore = this.interviewScore;
            volunteer.interviewScore =
              scores[interviewScore].indexOf("Pass") > -1
                ? "Pass"
                : scores[interviewScore];

            if (volunteer.interviewScore === "Pass") {
              volunteer.attendedOnlineSessionDate =
                this.orientationDate || new Date();
            }
          }

          this.savingVolunteer = true;
          const requests = [];
          if (this.newPhotoUploaded) {
            requests.push(
              this.programApi.adminSubmitVolunteerPhoto(
                this.volunteerId,
                this.photoBase64Encoded
              )
            );
          }
          const promise = this.volunteerId
            ? this.programApi.updateVolunteer(volunteer, eventId)
            : this.programApi.createVolunteer(volunteer, eventId);
          requests.push(promise);

          return Promise.all(requests)
            .then(() => {
              this.savingVolunteer = false;
              this.isNewVolunteerOpen = false;
              this.newPhotoUploaded = false;
              if (blockingCallback) {
                blockingCallback();
              } else {
                this.blockingStore.clearAttributes(); // allow push to go through
                this.routerStore.history.goBack();
              }

              if (!isVolunteerSave && !isRejectPhotoSave) {
                this.notificationStore.setMessage(
                  this.i18n.t("volunteer.notification.saved")
                );
              } else if (isRejectPhotoSave && !isVolunteerSave) {
                this.notificationStore.setMessage(
                  "Email sent and volunteer saved"
                );
              }

              // need this to land back on volunteers tab otherwise VolunteerDetails Redirect gets hit
              setTimeout(() => {
                const previousSort = this.currentSort; // preserve sort
                this.clearVolunteerAttributes();
                this.currentSort = previousSort;
                // TODO: Reload event because volunteer-tier counts could have changed
                this.eventStore.loadEvent(eventStore.eventId);
              }, 500);
            })
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.error(e);
              this.savingVolunteer = false;
              this.setValidationErrorMessages(e.message.split("|"));
            });
        }
      ),
      saveVolunteerFromList: action((eventId, volunteer) => {
        const scores = this.reduceScores();

        if (
          volunteer &&
          volunteer.interviewScore &&
          scores[volunteer.interviewScore]
        ) {
          const interviewScore = volunteer.interviewScore;
          volunteer.interviewScore =
            scores[interviewScore].indexOf("Pass") > -1
              ? "Pass"
              : scores[interviewScore];

          if (scores[interviewScore].indexOf("Pass") > -1) {
            volunteer.attendedOnlineSessionDate =
              volunteer.attendedOnlineSessionDate || new Date();
          } else {
            volunteer.attendedOnlineSessionDate = new Date();
          }
        } else {
          volunteer.attendedOnlineSessionDate = null;
        }

        this.savingVolunteer = true;
        return this.programApi
          .updateVolunteer(volunteer, eventId)
          .then(() => {
            this.savingVolunteer = false;
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e);
            this.savingVolunteer = false;
            this.setValidationErrorMessages(e.message.split("|"));
          });
      }),
      pendingDeleteVolunteer: action((volunteer) => {
        this.volunteers = this.volunteers.filter((v) => {
          if (v.id === volunteer.id) {
            this.volunteersPendingDelete.push(volunteer);
            return false;
          }
          return true;
        });
      }),
      deleteVolunteer: action(() => {
        this.programApi
          .deleteVolunteer(this.volunteerId, eventStore.eventId)
          .then(() => {
            this.blockingStore.clearAttributes(); // allow push to go through
            this.notificationStore.setMessage(
              this.i18n.t("volunteer.notification.deleted")
            );
            this.clearVolunteerAttributes();
            this.loadVolunteersForEvent(
              eventStore.eventId,
              this.currentVolunteersPage
            );
          })
          .catch(() => {
            this.notificationStore.setMessage(
              this.i18n.t("volunteer.notification.notDeleted"),
              NotificationConstants.ERROR
            );
          });
      }),
      deletePendingVolunteers: action(() => {
        if (
          this.volunteersPendingDelete &&
          this.volunteersPendingDelete.length > 0
        ) {
          return this.programApi
            .deleteVolunteer(
              this.volunteersPendingDelete.map((v) => v.id),
              eventStore.eventId
            )
            .then(() => {
              this.clearVolunteerAttributes();
              this.loadVolunteersForEvent(
                eventStore.eventId,
                this.currentVolunteersPage
              );
            })
            .catch(() => {
              this.notificationStore.setMessage(
                this.i18n.t("volunteer.notification.bulkDeleteError"),
                NotificationConstants.WARNING
              );
            });
        }
      }),
      loadVolunteersForEvent: action((eventId, page, sorted) => {
        if (!this.loadingVolunteers) {
          this.setLoadingVolunteers(true);
          // this.loadingVolunteers = true;
          this.setDisableSearchInput(true);
          this.searchVolunteers = "";
          return this.programApi
            .loadVolunteersForEvent(
              eventId,
              this.volunteersPageSize,
              page,
              sorted
            )
            .then((data) => {
              this.setDisableSearchInput(false);
              this.setVolunteers(
                data
                  ? data.volunteers.map((volunteer) => {
                      if (volunteer.attendedOnlineSessionDate) {
                        this.setupInterviewScore(
                          volunteer,
                          volunteer,
                          "Pass - Online"
                        );
                      }
                      return volunteer;
                    })
                  : []
              );
              this.sortFilters.initial = this.volunteers;
              this.currentVolunteersPage = page;
              this.setLoadingVolunteers(false);

              if (data) {
                this.totalVolunteers = data.totalVolunteers;
                this.numVolunteerPages = Math.ceil(
                  this.totalVolunteers / this.volunteersPageSize
                );
              }

              this.volunteers.forEach((volunteer) => {
                const { backgroundCheckConsentDate } = volunteer;
                this.consentBoxes[volunteer.id] = Boolean(
                  backgroundCheckConsentDate
                );
              });
            });
        }
      }),
      loadFailedImports: action((eventId) => {
        if (!this.loadingFailedImports) {
          this.setLoadingFailedImports(true);
          this.importApi.loadFailedImports(eventId).then((data) => {
            this.setLoadingFailedImports(false);
            if (data) {
              this.totalImport = data.totalImport;
              this.totalImportRejected = data.duplicate;
              this.totalImportAccepted =
                this.totalImport - this.totalImportRejected;
              this.failedImports = data.events.filter(
                (e) => e.type !== "disallowed-country"
              );
              this.totalImportNotProcessed = this.failedImports.filter(
                (e) => e.processed === "N"
              ).length;
              this.failedImports.forEach((e) => {
                e.allFields =
                  e.email +
                  " " +
                  e.firstName +
                  " " +
                  e.lastName +
                  " " +
                  e.newEmail;
              });
            }
          });
        }
      }),
      ImportResultFilterDropDownChanged: action((selected, filterText) => {
        this.importResultFilter = selected;
        this.importResultFilterDropDownValue = selected.value;
        this.importResultFilterTextBoxValue = filterText;
        this.importResultTableFilter = [
          {
            id: "processed",
            value: selected.value.trim(),
          },
          {
            id: "allFields",
            value: filterText.trim(),
          },
        ];
      }),
      ImportResultFilterTextBoxChanged: action((dropDownValue, filterText) => {
        this.importResultFilterTextBoxValue = filterText;
        this.importResultTableFilter = [
          {
            id: "processed",
            value: dropDownValue.trim(),
          },
          {
            id: "allFields",
            value: filterText.trim(),
          },
        ];
      }),
      pasteCurrentEmail: action((current) => {
        this.failedImports = this.failedImports.map((f) => {
          if (f.id === current.id) {
            f.newEmail = current.email;
          }
          return f;
        });
      }),
      assignNewEmail: action((current, newEmail) => {
        this.failedImports = this.failedImports.map((f) => {
          if (f.id === current.id) {
            f.newEmail = newEmail;
          }
          return f;
        });
      }),
      exportVolunteers: action(() => {
        const { id: eventId } = this.eventStore.event || {};
        const standby = new Set(["all", "none", "selected"]);
        const filters = this.searchFilters();
        filters.onlyNonUS = false;
        // create standby query params
        filters.standby = null;

        if (this.volunteerFilters.standby.value) {
          if (standby.has(this.volunteerFilters.standby.value)) {
            filters.standbyDate = DateUtilities.dateToString(
              new Date(),
              "YYYY-MM-DD"
            );
            filters.standbyTod = this.volunteerFilters.standby.value;
          } else {
            filters.standbyDate = this.volunteerFilters.standby.value.date;
            filters.standbyTod = this.volunteerFilters.standby.value.tod;
          }
        }

        const params = Object.keys(filters)
          .filter((k) => filters[k] !== null && filters[k] !== undefined)
          .map((k) => `${k}=${filters[k]}`)
          .join("&")
          .concat(`&eventId=${eventId}`);

        this.exporting = true;
        this.programApi
          .exportVolunteers(eventId, params)
          .then((binary) => {
            this.exporting = false;
            const date = DateUtilities.utcString();
            const formattedDate = date
              .replace("+00:00", "")
              .replace(/:+/g, ".");

            const blob = new Blob([binary], {
              type: "application/vnd.ms-excel",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.id = "volunteer-download";
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = `volunteerExport_${formattedDate}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
            const m = document.getElementById("volunteer-download");
            m.remove();
          })
          .catch(() => {
            this.exporting = false;
          });
      }),
      uploadImportExceptions: action((files) => {
        this.uploadingImportExceptions = true;
        this.fileReader.onloadend = () => {
          const regex = new RegExp('"|\\r', "g");
          const lines = this.fileReader.result.replace(regex, "").split("\n");
          lines.forEach((line) => {
            const emails = line.split(",");
            this.importExceptions.push({
              importExceptionPkey: {
                eventId: this.eventStore.eventId,
                oldEmail: emails[0],
              },
              newEmail: emails[1],
            });
          });
          this.programApi
            .uploadImportExceptions(
              this.importExceptions,
              this.eventStore.eventId
            )
            .then(() => {
              this.uploadingImportExceptions = false;
            });
        };
        this.fileReader.readAsText(files[0]);
      }),
      doFilter: action(() => {
        if (!this.areFiltersApplied()) {
          this.setSearchingVolunteers(true);
          this.loadVolunteersForEvent(
            eventStore.eventId,
            this.currentVolunteersPage,
            this.currentSort
          ).then(() => {
            this.filteredTotalVolunteers = null;
            this.setSearchingVolunteers(false);
          });
        } else {
          // filtered down already
          this.setLoadingVolunteers(true);
          this.search(this.currentVolunteersPage, this.currentSort);
        }
      }),
      setFilter: action((item, filter, multi) => {
        if (multi) {
          this.volunteerFilters[filter].push(item);
        } else {
          this.volunteerFilters[filter] = item;
        }
        this.doFilter();
      }),
      removeFilterItem: action((item, filter) => {
        this.volunteerFilters[filter] = this.volunteerFilters[filter].filter(
          (val) => val.key !== item.key
        );
        this.doFilter();
      }),
      getTierFilterOptions: action(() => {
        const tiers = [];
        this.eventStore.tierKeys.map((k) => {
          tiers.push({
            key: this.eventStore.tiers[k].id,
            value: this.eventStore.tiers[k].number,
            name: `${this.eventStore.tiers[k].number}`,
          });
        });
        return [FormConstants.VOLUNTEER_FILTERS.NONE, ...tiers];
      }),
      getStandbyFilterOptions: action(() => {
        let dates = [];
        this.eventInfoStore.eventDateRange.map((d) => {
          dates = dates.concat([
            {
              key: d + "-M",
              value: {
                date: d,
                tod: "Morning",
              },
              name: `${moment(d, "YYYY-MM-DD")
                .format("ddd, M/D")
                .replace(",", "")} - Morning`,
            },
            {
              key: d + "-A",
              value: {
                date: d,
                tod: "Afternoon",
              },
              name: `${moment(d, "YYYY-MM-DD")
                .format("ddd, M/D")
                .replace(",", "")} - Afternoon`,
            },
            {
              key: d + "-E",
              value: {
                date: d,
                tod: "Evening",
              },
              name: `${moment(d, "YYYY-MM-DD")
                .format("ddd, M/D")
                .replace(",", "")} - Evening`,
            },
          ]);
        });
        return [
          FormConstants.VOLUNTEER_FILTERS.SELECTED,
          FormConstants.VOLUNTEER_FILTERS.NONE,
          ...dates,
        ];
      }),
      setFailedReasonValue: action((item) => {
        this.failedReasonValue = item;
      }),
      setModalFailedReasonValue: action((item) => {
        this.modalFailedReasonValue = item;
      }),
      downloadStatSheet: action((volunteerId) => {
        if (!this.downloadingReport) {
          this.setDownloadingReport(true);
          this.programApi
            .downloadStatSheet(this.eventStore.eventId, volunteerId)
            .then((binary) => {
              const blob = new Blob([binary], { type: "application/pdf" });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.id = "stat-sheet-download";
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `${
                this.firstName ? this.firstName + "_" + this.lastName : ""
              }DetailReport.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              const m = document.getElementById("stat-sheet-download");
              m.remove();
              this.setDownloadingReport(false);
            });
        }
      }),
      downloadVolunteerAlumniCertificate: action((volunteerId) => {
        if (!this.downloadingReport) {
          this.setDownloadingReport(true);
          this.programApi
            .downloadAlumniCertificate(this.eventStore.eventId, volunteerId)
            .then((binary) => {
              const blob = new Blob([binary], { type: "application/pdf" });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              const id = "alumni-certificate-download";
              a.id = id;
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `${
                this.firstName ? this.firstName + "_" + this.lastName : ""
              }_AlumniCertificate.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              const m = document.getElementById(id);
              m.remove();
              this.setDownloadingReport(false);
            });
        }
      }),
      downloadWaiver: action((volunteerId) => {
        if (!this.downloadingWaiver) {
          this.setDownloadingWaiver(true);
          this.programApi
            .downloadWaiver(this.eventStore.eventId, volunteerId)
            .then((binary) => {
              const blob = new Blob([binary], { type: "application/pdf" });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.id = "volunteer-waiver-download";
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = url;
              a.download = `${
                this.firstName ? this.firstName + "_" + this.lastName : ""
              }_Waiver.pdf`;
              a.click();
              window.URL.revokeObjectURL(url);
              const m = document.getElementById("volunteer-waiver-download");
              m.remove();
              this.setDownloadingWaiver(false);
            });
        }
      }),
      downloadVolunteerDetailsReports: action(() => {
        if (this.selectAllOverride) {
          this.programApi.downloadAllVolunteerDetailsReports({
            eventId: this.eventStore.eventId,
            filters: { ...this.searchFilters() },
          });
        } else {
          this.programApi.downloadVolunteerDetailsReports({
            eventId: this.eventStore.eventId,
            volunteerIds: this.selectedVolunteers,
          });
        }
        const numReports = this.selectAllOverride
          ? this.totalVolunteers
          : this.selectedVolunteers.length;
        notificationStore.setMessage("Generating " + numReports + " reports");
      }),
      setDownloadingReport: action((value) => {
        this.downloadingReport = value;
      }),
      setDownloadingWaiver: action((value) => {
        this.downloadingWaiver = value;
      }),
      loadVolunteerPhoto: action((volunteerId) => {
        this.programApi
          .loadVolunteerPhoto(volunteerId)
          .then((data) => {
            this.volunteerPhoto = data;
            this.photoBase64Encoded = data.base64EncodedVal;
            this.photoSubmissionDate = DateUtilities.parseString(
              moment(data.createdTs).format("YYYY-MM-DD")
            );
            const tempStatus = data.status.toLowerCase();
            this.photoStatus = tempStatus.replace(/^\w/, (c) =>
              c.toUpperCase()
            );
            this.photoInfoChanged = false;
            this.photoId = data.id;
            this.selectedRejectionReason = data.rejectionReason
              ? FormConstants.PHOTO_REJECTION_REASONS.find(
                  (r) => r.value === data.rejectionReason
                )
              : FormConstants.PHOTO_REJECTION_REASONS[0];
            this.photoUploaded = Boolean(data.createdTs);
          })
          .catch(() => {
            this.volunteerPhoto = null;
            this.photoBase64Encoded = "";
            this.photoUploaded = false;
            this.newPhotoUploaded = false;
            this.photoSubmissionDate = null;
            this.photoStatus = null;
            this.photoInfoChanged = false;
            this.photoId = null;
          });
      }),
      closeRejectionEmailModal: action(() => {
        this.isRejectionEmailModalOpen = false;
      }),
      openRejectionEmailModal: action(() => {
        this.isRejectionEmailModalOpen = true;
      }),
      setSelectedRejectionReason: action((reason) => {
        this.selectedRejectionReason = reason;
      }),
      setSelectedRejectionEmailTemplate: action((template) => {
        this.selectedRejectionEmailTemplate = template;
      }),
      sendRejectionEmail: action(() => {
        this.sendingRejectionEmail = true;
        this.emailApi
          .sendEmailsWithTemplate(this.selectedRejectionEmailTemplate.value, {
            emailInfos: [
              {
                toAddresses: [this.email],
                placeholders: [
                  { key: "first_name", value: this.firstName },
                  { key: "email", value: this.email.toLowerCase() },
                  { key: "password", value: "" },
                  {
                    key: "unsubscribe_address",
                    value: this.contactEmailAddress,
                  },
                  {
                    key: "rejection_reason",
                    value: this.selectedRejectionReason.name,
                  },
                ],
              },
            ],
          })
          .then(() => {
            this.sendingRejectionEmail = false;
            this.closeRejectionEmailModal();
            this.saveVolunteer(
              this.eventStore.eventId,
              null /* blockingCallback  */,
              false /* isVolunteerSave   */,
              true /* isRejectPhotoSave */
            );
          });
      }),
      toggleFilterDrawer: action(() => {
        this.filterDrawerOpen = !this.filterDrawerOpen;
      }),
      resetFilters: action(() => {
        this.volunteerFilters = this.defaults.volunteerFilters;
        this.doFilter();
      }),
    });

    // load all volunteers for event autorun
    autorun(() => {
      const params = routerStore.getPathParams(RouteConstants.EDIT_EVENT);

      if (
        this.routerStore.isActive(RouteConstants.EDIT_EVENT) &&
        this.authStore.currentUserId &&
        this.authStore.isAdmin &&
        params.eventId
      ) {
        this.setVolunteerFormDirty(false); // should start not dirty
        this.setVolunteerTableDirty(false);
        this.loadVolunteersForEvent(params.eventId, 0);
        this.loadFailedImports(params.eventId);
      }
    });
  }

  reduceScores = () => {
    return FormConstants.SCORES.reduce(
      (p, s) => ({
        ...p,
        [s.name]: s.value,
      }),
      {}
    );
  };

  setupInterviewScore = (obj, volunteer, passType) => {
    obj.interviewScore = volunteer.interviewScore
      ? volunteer.interviewScore.indexOf("Pass") > -1
        ? passType
        : volunteer.interviewScore
      : null;
  };

  isRowSelected = (volunteerId) =>
    this.selectAllOverride || this.selectedVolunteersSet.has(volunteerId);

  strip = (arg) => arg.toLowerCase().trim();

  filterValue = (filter) => {
    let value = null;
    if (filter.key === FormConstants.VOLUNTEER_FILTERS.YES.key) {
      value = true;
    } else if (filter.key === FormConstants.VOLUNTEER_FILTERS.NO.key) {
      value = false;
    }
    return value;
  };

  areFiltersApplied = () => {
    let filtersApplied = false;
    for (const key in this.volunteerFilters) {
      const filter = this.volunteerFilters[key];
      if (
        (!isObservableArray(filter) &&
          filter.key !== FormConstants.VOLUNTEER_FILTERS.ALL.key &&
          filter.key !== FormConstants.VOLUNTEER_FILTERS.ANY.key &&
          filter !== "") ||
        (isObservableArray(filter) && filter.length > 0)
      ) {
        filtersApplied = true;
      }
    }
    return this.searchVolunteers || filtersApplied;
  };

  searchFilters = () => {
    const standby = new Set(["all", "none", "selected"]);

    return {
      searchText: this.searchVolunteers,
      waiver: this.filterValue(this.volunteerFilters.waiver),
      alumni: this.filterValue(this.volunteerFilters.alumni),
      score: this.volunteerFilters.score.map((item) => item.key),
      consent: this.volunteerFilters.consent.map((item) => item.key),
      tier: this.volunteerFilters.tier.map((item) => item.key),
      infoSession: this.volunteerFilters.infoSession.map((item) => item.key),
      opportunity: this.filterValue(this.volunteerFilters.opportunity),
      orientation: this.filterValue(this.volunteerFilters.orientation),
      standby: standby.has(this.volunteerFilters.standby.value)
        ? {
            date: DateUtilities.dateToString(new Date(), "YYYY-MM-DD"),
            tod: this.volunteerFilters.standby.value,
          }
        : this.volunteerFilters.standby.value,
      shiftsAttended: this.volunteerFilters.shiftsAttended.map(
        (item) => item.key
      ),
      photo: this.volunteerFilters.photo.map((item) =>
        item.value.toUpperCase()
      ),
    };
  };

  getSelectedFilters = (filters) => {
    return filters.toJS();
  };

  findVolunteerById = (id) => {
    const match = this.volunteers.filter((v) => v.id === id)[0];
    return match ? match : null;
  };

  get volunteerRows() {
    return this.gridStore.sortWithFilters(
      this.sortFilters,
      this.volunteers.toJS()
    );
  }

  get isEverythingSelected() {
    const len = this.selectedVolunteers.length;
    return (
      this.selectAllOverride ||
      (len > 0 &&
        len ===
          (!this.searchVolunteers
            ? this.totalVolunteers
            : this.filteredTotalVolunteers))
    );
  }

  get isNothingSelected() {
    const len = this.selectedVolunteers.length;
    return len === 0;
  }

  get volunteerLanguages() {
    return this.selectedLanguages.toJS();
  }

  get eventDates() {
    return this.selectedDates.toJS();
  }

  get opportunityNames() {
    return this.selectedOpportunities.toJS();
  }

  get availableLanguages() {
    return this.languageOptions.toJS();
  }

  get phoneNumbers() {
    return this.phoneNumbersList.toJS();
  }

  get isVolunteerSaveDisabled() {
    const numbers = this.phoneNumbersList;
    const badNumbers = numbers.some((p) => !p.type || !p.number);
    const p = numbers.length === 1 ? numbers[0] : null;
    const badNumber = p
      ? (Boolean(p.type) && !p.number) || (!p.type && Boolean(p.number))
      : false;

    return (
      !this.volunteerFormDirty ||
      !this.email ||
      !this.lastName ||
      !this.firstName ||
      !this.birthDate ||
      !this.country ||
      !this.city ||
      !this.zipCode ||
      (p ? badNumber : badNumbers) ||
      (this.waiverBox && !this.waiverDate) ||
      (this.attendedOnlineSessionBox && !this.attendedOnlineSessionDate) ||
      (this.backgroundBox && !this.backgroundDate) ||
      (this.interviewScore === "Fail" && !this.failedReasonValue.value) ||
      (this.attendedOnlineSessionDate &&
        this.interviewScore &&
        !this.orientationDate)
    );
  }

  get isSendEmailDisabled() {
    return (
      this.selectedEmailTemplate === null ||
      (!this.emailAllSelected && !this.emailNotPreviouslyReceivedSelected)
    );
  }

  get isSendRejectionEmailButtonDisabled() {
    return (
      this.selectedRejectionEmailTemplate === null || this.sendingRejectionEmail
    );
  }
}

decorate(VolunteerStore, {
  eventDates: computed,
  volunteerRows: computed,
  opportunityNames: computed,
  isVolunteerSaveDisabled: computed,
  isSendEmailDisabled: computed,
  isSendRejectionEmailButtonDisabled: computed,
});

import AbstractApi from "./AbstractApi";

export default class EmailApi extends AbstractApi {
  constructor() {
    super();
  }

  loadInfo = () => this.get("actuator/info");

  sendEmailsWithTemplate = (templateId, data) =>
    this.post(`${templateId}/send`, data);

  sendContactUsEmail = data => this.post("contactUs", data);

  previewEmail = data => this.post("preview", data);

  getAmbassadorPrefix() {
    return "email";
  }

  getLocalPort(){
    return "7701";
  }
}

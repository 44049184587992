import { computed, decorate, extendObservable, action } from "mobx";
import { NotificationConstants } from "../constants/NotificationConstants";
import { FormConstants } from "../constants/FormConstants";
import { StoreUtilities } from "../utilities/StoreUtilities";

class ResetStore {
  constructor(authApi, notificationStore, i18n) {
    this.authApi = authApi;
    this.notificationStore = notificationStore;
    this.i18n = i18n;

    this.defaults = {
      password: "",
      confirm: "",
      showPassword: false,
      email: "",
      showEmail: false,
      emailSent: false,
      confirmationCode: "",
      codeEntered: false,
      invalidCode: false,
      currentPassword: "",
      confirmPassword: false
    };

    extendObservable(this, {
      ...StoreUtilities.initialize(this),
      clearResetAttributes: action(() => {
        this.password = this.defaults.password;
        this.confirm = this.defaults.confirm;
        this.showPassword = this.defaults.showPassword;
        this.email = this.defaults.email;
        this.showEmail = this.defaults.showEmail;
        this.emailSent = this.defaults.emailSent;
        this.confirmationCode = this.defaults.confirmationCode;
        this.codeEntered = this.defaults.codeEntered;
        this.invalidCode = this.defaults.invalidCode;
        this.currentPassword = this.defaults.currentPassword;
        this.confirmPassword = this.defaults.confirmPassword;
      }),
      toggleShowEmailModal: action(() => {
        this.showEmail = !this.showEmail;
      }),
      toggleCodeEntered: action(() => {
        this.codeEntered = !this.codeEntered;
      }),
      toggleShowPassword: action(() => {
        this.showPassword = !this.showPassword;
      }),
      sendResetEmail: action(resend => {
        this.authApi.forgotPassword(this.email).then(() => {
          this.emailSent = true;
          if (resend) {
            this.notificationStore.setMessage(
              this.i18n.t("login.notification.confirmationCodeSent"),
              NotificationConstants.SUCCESS
            );
          }
        });
      }),
      processCode: action(() => {
        this.authApi
          .confirmForgotPasswordCode({
            code: this.confirmationCode,
            email: this.email.toLowerCase(),
            password: this.password
          })
          .then(result => {
            if (result.success) {
              this.notificationStore.setMessage(
                this.i18n.t("login.notification.success"),
                NotificationConstants.SUCCESS
              );
              this.showEmail = false;
            } else {
              this.codeEntered = false;
              this.invalidCode = true;
            }
          });
      })
    });
  }

  get isEmailValidated() {
    return FormConstants.REGEX.EMAIL.test(this.email);
  }

  /** Password length must be >= 8 */
  get lengthValidation() {
    return this.password.length >= 8;
  }

  /** Password must contain at least 1 number */
  get numberValidation() {
    return Boolean(/[0-9]+/g.exec(this.password));
  }

  /** Password must contain at least 1 uppercase letter */
  get caseValidation() {
    return Boolean(/[A-Z]+/g.exec(this.password));
  }

  /** Password must contain at least 1 char in '@,#,$' */
  get specialValidation() {
    return Boolean(/[@#$]+/g.exec(this.password));
  }

  /** Password must match confirm */
  get matchValidation() {
    return this.password === this.confirm;
  }

  /** When all conditions are met, this returns true */
  get isValidated() {
    return (
      this.lengthValidation &&
      this.numberValidation &&
      this.caseValidation &&
      this.specialValidation &&
      this.matchValidation
    );
  }
}

decorate(ResetStore, {
  lengthValidation: computed,
  numberValidation: computed,
  caseValidation: computed,
  specialValidation: computed,
  matchValidation: computed,
  isValidated: computed,
  isEmailValidated: computed
});

export default ResetStore;

import AbstractApi from "./AbstractApi";

export default class ProgramApi extends AbstractApi {
  constructor() {
    super();
  }

  loadInfo = () => this.get("actuator/info");

  loadEventsForUser = () => this.get("event");

  loadAllEvents = () => this.get("event");

  loadEvent = (eventId) => this.get(`event/${eventId}`);

  loadHistoricalEventsForUser = (email) =>
    this.get(`event/historical?volunteerEmail=${email}`);

  loadPrograms = () => this.get("program");

  loadProgram = (programId) => this.get(`program/${programId}`);

  loadVolunteersForEvent = (eventId, limit, page, sorted) => {
    if (sorted) {
      sorted = sorted[0]; // TODO: Currently only allowing sort by 1 column at a time
      if (
        sorted.id === "acceptedWaiverDate" ||
        sorted.id === "backgroundCheckConsentDate"
      ) {
        sorted.desc = !sorted.desc;
      }
      return this.get(
        `event/${eventId}/volunteers?limit=${limit}&page=${page || 0}&sort=${
          sorted.id
        }&direction=${sorted.desc === true ? "DESC" : "ASC"}`
      );
    } else {
      return this.get(
        `event/${eventId}/volunteers?limit=${limit}&page=${page || 0}`
      );
    }
  };

  loadOpportunitiesForEvent = (eventId) =>
    this.get(`event/${eventId}/opportunities`);

  loadEmailsForEvent = (eventId) => this.get(`event/${eventId}/emailTemplates`);

  createProgram = (program) => this.post("program", program);

  updateProgram = (program) => this.put(`program/${program.id}`, program);

  deleteProgram = (programId) => this.delete(`program/${programId}`);

  findEvent = (name) => this.get(`event?title=${name}`);

  createEvent = (event) => this.post("event", event);

  updateEvent = (event) => {
    if (
      event.virtualInfoSession.videoFile ||
      event.virtualInfoSession.videoFileSpanish
    ) {
      // First we need to update the event. If we do this after we upload a video
      // we overwrite the video location due to the nature of the Event object.
      // Second we upload the video and the backend persists the correct
      // video location. The backend does not store the videoFile object.
      // Third we query for the event to retrieve all the updated information.
      return this.put(`event/${event.id}`, event).then(() => {
        return Promise.all([
          this.uploadVideoForEvent(event, "en"),
          this.uploadVideoForEvent(event, "es"),
        ]).then(() => {
          return this.loadEvent(event.id);
        });
      });
    } else {
      return this.put(`event/${event.id}`, event);
    }
  };

  deleteEvent = (eventId) => this.delete(`event/${eventId}`);

  uploadVideoForEvent = (event, language) => {
    const videoFile =
      language === "en"
        ? event.virtualInfoSession.videoFile
        : event.virtualInfoSession.videoFileSpanish;
    if (!videoFile) {
      return Promise.resolve();
    }
    const formData = new FormData();
    formData.append("file", videoFile);
    return this.post(
      `event/${event.id}/videoUpload?language=${language}`,
      formData,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  };

  assignProgramAdmin = (programId, userId) =>
    this.post(`program/${programId}/admin`, { programId, userId });

  deactivateProgramAdmin = (programId, programAdminId) =>
    this.delete(`program/${programId}/admin/${programAdminId}`);

  loadProgramAdmins = () => this.get("program/admin");

  findVolunteers = (request) => this.post("volunteers", request);

  createVolunteer = (volunteer, eventId) =>
    this.post(`event/${eventId}/volunteers`, volunteer);

  updateVolunteer = (volunteer, eventId) =>
    this.put(`event/${eventId}/volunteers/${volunteer.id}`, volunteer);

  loadVolunteer = (volunteerId, eventId) =>
    this.get(`event/${eventId}/volunteers/${volunteerId}`);

  deleteVolunteer = (volunteerId, eventId) => {
    return !Array.isArray(volunteerId)
      ? this.delete(`event/${eventId}/volunteers/${volunteerId}`)
      : this.delete(`event/${eventId}/volunteers?ids=${volunteerId.join()}`);
  };

  leaveEvent = (volunteerId, eventId) =>
    this.delete(`event/${eventId}/volunteers/${volunteerId}?leaveEvent=true`);

  importVolunteers = (eventId) =>
    this.post(`event/${eventId}/importVolunteers`, {});

  signWaiver = (volunteerId, eventId) =>
    this.put(`event/${eventId}/volunteers/${volunteerId}/sign`, {});

  selectInfoSession = (eventId, volunteerId, dateId) =>
    this.put(
      `event/${eventId}/volunteers/${volunteerId}/infoSessionDate/${dateId}`,
      {}
    );

  saveSelections = (eventId, volunteerId, selections) =>
    this.post(
      `event/${eventId}/volunteers/${volunteerId}/selections`,
      selections
    );

  loadLocations = () => this.get("location");

  loadPhotos = (eventId, request) =>
    this.post(`event/${eventId}/photos`, request);

  loadPhotoStatus = (eventId) => this.get(`event/${eventId}/photoStatus`);

  loadVolunteerPhoto = (volunteerId) =>
    this.get(`volunteer/${volunteerId}/photo`);

  bulkApprovePhotos = (photoIdsToApprove) =>
    this.put("photo/bulkApprove", photoIdsToApprove);

  rejectPhoto = (photoIdToReject, rejectionReason) =>
    this.post(`photo/reject/${photoIdToReject}`, rejectionReason);

  createOpportunity = (opportunity, eventId) =>
    this.post(`event/${eventId}/opportunities`, opportunity);

  createLocation = (location) => this.post("location", location);

  updateLocation = (location) => this.put(`location/${location.id}`, location);

  updateOpportunity = (opportunity, eventId) =>
    this.put(`event/${eventId}/opportunities/${opportunity.id}`, opportunity);

  loadOpportunity = (eventId, opportunityId) =>
    this.get(`event/${eventId}/opportunities/${opportunityId}`);

  deleteOpportunity = (opportunityId, eventId) => {
    if (Array.isArray(opportunityId)) {
      return this.delete(
        `event/${eventId}/opportunities?ids=${opportunityId.join()}`
      );
    } else {
      return this.delete(`event/${eventId}/opportunities/${opportunityId}`);
    }
  };

  findLocationByName = (locationName) =>
    this.get(`location?name=${encodeURIComponent(locationName)}`);

  saveEmail = (email) => this.post("saveEmailTemplate", email);

  deleteEmail = (emailId) => this.delete(`deleteEmailTemplate/${emailId}`);

  emailAllVolunteers = (emailId, eventId, filters) =>
    this.post(`event/${eventId}/emailAllVolunteers/${emailId}`, filters);

  uploadImportExceptions = (exceptions, eventId) =>
    this.post(`import/${eventId}/exceptions`, exceptions);

  deleteVolunteerSelections = (eventId, volunteerId) =>
    this.post(`event/${eventId}/volunteers/${volunteerId}/deleteSelections`);

  generateTestVolunteers = (eventId, body) =>
    this.post(`event/${eventId}/generateTestVolunteers`, body);

  exportVolunteers = (eventId, params) =>
    this.get(`event/${eventId}/reports/volunteers?${params}`, null, {
      responseType: "blob",
    });

  exportVolunteersOpportunity = (eventId, payload) =>
    this.post(`event/${eventId}/reports/volunteersOpportunity`, payload, null, {
      responseType: "blob",
    });

  exportVolunteerSelections = (eventId) =>
    this.get(`event/${eventId}/reports/volunteer/selectionReportCard`, null, {
      responseType: "blob",
    });

  exportAlumniCertificates = (eventId) =>
    this.get(`event/${eventId}/reports/alumniCertificates`, null, {
      responseType: "blob",
    });

  downloadStatSheet = (eventId, volunteerId) =>
    this.get(`event/${eventId}/reports/volunteer/${volunteerId}/report`, null, {
      responseType: "blob",
    });

    downloadAlumniCertificate = (eventId, volunteerId) =>
    this.get(`event/${eventId}/reports/volunteer/${volunteerId}/alumniCertificate`, null, {
      responseType: "blob",
    });

  downloadWaiver = (eventId, volunteerId) =>
    this.get(`event/${eventId}/reports/volunteer/${volunteerId}/waiver`, null, {
      responseType: "blob",
    });

  downloadVolunteerDetailsReports = (body) =>
    this.post("reports/volunteer/bulk", body);

  downloadAllVolunteerDetailsReports = (body) =>
    this.post("reports/volunteer/all", body);

  submitVolunteerPhoto = (volunteerId, body) =>
    this.post(`volunteer/${volunteerId}/photo`, body);

  adminSubmitVolunteerPhoto = (volunteerId, body) =>
    this.post(`volunteer/${volunteerId}/photo?photoStatus=APPROVED`, body);

  sendPhotoToAccessBadge = (volunteerId) =>
    this.get(`photo/sendPhotoAccessBadge/${volunteerId}`);

  getAmbassadorPrefix() {
    return "program";
  }

  getLocalPort(){
    return "7700";
  }

  fetchSelections = (eventId, volunteerId) =>
    this.get(`event/${eventId}/volunteers/${volunteerId}/selections`);

  completedInfoSessionVideo = (eventId, volunteerId) =>
    this.put(`event/${eventId}/volunteers/${volunteerId}/infoSessionVideo`, {});

  submitQuestionnaireResult = (eventId, volunteerId, result) =>
    this.post(
      `event/${eventId}/volunteers/${volunteerId}/infoSessionQuestionnaire`,
      result
    );

    rollOverOpportunities = (eventId, fromTierId, toTierId) => {
      let url = `event/${eventId}/rollover?fromTierId=${fromTierId}&toTierId=${toTierId}`;
      return this.post(url);
    }
      
}

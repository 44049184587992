/**
 * Taken from example found at:
 * https://github.com/facebook/draft-js/blob/master/examples/draft-0-9-1/link/link.html
 */

import React from "react";
import PropTypes from "prop-types";
import { CompositeDecorator } from "draft-js";

const TextEditorLink = ({ entityKey, children, contentState }) => {
  const { url, newTab } = contentState.getEntity(entityKey).getData();
  const href = `${url.includes("@") ? "mailto:" : ""}${url}`;
  const target = newTab ? "_blank" : "_self";
  return (
    <a className="TextEditor-Link" href={href} target={target}>
      {children}
    </a>
  );
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: TextEditorLink
  }
]);

TextEditorLink.propTypes = {
  entityKey: PropTypes.string,
  children: PropTypes.any,
  contentState: PropTypes.object
};

export default decorator;

import AbstractApi from "./AbstractApi";

export default class ImportApi extends AbstractApi {
  constructor() {
    super();
  }

  loadFailedImports = (eventId) => {
    return this.get(
      `import/${eventId}/events`
    );
  };

  saveImportException = (eventId, importExceptions) => {
    return this.post(`import/${eventId}/exceptions`, importExceptions);
  }

  importVolunteers = eventId =>
    this.post(`event/${eventId}/importVolunteers`, {});

  getAmbassadorPrefix() {
    return "import";
  }

  getLocalPort(){
    return "7703";
  }
}

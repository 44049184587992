import _ from "lodash";
import moment from "moment";
import { action, autorun, computed, decorate, extendObservable } from "mobx";
import { NotificationConstants } from "../constants/NotificationConstants";
import { RouteConstants } from "../constants/RouteConstants";
import { FormConstants } from "../constants/FormConstants";
import { StoreUtilities } from "../utilities/StoreUtilities";
import { StringUtilities } from "../utilities/StringUtilities";
import { DateUtilities } from "../utilities/DateUtilities";
import { EditorUtilities } from "../utilities/EditorUtilities";

// TODO: break this store up
export default class EventStore {
  constructor(
    eventInfoStore,
    volunteerStore,
    importApi,
    volunteerScreensStore,
    authStore,
    programApi,
    emailApi,
    routerStore,
    gridStore,
    notificationStore,
    blockingStore,
    history,
    i18n
  ) {
    this.eventInfoStore = eventInfoStore;
    this.volunteerStore = volunteerStore;
    this.importApi = importApi;
    this.volunteerScreensStore = volunteerScreensStore;
    this.authStore = authStore;
    this.programApi = programApi;
    this.emailApi = emailApi;
    this.routerStore = routerStore;
    this.gridStore = gridStore;
    this.notificationStore = notificationStore;
    this.blockingStore = blockingStore;
    this.history = history;
    this.i18n = i18n;
    this.fileReader = new FileReader();

    this.defaults = {
      activeTab: "volunteers",
      eventSortFilters: {
        key: "date",
        direction: "DESC",
      },
      tierFilters: {
        key: "number",
        direction: "ASC",
        initial: [],
      },
      openEnrollmentFilters: {
        key: "date",
        direction: "ASC",
        initial: [],
      },
      usersEvents: [],
      loadedUserEvents: false,
      allEvents: [],
      savingEvent: false,
      selectedEvent: null,
      event: undefined,
      eventId: undefined,
      contactSubject: "Contact Us",
      isWarnOnDeleteOpen: false,
      isContactModalOpen: false,
      contactUsMessage: "",
      failedEventLoad: false,
      validationErrorMessages: [],
      eventDirty: false, // global event (all tabs)
      eventInfoDirty: false,
      waiverInfoDirty: false,
      loadingEvent: false,
      settingsDirty: false,
      importDirty: false,
      screenContentDirty: false,
      tiers: {},
      tierKeys: [],
      openEnrollments: {},
      openEnrollmentKeys: [],
      photos: {
        total: 0,
        pending: 0,
        approved: 0,
        rejected: 0,
        photosToShow: [],
      },
      photosApproving: false,
      photosLoading: false,
      volunteerPhotoFilters: {
        waiver: FormConstants.VOLUNTEER_FILTERS.ALL,
        tier: FormConstants.VOLUNTEER_FILTERS.NONE,
        alumni: FormConstants.VOLUNTEER_FILTERS.ALL,
        score: FormConstants.VOLUNTEER_FILTERS.ALL,
        consent: FormConstants.VOLUNTEER_FILTERS.ALL,
        orientation: FormConstants.VOLUNTEER_FILTERS.ALL,
        opportunity: FormConstants.VOLUNTEER_FILTERS.ALL,
        standby: FormConstants.VOLUNTEER_FILTERS.ALL,
        infoSession: FormConstants.VOLUNTEER_FILTERS.ALL,
        shiftsAttended: FormConstants.VOLUNTEER_FILTERS.ANY,
      },
      isRejectPhotoModalOpen: false,
      isEditPhotoModalOpen: false,
      rejectingPhoto: null,
      editingPhoto: null,
      selectedRejectionReason: FormConstants.PHOTO_REJECTION_REASONS[0],
      selectedEmailTemplate: null,
      sendRejectionEmail: false,
      requiredOpportunities: FormConstants.REQUIRED_OPPORTUNITIES[3],
      maxOpportunitiesPerDay: FormConstants.MAX_OPPORTUNITIES_PER_DAY[0],
      tiersModified: false,
      copyFromTier: null,
      copyToTier: [],
      rollOverModalOpen: false,
      rolloverToAllFlag: false
    };

    extendObservable(this, {
      // objects
      ...StoreUtilities.initialize(this),

      // functions
      setActiveTab: action((value) => {
        this.activeTab = value;
      }),
      setCopyFromTier: action((value) => {
        this.copyFromTier = value;

        if(this.rolloverToAllFlag === true){
          this.copyToTier = Object.values(this.tiers)
                                  .map(t => { return {key: t.id, value: `Tier ${t.number}`, name: `Tier ${t.number}`};})
                                  .filter(t => t.key > this.copyFromTier.key);
        } 
      }),
      setRolloverToAllFlag: action(() => {
        this.rolloverToAllFlag = !this.rolloverToAllFlag;

        if(this.rolloverToAllFlag === true){
          this.copyToTier = Object.values(this.tiers)
                                  .map(t => { return {key: t.id, value: `Tier ${t.number}`, name: `Tier ${t.number}`};})
                                  .filter(t => t.key > this.copyFromTier.key);
        }                              
      }),
      setCopyToTier: action((value) => {
        this.copyToTier.push(value);
      }),
      removeCopyToTier: action((value) => {
        this.copyToTier = this.copyToTier.filter(t => t.value !== value.value);
      }),
      setUserEvents: action((value) => {
        this.usersEvents = value;
      }),
      setPhotos: action((value) => {
        this.photos = value;
      }),
      setAllEvents: action((value) => {
        this.allEvents = value;
      }),
      setEventsSortDirection: action((col, direction) => {
        this.eventSortFilters.key = col;
        this.eventSortFilters.direction = direction;
      }),

      setNumRequiredOpportunities: action((item) => {
        this.requiredOpportunities = item;
      }),

      setNumMaxOpportunitiesPerDay: action((item) => {
        this.maxOpportunitiesPerDay = item;
      }),
      setEventDirty: action((value) => {
        this.eventDirty = value;
        if (!this.eventDirty) {
          this.setEventInfoDirty(false);
          this.setWaiverInfoDirty(false);
          this.setSettingsDirty(false);
          this.setImportDirty(false);
          this.setScreenContentDirty(false);
        }
      }),
      setEventInfoDirty: action((value) => {
        this.eventInfoDirty = value;
        if (this.eventInfoDirty) this.setEventDirty(true);
      }),
      setWaiverInfoDirty: action((value) => {
        this.waiverInfoDirty = value;
        if (this.waiverInfoDirty) this.setEventDirty(true);
      }),
      setSettingsDirty: action((value) => {
        this.settingsDirty = value;
        if (this.settingsDirty) this.setEventDirty(true);
      }),
      setImportDirty: action((value) => {
        this.importDirty = value;
        if (this.importDirty) this.setEventDirty(true);
      }),
      setScreenContentDirty: action((value) => {
        this.screenContentDirty = value;
        if (this.screenContentDirty) this.setEventDirty(true);
      }),
      setEventAttributes: action((value) => {
        this.event = value;
        this.eventId = value.id;

        this.eventInfoStore.setEventInfoAttributes(value);
        this.volunteerScreensStore.setVolunteerScreensAttributes(value);
        this.volunteerScreensStore.displayOpportunitiesToVolunteer =
          value.displayOpportunitiesToVolunteer;
        // set tiers
        this.tierKeys = [];
        this.tiers = value.tiers
          ? this.generateKeys(value.tiers, "tierKeys")
          : null;
        this.tierFilters.initial = this.tierKeys;

        // set open enrollments
        this.openEnrollmentKeys = [];
        this.openEnrollments = value.openEnrollments
          ? this.generateKeys(value.openEnrollments, "openEnrollmentKeys")
          : null;
        this.openEnrollmentFilters.initial = this.openEnrollmentKeys;
        if (!value.openEnrollments || value.openEnrollments.length === 0) {
          this.openEnrollments = {};
          this.addOpenEnrollment();
        }

        this.requiredOpportunities = value.numRequiredOpportunities
          ? FormConstants.REQUIRED_OPPORTUNITIES.find(
              (i) => i.value === value.numRequiredOpportunities
            )
          : this.defaults.requiredOpportunities;

        this.maxOpportunitiesPerDay = value.numMaxOpportunitiesPerDay
          ? FormConstants.MAX_OPPORTUNITIES_PER_DAY.find(
              (i) => i.value === value.numMaxOpportunitiesPerDay
            )
          : this.defaults.maxOpportunitiesPerDay;

        this.tiersModified = false;
      }),
      clearEventAttributes: action(() => {
        this.eventInfoStore.clearEventInfoAttributes();
        this.volunteerScreensStore.clearVolunteerScreensAttributes();
        this.loadedUserEvents = this.defaults.loadedUserEvents;
        this.event = this.defaults.event;
        this.eventId = this.defaults.eventId;
        this.failedEventLoad = this.defaults.failedEventLoad;
        this.tiers = this.defaults.tiers;
        this.tierKeys = this.defaults.tierKeys;
        this.tierFilters = this.defaults.tierFilters;
        this.openEnrollments = this.defaults.openEnrollments;
        this.openEnrollmentKeys = this.defaults.openEnrollmentKeys;
        this.openEnrollmentFilters = this.defaults.openEnrollmentFilters;
        this.eventInfoDirty = this.defaults.eventInfoDirty;
        this.screenContentDirty = this.defaults.screenContentDirty;
        this.settingsDirty = this.defaults.settingsDirty;
        this.importDirty = this.defaults.importDirty;
        this.eventDirty = this.defaults.eventDirty;
        this.photos = this.defaults.photos;
        this.photos.approved = this.defaults.photos.approved;
        this.photos.pending = this.defaults.photos.pending;
        this.photos.rejected = this.defaults.photos.rejected;
        this.photos.photosToShow = this.defaults.photos.photosToShow;
        this.photosApproving = this.defaults.photosApproving;
        this.photosLoading = this.defaults.photosLoading;
        this.requiredOpportunities = this.defaults.requiredOpportunities;
        this.maxOpportunitiesPerDay = this.defaults.maxOpportunitiesPerDay;
        this.tiersModified = this.defaults.isTiersModified;
      }),
      setValidationErrorMessages: action((messages) => {
        this.validationErrorMessages = messages;
      }),
      clearValidationErrorMessages: action(() => {
        this.validationErrorMessages = [];
      }),
      showDeleteWarning: action((event) => {
        this.isWarnOnDeleteOpen = true;
        this.setEventAttributes(event);
      }),
      hideDeleteWarning: action(() => {
        this.isWarnOnDeleteOpen = false;
        if (!this.routerStore.isActive(RouteConstants.EDIT_EVENT)) {
          this.clearEventAttributes();
        }
      }),
      toggleContactModal: action(() => {
        this.isContactModalOpen = !this.isContactModalOpen;
        if (!this.isContactModalOpen) {
          this.contactSubject = this.defaults.contactSubject;
          this.contactUsMessage = this.defaults.contactUsMessage;
        }
      }),
      toggleDisplayOpportunitiesToVolunteer: action(() => {
        this.displayOpportunitiesToVolunteer =
          !this.displayOpportunitiesToVolunteer;
      }),
      approvePhotos: action((eventId) => {
        this.photosApproving = true;
        const photoIdsToApprove = this.photos.photosToShow
          .filter((item) => item.base64EncodedVal && item.status === "PENDING")
          .map((item) => {
            return item.id;
          });

        if (photoIdsToApprove.length > 0) {
          this.programApi
            .bulkApprovePhotos(photoIdsToApprove)
            .then(() => {
              this.photosApproving = false;
              this.loadPhotos(eventId);
              this.notificationStore.setMessage(
                `${photoIdsToApprove.length} photos approved.`
              );
            })
            .catch((e) => {
              this.photosApproving = false;
              this.notificationStore.setMessage(
                "Error occurred while approving photos, please try again.",
                NotificationConstants.ERROR
              );
              /*eslint-disable-next-line no-console*/
              console.log(e);
            });
        } else {
          this.photosApproving = false;
        }
      }),
      loadPhotoStatus: action((eventId) => {
        this.photos.photosToShow = this.defaults.photos.photosToShow = [];
        this.programApi
          .loadPhotoStatus(eventId)
          .then((photoStatus) => {
            this.photos.approved = photoStatus["APPROVED"];
            this.photos.pending = photoStatus["PENDING"];
            this.photos.rejected = photoStatus["REJECTED"];
            this.photosLoading = false;
          })
          .catch((e) => {
            /*eslint-disable-next-line no-console*/
            console.error(e);
          });
      }),
      rejectPhoto: action((volunteerPhoto) => {
        this.rejectingPhoto = volunteerPhoto;
        this.isRejectPhotoModalOpen = true;
      }),
      editPhoto: action((volunteerPhoto) => {
        this.editingPhoto = volunteerPhoto;
        this.isEditPhotoModalOpen = true;
      }),
      closeRejectModal: action(() => {
        this.isRejectPhotoModalOpen = false;
      }),
      closeEditModal: action(() => {
        this.isEditPhotoModalOpen = false;
      }),
      setSelectedRejectionReason: action((reason) => {
        this.selectedRejectionReason = reason;
      }),
      toggleSendRejectionEmail: action(() => {
        this.sendRejectionEmail = !this.sendRejectionEmail;
      }),
      setSelectedEmailTemplate: action((template) => {
        this.selectedEmailTemplate = template;
      }),
      doRollover: action(() => {
        let copyToTier = this.copyToTier.map(t => t.key).join(",");
        const promise = this.programApi.rollOverOpportunities(this.eventId, this.copyFromTier.key, copyToTier);
        promise.then((data) => {
          this.rollOverModalOpen = false;
          this.opportunityStore.loadOpportunitiesForEvent(this.eventId);
          this.notificationStore.setMessage("Successfully rolled over opportunities");
        });
      }),
      doSavePhoto: action((eventId, volunteer, photo) => {
        this.programApi
          .submitVolunteerPhoto(volunteer.id, photo)
          .then(() => {
            this.closeEditModal();
            const index = this.photos.photosToShow.findIndex(
              (p) => p.volunteerId === volunteer.id
            );
            this.photos.photosToShow[index].base64EncodedVal = photo;
            this.notificationStore.setMessage("Saved");
          })
          .catch(() => {
            this.notificationStore.setMessage(
              "Save failed. Try again later",
              NotificationConstants.ERROR
            );
          });
      }),
      doRejection: action((volunteer) => {
        this.programApi
          .rejectPhoto(
            this.rejectingPhoto.id,
            this.selectedRejectionReason.value
          )
          .then((result) => {
            if (
              result.UPDATED &&
              result.UPDATED.includes(this.rejectingPhoto.id)
            ) {
              this.rejectingPhoto.status = "REJECTED";
              this.photos.pending--;
              this.photos.rejected++;

              if (this.sendRejectionEmail && this.selectedEmailTemplate) {
                this.emailApi.sendEmailsWithTemplate(
                  this.selectedEmailTemplate.value,
                  {
                    emailInfos: [
                      {
                        toAddresses: [volunteer.email],
                        placeholders: [
                          { key: "first_name", value: volunteer.firstName },
                          {
                            key: "email",
                            value: volunteer.email.toLowerCase(),
                          },
                          { key: "password", value: "" },
                          {
                            key: "unsubscribe_address",
                            value: this.contactEmailAddress,
                          },
                          {
                            key: "rejection_reason",
                            value: this.selectedRejectionReason.name,
                          },
                        ],
                      },
                    ],
                  }
                );
              }
            }
            this.rejectingPhoto = this.defaults.rejectingPhoto;
            this.closeRejectModal();
          });
      }),
      loadPhotos: action((eventId) => {
        this.photosLoading = true;
        this.photos.photosToShow = this.defaults.photos.photosToShow;
        const loadPhotoPromise = this.programApi.loadPhotos(
          eventId,
          this.searchFilters()
        );
        const loadPhotoStatusPromise = this.programApi.loadPhotoStatus(eventId);

        return Promise.all([loadPhotoPromise, loadPhotoStatusPromise])
          .then((results) => {
            const photos = results[0];
            const photoStatus = results[1];

            if (Array.isArray(photos)) {
              this.photos.photosToShow = photos;
            } else {
              this.photos.photosToShow = [];
            }

            if (photoStatus) {
              this.photos.approved = photoStatus["APPROVED"];
              this.photos.pending = photoStatus["PENDING"];
              this.photos.rejected = photoStatus["REJECTED"];
            }

            this.photosLoading = false;
          })
          .catch((e) => {
            this.photosLoading = false;
            /*eslint-disable-next-line no-console*/
            console.error(e);
          });
      }),
      loadEvent: action((eventId) => {
        this.loadingEvent = true;
        return this.programApi
          .loadEvent(eventId)
          .then((event) => {
            this.loadingEvent = false;
            this.failedEventLoad = false;
            this.setEventAttributes(event);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e);
            this.loadingEvent = false;
            this.failedEventLoad = true;
          });
      }),
      saveEvent: action((blockingCallback) => {
        // create payload
        const event = {
          id: this.eventId,
          ...this.eventInfoStore.getEventInfo(),
          ...this.volunteerScreensStore.getVolunteerScreens(),
          waiver: null,
          displayOpportunitiesToVolunteer:
            this.volunteerScreensStore.displayOpportunitiesToVolunteer,
          tiers: this.tierKeys.map((k) => ({
            ...this.tiers[k],
            startTime: DateUtilities.timeToString(this.tiers[k].startTime),
            endTime: DateUtilities.timeToString(this.tiers[k].endTime),
            date: new Date(this.tiers[k].date),
            endDate: this.tiers[k].endDate
              ? new Date(this.tiers[k].endDate)
              : undefined,
          })),
          openEnrollments: this.openEnrollmentKeys.map((k) => ({
            ...this.openEnrollments[k],
            startTime: DateUtilities.timeToString(
              this.openEnrollments[k].startTime
            ),
            endTime: DateUtilities.timeToString(
              this.openEnrollments[k].endTime
            ),
            date: new Date(this.openEnrollments[k].date),
            endDate: this.openEnrollments[k].endDate
              ? new Date(this.openEnrollments[k].endDate)
              : undefined,
          })),
          numRequiredOpportunities: this.requiredOpportunities.value,
          numMaxOpportunitiesPerDay: this.maxOpportunitiesPerDay.value,
          tiersModified: this.tiersModified,
        };

        // create payload for import
        const importExceptions = this.volunteerStore.failedImports
          .filter(
            (ie) => (ie.newEmail || ie.exceptionId) && ie.processed === "N"
          )
          .map((ie) => {
            return {
              id: ie.exceptionId,
              type: ie.type,
              eventId: this.eventId,
              firstName: ie.firstName,
              lastName: ie.lastName,
              oldEmail: ie.email,
              newEmail: ie.newEmail,
              submissionTime: ie.submissionTime,
              processed: "N",
            };
          });

        const waiver = {
          text: this.volunteerScreensStore.waiverText
            .getCurrentContent()
            .hasText()
            ? EditorUtilities.stateToRawString(
                this.volunteerScreensStore.waiverText.getCurrentContent()
              )
            : null,
          textEs: this.volunteerScreensStore.waiverTextSpanish
            .getCurrentContent()
            .hasText()
            ? EditorUtilities.stateToRawString(
                this.volunteerScreensStore.waiverTextSpanish.getCurrentContent()
              )
            : null,
        };

        if (this.event && this.event.waiver) {
          waiver.id = this.event.waiver.id;
          waiver.createdTs = this.event.waiver.createdTs;
          waiver.createdUser = this.event.waiver.createdUser;
        }

        if (waiver.id || waiver.text || waiver.textEs) {
          event.waiver = waiver;
        }

        if (this.openEnrollmentKeys.length === 1) {
          const openEnrollment =
            this.openEnrollments[this.openEnrollmentKeys[0]];
          if (
            !openEnrollment.startTime &&
            !openEnrollment.endTime &&
            !openEnrollment.date &&
            !openEnrollment.endDate
          ) {
            event.openEnrollments = [];
          }
        }

        const updatingEvent = !!this.eventId;
        const promise = updatingEvent
          ? this.programApi.updateEvent(event)
          : this.programApi.createEvent(event);

        let importPromise = null;
        if (importExceptions.length > 0) {
          importPromise = this.importApi.saveImportException(
            this.eventId,
            importExceptions
          );
        }

        this.savingEvent = true;
        // calling the delete first then save event is causing the save event to not delete the live count
        // there is also a race condition in these calls; usually when debugging, it goes into the save call first, then it goes into the delete logic in the middle of the save event call
        Promise.all([
          this._volunteerStore.deletePendingVolunteers(),
          this._opportunityStore.deletePendingOpportunities(),
        ]).then(() => {
          // saving the event
          promise
            .then((data) => {
              this.setEventAttributes(data);
              this.savingEvent = false;
              this.setEventInfoDirty(false);
              this.setEventDirty(false);

              if (!updatingEvent) {
                this.routerStore.pushWithPathParams(RouteConstants.EDIT_EVENT, {
                  eventId: this.eventId,
                });
              }

              if (blockingCallback) {
                blockingCallback();
              } else {
                this.blockingStore.clearAttributes(); // allow push to go through
                if (!this.routerStore.isEditEventPath) {
                  this.history.push(RouteConstants.EVENTS);
                }
              }
              this.notificationStore.setMessage(
                this.i18n.t("event.notification.saved")
              );
            })
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.error(e);
              this.savingEvent = false;
              this.setValidationErrorMessages(e.message.split("|"));
            });

          if (importPromise) {
            importPromise.then(() => {
              this.volunteerStore.loadFailedImports(this.eventId);
            });
          }
        });
      }),
      deleteEvent: action(() => {
        this.programApi
          .deleteEvent(this.eventId)
          .then(() => {
            this.hideDeleteWarning();
            this.blockingStore.clearAttributes(); // allow push to go through
            this.history.push(RouteConstants.EVENTS);
            this.notificationStore.setMessage(
              this.i18n.t("event.notification.deleted")
            );
            this.loadAllEvents();
          })
          .catch(() => {
            this.notificationStore.setMessage(
              this.i18n.t("event.notification.notDeleted"),
              NotificationConstants.ERROR
            );
          });
      }),
      tierAlumniStatusToggle: action((key) => {
        this.writeValue(key, !this.tiers[key].alumni, "tiers", "alumni");
        this.tiersModified = true;
      }),
      tierInPersonInfoSessionStatusToggle: action((key) => {
        this.writeValue(
          key,
          !this.tiers[key].inPersonInfoSession,
          "tiers",
          "inPersonInfoSession"
        );
        this.tiersModified = true;
      }),
      tierOnlineInfoSessionStatusToggle: action((key) => {
        this.writeValue(
          key,
          !this.tiers[key].onlineInfoSession,
          "tiers",
          "onlineInfoSession"
        );
        this.tiersModified = true;
      }),
      tierNoOppsStatusToggle: action((key) => {
        this.writeValue(key, !this.tiers[key].noOpps, "tiers", "noOpps");
        if (!this.tiers[key].noOpps) {
          this.setTierNoOppsBeforeDateFromEvent(key, null, null); // clear out noOppsBefore date
        }
        this.tiersModified = true;
      }),
      tierLastNameStartWithBeginChanged: action((key, event) => {
        this.setValue(key, event, "tiers", "lastNameStartWithBegin");
        this.tiersModified = true;
      }),
      tierLastNameStartWithEndChanged: action((key, event) => {
        this.setValue(key, event, "tiers", "lastNameStartWithEnd");
        this.tiersModified = true;
      }),
      setTierDateFromEvent: action((key, date, event) => {
        if (!(date instanceof Date)) {
          event = date;
        }

        if (!event) {
          this.writeValue(key, null, "tiers", "date");
        } else if (event.type === "click") {
          this.writeValue(key, date, "tiers", "date");
        } else {
          const d = moment(event.currentTarget.value, "MM/DD/YYYY", true);
          if (d.isValid()) {
            this.writeValue(key, d.toDate(), "tiers", "date");
          }
        }
        this.tiersModified = true;
      }),
      setTierEndDateFromEvent: action((key, date, event) => {
        if (!(date instanceof Date)) {
          event = date;
        }

        if (!event) {
          this.writeValue(key, null, "tiers", "endDate");
        } else if (event.type === "click") {
          this.writeValue(key, date, "tiers", "endDate");
        } else {
          const d = moment(event.currentTarget.value, "MM/DD/YYYY", true);
          if (d.isValid()) {
            this.writeValue(key, d.toDate(), "tiers", "endDate");
          }
        }
        this.tiersModified = true;
      }),
      setTierNoOppsBeforeDateFromEvent: action((key, date, event) => {
        if (!(date instanceof Date)) {
          event = date;
        }

        if (!event) {
          this.writeValue(key, null, "tiers", "noOppsBefore");
        } else if (event.type === "click") {
          this.writeValue(key, date, "tiers", "noOppsBefore");
        } else {
          const d = moment(event.currentTarget.value, "MM/DD/YYYY", true);
          if (d.isValid()) {
            this.writeValue(key, d.toDate(), "tiers", "noOppsBefore");
          }
        }
        this.tiersModified = true;
      }),
      setTierStartTime: action((key, event) => {
        this.setTime(key, event, "startTime", "tiers");
        this.tiersModified = true;
      }),
      setTierEndTime: action((key, event) => {
        this.setTime(key, event, "endTime", "tiers");
        this.tiersModified = true;
      }),
      setOpenEnrollmentStartTime: action((key, event) => {
        this.setTime(key, event, "startTime", "openEnrollments");
      }),
      setOpenEnrollmentEndTime: action((key, event) => {
        this.setTime(key, event, "endTime", "openEnrollments");
      }),
      setOpenEnrollmentDateFromEvent: action((key, date, event) => {
        if (!(date instanceof Date)) {
          event = date;
        }

        if (!event) {
          this.writeValue(key, null, "openEnrollments", "date");
        } else if (event.type === "click") {
          this.writeValue(key, date, "openEnrollments", "date");
        } else {
          const d = moment(event.currentTarget.value, "MM/DD/YYYY", true);
          if (d.isValid()) {
            this.writeValue(key, d.toDate(), "openEnrollments", "date");
          }
        }
      }),
      setOpenEnrollmentEndDateFromEvent: action((key, date, event) => {
        if (!(date instanceof Date)) {
          event = date;
        }

        if (!event) {
          this.writeValue(key, null, "openEnrollments", "endDate");
        } else if (event.type === "click") {
          this.writeValue(key, date, "openEnrollments", "endDate");
        } else {
          const d = moment(event.currentTarget.value, "MM/DD/YYYY", true);
          if (d.isValid()) {
            this.writeValue(key, d.toDate(), "openEnrollments", "endDate");
          }
        }
      }),
      openEnrollmentIsRangeToggle: action((key) => {
        this.writeValue(
          key,
          !this.openEnrollments[key].isRange,
          "openEnrollments",
          "isRange"
        );
        if (!this.openEnrollments[key].isRange) {
          this.writeValue(key, null, "openEnrollments", "endDate");
        }
      }),
      tiersIsRangeToggle: action((key) => {
        this.writeValue(key, !this.tiers[key].isRange, "tiers", "isRange");
        if (!this.tiers[key].isRange) {
          this.writeValue(key, null, "tiers", "endDate");
        }
        this.tiersModified = true;
      }),
      setTime: action((key, event, propname, array) => {
        let time;
        if (!event) {
          time = null;
        } else if (event instanceof Date) {
          time = event;
        } else {
          const d = moment(event.target.value, "h:mm a", true);
          time = d.isValid() ? d.toDate() : null;
        }

        this[array] = Object.assign({}, this[array], {
          [key]: Object.assign({}, this[array][key], {
            [propname]: time,
          }),
        });
      }),
      addTier: action(() => {
        if (this.tierKeys.length === 0) {
          this.addToList("tiers", {
            number: Object.keys(this.tiers).length + 1,
          });
        } else {
          this.addToList("tiers", {
            number: Object.keys(this.tiers).length + 1,
          });
        }
      }),
      addOpenEnrollment: action(() => {
        this.addToList("openEnrollments", {
          number: Object.keys(this.openEnrollments).length + 1,
        });
      }),
      removeTier: action((key) => {
        delete this.tiers[key];
        this.tierKeys = this.tierKeys.filter((i) => i !== key);
        this.tierFilters.initial = this.tierFilters.initial.filter(
          (i) => i !== key
        );
        this.tiersModified = true;
      }),
      removeOpenEnrollment: action((key) => {
        delete this.openEnrollments[key];
        this.openEnrollmentKeys = this.openEnrollmentKeys.filter(
          (i) => i !== key
        );
        this.openEnrollmentFilters.initial =
          this.openEnrollmentFilters.initial.filter((i) => i !== key);
      }),
      sortTiersByColumn: action((col, override = "") => {
        const colIsKey = col === this.tierFilters.key;
        const direction = colIsKey ? this.tierFilters.direction : "NONE";
        this.tierFilters.key = col;
        this.tierFilters.direction =
          override || this.gridStore.changeDirection(direction);

        if (this.tierFilters.direction === "NONE") {
          this.tierKeys = this.tierFilters.initial;
        } else {
          this.tierKeys = this.tierKeys.sort((key1, key2) => {
            const x = this.tiers[key1][this.tierFilters.key] || "";
            const y = this.tiers[key2][this.tierFilters.key] || "";
            return x - y;
          });

          if (this.tierFilters.direction === "DESC") {
            this.tierKeys = this.tierKeys.reverse();
          }
        }
      }),
      addToList: action((propname, defaultValue) => {
        const keyName =
          propname === "tiers" ? "tierKeys" : "openEnrollmentKeys";
        const filterName = keyName.replace("Keys", "Filters");
        let unique = StringUtilities.random();
        while (this[propname][unique]) {
          unique = StringUtilities.random();
        }

        this[keyName] = this[keyName].concat([unique]);
        this[filterName].initial = this[filterName].initial.concat([unique]);
        this[propname] = Object.assign({}, this[propname], {
          [unique]: Object.assign({}, defaultValue, {
            eventId: this.eventId,
          }),
        });
        return unique;
      }),
      writeValue: action((key, value, propname, parameter) => {
        this[propname] = Object.assign({}, this[propname], {
          [key]: Object.assign({}, this[propname][key], {
            [parameter]: value,
          }),
        });
      }),
      setValue: action((key, event, propname, parameter) => {
        this[propname] = Object.assign({}, this[propname], {
          [key]: Object.assign({}, this[propname][key], {
            [parameter]: event.target.value,
          }),
        });
      }),
      setFilter: action((item, filter) => {
        this.volunteerPhotoFilters[filter] = item;
      }),
    });

    // load user events autorun
    autorun(() => {
      if (
        this.authStore.currentUserId &&
        this.routerStore.isEventSelectionPath
      ) {
        this.loadUserEvents();
      }
    });

    // load event autorun
    autorun(() => {
      if (this.authStore.currentUserId && this.routerStore.isEditEventPath) {
        const params = this.routerStore.getPathParams(
          RouteConstants.EDIT_EVENT
        );
        this.loadEvent(params.eventId);
        this.loadPhotoStatus(params.eventId);
      }
    });

    // load all events autorun
    autorun(() => {
      if (
        this.routerStore.isEventsPath &&
        this.authStore.currentUserId &&
        this.authStore.isAdmin
      ) {
        this.setEventInfoDirty(false);
        this.setEventDirty(false);
        this.setSettingsDirty(false);
        this.setImportDirty(false);
        this.setWaiverInfoDirty(false);
        this.setScreenContentDirty(false);
        this.loadAllEvents();
      }
    });

    // reset active tab autorun
    autorun(() => {
      if (
        !this.routerStore.isActive(RouteConstants.EDIT_EVENT) &&
        !this.routerStore.isActive(RouteConstants.EDIT_VOLUNTEER) &&
        !this.routerStore.isActive(RouteConstants.NEW_VOLUNTEER) &&
        !this.routerStore.isActive(RouteConstants.EDIT_OPPORTUNITY) &&
        !this.routerStore.isActive(RouteConstants.NEW_OPPORTUNITY) &&
        !this.routerStore.isActive(RouteConstants.EDIT_EMAIL) &&
        !this.routerStore.isActive(RouteConstants.NEW_EMAIL) &&
        this.activeTab !== this.defaults.activeTab
      ) {
        this.setActiveTab(this.defaults.activeTab);
      }
    });
  }

  searchFilters = () => {
    return {
      searchText: "",
      waiver: null,
      alumni: this.filterValue(this.volunteerPhotoFilters.alumni),
      score:
        this.volunteerPhotoFilters.score.key === "all"
          ? []
          : [this.volunteerPhotoFilters.score.key],
      consent:
        this.volunteerPhotoFilters.consent.key === "all"
          ? []
          : [this.volunteerPhotoFilters.consent.key],
      tier:
        this.volunteerPhotoFilters.tier.key === "none"
          ? []
          : [this.volunteerPhotoFilters.tier.key],
      infoSession:
        this.volunteerPhotoFilters.infoSession.key === "all"
          ? []
          : [this.volunteerPhotoFilters.infoSession.key],
      opportunity: null,
      orientation: null,
      standby: null,
      shiftsAttended: null,
    };
  };

  getSelectedFilters = (filters) => {
    return filters.toJS();
  };

  filterValue = (filter) => {
    let value = null;
    if (filter.key === FormConstants.VOLUNTEER_FILTERS.YES.key) {
      value = true;
    } else if (filter.key === FormConstants.VOLUNTEER_FILTERS.NO.key) {
      value = false;
    }
    return value;
  };

  get userEvents() {
    if (this.usersEvents && this.usersEvents.length) {
      return this.usersEvents;
    }
    return [];
  }

  get allEventsData() {
    let events = this.allEvents.map((e) => {
      return Object.assign({}, {}, e);
    });

    return this.sort(events, this.eventSortFilters);
  }

  set volunteerStore(volunteerStore) {
    this._volunteerStore = volunteerStore;
  }

  get volunteerStore() {
    return this._volunteerStore;
  }

  set opportunityStore(opportunityStore) {
    this._opportunityStore = opportunityStore;
  }

  get opportunityStore() {
    return this._opportunityStore;
  }

  get tierRows() {
    const length = this.tierKeys.length;
    return this.tierKeys.map((i) => ({ ...this.tiers[i], key: i, length }));
  }

  get openEnrollmentRows() {
    return this.openEnrollmentKeys.map((i) => ({
      ...this.openEnrollments[i],
      key: i,
    }));
  }

  get isTiersInvalid() {
    return this.tierRows.some(
      (tier) =>
        !tier.date ||
        !tier.startTime ||
        !tier.endTime ||
        ((!tier.endDate || tier.date.getTime() === tier.endDate.getTime()) &&
          tier.endTime <= tier.startTime) ||
        (tier.noOpps && !tier.noOppsBefore)
    );
  }

  get dateOptions() {
    const { startDate, endDate } = this.event || {};
    if (!startDate) return [];

    const dateStr = DateUtilities.dateToString(
      startDate,
      "ddd, M/D",
      "YYYY-MM-DD"
    );
    if (!endDate) {
      return [
        {
          key: dateStr,
          name: dateStr,
          value: startDate,
        },
      ];
    }

    const results = [];
    const startObj = DateUtilities.parseString(startDate);
    const endObj = DateUtilities.parseString(endDate);

    if (startObj.getTime() > endObj.getTime()) {
      return [
        {
          key: dateStr,
          name: dateStr,
          value: startDate,
        },
      ];
    }

    while (startObj.getTime() <= endObj.getTime()) {
      const str = DateUtilities.dateToString(startObj, "ddd, M/D");
      const value = DateUtilities.dateToString(startObj, "YYYY-MM-DD");
      results.push({ key: str, name: str, value });
      startObj.setDate(startObj.getDate() + 1);
    }

    return results;
  }

  get rejectDisabled() {
    return (
      this.selectedRejectionReason === null ||
      (this.sendRejectionEmail && this.selectedEmailTemplate === null)
    );
  }

  loadUserEvents = () => {
    this.programApi.loadEventsForUser().then((data) => {
      this.setUserEvents(data ? data.events : []);
      this.loadedUserEvents = true;
    });
  };

  loadAllEvents = () => {
    this.programApi.loadAllEvents().then((data) => {
      this.setAllEvents(data ? data.events : []);
    });
  };

  sort = (events, sortFilters) => {
    if (sortFilters.direction === "NONE") {
      return _.sortBy(events, "name");
    } else if (sortFilters.direction === "ASC") {
      if (sortFilters.key === "date") {
        return events.concat().sort((e1, e2) => {
          const startDate1 = moment(e1.startDate, "YYYY-MM-DD", true).toDate();
          const startDate2 = moment(e2.startDate, "YYYY-MM-DD", true).toDate();
          return startDate1 - startDate2;
        });
      }
      return _.sortBy(events, sortFilters.key);
    } else if (sortFilters.direction === "DESC") {
      if (sortFilters.key === "date") {
        return events.concat().sort((e1, e2) => {
          const startDate1 = moment(e1.startDate, "YYYY-MM-DD", true).toDate();
          const startDate2 = moment(e2.startDate, "YYYY-MM-DD", true).toDate();
          return startDate2 - startDate1;
        });
      }
      return _.sortBy(events, sortFilters.key).reverse();
    } else {
      return events;
    }
  };

  generateKeys = (arr, property) => {
    const created = new Set();
    const result = {};
    this[property] = arr.map((item, index) => {
      let unique = StringUtilities.random();
      while (created.has(unique)) {
        unique = StringUtilities.random();
      }

      const date = DateUtilities.parseString(item.date);
      const endDate = DateUtilities.parseString(item.endDate);
      const startTime = DateUtilities.parseString(item.startTime, "HH:mm:ss");
      const endTime = DateUtilities.parseString(item.endTime, "HH:mm:ss");
      const noOppsBeforeDate = DateUtilities.parseString(item.noOppsBefore);

      created.add(unique);
      result[unique] = Object.assign({}, item, {
        number: index + 1,
        date: item.date ? date : undefined,
        endDate: item.endDate ? endDate : undefined,
        startTime: item.startTime ? startTime : undefined,
        endTime: item.endTime ? endTime : undefined,
        noOppsBefore: item.noOppsBefore ? noOppsBeforeDate : undefined,
      });
      if (result[unique].date && result[unique].endDate) {
        result[unique].isRange = true;
      }
      if (result[unique].noOppsBefore) {
        result[unique].noOpps = true;
      }
      return unique;
    });

    return result;
  };
}

decorate(EventStore, {
  userEvents: computed,
  dateOptions: computed,
  allEventsData: computed,
  rejectDisabled: computed,
});

import SockJS from "sockjs-client";
import webstomp from "webstomp-client";
import { environment } from "../../configs/environment";
import { AuthConstants } from "../constants/AuthConstants";

const debug = process.env.APP_ENV === "local";

export default class SelectionApi {
  connect = (subscriberFn) => {
    if (subscriberFn && typeof subscriberFn !== "function")
      throw "connect argument must be a function";

    const oktaTokenStr = localStorage.getItem("okta-token-storage");

    if (oktaTokenStr) {
      const { accessToken = {}, idToken = {} } = JSON.parse(oktaTokenStr);

    // get socket
      const socketUrl = environment.ambassador
        ? `${environment.baseURL}/selection`
        : environment.services && environment.services.selection.isOverridden
        ? `${environment.elbURL}/selection`
        : `${environment.services.selection.url}`;
      const socket = new SockJS(`${socketUrl}/realTime`);

      // get tokens & create headers
      const headers = {
        Authorization: `Bearer ${accessToken.accessToken}`,
        "x-id-token": idToken.idToken,
      };

      // connect to server
      this.client = webstomp.over(socket, { debug });
      this.client.connect(headers, subscriberFn, () => {
        // retry every 3 seconds
        window.setTimeout(() => {
          this.connect(subscriberFn);
        }, 3000);
      });
    }
  };

  send = (destination, payload) => {
    if (this.client.connected) {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem(
          AuthConstants.KEYS.ACCESS_TOKEN
        )}`,
        "x-id-token": localStorage.getItem(AuthConstants.KEYS.ID_TOKEN),
      };
      const body = JSON.stringify(payload || "");

      this.client.send(`/selection${destination}`, body, headers);
    }
  };

  disconnect = () => this.client.disconnect();

  getServerTime = () => this.send("/status/time");

  getStartTime = (volunteerId) => this.send(`/volunteer/${volunteerId}/time`);

  deleteVolunteerTimer = (volunteerId) =>
    this.send(`/volunteer/${volunteerId}/time/delete`);

  getOpportunities = (eventId) => this.send(`/event/${eventId}/opportunities`);

  getSelectedShifts = (volunteerId) =>
    this.send(`/volunteer/${volunteerId}/shifts`);

  getConfirmedShifts = (volunteerId) =>
    this.send(`/volunteer/${volunteerId}/confirmedShifts`);

  selectShift = (volunteerId, selection) =>
    this.send(`/volunteer/${volunteerId}/selectShift`, selection);

  selectMultiple = (volunteerId, selections) =>
    this.send(`/volunteer/${volunteerId}/selectMultiple`, selections);

  deleteSelectedShift = (volunteerId, selectionId) =>
    this.send(`/volunteer/${volunteerId}/deleteShift/${selectionId}`);

  deleteMultiple = (volunteerId, selectionIds) =>
    this.send(`/volunteer/${volunteerId}/deleteMultipleShifts`, selectionIds);

  updateLiveCounts = (eventId, tierId) =>
    this.send(`/event/${eventId}/tier/${tierId}/shifts`);

  clearSelections = (eventId, tierId, volunteerId) =>
    this.send(
      `/event/${eventId}/tier/${tierId}/volunteer/${volunteerId}/clearSelections`
    );

  persistSelections = (eventId, tierId, volunteerId) =>
    this.send(
      `/event/${eventId}/tier/${tierId}/volunteer/${volunteerId}/persistSelections`
    );

  resetSelectionTimer = (eventId, tierId, volunteerId) =>
    this.send(
      `/event/${eventId}/tier/${tierId}/volunteer/${volunteerId}/resetSelectionTimer`
    );

  // admin requests
  getSelections = (eventId, tierId) =>
    this.send(`/event/${eventId}/tier/${tierId}/selections`);
}

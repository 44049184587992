export const FormConstants = {
  GENDERS: [
    { key: "female", name: "Female", value: "F" },
    { key: "male", name: "Male", value: "M" },
    { key: "other", name: "Other", value: "O" }
  ],
  COUNTRIES: [
    { key: "Afghanistan", name: "Afghanistan", value: "AF" },
    { key: "Åland Islands", name: "Åland Islands", value: "AX" },
    { key: "Albania", name: "Albania", value: "AL" },
    { key: "Algeria", name: "Algeria", value: "DZ" },
    { key: "American Samoa", name: "American Samoa", value: "AS" },
    { key: "Andorra", name: "Andorra", value: "AD" },
    { key: "Angola", name: "Angola", value: "AO" },
    { key: "Anguilla", name: "Anguilla", value: "AI" },
    { key: "Antarctica", name: "Antarctica", value: "AQ" },
    { key: "Antigua and Barbuda", name: "Antigua and Barbuda", value: "AG" },
    { key: "Argentina", name: "Argentina", value: "AR" },
    { key: "Armenia", name: "Armenia", value: "AM" },
    { key: "Aruba", name: "Aruba", value: "AW" },
    { key: "Australia", name: "Australia", value: "AU" },
    { key: "Austria", name: "Austria", value: "AT" },
    { key: "Azerbaijan", name: "Azerbaijan", value: "AZ" },
    { key: "Bahamas", name: "Bahamas", value: "BS" },
    { key: "Bahrain", name: "Bahrain", value: "BH" },
    { key: "Bangladesh", name: "Bangladesh", value: "BD" },
    { key: "Barbados", name: "Barbados", value: "BB" },
    { key: "Belarus", name: "Belarus", value: "BY" },
    { key: "Belgium", name: "Belgium", value: "BE" },
    { key: "Belize", name: "Belize", value: "BZ" },
    { key: "Benin", name: "Benin", value: "BJ" },
    { key: "Bermuda", name: "Bermuda", value: "BM" },
    { key: "Bhutan", name: "Bhutan", value: "BT" },
    { key: "Bolivia", name: "Bolivia", value: "BO" },
    { key: "Bosnia and Herzegovina", name: "Bosnia and Herzegovina", value: "BA" },
    { key: "Botswana", name: "Botswana", value: "BW" },
    { key: "Bouvet Island", name: "Bouvet Island", value: "BV" },
    { key: "Brazil", name: "Brazil", value: "BR" },
    { key: "British Indian Ocean Territory", name: "British Indian Ocean Territory", value: "IO" },
    { key: "Brunei Darussalam", name: "Brunei Darussalam", value: "BN" },
    { key: "Bulgaria", name: "Bulgaria", value: "BG" },
    { key: "Burkina Faso", name: "Burkina Faso", value: "BF" },
    { key: "Burundi", name: "Burundi", value: "BI" },
    { key: "Cambodia", name: "Cambodia", value: "KH" },
    { key: "Cameroon", name: "Cameroon", value: "CM" },
    { key: "Canada", name: "Canada", value: "CA" },
    { key: "Cape Verde", name: "Cape Verde", value: "CV" },
    { key: "Cayman Islands", name: "Cayman Islands", value: "KY" },
    { key: "Central African Republic", name: "Central African Republic", value: "CF" },
    { key: "Chad", name: "Chad", value: "TD" },
    { key: "Chile", name: "Chile", value: "CL" },
    { key: "China", name: "China", value: "CN" },
    { key: "Christmas Island", name: "Christmas Island", value: "CX" },
    { key: "Cocos (Keeling) Islands", name: "Cocos (Keeling) Islands", value: "CC" },
    { key: "Colombia", name: "Colombia", value: "CO" },
    { key: "Comoros", name: "Comoros", value: "KM" },
    { key: "Congo", name: "Congo", value: "CG" },
    { key: "Congo, The Democratic Republic of the",
      name: "Congo, The Democratic Republic of the", value: "CD" },
    { key: "Cook Islands", name: "Cook Islands", value: "CK" },
    { key: "Costa Rica", name: "Costa Rica", value: "CR" },
    { key: "Cote D\"Ivoire", name: "Cote D\"Ivoire", value: "CI" },
    { key: "Croatia", name: "Croatia", value: "HR" },
    { key: "Cuba", name: "Cuba", value: "CU" },
    { key: "Cyprus", name: "Cyprus", value: "CY" },
    { key: "Czech Republic", name: "Czech Republic", value: "CZ" },
    { key: "Denmark", name: "Denmark", value: "DK" },
    { key: "Djibouti", name: "Djibouti", value: "DJ" },
    { key: "Dominica", name: "Dominica", value: "DM" },
    { key: "Dominican Republic", name: "Dominican Republic", value: "DO" },
    { key: "Ecuador", name: "Ecuador", value: "EC" },
    { key: "Egypt", name: "Egypt", value: "EG" },
    { key: "El Salvador", name: "El Salvador", value: "SV" },
    { key: "Equatorial Guinea", name: "Equatorial Guinea", value: "GQ" },
    { key: "Eritrea", name: "Eritrea", value: "ER" },
    { key: "Estonia", name: "Estonia", value: "EE" },
    { key: "Ethiopia", name: "Ethiopia", value: "ET" },
    { key: "Falkland Islands (Malvinas)", name: "Falkland Islands (Malvinas)", value: "FK" },
    { key: "Faroe Islands", name: "Faroe Islands", value: "FO" },
    { key: "Fiji", name: "Fiji", value: "FJ" },
    { key: "Finland", name: "Finland", value: "FI" },
    { key: "France", name: "France", value: "FR" },
    { key: "French Guiana", name: "French Guiana", value: "GF" },
    { key: "French Polynesia", name: "French Polynesia", value: "PF" },
    { key: "French Southern Territories", name: "French Southern Territories", value: "TF" },
    { key: "Gabon", name: "Gabon", value: "GA" },
    { key: "Gambia", name: "Gambia", value: "GM" },
    { key: "Georgia", name: "Georgia", value: "GE" },
    { key: "Germany", name: "Germany", value: "DE" },
    { key: "Ghana", name: "Ghana", value: "GH" },
    { key: "Gibraltar", name: "Gibraltar", value: "GI" },
    { key: "Greece", name: "Greece", value: "GR" },
    { key: "Greenland", name: "Greenland", value: "GL" },
    { key: "Grenada", name: "Grenada", value: "GD" },
    { key: "Guadeloupe", name: "Guadeloupe", value: "GP" },
    { key: "Guam", name: "Guam", value: "GU" },
    { key: "Guatemala", name: "Guatemala", value: "GT" },
    { key: "Guernsey", name: "Guernsey", value: "GG" },
    { key: "Guinea", name: "Guinea", value: "GN" },
    { key: "Guinea-Bissau", name: "Guinea-Bissau", value: "GW" },
    { key: "Guyana", name: "Guyana", value: "GY" },
    { key: "Haiti", name: "Haiti", value: "HT" },
    { key: "Heard Island and Mcdonald Islands",
      name: "Heard Island and Mcdonald Islands", value: "HM" },
    { key: "Holy See (Vatican City State)", name: "Holy See (Vatican City State)", value: "VA" },
    { key: "Honduras", name: "Honduras", value: "HN" },
    { key: "Hong Kong", name: "Hong Kong", value: "HK" },
    { key: "Hungary", name: "Hungary", value: "HU" },
    { key: "Iceland", name: "Iceland", value: "IS" },
    { key: "India", name: "India", value: "IN" },
    { key: "Indonesia", name: "Indonesia", value: "ID" },
    { key: "Iran, Islamic Republic Of", name: "Iran, Islamic Republic Of", value: "IR" },
    { key: "Iraq", name: "Iraq", value: "IQ" },
    { key: "Ireland", name: "Ireland", value: "IE" },
    { key: "Isle of Man", name: "Isle of Man", value: "IM" },
    { key: "Israel", name: "Israel", value: "IL" },
    { key: "Italy", name: "Italy", value: "IT" },
    { key: "Jamaica", name: "Jamaica", value: "JM" },
    { key: "Japan", name: "Japan", value: "JP" },
    { key: "Jersey", name: "Jersey", value: "JE" },
    { key: "Jordan", name: "Jordan", value: "JO" },
    { key: "Kazakhstan", name: "Kazakhstan", value: "KZ" },
    { key: "Kenya", name: "Kenya", value: "KE" },
    { key: "Kiribati", name: "Kiribati", value: "KI" },
    { key: "Korea, Democratic People\"S Republic of",
      name: "Korea, Democratic People\"S Republic of", value: "KP" },
    { key: "Korea, Republic of", name: "Korea, Republic of", value: "KR" },
    { key: "Kuwait", name: "Kuwait", value: "KW" },
    { key: "Kyrgyzstan", name: "Kyrgyzstan", value: "KG" },
    { key: "Lao People\"S Democratic Republic",
      name: "Lao People\"S Democratic Republic", value: "LA" },
    { key: "Latvia", name: "Latvia", value: "LV" },
    { key: "Lebanon", name: "Lebanon", value: "LB" },
    { key: "Lesotho", name: "Lesotho", value: "LS" },
    { key: "Liberia", name: "Liberia", value: "LR" },
    { key: "Libyan Arab Jamahiriya", name: "Libyan Arab Jamahiriya", value: "LY" },
    { key: "Liechtenstein", name: "Liechtenstein", value: "LI" },
    { key: "Lithuania", name: "Lithuania", value: "LT" },
    { key: "Luxembourg", name: "Luxembourg", value: "LU" },
    { key: "Macao", name: "Macao", value: "MO" },
    { key: "Macedonia, The Former Yugoslav Republic of",
      name: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
    { key: "Madagascar", name: "Madagascar", value: "MG" },
    { key: "Malawi", name: "Malawi", value: "MW" },
    { key: "Malaysia", name: "Malaysia", value: "MY" },
    { key: "Maldives", name: "Maldives", value: "MV" },
    { key: "Mali", name: "Mali", value: "ML" },
    { key: "Malta", name: "Malta", value: "MT" },
    { key: "Marshall Islands", name: "Marshall Islands", value: "MH" },
    { key: "Martinique", name: "Martinique", value: "MQ" },
    { key: "Mauritania", name: "Mauritania", value: "MR" },
    { key: "Mauritius", name: "Mauritius", value: "MU" },
    { key: "Mayotte", name: "Mayotte", value: "YT" },
    { key: "Mexico", name: "Mexico", value: "MX" },
    { key: "Micronesia, Federated States of",
      name: "Micronesia, Federated States of", value: "FM" },
    { key: "Moldova, Republic of", name: "Moldova, Republic of", value: "MD" },
    { key: "Monaco", name: "Monaco", value: "MC" },
    { key: "Mongolia", name: "Mongolia", value: "MN" },
    { key: "Montserrat", name: "Montserrat", value: "MS" },
    { key: "Morocco", name: "Morocco", value: "MA" },
    { key: "Mozambique", name: "Mozambique", value: "MZ" },
    { key: "Myanmar", name: "Myanmar", value: "MM" },
    { key: "Namibia", name: "Namibia", value: "NA" },
    { key: "Nauru", name: "Nauru", value: "NR" },
    { key: "Nepal", name: "Nepal", value: "NP" },
    { key: "Netherlands", name: "Netherlands", value: "NL" },
    { key: "Netherlands Antilles", name: "Netherlands Antilles", value: "AN" },
    { key: "New Caledonia", name: "New Caledonia", value: "NC" },
    { key: "New Zealand", name: "New Zealand", value: "NZ" },
    { key: "Nicaragua", name: "Nicaragua", value: "NI" },
    { key: "Niger", name: "Niger", value: "NE" },
    { key: "Nigeria", name: "Nigeria", value: "NG" },
    { key: "Niue", name: "Niue", value: "NU" },
    { key: "Norfolk Island", name: "Norfolk Island", value: "NF" },
    { key: "Northern Mariana Islands", name: "Northern Mariana Islands", value: "MP" },
    { key: "Norway", name: "Norway", value: "NO" },
    { key: "Oman", name: "Oman", value: "OM" },
    { key: "Pakistan", name: "Pakistan", value: "PK" },
    { key: "Palau", name: "Palau", value: "PW" },
    { key: "Palestinian Territory, Occupied",
      name: "Palestinian Territory, Occupied", value: "PS" },
    { key: "Panama", name: "Panama", value: "PA" },
    { key: "Papua New Guinea", name: "Papua New Guinea", value: "PG" },
    { key: "Paraguay", name: "Paraguay", value: "PY" },
    { key: "Peru", name: "Peru", value: "PE" },
    { key: "Philippines", name: "Philippines", value: "PH" },
    { key: "Pitcairn", name: "Pitcairn", value: "PN" },
    { key: "Poland", name: "Poland", value: "PL" },
    { key: "Portugal", name: "Portugal", value: "PT" },
    { key: "Puerto Rico", name: "Puerto Rico", value: "PR" },
    { key: "Qatar", name: "Qatar", value: "QA" },
    { key: "Reunion", name: "Reunion", value: "RE" },
    { key: "Romania", name: "Romania", value: "RO" },
    { key: "Russian Federation", name: "Russian Federation", value: "RU" },
    { key: "RWANDA", name: "RWANDA", value: "RW" },
    { key: "Saint Helena", name: "Saint Helena", value: "SH" },
    { key: "Saint Kitts and Nevis", name: "Saint Kitts and Nevis", value: "KN" },
    { key: "Saint Lucia", name: "Saint Lucia", value: "LC" },
    { key: "Saint Pierre and Miquelon", name: "Saint Pierre and Miquelon", value: "PM" },
    { key: "Saint Vincent and the Grenadines",
      name: "Saint Vincent and the Grenadines", value: "VC" },
    { key: "Samoa", name: "Samoa", value: "WS" },
    { key: "San Marino", name: "San Marino", value: "SM" },
    { key: "Sao Tome and Principe", name: "Sao Tome and Principe", value: "ST" },
    { key: "Saudi Arabia", name: "Saudi Arabia", value: "SA" },
    { key: "Senegal", name: "Senegal", value: "SN" },
    { key: "Serbia and Montenegro", name: "Serbia and Montenegro", value: "CS" },
    { key: "Seychelles", name: "Seychelles", value: "SC" },
    { key: "Sierra Leone", name: "Sierra Leone", value: "SL" },
    { key: "Singapore", name: "Singapore", value: "SG" },
    { key: "Slovakia", name: "Slovakia", value: "SK" },
    { key: "Slovenia", name: "Slovenia", value: "SI" },
    { key: "Solomon Islands", name: "Solomon Islands", value: "SB" },
    { key: "Somalia", name: "Somalia", value: "SO" },
    { key: "South Africa", name: "South Africa", value: "ZA" },
    { key: "South Georgia and the South Sandwich Islands",
      name: "South Georgia and the South Sandwich Islands", value: "GS" },
    { key: "Spain", name: "Spain", value: "ES" },
    { key: "Sri Lanka", name: "Sri Lanka", value: "LK" },
    { key: "Sudan", name: "Sudan", value: "SD" },
    { key: "Suriname", name: "Suriname", value: "SR" },
    { key: "Svalbard and Jan Mayen", name: "Svalbard and Jan Mayen", value: "SJ" },
    { key: "Swaziland", name: "Swaziland", value: "SZ" },
    { key: "Sweden", name: "Sweden", value: "SE" },
    { key: "Switzerland", name: "Switzerland", value: "CH" },
    { key: "Syrian Arab Republic", name: "Syrian Arab Republic", value: "SY" },
    { key: "Taiwan, Province of China", name: "Taiwan, Province of China", value: "TW" },
    { key: "Tajikistan", name: "Tajikistan", value: "TJ" },
    { key: "Tanzania, United Republic of", name: "Tanzania, United Republic of", value: "TZ" },
    { key: "Thailand", name: "Thailand", value: "TH" },
    { key: "Timor-Leste", name: "Timor-Leste", value: "TL" },
    { key: "Togo", name: "Togo", value: "TG" },
    { key: "Tokelau", name: "Tokelau", value: "TK" },
    { key: "Tonga", name: "Tonga", value: "TO" },
    { key: "Trinidad and Tobago", name: "Trinidad and Tobago", value: "TT" },
    { key: "Tunisia", name: "Tunisia", value: "TN" },
    { key: "Turkey", name: "Turkey", value: "TR" },
    { key: "Turkmenistan", name: "Turkmenistan", value: "TM" },
    { key: "Turks and Caicos Islands", name: "Turks and Caicos Islands", value: "TC" },
    { key: "Tuvalu", name: "Tuvalu", value: "TV" },
    { key: "Uganda", name: "Uganda", value: "UG" },
    { key: "Ukraine", name: "Ukraine", value: "UA" },
    { key: "United Arab Emirates", name: "United Arab Emirates", value: "AE" },
    { key: "United Kingdom", name: "United Kingdom", value: "GB" },
    { key: "United States", name: "United States", value: "US" },
    { key: "United States Minor Outlying Islands",
      name: "United States Minor Outlying Islands", value: "UM" },
    { key: "Uruguay", name: "Uruguay", value: "UY" },
    { key: "Uzbekistan", name: "Uzbekistan", value: "UZ" },
    { key: "Vanuatu", name: "Vanuatu", value: "VU" },
    { key: "Venezuela", name: "Venezuela", value: "VE" },
    { key: "Viet Nam", name: "Viet Nam", value: "VN" },
    { key: "Virgin Islands, British", name: "Virgin Islands, British", value: "VG" },
    { key: "Virgin Islands, U.S.", name: "Virgin Islands, U.S.", value: "VI" },
    { key: "Wallis and Futuna", name: "Wallis and Futuna", value: "WF" },
    { key: "Western Sahara", name: "Western Sahara", value: "EH" },
    { key: "Yemen", name: "Yemen", value: "YE" },
    { key: "Zambia", name: "Zambia", value: "ZM" },
    { key: "Zimbabwe", name: "Zimbabwe", value: "ZW" }
  ],
  LANGUAGES: [
    { key: "en", name: "English", value: "en" },
    { key: "es", name: "Spanish", value: "es" }
  ],
  PHONE_TYPES: [
    { key: "m", name: "Mobile", value: "m" },
    { key: "h", name: "Home", value: "h" }
  ],
  YES_NO: [
    { key: "y", name: "Yes", value: "y" },
    { key: "n", name: "No", value: "n" }
  ],
  SCORES: [
    { key: "po", name: "Pass - Online", value: "Pass - Online" },
    { key: "f", name: "Fail", value: "Fail" },
    { key: "n", name: "N/A", value: null }
  ],
  BG_CHECK_SCORES: [
    { key: "p", name: "Pass", value: "Pass" },
    { key: "f", name: "Fail", value: "Fail" },
    { key: "n", name: "N/A", value: null }
  ],
  STATES: [
    {
      key: "AL",
      name: "Alabama",
      value: "AL"
    },
    {
      key: "AK",
      name: "Alaska",
      value: "AK"
    },
    {
      key: "AS",
      name: "American Samoa",
      value: "AS"
    },
    {
      key: "AZ",
      name: "Arizona",
      value: "AZ"
    },
    {
      key: "AR",
      name: "Arkansas",
      value: "AR"
    },
    {
      key: "CA",
      name: "California",
      value: "CA"
    },
    {
      key: "CO",
      name: "Colorado",
      value: "CO"
    },
    {
      key: "CT",
      name: "Connecticut",
      value: "CT"
    },
    {
      key: "DE",
      name: "Delaware",
      value: "DE"
    },
    {
      key: "DC",
      name: "Washington, D.C.",
      value: "DC"
    },
    {
      key: "FM",
      name: "Federated States Of Micronesia",
      value: "FM"
    },
    {
      key: "FL",
      name: "Florida",
      value: "FL"
    },
    {
      key: "GA",
      name: "Georgia",
      value: "GA"
    },
    {
      key: "GU",
      name: "Guam",
      value: "GU"
    },
    {
      key: "HI",
      name: "Hawaii",
      value: "HI"
    },
    {
      key: "ID",
      name: "Idaho",
      value: "ID"
    },
    {
      key: "IL",
      name: "Illinois",
      value: "IL"
    },
    {
      key: "IN",
      name: "Indiana",
      value: "IN"
    },
    {
      key: "IA",
      name: "Iowa",
      value: "IA"
    },
    {
      key: "KS",
      name: "Kansas",
      value: "KS"
    },
    {
      key: "KY",
      name: "Kentucky",
      value: "KY"
    },
    {
      key: "LA",
      name: "Louisiana",
      value: "LA"
    },
    {
      key: "ME",
      name: "Maine",
      value: "ME"
    },
    {
      key: "MH",
      name: "Marshall Islands",
      value: "MH"
    },
    {
      key: "MD",
      name: "Maryland",
      value: "MD"
    },
    {
      key: "MA",
      name: "Massachusetts",
      value: "MA"
    },
    {
      key: "MI",
      name: "Michigan",
      value: "MI"
    },
    {
      key: "MN",
      name: "Minnesota",
      value: "MN"
    },
    {
      key: "MS",
      name: "Mississippi",
      value: "MS"
    },
    {
      key: "MO",
      name: "Missouri",
      value: "MO"
    },
    {
      key: "MT",
      name: "Montana",
      value: "MT"
    },
    {
      key: "NE",
      name: "Nebraska",
      value: "NE"
    },
    {
      key: "NV",
      name: "Nevada",
      value: "NV"
    },
    {
      key: "NH",
      name: "New Hampshire",
      value: "NH"
    },
    {
      key: "NJ",
      name: "New Jersey",
      value: "NJ"
    },
    {
      key: "NM",
      name: "New Mexico",
      value: "NM"
    },
    {
      key: "NY",
      name: "New York",
      value: "NY"
    },
    {
      key: "NC",
      name: "North Carolina",
      value: "NC"
    },
    {
      key: "ND",
      name: "North Dakota",
      value: "ND"
    },
    {
      key: "MP",
      name: "Northern Mariana Islands",
      value: "MP"
    },
    {
      key: "OH",
      name: "Ohio",
      value: "OH"
    },
    {
      key: "OK",
      name: "Oklahoma",
      value: "OK"
    },
    {
      key: "OR",
      name: "Oregon",
      value: "OR"
    },
    {
      key: "PW",
      name: "Palau",
      value: "PW"
    },
    {
      key: "PA",
      name: "Pennsylvania",
      value: "PA"
    },
    {
      key: "PR",
      name: "Puerto Rico",
      value: "PR"
    },
    {
      key: "RI",
      name: "Rhode Island",
      value: "RI"
    },
    {
      key: "SC",
      name: "South Carolina",
      value: "SC"
    },
    {
      key: "SD",
      name: "South Dakota",
      value: "SD"
    },
    {
      key: "TN",
      name: "Tennessee",
      value: "TN"
    },
    {
      key: "TX",
      name: "Texas",
      value: "TX"
    },
    {
      key: "UT",
      name: "Utah",
      value: "UT"
    },
    {
      key: "VT",
      name: "Vermont",
      value: "VT"
    },
    {
      key: "VI",
      name: "Virgin Islands",
      value: "VI"
    },
    {
      key: "VA",
      name: "Virginia",
      value: "VA"
    },
    {
      key: "WA",
      name: "Washington",
      value: "WA"
    },
    {
      key: "WV",
      name: "West Virginia",
      value: "WV"
    },
    {
      key: "WI",
      name: "Wisconsin",
      value: "WI"
    },
    {
      key: "WY",
      name: "Wyoming",
      value: "WY"
    }
  ],
  LOCATIONS: [
    {
      key: "2401OntarioStreet|Cleveland|OH|44115|UnitedStates",
      name: "Progressive Field",
      value: {
        street1: "2401 Ontario Street",
        city: "Cleveland",
        state: "OH",
        zipCode: "44115",
        country: "United States"
      }
    },
    {
      key: "333WestCamdenStreet|Baltimore|MD|21201|UnitedStates",
      name: "Oriole Park",
      value: {
        street1: "333 West Camden Street",
        city: "Baltimore",
        state: "MD",
        zipCode: "21201",
        country: "United States"
      }
    },
    {
      key: "4YawkeyWay|Boston|MA|02215|UnitedStates",
      name: "Fenway Park",
      value: {
        street1: "4 Yawkey Way",
        city: "Boston",
        state: "MA",
        zipCode: "02215",
        country: "United States"
      }
    },
    {
      key: "333West35thStreet|Chicago|IL|60616|UnitedStates",
      name: "Guaranteed Rate Field",
      value: {
        street1: "333 West 35th Street",
        city: "Chicago",
        state: "IL",
        zipCode: "60616",
        country: "United States"
      }
    },
    {
      key: "2100WoodwardAvenue|Detroit|MI|48201|UnitedStates",
      name: "Comerica Park",
      value: {
        street1: "2100 Woodward Avenue",
        city: "Detroit",
        state: "MI",
        zipCode: "48201",
        country: "United States"
      }
    },
    {
      key: "501CrawfordStreet|Houston|TX|77002|UnitedStates",
      name: "Minute Maid Park",
      value: {
        street1: "501 Crawford Street",
        city: "Houston",
        state: "TX",
        zipCode: "77002",
        country: "United States"
      }
    },
    {
      key: "OneRoyalWay|KansasCity|MO|64129|UnitedStates",
      name: "Kauffman Stadium",
      value: {
        street1: "One Royal Way",
        city: "Kansas City",
        state: "MO",
        zipCode: "64129",
        country: "United States"
      }
    },
    {
      key: "2000GeneAutryWay|Anaheim|CA|92806|UnitedStates",
      name: "Angel Stadium",
      value: {
        street1: "2000 Gene Autry Way",
        city: "Anaheim",
        state: "CA",
        zipCode: "92806",
        country: "United States"
      }
    },
    {
      key: "1TwinsWay|Minneapolis|MN|55403|UnitedStates",
      name: "Target Field",
      value: {
        street1: "1 Twins Way",
        city: "Minneapolis",
        state: "MN",
        zipCode: "55403",
        country: "United States"
      }
    },
    {
      key: "OneEast161stStreet|Bronx|NY|10451|UnitedStates",
      name: "Yankee Stadium",
      value: {
        street1: "One East 161st Street",
        city: "Bronx",
        state: "NY",
        zipCode: "10451",
        country: "United States"
      }
    },
    {
      key: "7000ColiseumWay|Oakland|CA|94621|UnitedStates",
      name: "Oakland Coliseum",
      value: {
        street1: "7000 Coliseum Way",
        city: "Oakland",
        state: "CA",
        zipCode: "94621",
        country: "United States"
      }
    },
    {
      key: "P.O.Box4100|Seattle|WA|98104|UnitedStates",
      name: "T-Mobile Park",
      value: {
        street1: "P.O. Box 4100",
        city: "Seattle",
        state: "WA",
        zipCode: "98104",
        country: "United States"
      }
    },
    {
      key: "OneTropicanaDrive|St.Petersburg|FL|33705|UnitedStates",
      name: "Tropicana Field",
      value: {
        street1: "One Tropicana Drive",
        city: "St. Petersburg",
        state: "FL",
        zipCode: "33705",
        country: "United States"
      }
    },
    {
      key: "1000BallparkWay|Arlington|TX|76011|UnitedStates",
      name: "Globe Life Park in Arlington",
      value: {
        street1: "1000 Ballpark Way",
        city: "Arlington",
        state: "TX",
        zipCode: "76011",
        country: "United States"
      }
    },
    {
      key: "1BlueJaysWay,Suite3200|Toronto|Ontario|M5V1J1|Canada",
      name: "Rogers Centre",
      value: {
        street1: "1 Blue Jays Way, Suite 3200",
        city: "Toronto",
        state: "Ontario",
        zipCode: "M5V1J1",
        country: "Canada"
      }
    },
    {
      key: "401EastJeffersonStreet|Phoenix|AZ|85004|UnitedStates",
      name: "Chase Field",
      value: {
        street1: "401 East Jefferson Street",
        city: "Phoenix",
        state: "AZ",
        zipCode: "85004",
        country: "United States"
      }
    },
    {
      key: "755BatteryAvenue|Atlanta|GA|30339|UnitedStates",
      name: "SunTrust Park",
      value: {
        street1: "755 Battery Avenue",
        city: "Atlanta",
        state: "GA",
        zipCode: "30339",
        country: "United States"
      }
    },
    {
      key: "1060WestAddison|Chicago|IL|60613-4397|UnitedStates",
      name: "Wrigley Field",
      value: {
        street1: "1060 West Addison",
        city: "Chicago",
        state: "IL",
        zipCode: "60613-4397",
        country: "United States"
      }
    },
    {
      key: "100MainStreet|Cincinnati|OH|45202-4109|UnitedStates",
      name: "Great American Ball Park",
      value: {
        street1: "100 Main Street",
        city: "Cincinnati",
        state: "OH",
        zipCode: "45202-4109",
        country: "United States"
      }
    },
    {
      key: "2001BlakeStreet|Denver|CO|80205-2000|UnitedStates",
      name: "Coors Field",
      value: {
        street1: "2001 Blake Street",
        city: "Denver",
        state: "CO",
        zipCode: "80205-2000",
        country: "United States"
      }
    },
    {
      key: "1000VinScullyAvenue|LosAngeles|CA|90012|UnitedStates",
      name: "Dodger Stadium",
      value: {
        street1: "1000 Vin Scully Avenue",
        city: "Los Angeles",
        state: "CA",
        zipCode: "90012",
        country: "United States"
      }
    },
    {
      key: "501MarlinsWay|Miami|FL|33125|UnitedStates",
      name: "Marlins Park",
      value: {
        street1: "501 Marlins Way",
        city: "Miami",
        state: "FL",
        zipCode: "33125",
        country: "United States"
      }
    },
    {
      key: "OneBrewersWay|Milwaukee|WI|53214|UnitedStates",
      name: "Miller Park",
      value: {
        street1: "One Brewers Way",
        city: "Milwaukee",
        state: "WI",
        zipCode: "53214",
        country: "United States"
      }
    },
    {
      key: "120-01RooseveltAvenue|Corona|NY|11368|UnitedStates",
      name: "Citi Field",
      value: {
        street1: "120-01 Roosevelt Avenue",
        city: "Corona",
        state: "NY",
        zipCode: "11368",
        country: "United States"
      }
    },
    {
      key: "OneCitizensBankWay|Philadelphia|PA|19148|UnitedStates",
      name: "Citizens Bank Park",
      value: {
        street1: "One Citizens Bank Way",
        city: "Philadelphia",
        state: "PA",
        zipCode: "19148",
        country: "United States"
      }
    },
    {
      key: "115FederalStreet|Pittsburgh|PA|15212|UnitedStates",
      name: "PNC Park",
      value: {
        street1: "115 Federal Street",
        city: "Pittsburgh",
        state: "PA",
        zipCode: "15212",
        country: "United States"
      }
    },
    {
      key: "100ParkBoulevard|SanDiego|CA|92101|UnitedStates",
      name: "Petco Park",
      value: {
        street1: "100 Park Boulevard",
        city: "San Diego",
        state: "CA",
        zipCode: "92101",
        country: "United States"
      }
    },
    {
      key: "24WillieMaysPlaza|SanFrancisco|CA|94107|UnitedStates",
      name: "Oracle Park",
      value: {
        street1: "24 Willie Mays Plaza",
        city: "San Francisco",
        state: "CA",
        zipCode: "94107",
        country: "United States"
      }
    },
    {
      key: "700ClarkStreet|St.Louis|MO|63102|UnitedStates",
      name: "Busch Stadium",
      value: {
        street1: "700 Clark Street",
        city: "St. Louis",
        state: "MO",
        zipCode: "63102",
        country: "United States"
      }
    },
    {
      key: "1500SouthCapitolStreet,SE|Washington|DC|20003-1507|UnitedStates",
      name: "Nationals Park",
      value: {
        street1: "1500 South Capitol Street, SE",
        city: "Washington",
        state: "DC",
        zipCode: "20003-1507",
        country: "United States"
      }
    }
  ],
  REGEX: {
    EMAIL: new RegExp(
      [
        "^(([^<>()[\\]\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\"]+)*)",
        "|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.",
        "[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+",
        "[a-zA-Z]{2,}))$"
      ].join("")
    ),
    /* eslint-disable no-control-regex, no-useless-escape */
    PHONE: /([\sA-Za-z`~!@#$%^&*()\-_=+\[{}\]\\|;:'",<.>/?]|[^\x00-\x7F])/g,
    MOBILE_BROWSER: new RegExp(
      [
        "(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fen",
        "nec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|",
        "netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(",
        "4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a",
        ")||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn",
        ")|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|",
        "avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cd",
        "m\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|d",
        "o(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|f",
        "ly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|h",
        "ei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma",
        ")|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|j",
        "igs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54",
        "|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|m",
        "i(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-",
        "2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i",
        ")|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pir",
        "e|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i",
        "\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|",
        "p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|",
        "ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|",
        "lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|",
        "si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|6",
        "1|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|",
        "zeto|zte\-"
      ].join(""),
      "i"
    )
    /* eslint-enable no-control-regex, no-useless-escape */
  },
  TABLE_HEIGHT: "450px",
  OPPORTUNITY_LOCATIONS: {
    ALL: {
      key: "all",
      name: "flow.insideAndOutsideOpps",
      value: "all"
    },
    INSIDE: {
      key: "inside",
      name: "flow.insideOpps",
      value: "inside"
    },
    OUTSIDE: {
      key: "outside",
      name: "flow.outsideOpps",
      value: "outside"
    }
  },
  IMPORT_RESULT_FILTERS: [
    {
      key:"all",
      name: "All",
      value: ""
    },
    {
      key:"dupNotProcessed",
      name:"Duplicate Emails - Not Processed",
      value: "N"
    },
    {
      key:"dupProcessed",
      name:"Duplicate Emails - Processed",
      value: "Y"
    }
  ],
  OPPORTUNITY_FILTERS: {
    YES: {
      key: "yes",
      name: "Yes",
      value: "yes"
    },
    NO: {
      key: "no",
      name: "No",
      value: "no"
    },
    ALL: {
      key: "all",
      name: "All",
      value: "all"
    },
    YES_NO_ALL: [
      {
        key: "yes",
        name: "Yes",
        value: "yes"
      },
      {
        key: "no",
        name: "No",
        value: "no"
      },
      {
        key: "all",
        name: "All",
        value: "all"
      },
    ],
  },
  VOLUNTEER_FILTERS: {
    YES: {
      key: "yes",
      name: "Yes",
      value: "yes"
    },
    NO: {
      key: "no",
      name: "No",
      value: "no"
    },
    ALL: {
      key: "all",
      name: "All",
      value: "all"
    },
    NONE: {
      key: "none",
      name: "None",
      value: "none"
    },
    SELECTED: {
      key: "selected",
      name: "Selected",
      value: "selected"
    },
    ANY: {
      key: "any",
      name: "Any",
      value: null
    },
    YES_NO: [
      {
        key: "yes",
        name: "Yes",
        value: "yes"
      },
      {
        key: "no",
        name: "No",
        value: "no"
      }
    ],
    YES_NO_ALL: [
      {
        key: "yes",
        name: "Yes",
        value: "yes"
      },
      {
        key: "no",
        name: "No",
        value: "no"
      },
      {
        key: "all",
        name: "All",
        value: "all"
      },
    ],
    CONSENT: [
      {
        key: "started",
        name: "Started",
        value: "started"
      },
      {
        key: "yes",
        name: "Yes",
        value: "yes"
      },
      {
        key: "no",
        name: "No",
        value: "no"
      }
    ],
    SCORE: [
      {
        key: "passInPerson",
        name: "Pass (In Person)",
        value: "passInPerson"
      },
      {
        key: "passOnline",
        name: "Pass (Online)",
        value: "passOnline"
      },
      {
        key: "fail",
        name: "Fail",
        value: "fail"
      },
      {
        key: "na",
        name: "N/A",
        value: "na"
      }
    ],
    NUM_SHIFTS: [
      {
        key: 0,
        name: "0",
        value: 0
      },
      {
        key: 1,
        name: "1",
        value: 1
      },
      {
        key: 2,
        name: "2",
        value: 2
      },
      {
        key: 3,
        name: "3",
        value: 3
      }
    ],
    PHOTO: [
      {
        key: "approved",
        name: "Approved",
        value: "approved"
      },
      {
        key: "rejected",
        name: "Rejected",
        value: "rejected"
      },
      {
        key: "pending",
        name: "Pending",
        value: "pending"
      },
      {
        key: "na",
        name: "N/A",
        value: "na"
      }
    ]
  },
  FAILED_REASONS: [
    {
      key: "rude",
      name: "Rude to interviewer",
      value: "Rude to interviewer"
    },
    {
      key: "intoxicated",
      name: "Appeared intoxicated",
      value: "Appeared intoxicated"
    },
    {
      key: "declined",
      name: "Declined to participate in volunteer program",
      value: "Declined to participate in volunteer program"
    },
    {
      key: "kids",
      name: "Does not like kids",
      value: "Does not like kids"
    }
  ],
  REQUIRED_OPPORTUNITIES: [
    {
      key: 0,
      name: "0",
      value: 0
    },
    {
      key: 1,
      name: "1",
      value: 1
    },
    {
      key: 2,
      name: "2",
      value: 2
    },
    {
      key: 3,
      name: "3",
      value: 3
    },
    {
      key: 4,
      name: "4",
      value: 4
    },
    {
      key: 5,
      name: "5",
      value: 5
    },
    {
      key: 6,
      name: "6",
      value: 6
    },
    {
      key: 7,
      name: "7",
      value: 7
    },
    {
      key: 8,
      name: "8",
      value: 8
    },
    {
      key: 9,
      name: "9",
      value: 9
    },
    {
      key: 10,
      name: "10",
      value: 10
    }
  ],
  MAX_OPPORTUNITIES_PER_DAY: [
    {
      key: 1,
      name: "1",
      value: 1
    },
    {
      key: 2,
      name: "2",
      value: 2
    }
  ],
  REPORT_OPTIONS: [
    {
      key: "nonUSVolunteers",
      name: "Non-US Volunteers",
      value: "nonUSVolunteers"
    },
    {
      key: "opportunity",
      name: "Opportunity",
      value: "opportunity"
    },
    {
      key: "volunteerSelections",
      name: "Volunteer Selections",
      value: "volunteerSelections"
    },
    {
      key: "alumniCertificates",
      name: "Alumni Certificates",
      value: "alumniCertificates"
    }
  ],
  PHOTO_STATUS_OPTION: [
    {
      key: "Pending",
      name: "Pending",
      value: "Pending"
    },
    {
      key: "Approved",
      name: "Approved",
      value: "Approved"
    },
    {
      key: "Rejected",
      name: "Rejected",
      value: "Rejected"
    }

  ],
  PHOTO_REJECTION_REASONS: [
    {
      key: "blurry",
      name: "The photo is blurry",
      value: "blurry"
    },
    {
      key: "facing",
      name: "Your head is not facing the camera",
      value: "facing"
    },
    {
      key: "neutral",
      name: "You're making an unnatural face or have your eyes closed",
      value: "neutral"
    },
    {
      key: "background",
      name: "Your background is too busy",
      value: "background"
    },
    {
      key: "hat",
      name: "You're wearing a hat or head covering not required for medical or religious purposes",
      value: "hat"
    },
    {
      key: "headphones",
      name: "You're wearing headphones or a wireless hands-free device",
      value: "headphones"
    },
    {
      key: "format",
      name: "You uploaded the wrong file format.  A .jpeg is required",
      value: "format"
    },
    {
      key: "shoulders",
      name: "Your photo is not cropped tight enough.  A photo from the shoulder up is required",
      value: "shoulders"
    }
  ],
  ALLOW_VIRTUAL_INFO_SESSION_OPTIONS: [
    { display: "Yes", value: true },
    { display: "No", value: false }
  ],
};

import moment from "moment";
import { extendObservable, action, autorun, computed, decorate, observable } from "mobx";
import { FormConstants } from "../constants/FormConstants";
import { NotificationConstants } from "../constants/NotificationConstants";
import { RouteConstants } from "../constants/RouteConstants";
import { StoreUtilities } from "../utilities/StoreUtilities";
import { StringUtilities } from "../utilities/StringUtilities";
import { DateUtilities } from "../utilities/DateUtilities";
import { EditorState } from "draft-js";
import { EditorUtilities } from "../utilities/EditorUtilities";
import decorator from "../components/elements/form/TextEditorDecorators";

export default class OpportunityStore {
  constructor(
    programApi,
    authApi,
    routerStore,
    authStore,
    eventStore,
    gridStore,
    notificationStore,
    blockingStore,
    i18n
  ) {
    this.programApi = programApi;
    this.authApi = authApi;
    this.routerStore = routerStore;
    this.authStore = authStore;
    this.eventStore = eventStore;
    this.gridStore = gridStore;
    this.notificationStore = notificationStore;
    this.blockingStore = blockingStore;
    this.i18n = i18n;

    this.defaults = {
      opportunitiesLoading: false,
      savingOpportunity: false,
      opportunityId: undefined,
      opportunity: {},
      opportunities: [],
      opportunitiesPendingDelete: [],
      shifts: [],
      linkShifts: false,
      contacts: {},
      zones: [],
      contactKeys: [],
      sortFilters: {
        key: "name",
        direction: "ASC",
        initial: []
      },
      contactFilters: {
        key: "name",
        direction: "ASC",
        initial: []
      },
      searchOpportunities: "",
      name: "",
      description: EditorState.createEmpty(decorator),
      reportingLocation: "",
      location: "",
      selectedLocation: undefined,
      street1: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      locationOptions: [],
      initialLocationOptions: [],
      opportunityFormDirty: false,
      opportunityTableDirty: false,
      opportunityLoadError: false,
      loadOpportunityCalled: false,
      dateSameAsEvent: false,
      isOpportunityDatesOpen: false,
      date: "",
      dates: [],
      newLocationId: undefined,
      newLocationName: "",
      newLocationStreet1: "",
      newLocationStreet2: "",
      newLocationStreet3: "",
      newLocationCountry: FormConstants.COUNTRIES.find(c => c.key === "United States").name,
      newLocationCity: "",
      newLocationState: "",
      newLocationZipCode: "",
      newLocationOpen: false,
      newLocationExists: false,
      validationErrorMessages: [],
      locationSetupCalled: false,
      locationFilterName: FormConstants.OPPORTUNITY_LOCATIONS.ALL.name,
      locationFilterKey: FormConstants.OPPORTUNITY_LOCATIONS.ALL.key,
      opportunityDateMap: {},
      shiftDateToShiftAndOppMap: {},
      opportunityReportFilters: {
        opportunities: [],
        dates: [],
        locations: [],
        reportingLocations: [],
      },
      selectedDate: "",
      mappedZones: [],
      shiftCount: 0,
      selectedCopyDate: "",
      selectedPasteDate: "",
      isCopyShiftsOpen: false
    };

    // only actions & overrides need to go in here
    extendObservable(this, {
      ...StoreUtilities.initialize(this),

      clearOpportunityAttributes: action(() => {
        this.name = this.defaults.name;
        this.opportunityId = this.defaults.opportunityId;
        this.opportunity = this.defaults.opportunity;
        this.description = this.defaults.description;
        this.location = this.defaults.location;
        this.selectedLocation = this.defaults.selectedLocation;
        this.street1 = this.defaults.street1;
        this.city = this.defaults.city;
        this.state = this.defaults.state;
        this.zipCode = this.defaults.zipCode;
        this.country = this.defaults.country;
        this.contacts = this.defaults.contacts;
        this.contactKeys = this.defaults.contactKeys;
        this.contactFilters = this.defaults.contactFilters;
        this.zones = this.defaults.zones;
        this.initialZones = this.defaults.initialZones;
        this.sortFilters = this.defaults.sortFilters;
        this.opportunity = this.defaults.opportunity;
        this.reportingLocation = this.defaults.reportingLocation;
        this.initialLocationOptions = this.defaults.initialLocationOptions;
        this.locationOptions = this.defaults.locationOptions;
        this.dateSameAsEvent = this.defaults.dateSameAsEvent;
        this.isOpportunityDatesOpen = this.defaults.isOpportunityDatesOpen;
        this.date = this.defaults.date;
        this.dates = this.defaults.dates;
        this.newLocationId = this.defaults.newLocationId;
        this.newLocationName = this.defaults.newLocationName;
        this.newLocationStreet1 = this.defaults.newLocationStreet1;
        this.newLocationStreet2 = this.defaults.newLocationStreet2;
        this.newLocationStreet3 = this.defaults.newLocationStreet3;
        this.newLocationCountry = this.defaults.newLocationCountry;
        this.newLocationCity = this.defaults.newLocationCity;
        this.newLocationState = this.defaults.newLocationState;
        this.newLocationZipCode = this.defaults.newLocationZipCode;
        this.newLocationOpen = this.defaults.newLocationOpen;
        this.newLocationExists = this.defaults.newLocationExists;
        this.validationErrorMessages = this.defaults.validationErrorMessages;
        this.locationSetupCalled = this.defaults.locationSetupCalled;
        this.linkShifts = this.defaults.linkShifts;
        this.opportunityReportFilters = this.defaults.opportunityReportFilters;
        this.selectedDate = this.defaults.selectedDate;
        this.mappedZones = this.defaults.mappedZones;
        this.shiftCount = this.defaults.shiftCount;
        this.selectedCopyDate = this.defaults.selectedCopyDate;
        this.selectedPasteDate = this.defaults.selectedPasteDate;
        this.isCopyShiftsOpen = this.defaults.isCopyShiftsOpen;
      }),
      setOpportunityAttributes: action(item => {
        this.opportunity = item;
        this.opportunityId = item.id;
        this.name = item.name;
        this.description = EditorUtilities.stateFromRawString(item.description);
        this.setupLocation(item);
        this.locationSetupCalled = true;
        this.street1 = item.street1;
        this.city = item.city;
        this.state = item.state;
        this.zipCode = item.zipCode;
        this.country = item.country;
        this.reportingLocation = item.reportingLocation;
        this.contacts = this.generateKeys(item.contacts, "contactKeys");
        this.contactFilters.initial = this.contactKeys;
        this.dates = !item.dates ? []: item.dates.map(d => ({
          ...d,
          date: DateUtilities.dateToString(d.date)
        }));
        this.linkShifts = item.linkShifts;
        this.selectedDate = this.dates[0].date;
        this.shiftCount = item.shiftCount;
        // set zones
        const tiers = (this.eventStore.event || {}).tiers || [];
        this.mappedZones = Object.values(item.mappedZones).map(z => ({
          ...z,
          key: z.number
        }));
        this.mappedZones.forEach(z => {
          Object.keys(z.mappedShifts).forEach(s => {
            z.mappedShifts[s].forEach(s2 => {
              s2.startTime = DateUtilities.parseString(s2.startTime, "HH:mm:00");
              s2.endTime = DateUtilities.parseString(s2.endTime, "HH:mm:00");
              s2.total = s2.tiers.reduce(
                (p, _, i) => p + (s2.tiers[i] || {}).volunteerCount || 0,
                0
              );
            });
          });
        });
        this.zones = item.zones.sort((a, b) => a.number - b.number).map(z => ({
          ...z,
          shifts: z.shifts.map(s => ({
            ...s,
            key: StringUtilities.random(),
            allDatesSelected: this.dates.length === s.dates.length,
            startTime: DateUtilities.parseString(s.startTime, "HH:mm:00"),
            endTime: DateUtilities.parseString(s.endTime, "HH:mm:00"),
            dates: s.dates.map(d => ({
              ...d,
              date: DateUtilities.dateToString(d.date)
            })),
            tiers: tiers.map((_, i) => ({
              ...(s.tiers[i] || {
                tierId: _.id,
                volunteerCount: ""
              })
            })),
            total: tiers.reduce(
              (p, _, i) => p + (s.tiers[i] || {}).volunteerCount || 0,
              0
            )
          }))
        }));

        // perform any initial sorts
        this.sortContactsByColumn(
          this.contactFilters.key,
          this.contactFilters.direction
        );
      }),
      setValidationErrorMessages: action(messages => {
        this.validationErrorMessages = messages;
      }),
      clearValidationErrorMessages: action(() => {
        this.validationErrorMessages = [];
      }),
      showOpportunityDetails: action(() => {
        this.clearOpportunityAttributes();
        this.addContact();
        this.routerStore.pushWithQueryParams(RouteConstants.NEW_OPPORTUNITY, {
          eventId: this.eventStore.eventId
        });
      }),
      dateSameAsEventToggle: action(() => {
        this.dateSameAsEvent = !this.dateSameAsEvent;

        if (this.dateSameAsEvent) {
          const datesMap = (this.opportunity.dates || []).reduce((p, c) => ({
            ...p,
            [DateUtilities.dateToString(c.date)]: c
          }), {});

          this.dates = this.getDateOptions(this.eventStore.event).map(o => {
            return Object.assign({}, datesMap[o.value] || {}, {
              date: o.value
            });
          });
        }
      }),
      toggleAllShiftDates: action((zoneNumber, idx) => {
        this.zones[zoneNumber - 1].shifts[idx].allDatesSelected =
          !this.zones[zoneNumber - 1].shifts[idx].allDatesSelected;

        const {
          allDatesSelected
        } = this.zones[zoneNumber - 1].shifts[idx];

        if (allDatesSelected) {
          // get initial shift dates + date options
          const oldZone = (this.opportunity.zones || [])[zoneNumber - 1] || {};
          const oldShifts = oldZone.shifts || [];
          const oldDates = oldShifts.length > idx ? oldShifts[idx].dates || [] : [];
          const shiftDatesMap = oldDates.reduce((p, c) => ({
            ...p,
            [DateUtilities.dateToString(c.date)]: c
          }), {});

          // iterate through options. if shift date used to exist, add that
          this.zones[zoneNumber - 1].shifts[idx].dates = this.dates.map(d =>
            Object.assign({}, shiftDatesMap[d.date] || {}, {
              date: d.date
            })
          );
        } else {
          this.zones[zoneNumber - 1].shifts[idx].dates = [];
        }
      }),
      toggleZoneOutside: action(zoneNumber => {
        this.zones = this.mappedZones.map(z => {
          if (z.number === zoneNumber) {
            z.outside = !z.outside;
          }

          return z;
        });
      }),
      opportunityDateToggle: action(() => {
        this.isOpportunityDatesOpen = !this.isOpportunityDatesOpen;
      }),
      setOpportunityDate: action(event => {
        this.date = typeof event === "string" ? event : event.target.value;
      }),
      setOpportunityDates: action(value => {
        // improvement: check the initial opp dates for value
        // if it's there, add that on to the object, else
        // concat the value. helps alleviate id issues
        const oldDate = (this.opportunity.dates || []).find(elem => (
          DateUtilities.parseString(elem.date) === value.value
        ));
        const newDate = Object.assign({}, oldDate || {}, {
          date: value.value
        });

        this.dates.push(newDate);

        if (this.dates.length === this.getDateOptions(this.eventStore.event).length) {
          this.dateSameAsEvent = true;
        }

        // need to keep it up to date
        this.zones.forEach((z, i) => {
          z.shifts.forEach((s, j) => {
            if (s.allDatesSelected) {
              this.zones[i].shifts[j].dates.push(newDate);
            }
          });
        });
      }),
      clearNewLocationAttributes: action(() => {
        this.newLocationId = this.defaults.newLocationId;
        this.newLocationName = this.defaults.newLocationName;
        this.newLocationStreet1 = this.defaults.newLocationStreet1;
        this.newLocationStreet2 = this.defaults.newLocationStreet2;
        this.newLocationStreet3 = this.defaults.newLocationStreet3;
        this.newLocationCountry = this.defaults.newLocationCountry;
        this.newLocationCity = this.defaults.newLocationCity;
        this.newLocationState = this.defaults.newLocationState;
        this.newLocationZipCode = this.defaults.newLocationZipCode;
        this.newLocationExists = this.defaults.newLocationExists;
      }),
      setNewLocationOpen: action((value = undefined, location) => {
        this.newLocationOpen =
          typeof value === "boolean" ? value : !this.newLocationOpen;
        if (!this.newLocationOpen) {
          this.clearNewLocationAttributes();
        }

        if (location) {// editing a location
          this.newLocationId = location.id;
          this.newLocationName = location.name;
          this.newLocationStreet1 = location.street1;
          this.newLocationStreet2 = location.street2;
          this.newLocationStreet3 = location.street3;
          this.newLocationCountry = location.country;
          this.newLocationCity = location.city;
          this.newLocationState = location.state;
          this.newLocationZipCode = location.zipCode;
        }
      }),
      setNewLocationName: action(event => {
        this.newLocationName = event.target.value;
        if (this.newLocationName && this.newLocationName.length > 0) {
          this.verifyLocation(this.newLocationName);
        } else {
          this.newLocationExists = false;
        }
      }),
      setNewLocationCountry: action(item => {
        this.newLocationCountry = item.name;
      }),
      setNewLocationState: action(item => {
        this.newLocationState = item.name;
      }),
      removeOpportunityDate: action(item => {
        this.dates = this.dates.filter(d => d.date !== item.value);
        this.dateSameAsEvent = false;
      }),
      removeShiftDate: action((zoneNumber, idx, date) => {
        const dates = this.zones[zoneNumber - 1].shifts[idx].dates;
        this.zones[zoneNumber - 1].shifts[idx].dates = dates.filter(d => (
          d.date !== date
        ));
      }),
      sortOpportunitiesByColumn: action((col, override = "") => {
        const colIsKey = col === this.sortFilters.key;
        const direction = colIsKey ? this.sortFilters.direction : "NONE";
        this.sortFilters.key = col;
        this.sortFilters.direction =
          override || this.gridStore.changeDirection(direction);

        if (this.sortFilters.direction === "NONE") {
          this.opportunities = this.sortFilters.initial;
        } else if (this.sortFilters.key === "needed") {
          this.opportunities = this.opportunities.sort((a, b) => {
            // NOTE: if performance issues, calculate total on shift load
            const x = a.shifts.reduce((p, c) => p + c.tier1 + c.tier2 + c.tier3, 0);
            const y = b.shifts.reduce((p, c) => p + c.tier1 + c.tier2 + c.tier3, 0);
            return this.sortFilters.direction === "ASC" ? x - y : y - x;
          });
        } else {
          this.opportunities = this.gridStore.sortWithFilters(
            this.sortFilters,
            this.opportunities
          );
        }
      }),
      sortContactsByColumn: action((col, override = "") => {
        const colIsKey = col === this.contactFilters.key;
        const direction = colIsKey ? this.contactFilters.direction : "NONE";
        this.contactFilters.key = col;
        this.contactFilters.direction =
          override || this.gridStore.changeDirection(direction);

        if (this.contactFilters.direction === "NONE") {
          this.contactKeys = this.contactFilters.initial;
        } else {
          this.contactKeys = this.contactKeys.sort((key1, key2) => {
            const x = this.contacts[key1][this.contactFilters.key] || "";
            const y = this.contacts[key2][this.contactFilters.key] || "";
            return x.localeCompare(y);
          });

          if (this.contactFilters.direction === "DESC") {
            this.contactKeys = this.contactKeys.reverse();
          }
        }
      }),
      loadOpportunity: action((eventId, opportunityId) => {
        if (!this.loadOpportunityCalled) {
          this.loadOpportunityCalled = true;
          this.programApi.loadOpportunity(eventId, opportunityId).then(opportunity => {
            this.opportunityLoadError = false;
            this.setOpportunityAttributes(opportunity);
          }).catch(e => {
            // eslint-disable-next-line no-console
            console.error(e);
            this.opportunityLoadError = true;
            this.loadOpportunityCalled = false;
          });
        }
      }),
      saveOpportunity: action((eventId, blockingCallback) => {
        const description = this.description.getCurrentContent();

        const opportunity = {
          // TODO: backend should handle the Ts stuff
          createdTs: this.opportunity && this.opportunity.createdTs,
          createdUser: this.opportunity && this.opportunity.createdUser,
          eventId,
          id: this.opportunityId,
          name: this.name,
          description: description.hasText() ?
            EditorUtilities.stateToRawString(description)
            : null,
          location: this.selectedLocation,
          street1: this.street1,
          city: this.city,
          state: this.state,
          zipCode: this.zipCode,
          country: this.country,
          reportingLocation: this.reportingLocation,
          contacts: this.contactKeys.map(k => this.contacts[k]),
          dates: this.dates.map(d => ({
            ...d,
            date: new Date(d.date),
            opportunityId: this.opportunityId
          })),
          mappedZones: JSON.parse(JSON.stringify(this.mappedZones)).reduce((map, obj) => {
            map[obj.number] = obj;
            Object.keys(obj.mappedShifts).forEach(k => {
              let shifts = obj.mappedShifts[k];
              shifts.forEach(s => {
                s.startTime = DateUtilities.dateTimeToString(s.startTime);
                s.endTime = DateUtilities.dateTimeToString(s.endTime);
              });
            });
            return map;
          }, {}),
          linkShifts: this.linkShifts
        };

        const promise = this.opportunityId
          ? this.programApi.updateOpportunity(opportunity, eventId)
          : this.programApi.createOpportunity(opportunity, eventId);

        this.savingOpportunity = true;
        promise.then(() => {
          this.savingOpportunity = false;
          if (blockingCallback) {
            blockingCallback();
          } else {
            this.blockingStore.clearAttributes();// allow push to go through
            this.routerStore.history.goBack();
          }

          this.notificationStore.setMessage("Opportunity Saved");

          setTimeout(() => {
            this.clearOpportunityAttributes();
          }, 500);
        }).catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          this.savingOpportunity = false;
          this.setValidationErrorMessages(e.message.split("|"));
        });
      }),
      saveLocation: action(() => {
        const location = {
          id: this.newLocationId,
          name: this.newLocationName,
          street1: this.newLocationStreet1,
          street2: this.newLocationStreet2,
          street3: this.newLocationStreet3,
          city: this.newLocationCity,
          state: this.newLocationState,
          zipCode: this.newLocationZipCode,
          country: this.newLocationCountry
        };

        const promise = location.id
          ? this.programApi.updateLocation(location)
          : this.programApi.createLocation(location);

        promise.then(result => {
          this.clearNewLocationAttributes();

          const wasUpdate = !!location.id;
          this.selectedLocation = result;
          this.location = this.selectedLocation.name;
          if (!wasUpdate) {
            this.initialLocationOptions.push(this.selectedLocation);
          } else {
            this.initialLocationOptions = this.initialLocationOptions.map(loc => {
              if (loc.id !== location.id) {
                return loc;
              } else {
                return Object.assign({}, loc, this.selectedLocation);
              }
            });
          }
          this.newLocationOpen = false;
          this.opportunityFormDirty = true;
          this.notificationStore.setMessage("Location Saved");
        }).catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          this.setValidationErrorMessages(e.message.split("|"));
        });
      }),
      pendingDeleteOpportunity: action((opportunity) => {
        this.opportunities = this.opportunities.filter(o => {
          if (o.id === opportunity.id) {
            this.opportunitiesPendingDelete.push(opportunity);
            return false;
          }
          return true;
        });
      }),
      deleteOpportunity: action(() => {
        const id = this.opportunityId;
        const eventId = this.eventStore.eventId;
        this.programApi.deleteOpportunity(id, eventId).then(() => {
          this.blockingStore.clearAttributes();// allow push to go through
          this.notificationStore.setMessage("Opportunity Deleted");
          this.clearOpportunityAttributes();
          this.loadOpportunitiesForEvent(eventId);
        }).catch(() => {
          this.notificationStore.setMessage(
            "Error Deleting Opportunity",
            NotificationConstants.ERROR
          );
        });
      }),
      deletePendingOpportunities: action(() => {
        if (this.opportunitiesPendingDelete && this.opportunitiesPendingDelete.length > 0) {
          const eventId = this.eventStore.eventId;
          this.programApi.deleteOpportunity(
            this.opportunitiesPendingDelete.map(o => o.id), eventId).then(() => {
            this.clearOpportunityAttributes();
            this.loadOpportunitiesForEvent(eventId);
          }).catch(() => {
            this.notificationStore.setMessage(
              this.i18n.t("opportunities.notification.bulkDeleteError"),
              NotificationConstants.WARNING
            );
          });
        }
      }),
      resetPendingOpportunities: action(() => {
        this.opportunitiesPendingDelete.forEach(p => {
          this.opportunities.push(p);
        });
        this.opportunitiesPendingDelete = this.defaults.opportunitiesPendingDelete;
      }),
      setOpportunityFormDirty: action(value => {
        this.opportunityFormDirty = value;
      }),
      setOpportunityTableDirty: action(value => {
        this.opportunityTableDirty = value;
      }),
      setSelectedCopyDate: action(value => {
        this.selectedCopyDate = value.key;
      }),
      setSelectedPasteDate: action(value => {
        this.selectedPasteDate = value.key;
      }),
      setLocation: action(event => {
        this.location = typeof event === "string" ? event : event.target.value;

        // clear opportunity location if searching
        if (typeof event !== "string") {
          this.selectedLocation = this.defaults.selectedLocation;
          this.street1 = this.defaults.street1;
          this.street2 = this.defaults.street2;
          this.street3 = this.defaults.street3;
          this.city = this.defaults.city;
          this.state = this.defaults.state;
          this.zipCode = this.defaults.zipCode;
          this.country = this.defaults.country;
        }
      }),
      setOpportunityLocation: action(item => {
        this.street1 = item.value.street1;
        this.street2 = item.value.street2;
        this.street3 = item.value.street3;
        this.city = item.value.city;
        this.state = item.value.state;
        this.zipCode = item.value.zipCode;
        this.country = item.value.country;
        this.selectedLocation = item.value;
      }),
      setSearchOpportunities: action(event => {
        this.searchOpportunities = event.target.value;

        if (!this.searchOpportunities) {
          this.opportunities = this.sortFilters.initial;
        } else {
          // TODO: maybe this should be a network call?
          this.opportunities = this.sortFilters.initial.filter(opportunity => {
            const regex = new RegExp(`.*${this.searchOpportunities}.*`, "i");
            return regex.test(opportunity.name);
          });
        }
      }),
      selectShiftDate: action((zoneNumber, idx, value) => {
        const zone = (this.opportunity.zones || [])[zoneNumber - 1] || {};
        const shifts = zone.shifts || [];
        const dates = shifts.length > idx ? shifts[idx].dates || [] : [];
        const oldDate = dates.find(elem => (
          DateUtilities.dateToString(elem.date) === value.value)
        );
        const newDate = Object.assign({}, oldDate || {}, {
          date: value.value
        });
        this.zones[zoneNumber - 1].shifts[idx].dates.push(newDate);
      }),
      setStartTime: action((zoneNumber, idx, event) => {
        this.setTime(zoneNumber, idx, event, "startTime");
      }),
      setEndTime: action((zoneNumber, idx, event) => {
        this.setTime(zoneNumber, idx, event, "endTime");
      }),
      setShiftTier: action((zoneNumber, idx, tierIdx, event) => {
        const tiers = this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate][idx].tiers;
        // if tier at tierIdx doesn't exist, create it
        if (tierIdx >= tiers.length) {
          const eventTiers = (this.eventStore.event || {}).tiers || [];
          for (let i = 0; i < eventTiers.length; i++) {
            if (i >= tiers.length) {
              this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate][idx].tiers.push({
                volunteerCount: ""
              });
            }
          }
        }

        // update count
        this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate][idx].tiers[tierIdx].volunteerCount =
          event.target.value;

        /*if (this.opportunityId) {
          this.zones[zoneNumber - 1].shifts[idx].dates.forEach(d => {
            d.shiftTiers.forEach(st => {
              if (st.tierId === this.zones[zoneNumber - 1].shifts[idx].tiers[tierIdx].tierId) {
                st.volunteerCount = event.target.value;
              }
            });
          });
        }*/

        // update total
        this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate][idx].total =
        this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate][idx].tiers.reduce(
            (p, c) => p + parseInt(c.volunteerCount || 0),
            0
          );
      }),
      setShiftTierTotal: action((zoneNumber, idx, event) => {
        this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate][idx].total = event.target.value;

        // evenly distribute total accross all shift tiers
        const total = parseInt(this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate][idx].total || 0);

        if (total) {
          const tiers = this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate][idx].tiers;
          this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate][idx].tiers.forEach(t => {
            t.volunteerCount = Math.floor(total / tiers.length);
          });

          // distribute remainder
          const rem = total % tiers.length;
          const inc = Math.ceil(rem / tiers.length);
          let dec = rem;
          let i = 0;
          while (dec && i < tiers.length) {
            const count =
            this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate][idx].tiers[i].volunteerCount;
            this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate][idx].tiers[i].volunteerCount =
              count + inc;

            /*if (this.opportunityId) {
              this.zones[zoneNumber - 1].shifts[idx].dates.forEach(d => {
                d.shiftTiers.forEach(st => {
                  if (st.tierId === this.zones[zoneNumber - 1].shifts[idx].tiers[i].tierId) {
                    st.volunteerCount = count + inc;
                  }
                });
              });
            }*/

            i++;
            dec -= inc;
          }
        }
      }),
      setContactName: action((key, event) => {
        this.setValue(key, event, "contacts", "name");
      }),
      setContactPhone: action((key, event) => {
        const old = this.contacts[key].phoneNumber.replace(FormConstants.REGEX.PHONE, "");
        let number = event.target.value.replace(FormConstants.REGEX.PHONE, "");
        if (number) {
          if (old === number && event.target.value.length < this.contacts[key].phoneNumber.length) {
            number = event.target.value;
          } else if (number.length < 3) {
            number = `(${number}`;
          } else if (number.length < 7) {
            number = `(${number.substring(0, 3)}) ${number.substring(3)}`;
          } else if (number.length >= 7) {
            number = `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`;
          }
        }

        this.contacts = Object.assign({}, this.contacts, {
          [key]: Object.assign({}, this.contacts[key], {
            phoneNumber: number
          })
        });
      }),
      setContactNotes: action((key, event) => {
        this.setValue(key, event, "contacts", "notes");
      }),
      addContact: action(() => {
        this.addToList("contacts", {
          name: "",
          phoneNumber: "",
          notes: ""
        });
      }),
      addShift: action(zoneNumber => {
        // get the tiers for the event
        const tiers = (this.eventStore.event || {}).tiers || [];

        // add shift to array
        if(!this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate]){
          this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate] = observable([]);
        }
        this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate].push({
          // state
          key: StringUtilities.random(),
          allDatesSelected: false,
          total: 0,

          // persist
          dates: [],
          tiers: tiers.map(t => ({ volunteerCount: "", tierId: t.id })),
          startTime: null,
          endTime: null
        });

        this.shiftCount = this.shiftCount + 1;
      }),
      addZone: action(() => {
        this.mappedZones.push({
          number: this.mappedZones.length + 1,
          mappedShifts: {},
          outside: false
        });

        // add a shift by default as well
        this.addShift(this.mappedZones.length);
      }),
      duplicateZone: action(index => {
        // need to clear out ids and other fields that shouldn't be filled in yet
        const z = Object.assign({}, this.mappedZones[index], {
          id: undefined,
          number: this.mappedZones.length + 1,
          createdTs: undefined,
          createdUser: undefined,
          status: undefined,
          updatedTs: undefined,
          updatedUser: undefined
        });

        let mappedShifts = {};
        Object.keys(this.mappedZones[index].mappedShifts).forEach((key) => {
          let shift = this.mappedZones[index].mappedShifts[key];

          mappedShifts[key] = observable(shift.map(s => {
            const newShift = Object.assign({}, s, {id: null, startTime: !s.startTime ? null : new Date(s.startTime.getTime()), endTime: !s.endTime ? null : new Date(s.endTime.getTime())});

            newShift.tiers = newShift.tiers.map(t => {
              return Object.assign({},t);
            })
            return newShift;
          }));
        });

        z.mappedShifts = mappedShifts;
        this.mappedZones.push(z);
      }),
      removeShift: action((zoneNumber, idx) => {
        this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate].splice(idx, 1);
      }),
      removeZone: action(zoneNumber => {
        this.mappedZones.splice(zoneNumber - 1, 1);
        this.mappedZones.forEach((z, i) => {
          z.number = i + 1;
        });
      }),
      removeContact: action(key => {
        this.removeFromList(key, "contacts");
      }),
      addToList: action((propname, defaultValue) => {
        const keyName = propname === "contacts" ? "contactKeys" : "zoneKeys";
        const filterName = keyName.replace("Keys", "Filters");
        let unique = StringUtilities.random();
        while (this[propname][unique]) {
          unique = StringUtilities.random();
        }

        this[keyName] = this[keyName].concat([unique]);
        this[filterName].initial = this[filterName].initial.concat([unique]);
        this[propname] = Object.assign({}, this[propname], {
          [unique]: Object.assign({}, defaultValue, {
            opportunityId: this.opportunityId
          })
        });
        return unique;
      }),
      removeFromList: action((key, propname) => {
        const keyName = propname === "contacts" ? "contactKeys" : "zoneKeys";
        const filterName = keyName.replace("Keys", "Filters");
        this[keyName] = this[keyName].filter(i => i !== key);
        this[filterName].initial = this[filterName].initial.filter(i => (
          i !== key
        ));
        this[propname] = Object.assign({}, this[propname], {
          [key]: undefined
        });
      }),
      setValue: action((key, event, propname, parameter) => {
        this[propname] = Object.assign({}, this[propname], {
          [key]: Object.assign({}, this[propname][key], {
            [parameter]: event.target.value
          })
        });
      }),
      setDate: action((key, event) => {
        let date;
        if (!event) {
          date = null;
        } else if (event instanceof Date) {
          date = event;
        } else {
          const d = moment(event.target.value, "MM/DD/YYYY", true);
          date = d.isValid() ? d.toDate() : null;
        }

        this.shifts = Object.assign({}, this.shifts, {
          [key]: Object.assign({}, this.shifts[key], { date })
        });
      }),
      setTime: action((zoneNumber, idx, event, propname) => {
        let time;
        if (!event) {
          time = null;
        } else if (event instanceof Date) {
          time = event;
        } else {
          const d = moment(event.target.value, "h:mm a", true);
          time = d.isValid() ? d.toDate() : null;
        }

        this.mappedZones[zoneNumber - 1].mappedShifts[this.selectedDate][idx][propname] = time;
      }),
      setLocationFilter: action(option => {
        this.locationFilterName = option.name;
        this.locationFilterKey = option.key;
      }),
      toggleExpander: action(item => {
        item.expanded = !item.expanded;
      }),
      toggleDateExpander: action(dateStr => {
        this.opportunityDateMap[dateStr].expanded =
          !this.opportunityDateMap[dateStr].expanded;
      }),
      toggleOpportunityExpander: action((dateStr, opportunityId) => {
        this.opportunityDateMap[dateStr].opportunityMap[opportunityId].expanded =
          !this.opportunityDateMap[dateStr].opportunityMap[opportunityId].expanded;
      }),
      assembleOpportunitiesByDate: action(tierId => {
        // note: setting the total may not be necessary
        // once the cache is primed correctly, but doing this for now
        const { tiers } = this.eventStore.event || {};
        const index = tiers ? tiers.findIndex(t => t.id === tierId) : -1;

        const finalMap = {};
        const shiftsByDate = {};
        this.opportunities.forEach(opportunity => {
          // create shiftsByDate
          opportunity.zones.forEach(zone => {
            zone.shifts.forEach(shift => {
              const remaining =
                index > -1 ? shift.tiers[index].volunteerCount : 0;
              shift.dates.forEach(shiftDate => {
                const obj = {
                  ...shiftDate,
                  remaining,
                  key: StringUtilities.random(),
                  shiftId: shift.id,
                  opportunityId: opportunity.id,
                  selected: false,
                  outside: zone.outside,
                  startTime: shift.startTime,
                  endTime: shift.endTime
                };

                if (!shiftsByDate[shiftDate.date]) {
                  shiftsByDate[shiftDate.date] = {
                    insideShiftDates: [],
                    outsideShiftDates: []
                  };
                }

                if (zone.outside) {
                  shiftsByDate[shiftDate.date].outsideShiftDates.push(obj);
                } else {
                  shiftsByDate[shiftDate.date].insideShiftDates.push(obj);
                }
              });
            });
          });

          // create parent object
          opportunity.dates.forEach(date => {
            if (!finalMap[date.date]) {
              finalMap[date.date] = { expanded: false, opportunityMap: {} };
            }

            if (!finalMap[date.date].opportunityMap[opportunity.id]) {
              const inside = shiftsByDate[date.date].insideShiftDates
                .filter(sd => sd.opportunityId === opportunity.id)
                .sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
              const outside = shiftsByDate[date.date].outsideShiftDates
                .filter(sd => sd.opportunityId === opportunity.id)
                .sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
              if (inside.length || outside.length) {
                finalMap[date.date].opportunityMap[opportunity.id] = {
                  ...opportunity,
                  key: StringUtilities.random(),
                  expanded: false,
                  insideShiftDates: inside,
                  outsideShiftDates: outside
                };
              }
            }
          });
        });

        this.opportunityDateMap = finalMap;
      }),
      toggleLinkShifts: action(() => {
        this.linkShifts = !this.linkShifts;
      }),

      setOpportunityReportFilters: action((item, filter, multi) => {
        if (multi) {
          if(item.key === FormConstants.OPPORTUNITY_FILTERS.ALL.key) {
            this.opportunityReportFilters[filter] =[];
            this.opportunityReportFilters[filter].push(item);
          } else {
            this.opportunityReportFilters[filter] = this.opportunityReportFilters[filter]
              .filter(item => item.key!==FormConstants.OPPORTUNITY_FILTERS.ALL.key);
            this.opportunityReportFilters[filter].push(item);
          }
        } else {
          this.opportunityReportFilters[filter] = item;
        }
      }),

      removeOpportunityReportFilterItem: action((item, filter) => {
        this.opportunityReportFilters[filter] = this.opportunityReportFilters[filter].filter(
          val => val.key !== item.key
        );
      })
    });

    // load opportunities for event
    autorun(() => {
      let params;
      if (this.routerStore.isActive(RouteConstants.EDIT_EVENT)) {
        params = this.routerStore.getPathParams(RouteConstants.EDIT_EVENT);
      } else if (this.routerStore.isActive(RouteConstants.EDIT_VOLUNTEER) ||
                 this.routerStore.isActive(RouteConstants.NEW_VOLUNTEER)) {
        params = this.routerStore.getQueryParams();
      }

      // running this on volunteer page as we need shiftDateToShiftAndOppMap populated there
      if (
        (this.routerStore.isActive(RouteConstants.EDIT_EVENT) ||
        this.routerStore.isActive(RouteConstants.VOLUNTEERS) ||
        this.routerStore.isActive(RouteConstants.EDIT_VOLUNTEER) ||
        this.routerStore.isActive(RouteConstants.NEW_VOLUNTEER)) &&
        this.authStore.currentUserId &&
        this.authStore.isAdmin &&
        params.eventId
      ) {
        this.opportunityFormDirty = false;
        this.opportunityTableDirty = false;
        this.clearOpportunityAttributes();
        this.loadOpportunitiesForEvent(params.eventId);
      }
    });

    // load locations for dropdown
    autorun(() => {
      if (this.routerStore.isActive(RouteConstants.EDIT_OPPORTUNITY) ||
        this.routerStore.isActive(RouteConstants.NEW_OPPORTUNITY)) {
        this.programApi.loadLocations().then(data => {
          this.initialLocationOptions = !data ? [] : data.locations;
          this.locationOptions = this.initialLocationOptions.map(i => i);
          if (this.locationSetupCalled) {
            this.setupLocation(this.opportunity);
          }
        });
      }
    });
  }

  loadOpportunitiesForEvent = eventId => {
    this.opportunitiesLoading = true;
    this.programApi.loadOpportunitiesForEvent(eventId).then(data => {
      this.opportunities = data ? data.opportunities : [];
      const sdm = {};
      this.opportunities.forEach(o => {
        o.zones.forEach(z => {
          z.shifts.forEach(s => {
            s.dates.forEach(d => {
              sdm[d.shiftTiers[0].opportunityShiftId] = {
                date: d.date,
                startTime: s.startTime,
                endTime: s.endTime,
                name: o.name,
                zoneNumber: z.number,
                outside: z.outside
              };
            });
          });
        });
      });

      this.shiftDateToShiftAndOppMap = sdm;
      this.opportunitiesLoading = false;
      this.sortFilters.initial = this.opportunities;

      // do initial sort of opportunities
      this.sortOpportunitiesByColumn(
        this.sortFilters.key,
        this.sortFilters.direction
      );
    });
  };

  verifyLocation = name => {
    this.programApi.findLocationByName(name).then(result => {
      this.newLocationExists = result.locations.length > 0;
    }).catch(() => {
      this.newLocationExists = false;
    });
  };

  setupLocation = opportunity => {
    const location = opportunity.location && opportunity.location.id ?
      this.locationOptions.find(loc => loc.id === opportunity.location.id) : null;
    this.location = location ? location.name : "";
    this.selectedLocation = location;
    this.locationSetupCalled = true;
  };

  generateKeys = (arr, property) => {
    // generates the keys for shifts and contacts
    // sets the date and time as well
    const created = new Set();
    const result = {};
    this[property] = arr.map(item => {
      let unique = StringUtilities.random();
      while (created.has(unique)) {
        unique = StringUtilities.random();
      }

      created.add(unique);
      const date = DateUtilities.parseString(item.date);
      const startTime = DateUtilities.parseString(item.startTime, "HH:mm:ss");
      const endTime = DateUtilities.parseString(item.endTime, "HH:mm:ss");

      result[unique] = Object.assign({}, item, {
        date: item.date ? date : undefined,
        startTime: item.startTime ? startTime : undefined,
        endTime: item.endTime ? endTime : undefined
      });
      return unique;
    });

    return result;
  };

  generateLocationKey = item => {
    const { street1, city, state, zipCode, country } = item;
    return `${street1}|${city}|${state}|${zipCode}|${country}`.replace(/\s/g, "");
  };

  getSelectedFilters = filters => {
    return filters.toJS();
  };

  getDateOptions = event => {
    if (!event) {
      return [];
    }

    if (!event.endDate) {
      const dateString = moment(event.startDate).format("MM/DD/YYYY");
      return [{
        key: dateString,
        name: dateString,
        value: dateString
      }];
    }

    const dateOptions = [];
    let dateParts = event.startDate.split("-");
    const startDate = new Date(dateParts[0], parseInt(dateParts[1]) - 1, dateParts[2]);
    dateParts = event.endDate.split("-");
    const endDate = new Date(dateParts[0], parseInt(dateParts[1]) - 1, dateParts[2]);
    let currentDate = startDate;
    while (currentDate <= endDate) {
      const dateStr = moment(currentDate).format("MM/DD/YYYY");
      dateOptions.push({
        key: dateStr,
        name: dateStr,
        value: dateStr
      });
      const d = new Date(currentDate);
      d.setDate(d.getDate() + 1);
      currentDate = d;
    }
    return dateOptions;
  }

  getSelectedShiftDates = shift => {
    return shift.dates.map(d => ({
      key: d.date,
      name: d.date,
      value: d.date
    }));
  };

  getOpportunityForSelection = selection => {
    let opportunity;

    if (selection.opportunityShift && selection.opportunityShift.opportunityId) {
      const foundOpportunity = this.opportunities.find(opp => {
        return opp.id === selection.opportunityShift.opportunityId;
      });
      opportunity = { ...foundOpportunity };

      opportunity.date = selection.opportunityShift.date;
      opportunity.startTime = selection.opportunityShift.startTime;
      opportunity.endTime = selection.opportunityShift.endTime;
    } else {
      this.opportunities.forEach(opp => {
        opp.zones.forEach(zone => {
          zone.shifts.forEach(shift => {
            shift.dates.forEach(date => {
                date.shiftTiers.forEach(tier => {
                    if (tier.opportunityShiftId === selection.opportunityShift.id) {
                      opportunity = { ...opp };
                      opportunity.selectionId = selection.id;
                      opportunity.date = date.date;
                      opportunity.startTime = shift.startTime;
                      opportunity.endTime = shift.endTime;
                    }
                });
            });
          });
        });
      });
    }

    return opportunity;
  };

  areAllDatesSelected = shift => {
    return shift.allDatesSelected || shift.dates.length === this.dates.length;
  };

  get contactRows() {
    return this.contactKeys.map(i => Object.assign({}, this.contacts[i], {
      key: i
    }));
  }

  get zoneRows() {
    return this.mappedZones.toJS().map(z => ({
      ...z,
      total: z.mappedShifts[this.selectedDate] ? z.mappedShifts[this.selectedDate].reduce((t, s) => t + parseInt(s.total || 0), 0) : 0
    }));
  }

  get opportunityRows() {
    return this.opportunities.toJS();
  }

  get opportunityReportFilterOptions () {
    const options =  this.opportunities.map(o => ({
      key: o.name,
      name: o.name,
      value: o.id,
    }));

    options.unshift(FormConstants.OPPORTUNITY_FILTERS.ALL);
    return options;
  }

  get opportunityReportDatesFilterOptions() {
    let dates = this.opportunities.flatMap((opp) => [opp.dates.map((d) => d.date)]);
    const allDates = Array.from(new Set([].concat(...dates))).sort();
    const options =  allDates.map(date => ({
      key: date,
      name: date,
      value: date,
    }));

    options.unshift(FormConstants.OPPORTUNITY_FILTERS.ALL);
    return options;
  }

  getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  get opportunityReportLocationsFilterOptions() {
    const locations = this.opportunities.map( opp => (
      {
        id: opp.location?.id,
        name: opp.location?.name
      }
    ));
    const allLocations = this.getUniqueListBy(locations, "id");
    const options =  allLocations.map(location => ({
      key: location.id,
      name: location.name,
      value: location.id,
    }));

    options.unshift(FormConstants.OPPORTUNITY_FILTERS.ALL);
    return options;
  }

  get opportunityReportReportingLocationsFilterOptions() {
    const reportingLocations = this.opportunities.map( (opp, index) => ({
      name: opp.reportingLocation,
      id: index
    }));

    const allReportingLocations = this.getUniqueListBy(reportingLocations, "name");
    const options =  allReportingLocations.map(location => ({
      key: location.id,
      name: location.name,
      value: location.name,
    }));

    options.unshift(FormConstants.OPPORTUNITY_FILTERS.ALL);
    return options;
  }

  get opportunityLocations() {
    return this.locationOptions.map(loc => ({
      key: loc.id, name: loc.name, value: loc
    }));
  }

  get selectedDates() {
    return (this.dates || []).map(date => ({
      key: date.date,
      name: date.date,
      value: date.date
    }));
  }

  get opportunityOptions() {
    return this.opportunities.map(o => ({
      key: o.id,
      name: o.name,
      value: o.id
    }));
  }

  get isOpportunitySaveDisabled() {
    const badContacts = this.contactRows.some(contact => (
      !contact.name || !contact.phoneNumber
    ));

    /*const badZones = this.zones.some(zone => {
      return zone.shifts.some(shift => (
        !shift.dates.length ||
        !shift.startTime ||
        !shift.endTime ||
        shift.startTime >= shift.endTime
      ));
    });*/

    return (
      !this.opportunityFormDirty ||
      !this.name ||
      !this.location ||
      !this.reportingLocation ||
      !this.contactKeys.length ||
      badContacts
    );
  }

  get opportunitiesByDate() {
    return Object.keys(this.opportunityDateMap).sort().map(dateStr => {
      const obj = this.opportunityDateMap[dateStr];
      return {
        date: dateStr,
        expanded: obj.expanded,
        opportunities: Object.values(obj.opportunityMap),
        remaining: Object.values(obj.opportunityMap).reduce((pv, o) => {
          const inTotal = o.insideShiftDates.reduce((pv, sd) => pv + sd.remaining, 0);
          const outTotal = o.outsideShiftDates.reduce((pv, sd) => pv + sd.remaining, 0);
          return pv + inTotal + outTotal;
        }, 0)
      };
    });
  }

  get opportunitiesRemaining() {
    return this.opportunitiesByDate.reduce((pv, obd) => pv + obd.remaining, 0);
  }

  get availableOpportunitiesMap() {
    const results = {};
    this.opportunities.forEach(o => {
      o.zones.forEach(z => {
        z.shifts.forEach(s => {
          s.dates.forEach(d => {
            results[`${o.id}${z.number}${s.startTime}${s.endTime}${d.date}`] = {
              shiftDate: d,
              opportunityId: o.id,
              name: o.name,
              date: d.date,
              startTime: s.startTime,
              endTime: s.endTime,
              outside: z.outside,
              zoneNumber: z.number,
              selected: false,
              conflicted: false,
              opportunityShiftIds: d.shiftTiers.map(t => {
                return {
                  opportunityShiftId: t.opportunityShiftId,
                  tierId: t.tierId
                };
              })
            };
          });
        });
      });
    });

    return results;
  }
}

decorate(OpportunityStore, {
  selectedDates: computed,
  opportunityOptions: computed,
  opportunitiesByDate: computed,
  opportunitiesRemaining: computed,
  isOpportunitySaveDisabled: computed,
  availableOpportunitiesMap: computed
});

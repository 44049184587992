import React, { useState, useContext, createContext, useEffect } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { AuthConstants } from "../constants/AuthConstants";
import { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import VolunteerConfig from "../config/VolunteerConfig";
import AdminConfig from "../config/AdminConfig";
import { environment } from "../../configs/environment";
import { datadogRum } from "@datadog/browser-rum";

const getOktaAuth = () => {
  if (environment.envString === "local") {
    const localAdmin = localStorage.getItem("localAdmin");
    if (!localAdmin || localAdmin === "false") {
      const urlArray = window.location.href.split("/");
      if (urlArray[urlArray.length - 1] === "admin") {
        localStorage.setItem("localAdmin", "true");
        return "admin";
      } else {
        localStorage.setItem("localAdmin", "false");
        return "vol";
      }
    } else {
      if (localAdmin === "true") {
        return "admin";
      } else {
        return "vol";
      }
    }
  } else {
    const urlArray = window.location.href.split(".");
    if (urlArray[0].indexOf("admin") !== -1) {
      return "admin";
    } else {
      return "vol";
    }
  }
};

const oktaAuth =
  getOktaAuth() === "admin"
    ? new OktaAuth(AdminConfig.oidc)
    : new OktaAuth(VolunteerConfig.oidc);

const AuthContext = createContext({
  userInfo: null,
  setUserInfo: null,
  loggedIn: false,
  login: null,
  logout: null,
  isAdmin: false,
  isSuperAdmin: false,
  isEtaStaff: false,
  userRoles: null,
  oktaLogin: null,
  setOktaLogin: null,
  oktaLogout: null,
  setOktaLogout: null,
});

const initialState = {
  userInfo: {
    accessToken: "",
    idToken: "",
    email: "",
  },
  loggedIn: false,
  authToken: "",
  email: "",
  isAdmin: false,
  isSuperAdmin: false,
  isEtaStaff: false,
  userRoles: null,
  oktaLogin: null,
  setOktaLogin: null,
  oktaLogout: null,
  setOktaLogout: null,
};

const AuthContextProvider = inject("rootStore")(
  observer(({ rootStore, children }) => {
    const [userInfo, setUserInfo] = useState(initialState.userInfo);
    const [loggedIn, setLoggedIn] = useState(initialState.loggedIn);
    const [userRoles, setUserRoles] = useState(initialState.userRoles);
    const [isAdmin, setIsAdmin] = useState(initialState.isAdmin);
    const [isSuperAdmin, setIsSuperAdmin] = useState(initialState.isSuperAdmin);
    const [isEtaStaff, setIsEtaStaff] = useState(initialState.isEtaStaff);
    const {
      getUserInfo,
      userData,
      setLoggedIn: authStoreSetLoggedIn,
    } = rootStore.authStore;

    const { authState } = useOktaAuth();

    useEffect(() => {
      if (loggedIn && userInfo && userData) {
        authStoreSetLoggedIn({
          // idToken: userInfo.accessToken.idToken,
          accessToken: userInfo.accessToken.accessToken,
        });
        const roles = new Set(userData.roles);
        setUserRoles(roles);
        const { SUPER_ADMIN, PROGRAM_ADMIN, ETA_STAFF } =
          AuthConstants.KEYS.ROLES;
        setIsAdmin(
          loggedIn &&
            (roles.has(SUPER_ADMIN) ||
              roles.has(PROGRAM_ADMIN) ||
              roles.has(ETA_STAFF))
        );
        setIsSuperAdmin(loggedIn && roles.has(SUPER_ADMIN));
        setIsEtaStaff(loggedIn && roles.has(ETA_STAFF));
      }
    }, [userData, loggedIn]);

    useEffect(() => {
      if (authState?.isAuthenticated) {
        login({
          email: "",
          orgId: 0,
          orgName: "",
          orgShortName: "",
          roles: undefined,
          userFullName: "",
          userName: "",
          accessToken: authState.accessToken,
        });

        if (authState?.isAuthenticated && authState.idToken?.claims) {
          datadogRum.setUser({
            id: authState.idToken?.claims.email,
            email: authState.idToken?.claims.email,
            name: authState.idToken?.claims.name,
          });
        } else {
          datadogRum.removeUser();
        }
      }
    }, [authState]);

    const login = (userData) => {
      if (userData) {
        setUserInfo(userData);
        setLoggedIn(true);
        getUserInfo();
      }
    };

    const logout = () => {
      localStorage.clear();
      setUserInfo(initialState.userInfo);
      setLoggedIn(false);
      oktaAuth.signOut();
    };

    return (
      <AuthContext.Provider
        value={{
          userInfo,
          setUserInfo,
          loggedIn,
          login,
          logout,
          userRoles,
          isAdmin,
          isSuperAdmin,
          isEtaStaff,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  })
);

const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within AuthContextProvider");
  }
  return authContext;
};

AuthContextProvider.propTypes = {
  children: PropTypes.element,
};

export { AuthContext, AuthContextProvider, useAuth, oktaAuth };

/*
  The entrypoint where the environment is loaded based on the
  environment one is in.
*/

import { environment as dev } from "./environment.dev";
import { environment as stg } from "./environment.stg";
import { environment as local } from "./environment.local";
import { environment as prod } from "./environment.prod";

const getEnv = () => {
  switch (process.env.APP_ENV) {
    case "dev":
      return dev;
    case "stg":
      return stg;
    case "prod":
      return prod;
    default:
      return local;
  }
};

const getEnvString = () => {
  return process.env.APP_ENV;
};

// define global parameters here
const globals = {
  programAdminRole: "PROGRAM_ADMIN",
  etaStaffRole:"ETA_STAFF"

};

export const environment = Object.assign({}, globals, getEnv(), { envString: getEnvString() });

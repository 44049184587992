import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en";
import es from "./es";

i18n.use(LanguageDetector).init({
  fallbackLng: "en",
  load: "languageOnly",
  debug: process.env.APP_ENV === "local",
  resources: {
    en,
    es
  }
});

export default i18n;

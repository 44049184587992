export class StringUtilities {
  static random = (num = 5) => Math.random().toString(36).substr(2, num);

  static dePluralize = (len, arg) =>
    len === 1 ? arg : arg.replace(/ies$/i, "y").replace(/s$/i, "");

  static toNumber = arg => {
    try {
      const result = parseInt(arg || 0);
      return isNaN(result) ? 0 : result;
    } catch (error) {
      return 0;
    }
  };
}

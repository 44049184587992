import AbstractApi from "./AbstractApi";

export default class AuthApi extends AbstractApi {
  constructor() {
    super();
  }

  loadInfo = () => this.get("actuator/info");

  login = body => this.post("login", body);

  resetPassword = body => this.post("resetPassword", body);

  forgotPassword = userId => this.get(`forgotPassword/${userId.toLowerCase()}`);

  confirmForgotPasswordCode = body =>
    this.post("confirmForgotPasswordCode", body);

  refresh = body => this.post("refresh", body);

  createUser = createUserRequest => this.post("user", createUserRequest);

  getUserInfo = () => this.get("userInfo");

  getUserProfile = email => this.get(`userProfile/${email}`);

  searchUsers = (userName, limit) =>
    this.get(`user?email=${encodeURIComponent(userName)}&limit=${limit}`);

  updateUser = body => this.put("user", body);

  getAmbassadorPrefix() {
    return "user";
  }

  getLocalPort(){
    return "7702";
  }
}

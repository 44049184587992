import React from "react";
import axios from "axios";
import { inject, observer } from "mobx-react";
import { useLoading } from "../contexts/LoadingContext";
import axiosRetry from "axios-retry";
import { useAuth } from "../contexts/AuthContext";

const HttpInterceptor = inject("rootStore")(
  observer(({ children }) => {
    const { logout, oktaLogin } = useAuth();
    const { setLoading } = useLoading();

    /**
     * Only retry api request when okta token has expired - JL
     */
    const oktaTokenStr = localStorage.getItem("okta-token-storage");
    const currentTime = Math.round(new Date().getTime() / 1000);
    const oktaTokenExpiresAt = JSON.parse(oktaTokenStr)?.accessToken?.expiresAt;
    const idTokenExpiresAt = JSON.parse(oktaTokenStr)?.idToken?.expiresAt;
    const tokenExpired = oktaTokenExpiresAt - currentTime < 1;
    const idTokenExpired = idTokenExpiresAt - currentTime < 1;

    axiosRetry(axios, {
      retries: 3, //attempts to retry
      retryCondition: () => {
        return tokenExpired || idTokenExpired;
      }, // only retry on unauthenticated errors to prevent retrying 500s unless that is wanted
      retryDelay: (retryCount) => retryCount * 1000, // wait 1 second between retrys
    });

    let pendingRequest = 0;
    axios.interceptors.request.use((config) => {
      pendingRequest++;
      setLoading(true);
      const oktaTokenStr = localStorage.getItem("okta-token-storage");

      if (oktaTokenStr) {
        const { accessToken = {}, idToken = {} } = JSON.parse(oktaTokenStr);

        if (config.url.indexOf("/oauth/token") < 0 || config.headers.addToken) {
          config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
          config.headers["x-id-token"] = idToken.idToken;
        }
      }

      return config;
    });

    axios.interceptors.response.use(
      (resp) => {
        pendingRequest--;
        if (!resp) return null;
        if (pendingRequest === 0) setLoading(false);
        return resp.data ? resp.data : resp;
      },
      (err) => {
        pendingRequest--;
        if (pendingRequest === 0) setLoading(false);
        // Check if token has expired or token is gone from storage
        // if condition is true, go back to okta for token/login - JL
        if (tokenExpired || idTokenExpired || !localStorage.getItem("okta-token-storage")) {
          if (oktaLogin) {
            oktaLogin.loginFunction();
          } else {
            logout();
          }
        }
        return Promise.reject(err);
      }
    );

    return <div>{children}</div>;
  })
);

export default HttpInterceptor;

import React, { Suspense } from "react";
import EtaProtectedContents from "./EtaProtectedContents";

const App = () => {
  return (
    <Suspense fallback={<h1>loading...</h1>}>
      <EtaProtectedContents />
    </Suspense>
  );
};

export default App;

import React, { lazy } from "react";
import { useAuth } from "./contexts/AuthContext";
import { ThemeProvider } from "styled-components";
import Theme from "./Theme";

const AuthenticatedApp = lazy(() => import("./AuthenticatedApp.js"));
const UnauthenticatedApp = lazy(() => import("./UnauthenticatedApp.js"));

const EtaProtectedContents = () => {
  const { loggedIn } = useAuth();

  return (
    <ThemeProvider theme={Theme}>
      {loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </ThemeProvider>
  );
};

export default EtaProtectedContents;

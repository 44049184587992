/*
  This contains the environment variables needed for the application
  in the production environment
*/

export const environment = {
  ambassador: true,
  baseURL: "https://eta.best-gcp.mlbinfra.com",
  adminLoginURL:
    "https://vms-prod.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=token&client_id=le9jsi156k2128ufrfblmthd&redirect_uri=https://admin.volunteer.mlb.com/okta",
  superAdminRole: "SUPER_ADMIN",
  REACT_APP_OKTA_REDIRECT_URI: "https://admin.eta.mlb.com",
  REACT_APP_OKTA_REDIRECT_URI_VOL: "https://eta.mlb.com",
  REACT_APP_OKTA_POST_LOGOUT_REDIRECT_URI: "https://admin.eta.mlb.com",
  REACT_APP_OKTA_POST_LOGOUT_REDIRECT_URI_VOL: "https://eta.mlb.com",
  REACT_APP_OKTA_BASE_URL: "https://mlb.okta.com",
  REACT_APP_OKTA_CLIENT_ID: "0oaqujefmseY97Z7b0x7",
  REACT_APP_OKTA_ISSUER: "https://mlb.okta.com/oauth2/auslv3cyxpTq2DdSK0x7",
  REACT_APP_OKTA_BASE_URL_VOL: "https://ids.mlb.com",
  REACT_APP_OKTA_CLIENT_ID_VOL: "0oapwsbnotq5Sk9Wr357",
  REACT_APP_OKTA_ISSUER_VOL: "https://ids.mlb.com/oauth2/aus1m088yK07noBfh356",
};

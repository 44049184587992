import _ from "lodash";
import { action, autorun, computed, decorate, extendObservable } from "mobx";
import { RouteConstants } from "../constants/RouteConstants";
import { StoreUtilities } from "../utilities/StoreUtilities";
import { DateUtilities } from "../utilities/DateUtilities";

export default class TrackerStore {
  constructor(
    auth,
    event,
    volunteer,
    opportunity,
    router,
    programApi,
    trackerApi
  ) {
    this.authStore = auth;
    this.eventStore = event;
    this.volunteerStore = volunteer;
    this.opportunityStore = opportunity;
    this.routerStore = router;
    this.programApi = programApi;
    this.trackerApi = trackerApi;
    this.defaults = {
      serverTime: null,
      systemTime: null,
      timeSubscription: null,
      connecting: false,
      tiers: [],
      emails: {},
      volunteers: {},
      confirmedVolunteers: {},
      opportunityTierCounts: {},
      availableOpportunitiesMap: {},
      selectionPeriodInProgress: false,
      selectedTier: { name: "" },
      confirmedFilters: {
        key: "shiftDate",
        direction: "ASC"
      },
      availableFilters: {
        key: "shiftDate",
        direction: "ASC"
      },
      timeParts: {
        hours: 0,
        mins: 0,
        secs: 0
      }
    };

    extendObservable(this, {
      ...StoreUtilities.initialize(this),

      // socket requests go here
      getSelections: action((eventId, tierId) => {
        this.trackerApi.getSelections(eventId, tierId);
      }),
      getServerTime: action(() => {
        this.trackerApi.getServerTime();
      }),

      // socket responses go here
      updateServerTime: action(response => {
        const data = JSON.parse(response.body);
        const startTimeStamp = data.startTime
          ? typeof data.startTime === "string"
            ? data.startTime
            : data.startTime.epochSecond * 1000
          : null;
        this.serverTime = startTimeStamp ? new Date(startTimeStamp) : null;
        if (startTimeStamp) {
          this.systemTime = new Date();
          this.timeSubscription.unsubscribe();
        }
      }),
      updateSelections: action((response, tierId) => {
        const data = JSON.parse(response.body);
        const { id: eventId } = this.eventStore.event;
        const assignedMap = {};
        const volunteers = {};
        const confirmedVolunteers = {};
        data.selections.forEach(selection => {
          const {
            opportunityId: oppId,
            volunteerId: volId,
            saveTimestamp
          } = selection;
          const volunteerId = parseInt(volId);
          const opportunityId = parseInt(oppId);
          const assigned = assignedMap[opportunityId] || 0;

          assignedMap[opportunityId] = assigned + 1;

          // now update the volunteer counts
          if (saveTimestamp) {
            const confirmed = confirmedVolunteers[volunteerId] || {};
            const tiers = confirmed.tiers || {};
            const { count } = tiers[tierId] || [];
            confirmedVolunteers[volunteerId] = {
              tiers: {
                ...tiers,
                [tierId]: {
                  count: (count || 0) + 1,
                  timestamp: saveTimestamp
                }
              }
            };
          } else {
            const volunteer = volunteers[volunteerId] || {};
            const tiers = volunteer.tiers || {};
            volunteers[volunteerId] = {
              tiers: {
                ...tiers,
                [tierId]: (tiers[tierId] || 0) + 1
              }
            };
          }

          // get volunteer email
          const { address, loading } = this.emails[volunteerId] || {};
          if (!address && !loading) {
            this.emails[volunteerId] = { loading: true };
            this.programApi
              .loadVolunteer(volunteerId, eventId)
              .then(r => {
                this.emails[volunteerId] = {
                  loading: false,
                  address: r.email
                };
              })
              .catch(() => {
                this.emails[volunteerId] = { loading: false };
              });
          }
        });

        // update the volunteers
        const oldVolunteers = new Set(Object.keys(this.volunteers));
        const newVolunteers = new Set(Object.keys(volunteers));
        const diff = this.setDifference(oldVolunteers, newVolunteers);

        diff.forEach(volId => {
          const volunteerId = parseInt(volId);
          const isConfirmed = Boolean(confirmedVolunteers[volunteerId]);
          const volunteer = this.volunteers[volunteerId] || {};
          const tiers = volunteer.tiers || {};
          volunteers[volunteerId] = {
            tiers: {
              ...tiers,
              [tierId]: isConfirmed ? undefined : 0
            }
          };
        });

        newVolunteers.forEach(volId => {
          const volunteerId = parseInt(volId);
          const isConfirmed = Boolean(confirmedVolunteers[volunteerId]);
          const volunteer = volunteers[volunteerId] || {};
          const tiers = volunteer.tiers || {};
          const value = tiers[tierId] || 0;
          volunteers[volunteerId] = {
            tiers: {
              ...tiers,
              [tierId]: isConfirmed ? undefined : value
            }
          };
        });

        this.confirmedVolunteers = confirmedVolunteers;

        this.volunteers = { ...this.volunteers, ...volunteers };

        // now go through opportunities, using assignedMap vals
        // and setting rest to 0
        this.opportunityStore.opportunities.forEach(o => {
          const assigned = assignedMap[o.id] || 0;
          const counts = this.opportunityTierCounts[o.id] || {};
          const oldTierCounts = counts.tiers || {};

          this.opportunityTierCounts = {
            ...this.opportunityTierCounts,
            [o.id]: {
              tiers: {
                ...oldTierCounts,
                [tierId]: assigned
              }
            }
          };
        });
      }),

      // misc
      onTierSelect: action(option => {
        this.selectedTier = option;
      }),
      sortAvailableByColumn: action(key => {
        if (this.availableFilters.key !== key) {
          this.availableFilters.key = key;
          this.availableFilters.direction = "ASC";
        } else if (this.availableFilters.direction === "ASC") {
          this.availableFilters.direction = "DESC";
        } else {
          this.availableFilters = this.defaults.availableFilters;
        }
      }),
      sortConfirmedByColumn: action(key => {
        if (this.confirmedFilters.key !== key) {
          this.confirmedFilters.key = key;
          this.confirmedFilters.direction = "ASC";
        } else if (this.confirmedFilters.direction === "ASC") {
          this.confirmedFilters.direction = "DESC";
        } else {
          this.confirmedFilters = this.defaults.confirmedFilters;
        }
      }),
      initializeSelections: action(() => {
        const counts = {};
        this.opportunityStore.opportunities.forEach(o => {
          counts[o.id] = counts[o.id] || { tiers: {} };
          this.tiers.forEach(t => {
            counts[o.id].tiers[t.id] = 0;
          });
        });

        this.opportunityTierCounts = {
          ...this.opportunityTierCounts,
          ...counts
        };
      }),
      computeDuringSelectionPeriod: action(() => {
        // check if during a tier for volunteer
        const { assignedTierId: tierId } = this.volunteerStore.volunteer;
        const { tiers: tiersArr } = this.eventStore.event || {};
        const tiers = tiersArr || [];

        const now = new Date();
        let during = tiers.some(t => {
          if (t.id !== tierId) return false;
          const tierStartsAt = `${t.date} ${t.startTime}`;
          const tierEndsAt = `${t.endDate || t.date} ${t.endTime}`;
          const startTime = DateUtilities.parseTZString(tierStartsAt);
          const endTime = DateUtilities.parseTZString(tierEndsAt);

          const diff = startTime - now;
          return diff < 0 && endTime - now > 0;
        });

        if (!during) {
          // check if during an open enrollment
          const openEnrollments = Object.keys(this.eventStore.openEnrollments)
            .map(key => this.eventStore.openEnrollments[key])
            .filter(oe => oe.id);

          during = openEnrollments.some(oe => {
            const oeStartsAt = this.getOpenEnrollmentDateTime(
              oe,
              "date",
              "startTime"
            );
            const oeEndsAt = this.getOpenEnrollmentDateTime(
              oe,
              "endDate",
              "endTime"
            );
            const startTime = DateUtilities.parseTZString(oeStartsAt);
            const endTime = DateUtilities.parseTZString(oeEndsAt);
            const diff = startTime - now;

            return diff < 0 && endTime - now > 0;
          });
        }

        this.selectionPeriodInProgress = during;
      })
    });

    autorun(() => {
      // check if the auth store data is initialized
      const { accessToken, checkLocalStorage } = this.authStore;
      const params = this.routerStore.getPathParams(RouteConstants.TRACKER);
      const eventId = params.eventId;
      const isTracker = this.isTracker(eventId);

      if (isTracker && !accessToken && eventId) {
        checkLocalStorage();
      }
    });

    autorun(() => {
      // load opportunities for event if they haven't been already
      const { isAdmin } = this.authStore;
      const { event, loadEvent, loadingEvent } = this.eventStore;
      const {
        opportunities,
        loadOpportunitiesForEvent,
        opportunitiesLoading: loading
      } = this.opportunityStore;
      const params = this.routerStore.getPathParams(RouteConstants.TRACKER);

      const eventId = params.eventId;
      const isTracker = this.isTracker(eventId);

      if (
        isAdmin &&
        eventId &&
        isTracker &&
        !opportunities.length &&
        !loading
      ) {
        loadOpportunitiesForEvent(eventId);
      }

      if (isAdmin && eventId && isTracker && !event && !loadingEvent) {
        loadEvent(eventId);
      }
    });

    autorun(() => {
      const { isAdmin } = this.authStore;
      const { opportunities } = this.opportunityStore;
      const cxn = this.trackerApi.client && this.trackerApi.client.connected;
      const { id: eventId, tiers: arr } = this.eventStore.event || {};
      const tiers = arr || [];
      const data = tiers.length && opportunities.length;
      const isTracker = this.isTracker(eventId);

      if (isAdmin && isTracker && !cxn && !this.connecting && data) {
        this.connecting = true;
        this.tiers = _.sortBy(tiers, "id");
        this.selectedTier = {
          key: this.tiers[0].id,
          name: "Tier 1",
          value: this.tiers[0]
        };
        this.initializeSelections();
        this.connect(eventId, this.tiers);
      }
    });
  }

  connect = (eventId, tiers) => {
    this.trackerApi.connect(() => {
      this.connecting = false;
      tiers.forEach(tier => {
        this.trackerApi.client.subscribe(
          `/event/${eventId}/tier/${tier.id}/selections`,
          response => this.updateSelections(response, tier.id)
        );
      });
      this.timeSubscription = this.trackerApi.client.subscribe(
        "/status/time",
        this.updateServerTime
      );

      // add initializers here
      this.getServerTime();
      tiers.forEach(tier => this.getSelections(eventId, tier.id));
    });
  };

  calculateTimeParts = date => {
    const hours = date / (1000 * 60 * 60);
    const mins = (hours - Math.floor(hours)) * 60;
    const secs = Math.floor((mins - Math.floor(mins)) * 60);

    return { hours: Math.floor(hours), mins: Math.floor(mins), secs };
  };

  remainingTime = () => {
    if (
      !this.serverTime ||
      !this.systemTime ||
      !this.startDateTime ||
      !this.endDateTime
    ) {
      return;
    }

    const duration = new Date() - this.systemTime;
    const now = new Date(this.serverTime.getTime() + duration);
    let diff = this.startDateTime - now;
    if (diff > 0) {
      this.timeParts = this.calculateTimeParts(diff);
    } else {
      diff = this.endDateTime - now;
      if (diff > 0) {
        this.timeParts = this.calculateTimeParts(diff);
      } else {
        this.timeParts = this.defaults.timeParts;
      }
    }
  };

  sortDateTimes = (a, b, filters) => {
    const direction = filters.direction === "ASC" ? 1 : -1;
    const timeStamp1 = `${a.date} ${a.startTime}`;
    const timeStamp2 = `${b.date} ${b.startTime}`;

    switch (filters.key) {
      case "name":
        return a.name.toLowerCase() > b.name.toLowerCase()
          ? 1 * direction
          : -1 * direction;
      case "date":
        return (a.date > b.date ? 1 : -1) * direction;
      case "shift":
        if (a.startTime > b.startTime) return 1 * direction;
        if (a.startTime < b.startTime) return -1 * direction;

        return (a.endTime > b.endTime ? 1 : -1) * direction;
      case "zone":
        return (a.zoneNumber > b.zoneNumber ? 1 : -1) * direction;
      case "spots":
        return (a.spots > b.spots ? 1 : -1) * direction;
      case "check":
        return (a.outside > b.outside ? 1 : -1) * direction;
      case "attendedShift":
        return (a.attendedShift > b.attendedShift ? 1 : -1) * direction;
      default:
        return timeStamp1 > timeStamp2 ? 1 : -1;
    }
  };

  sortConfirmed = (a, b) => (a.timestamp > b.timestamp ? -1 : 1);

  sortUnconfirmed = (a, b) => (a.count > b.count ? -1 : 1);

  isConflicted = (opportunityShift, opportunity) => {
    const { shiftDateToShiftAndOppMap: sdm } = this.opportunityStore;
    const selection = sdm[opportunityShift.id] || {};
    const startTime1 = selection.startTime,
      startTime2 = opportunity.startTime,
      endTime1 = selection.endTime,
      endTime2 = opportunity.endTime;

    return (
      selection.date === opportunity.date &&
      !(
        (startTime1 < startTime2 && endTime1 === startTime2) ||
        (startTime1 === endTime2 && endTime1 > endTime2) ||
        (startTime1 < startTime2 && endTime1 < startTime2) ||
        (startTime1 > endTime2 && endTime1 > endTime2)
      )
    );
  };

  sortObj = o => {
    const { shiftDateToShiftAndOppMap: sdm } = this.opportunityStore;
    const selection = sdm[o.opportunityShift.id] || {};
    return {
      ...o,
      date: selection.date,
      startTime: selection.startTime,
      endTime: selection.endTime
    };
  };

  getOpenEnrollmentDateTime = (oe, dateProperty, timeProperty) => {
    const hours = oe[timeProperty]
      .getHours()
      .toString()
      .padStart(2, "0");
    const minutes = oe[timeProperty]
      .getMinutes()
      .toString()
      .padStart(2, "0");

    const date = oe[dateProperty] || oe.date;
    return `${DateUtilities.dateToString(
      date,
      "YYYY-MM-DD"
    )} ${hours}:${minutes}:00`;
  };

  isTracker = eventId => {
    if (!eventId) return false;
    const { stripSlash, location } = this.routerStore;
    const trackerPath = RouteConstants.TRACKER.replace(":eventId", eventId);
    return stripSlash(location.pathname) === trackerPath;
  };

  setDifference = (setA, setB) => {
    const diff = new Set(setA.values());
    for (const elem of setB) {
      diff.delete(elem);
    }
    return diff;
  };

  get tierOptions() {
    return this.tiers.map((t, i) => ({
      key: t.id,
      name: `Tier ${i + 1}`,
      value: t
    }));
  }

  get startDateTime() {
    const { date, startTime } = this.selectedTier.value || {};
    return !date ? null : DateUtilities.parseTZString(`${date} ${startTime}`);
  }

  get endDateTime() {
    const { date, endDate, endTime } = this.selectedTier.value || {};
    return !date
      ? null
      : DateUtilities.parseTZString(`${endDate || date} ${endTime}`);
  }

  get confirmedVolunteerSelections() {
    const { selections } = this.volunteerStore;
    const { shiftDateToShiftAndOppMap: sdm } = this.opportunityStore;
    const filters = this.confirmedFilters;

    return selections
      .map(s => {
        const selection = sdm[s.opportunityShift.id] || {};
        const date = selection.date
          ? DateUtilities.dateToString(selection.date, "ddd, M/D", "YYYY-MM-DD")
          : undefined;
        const startTime = selection.startTime
          ? DateUtilities.timeToString(selection.startTime, "h:mma", "HH:mm:00")
          : undefined;
        const endTime = selection.endTime
          ? DateUtilities.timeToString(selection.endTime, "h:mma", "HH:mm:00")
          : undefined;

        return {
          ...s,
          ...selection,
          date,
          startTime,
          endTime
        };
      })
      .sort((a, b) =>
        this.sortDateTimes(this.sortObj(a), this.sortObj(b), filters)
      );
  }

  get availableOpportunities() {
    const {
      selections,
      selectedDates,
      selectedOpportunities
    } = this.volunteerStore;
    const { availableOpportunitiesMap } = this.opportunityStore;
    const selectedValues = new Set(selections.map(s => s.opportunityShift.id));
    const dateSet = new Set(selectedDates.map(d => d.value));
    const oppSet = new Set(selectedOpportunities.map(o => o.value));
    const filters = this.availableFilters;

    return Object.values(availableOpportunitiesMap)
      .filter(
        o =>
          (!dateSet.size || dateSet.has(o.date)) &&
          (!oppSet.size || oppSet.has(o.opportunityId))
      )
      .sort((a, b) => this.sortDateTimes(a, b, filters))
      .map(o => {
        let selected = false;
        for (let i = 0; i < o.opportunityShiftIds.length; i++) {
          const osid = o.opportunityShiftIds[i].opportunityShiftId;
          selected = selectedValues.has(osid);
          if (selected) {
            break;
          }
        }

        return {
          ...o,
          spots: 3,
          selected: selected,
          conflicted: selections.some(s => this.isConflicted(s.opportunityShift, o)),
          date: DateUtilities.dateToString(o.date, "ddd, M/D", "YYYY-MM-DD"),
          startTime: DateUtilities.timeToString(o.startTime, "h:mma", "HH:mm:00"),
          endTime: DateUtilities.timeToString(o.endTime, "h:mma", "HH:mm:00")
        };
      });
  }

  get opportunityCountMap() {
    const oppMap = {};
    this.opportunityStore.opportunities.forEach(o => {
      o.zones.forEach(z => {
        z.shifts.forEach(s => {
          _.sortBy(s.tiers, "id").forEach((t, i) => {
            const { id } = this.tiers[i] || {};
            if (!id) return;

            const tiers = (oppMap[o.id] || {}).tiers || {};
            const total = tiers[id] || 0;
            oppMap[o.id] = {
              tiers: {
                ...tiers,
                [id]: total + t.volunteerCount * s.dates.length
              }
            };
          });
        });
      });
    });

    return oppMap;
  }

  get opportunityCounts() {
    const { id } = this.selectedTier.value || {};
    if (!id) return [];

    return this.opportunityStore.opportunities.map(o => {
      const opportunity = this.opportunityTierCounts[o.id] || {};
      const assigned = (opportunity.tiers || {})[id] || 0;
      const total = this.opportunityCountMap[o.id].tiers[id];

      return { ...o, assigned, needed: total, full: assigned >= total };
    });
  }

  get opportunityTotals() {
    return this.opportunityCounts.reduce(
      (pv, o) => ({
        assigned: pv.assigned + o.assigned,
        needed: pv.needed + o.needed
      }),
      { assigned: 0, needed: 0 }
    );
  }

  get confirmed() {
    const { id: tierId } = this.selectedTier.value || {};
    if (!tierId) return [];

    return Object.keys(this.confirmedVolunteers)
      .filter(volId => {
        const volunteerId = parseInt(volId);
        const volunteer = this.confirmedVolunteers[volunteerId] || {};
        const tiers = volunteer.tiers || {};
        const { count } = tiers[tierId] || {};
        return count !== undefined;
      })
      .map(volId => {
        const volunteerId = parseInt(volId);
        const volunteer = this.confirmedVolunteers[volunteerId].tiers;
        const { address } = this.emails[volunteerId] || {};
        const { count, timestamp } = volunteer[tierId];
        return { email: address, count, timestamp };
      })
      .sort(this.sortConfirmed);
  }

  get unconfirmed() {
    const { id: tierId } = this.selectedTier.value || {};
    if (!tierId) return [];

    return Object.keys(this.volunteers)
      .filter(volId => {
        const volunteerId = parseInt(volId);
        const volunteer = this.volunteers[volunteerId] || {};
        const tiers = volunteer.tiers || {};
        return tiers[tierId] !== undefined;
      })
      .map(volId => {
        const volunteerId = parseInt(volId);
        const volunteer = this.volunteers[volunteerId].tiers;
        const { address } = this.emails[volunteerId] || {};
        return { email: address, count: volunteer[tierId] };
      })
      .sort(this.sortUnconfirmed);
  }
}

decorate(TrackerStore, {
  confirmed: computed,
  unconfirmed: computed,
  tierOptions: computed,
  startDateTime: computed,
  endDateTime: computed,
  opportunityTotals: computed,
  opportunityCounts: computed,
  opportunityCountMap: computed,
  confirmedVolunteerSelections: computed
});

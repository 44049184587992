import moment from "moment";
import { extendObservable, action, decorate, computed } from "mobx";
import { EditorState } from "draft-js";
import { StoreUtilities } from "../utilities/StoreUtilities";
import { StringUtilities } from "../utilities/StringUtilities";
import { DateUtilities } from "../utilities/DateUtilities";
import { EditorUtilities } from "../utilities/EditorUtilities";
import { ScreenConstants } from "../constants/ScreenConstants";
import { FormConstants } from "../constants/FormConstants";
import decorator from "../components/elements/form/TextEditorDecorators";

export default class VolunteerScreensStore {
  constructor(eventInfoStore) {
    this.eventInfoStore = eventInfoStore;
    this.fileReader = new FileReader();

    this.defaults = {
      englishScreenContentId: null,
      englishScreenContentCreatedTs: null,
      englishScreenContentCreatedUser: null,
      spanishScreenContentId: null,
      spanishScreenContentCreatedTs: null,
      spanishScreenContentCreatedUser: null,
      preSelectionTitle: "",
      preSelectionTitleSpanish: "",
      preSelectionSubtitle: EditorState.createEmpty(decorator),
      preSelectionSubtitleSpanish: EditorState.createEmpty(decorator),
      displayOpportunitiesToVolunteer: false,
      postSelectionTitle: "",
      postSelectionTitleSpanish: "",
      postSelectionSubtitle: EditorState.createEmpty(decorator),
      postSelectionSubtitleSpanish: EditorState.createEmpty(decorator),
      awaitingSelectionTitle: "",
      awaitingSelectionTitleSpanish: "",
      awaitingSelectionSubtitle: EditorState.createEmpty(decorator),
      awaitingSelectionSubtitleSpanish: EditorState.createEmpty(decorator),
      openSelectionTitle: "",
      openSelectionTitleSpanish: "",
      openSelectionSubtitle: EditorState.createEmpty(decorator),
      openSelectionSubtitleSpanish: EditorState.createEmpty(decorator),
      applicationReceivedTitle: "",
      applicationReceivedTitleSpanish: "",
      applicationReceivedSubtitle: EditorState.createEmpty(decorator),
      applicationReceivedSubtitleSpanish: EditorState.createEmpty(decorator),
      waiverAcceptedTitle: "",
      waiverAcceptedTitleSpanish: "",
      waiverAcceptedSubtitle: EditorState.createEmpty(decorator),
      waiverAcceptedSubtitleSpanish: EditorState.createEmpty(decorator),
      waiverText: EditorState.createEmpty(decorator),
      waiverTextSpanish: EditorState.createEmpty(decorator),
      waiverFilename: "",
      waiverRef: undefined,
      waiverPdf: null,
      uploadPhotoTitle: "",
      uploadPhotoTitleSpanish: "",
      uploadPhotoSubtitle: EditorState.createEmpty(decorator),
      uploadPhotoSubtitleSpanish: EditorState.createEmpty(decorator),
      uploadPhotoRejectedText: EditorState.createEmpty(decorator),
      uploadPhotoRejectedTextSpanish: EditorState.createEmpty(decorator),
      uploadPhotoPendingText: EditorState.createEmpty(decorator),
      uploadPhotoPendingTextSpanish: EditorState.createEmpty(decorator),
      informationSessionTitle: "",
      informationSessionTitleSpanish: "",
      informationSessionSubtitle: EditorState.createEmpty(decorator),
      informationSessionSubtitleSpanish: EditorState.createEmpty(decorator),
      missedInformationSessionTitle: "",
      missedInformationSessionTitleSpanish: "",
      missedInformationSessionSubtitle: EditorState.createEmpty(decorator),
      missedInformationSessionSubtitleSpanish:
        EditorState.createEmpty(decorator),
      failedInformationSessionTitle: "",
      failedInformationSessionTitleSpanish: "",
      failedInformationSessionSubtitle: EditorState.createEmpty(decorator),
      failedInformationSessionSubtitleSpanish:
        EditorState.createEmpty(decorator),
      backgroundCheckTitle: "",
      backgroundCheckTitleSpanish: "",
      backgroundCheckSubtitle: EditorState.createEmpty(decorator),
      backgroundCheckSubtitleSpanish: EditorState.createEmpty(decorator),
      eventInfoTitle: "",
      eventInfoTitleSpanish: "",
      eventInfoSubtitle: EditorState.createEmpty(decorator),
      eventInfoSubtitleSpanish: EditorState.createEmpty(decorator),
      faqTitle: "",
      faqTitleSpanish: "",
      faqs: [],
      contactEmailAddress: "",
      contactInstructionText: EditorState.createEmpty(decorator),
      contactInstructionTextSpanish: EditorState.createEmpty(decorator),
      infoSessions: [],
      orientations: [],
      noOpportunitiesAwaitingEnrollmentTitle: "",
      noOpportunitiesAwaitingEnrollmentTitleSpanish: "",
      noOpportunitiesAwaitingEnrollmentSubtitle:
        EditorState.createEmpty(decorator),
      noOpportunitiesAwaitingEnrollmentSubtitleSpanish:
        EditorState.createEmpty(decorator),
      noOpportunitiesMissedEnrollmentTitle: "",
      noOpportunitiesMissedEnrollmentTitleSpanish: "",
      noOpportunitiesMissedEnrollmentSubtitle:
        EditorState.createEmpty(decorator),
      noOpportunitiesMissedEnrollmentSubtitleSpanish:
        EditorState.createEmpty(decorator),
      orientationTitle: "",
      orientationTitleSpanish: "",
      orientationSubtitle: EditorState.createEmpty(decorator),
      orientationSubtitleSpanish: EditorState.createEmpty(decorator),
      standbySelectionsTitle: "",
      standbySelectionsTitleSpanish: "",
      standbySelectionsSubtitle: EditorState.createEmpty(decorator),
      standbySelectionsSubtitleSpanish: EditorState.createEmpty(decorator),
      openOpportunitiesTitle: "",
      openOpportunitiesTitleSpanish: "",
      openOpportunitiesSubtitle: EditorState.createEmpty(decorator),
      openOpportunitiesSubtitleSpanish: EditorState.createEmpty(decorator),
      expanders: {
        applicationReceivedExpanded: false,
        infoSessionExpanded: false,
        waiverExpanded: false,
        uploadPhotoExpanded: false,
        missedInfoExpanded: false,
        failedInfoExpanded: false,
        backgroundCheckExpanded: false,
        preSelectionExpanded: false,
        postSelectionExpanded: false,
        awaitingSelectionExpanded: false,
        openSelectionExpanded: false,
        eventInfoExpanded: false,
        contactExpanded: false,
        noOpportunitiesAwaitingEnrollmentExpanded: false,
        noOpportunitiesMissedEnrollmentExpanded: false,
        standbySelectionsExpanded: false,
        openOpportunitiesExpanded: false,
        orientationExpanded: false,
      },
      allOppBlocksExpanded: false,
      languageOptions: FormConstants.LANGUAGES,
      currentLanguage: FormConstants.LANGUAGES[0],
      language: FormConstants.LANGUAGES[0].name,
      isLanguageOpen: false,
      virtualInfoSession: {
        id: null,
        eventId: null,
        allow: false,
        deadlineDate: null,
        deadlineTime: null,
        deadlineTimezone: "",
        description: EditorState.createEmpty(decorator),
        descriptionSpanish: EditorState.createEmpty(decorator),
        questions: [],
        videoLocation: "",
        videoLocationSpanish: "",
        videoFile: null,
        videoFileSpanish: null,
      },
    };

    extendObservable(this, {
      ...StoreUtilities.initialize(
        this,
        new Set([
          "applicationReceivedTitle",
          "applicationReceivedSubtitle",
          "waiverAcceptedTitle",
          "waiverAcceptedSubtitle",
          "waiverText",
          "uploadPhotoTitle",
          "uploadPhotoSubtitle",
          "uploadPhotoRejectedText",
          "uploadPhotoPendingText",
          "informationSessionTitle",
          "informationSessionSubtitle",
          "missedInformationSessionTitle",
          "missedInformationSessionSubtitle",
          "failedInformationSessionTitle",
          "failedInformationSessionSubtitle",
          "backgroundCheckTitle",
          "backgroundCheckSubtitle",
          "preSelectionTitle",
          "preSelectionSubtitle",
          "postSelectionTitle",
          "postSelectionSubtitle",
          "awaitingSelectionTitle",
          "awaitingSelectionSubtitle",
          "openSelectionTitle",
          "openSelectionSubtitle",
          "eventInfoTitle",
          "eventInfoSubtitle",
          "faqTitle",
          "contactInstructionText",
          "noOpportunitiesAwaitingEnrollmentTitle",
          "noOpportunitiesAwaitingEnrollmentSubtitle",
          "noOpportunitiesMissedEnrollmentTitle",
          "noOpportunitiesMissedEnrollmentSubtitle",
          "orientationTitle",
          "orientationSubtitle",
          "standbySelectionsTitle",
          "standbySelectionsSubtitle",
          "openOpportunitiesTitle",
          "openOpportunitiesSubtitle",
        ]),
        "currentLanguage"
      ),

      setLanguage: action((event) => {
        if (typeof event === "string") {
          this.language = event;
        } else {
          this.language = event.target.value;
        }
      }),
      setCurrentLanguage: action((value) => {
        this.currentLanguage = value;
        this.setLanguage(this.currentLanguage.name);
      }),

      setWaiverPdf: action((files) => {
        this.waiverFilename = files[0].name;
        this.waiverRef = URL.createObjectURL(files[0]);
        this.fileReader.onloadend = () => {
          this.waiverPdf = this.fileReader.result;
        };
        this.fileReader.readAsDataURL(files[0]);
      }),
      addFAQ: action(() => {
        this.faqs = this.faqs.concat({
          question: "",
          answer: "",
          questionSpanish: "",
          answerSpanish: "",
          key: StringUtilities.random(),
        });
      }),
      addInfoSession: action(() => {
        this.infoSessions = this.infoSessions.concat({
          key: StringUtilities.random(),
          description: EditorState.createEmpty(decorator),
          infoSessionDates: [
            {
              key: StringUtilities.random(),
              date: null,
              startTime: null,
              endTime: null,
            },
          ],
        });
      }),
      addOrientation: action(() => {
        this.orientations = this.orientations.concat({
          key: StringUtilities.random(),
          description: EditorState.createEmpty(decorator),
          orientationDates: [
            {
              key: StringUtilities.random(),
              date: null,
              startTime: null,
              endTime: null,
            },
          ],
        });
      }),
      removeFAQ: action((index) => {
        this.faqs = [
          ...this.faqs.slice(0, index),
          ...this.faqs.slice(index + 1),
        ];
      }),
      removeInfoSession: action((index) => {
        this.infoSessions = [
          ...this.infoSessions.slice(0, index),
          ...this.infoSessions.slice(index + 1),
        ];
      }),
      removeOrientation: action((index) => {
        this.orientations = [
          ...this.orientations.slice(0, index),
          ...this.orientations.slice(index + 1),
        ];
      }),
      addInfoSessionSchedule: action((locationIndex) => {
        this.infoSessions = this.infoSessions.map((isl, index) => {
          if (index === locationIndex) {
            const dates = isl.infoSessionDates || [];
            isl.infoSessionDates = dates.concat({
              key: StringUtilities.random(),
            });
          }

          return isl;
        });
      }),
      addOrientationSchedule: action((locationIndex) => {
        this.orientations = this.orientations.map((o, index) => {
          if (index === locationIndex) {
            const dates = o.orientationDates || [];
            o.orientationDates = dates.concat({
              key: StringUtilities.random(),
            });
          }

          return o;
        });
      }),
      removeLocationSchedule: action((locationIndex, scheduleIndex) => {
        this.infoSessions = this.infoSessions.map((isl, index) => {
          if (index !== locationIndex) {
            return isl;
          }

          isl.infoSessionDates = [
            ...isl.infoSessionDates.slice(0, scheduleIndex),
            ...isl.infoSessionDates.slice(scheduleIndex + 1),
          ];
          return isl;
        });
      }),
      removeOrientationSchedule: action((orientationIndex, scheduleIndex) => {
        this.orientations = this.orientations.map((o, index) => {
          if (index !== orientationIndex) {
            return o;
          }

          o.orientationDates = [
            ...o.orientationDates.slice(0, scheduleIndex),
            ...o.orientationDates.slice(scheduleIndex + 1),
          ];
          return o;
        });
      }),
      setLocationScheduleCapacity: action(
        (locationIndex, scheduleIndex, event) => {
          this.infoSessions[locationIndex].infoSessionDates[
            scheduleIndex
          ].capacity = event.target.value;

          this.infoSessions[locationIndex].infoSessionDates = this.infoSessions[
            locationIndex
          ].infoSessionDates.map((i) => i);
        }
      ),
      setLocationScheduleStartTime: action(
        (locationIndex, scheduleIndex, event) => {
          this.infoSessions = this.infoSessions.map((isl, index) => {
            if (index !== locationIndex) {
              return isl;
            }
            this.updateLocationScheduleValue(
              isl,
              event,
              scheduleIndex,
              "startTime"
            );
            return isl;
          });
        }
      ),
      setLocationScheduleEndTime: action(
        (locationIndex, scheduleIndex, event) => {
          this.infoSessions = this.infoSessions.map((isl, index) => {
            if (index !== locationIndex) {
              return isl;
            }
            this.updateLocationScheduleValue(
              isl,
              event,
              scheduleIndex,
              "endTime"
            );
            return isl;
          });
        }
      ),
      setOrientationScheduleStartTime: action(
        (locationIndex, scheduleIndex, event) => {
          this.orientations = this.orientations.map((o, index) => {
            if (index !== locationIndex) {
              return o;
            }
            this.updateOrientationScheduleValue(
              o,
              event,
              scheduleIndex,
              "startTime"
            );
            return o;
          });
        }
      ),
      setVirtualInfoSessionAttribute: action((update) => {
        this.virtualInfoSession = { ...this.virtualInfoSession, ...update };
      }),
      setOrientationScheduleEndTime: action(
        (locationIndex, scheduleIndex, event) => {
          this.orientations = this.orientations.map((o, index) => {
            if (index !== locationIndex) {
              return o;
            }
            this.updateOrientationScheduleValue(
              o,
              event,
              scheduleIndex,
              "endTime"
            );
            return o;
          });
        }
      ),
      setLocationScheduleDateFromEvent: action(
        (locationIndex, scheduleIndex, date, event) => {
          if (!(date instanceof Date)) {
            event = date;
          }

          if (!event) {
            this.infoSessions = this.infoSessions.map((isl, index) => {
              if (index !== locationIndex) {
                return isl;
              }
              this.updateLocationScheduleValue(
                isl,
                null,
                scheduleIndex,
                "date"
              );
              return isl;
            });
          } else if (event.type === "click") {
            this.infoSessions = this.infoSessions.map((isl, index) => {
              if (index !== locationIndex) {
                return isl;
              }
              this.updateLocationScheduleValue(
                isl,
                date,
                scheduleIndex,
                "date"
              );
              return isl;
            });
          } else {
            const d = moment(event.currentTarget.value, "MM/DD/YYYY", true);
            if (d.isValid()) {
              this.infoSessions = this.infoSessions.map((isl, index) => {
                if (index !== locationIndex) {
                  return isl;
                }
                this.updateLocationScheduleValue(
                  isl,
                  d.toDate(),
                  scheduleIndex,
                  "date"
                );
                return isl;
              });
            }
          }
        }
      ),
      setOrientationScheduleDateFromEvent: action(
        (locationIndex, scheduleIndex, date, event) => {
          if (!(date instanceof Date)) {
            event = date;
          }

          if (!event) {
            this.orientations = this.orientations.map((o, index) => {
              if (index !== locationIndex) {
                return o;
              }
              this.updateOrientationScheduleValue(
                o,
                null,
                scheduleIndex,
                "date"
              );
              return o;
            });
          } else if (event.type === "click") {
            this.orientations = this.orientations.map((o, index) => {
              if (index !== locationIndex) {
                return o;
              }
              this.updateOrientationScheduleValue(
                o,
                date,
                scheduleIndex,
                "date"
              );
              return o;
            });
          } else {
            const d = moment(event.currentTarget.value, "MM/DD/YYYY", true);
            if (d.isValid()) {
              this.orientations = this.orientations.map((o, index) => {
                if (index !== locationIndex) {
                  return o;
                }
                this.updateOrientationScheduleValue(
                  o,
                  d.toDate(),
                  scheduleIndex,
                  "date"
                );
                return o;
              });
            }
          }
        }
      ),
      setLocationName: action((event, index) => {
        this.updateLocationValue(event.target.value, index, "name");
      }),
      setLocationCountry: action((item, index) => {
        this.updateLocationValue(item.name, index, "country");
      }),
      setLocationStreet1: action((event, index) => {
        this.updateLocationValue(event.target.value, index, "street1");
      }),
      setLocationStreet2: action((event, index) => {
        this.updateLocationValue(event.target.value, index, "street2");
      }),
      setLocationStreet3: action((event, index) => {
        this.updateLocationValue(event.target.value, index, "street3");
      }),
      setLocationCity: action((event, index) => {
        this.updateLocationValue(event.target.value, index, "city");
      }),
      setLocationState: action((item, index) => {
        this.updateLocationValue(item.name, index, "state");
      }),
      setLocationZipCode: action((event, index) => {
        this.updateLocationValue(event.target.value, index, "zipCode");
      }),
      setLocationDescription: action((editorState, index) => {
        this.updateLocationValue(editorState, index, "description");
      }),
      updateLocationValue: action((value, index, propname) => {
        this.infoSessions = this.infoSessions.map((item, idx) => {
          if (idx !== index) {
            return item;
          }
          return {
            ...item,
            [propname]: value,
          };
        });
      }),
      setOrientationName: action((event, index) => {
        this.updateOrientationValue(event.target.value, index, "name");
      }),
      setOrientationCountry: action((item, index) => {
        this.updateOrientationValue(item.name, index, "country");
      }),
      setOrientationStreet1: action((event, index) => {
        this.updateOrientationValue(event.target.value, index, "street1");
      }),
      setOrientationStreet2: action((event, index) => {
        this.updateOrientationValue(event.target.value, index, "street2");
      }),
      setOrientationStreet3: action((event, index) => {
        this.updateOrientationValue(event.target.value, index, "street3");
      }),
      setOrientationCity: action((event, index) => {
        this.updateOrientationValue(event.target.value, index, "city");
      }),
      setOrientationState: action((item, index) => {
        this.updateOrientationValue(item.name, index, "state");
      }),
      setOrientationZipCode: action((event, index) => {
        this.updateOrientationValue(event.target.value, index, "zipCode");
      }),
      setOrientationDescription: action((editorState, index) => {
        this.updateOrientationValue(editorState, index, "description");
      }),
      updateOrientationValue: action((value, index, propname) => {
        this.orientations = this.orientations.map((item, idx) => {
          if (idx !== index) {
            return item;
          }
          return {
            ...item,
            [propname]: value,
          };
        });
      }),
      updateLocationScheduleValue: action((isl, value, index, propname) => {
        isl.infoSessionDates = isl.infoSessionDates.map((s, idx) => {
          if (idx !== index) {
            return s;
          }
          return {
            ...s,
            [propname]: value,
          };
        });
      }),
      updateOrientationScheduleValue: action((o, value, index, propname) => {
        o.orientationDates = o.orientationDates.map((s, idx) => {
          if (idx !== index) {
            return s;
          }
          return {
            ...s,
            [propname]: value,
          };
        });
      }),
      setFaqQuestion: action((event, index) => {
        if (
          this.eventInfoStore.currentLanguage &&
          this.eventInfoStore.currentLanguage.key === "es"
        ) {
          this.faqs[index].questionSpanish = event.target.value;
        } else {
          this.faqs[index].question = event.target.value;
        }
      }),
      setFaqAnswer: action((event, index) => {
        if (
          this.eventInfoStore.currentLanguage &&
          this.eventInfoStore.currentLanguage.key === "es"
        ) {
          this.faqs[index].answerSpanish = event.target.value;
        } else {
          this.faqs[index].answer = event.target.value;
        }
      }),
      toggleExpander: action((key) => {
        this.expanders[key] = !this.expanders[key];
      }),
      toggleDisplayOpportunitiesToVolunteer: action(() => {
        this.displayOpportunitiesToVolunteer =
          !this.displayOpportunitiesToVolunteer;
      }),
      expandAllNavigation: action(() => {
        ScreenConstants.NAVIGATION.forEach((k) => {
          this.expanders[`${k}Expanded`] = true;
        });
        this.allOppBlocksExpanded = true;
      }),
      collapseAllNavigation: action(() => {
        ScreenConstants.NAVIGATION.forEach((k) => {
          this.expanders[`${k}Expanded`] = false;
        });
        this.allOppBlocksExpanded = false;
      }),
      expandAllOpportunities: action(() => {
        this.allOppBlocksExpanded = true;
      }),
      collapseAllOpportunities: action(() => {
        ScreenConstants.OPPORTUNITIES_NAVIGATION.forEach((k) => {
          this.expanders[`${k}Expanded`] = false;
        });
        this.allOppBlocksExpanded = false;
      }),
      expandAllRegistration: action(() => {
        ScreenConstants.REGISTRATION.forEach((k) => {
          this.expanders[`${k}Expanded`] = true;
        });
      }),
      collapseAllRegistration: action(() => {
        ScreenConstants.REGISTRATION.forEach((k) => {
          this.expanders[`${k}Expanded`] = false;
        });
      }),
      setVolunteerScreensAttributes: action((value) => {
        this.englishScreenContentId = this.defaults.englishScreenContentId;
        this.englishScreenContentCreatedTs =
          this.defaults.spanishScreenContentCreatedTs;
        this.englishScreenContentCreatedUser =
          this.defaults.spanishScreenContentCreatedUser;
        this.spanishScreenContentId = this.defaults.spanishScreenContentId;
        this.spanishScreenContentCreatedTs =
          this.defaults.spanishScreenContentCreatedTs;
        this.spanishScreenContentCreatedUser =
          this.defaults.spanishScreenContentCreatedUser;

        let englishIndex = 0;
        let spanishIndex = 1;

        if (value.screenContent) {
          for (let i = 0; i < value.screenContent.length; ++i) {
            if (value.screenContent[i].locale === "es") {
              spanishIndex = i;
              this.spanishScreenContentId = value.screenContent[i].id;
              this.spanishScreenContentCreatedTs =
                value.screenContent[i].createdTs;
              this.spanishScreenContentCreatedUser =
                value.screenContent[i].createdUser;
            } else {
              englishIndex = i;
              this.englishScreenContentId = value.screenContent[i].id;
              this.englishScreenContentCreatedTs =
                value.screenContent[i].createdTs;
              this.englishScreenContentCreatedUser =
                value.screenContent[i].createdUser;
            }
          }

          const {
            applicationReceivedTitle,
            applicationReceivedSubtitle,
            waiverAcceptedTitle,
            waiverAcceptedSubtitle,
            uploadPhotoTitle,
            uploadPhotoSubtitle,
            uploadPhotoRejectedText,
            uploadPhotoPendingText,
            informationSessionTitle,
            informationSessionSubtitle,
            missedInformationSessionTitle,
            missedInformationSessionSubtitle,
            failedInformationSessionTitle,
            failedInformationSessionSubtitle,
            backgroundCheckTitle,
            backgroundCheckSubtitle,
            preSelectionTitle,
            preSelectionSubtitle,
            postSelectionTitle,
            postSelectionSubtitle,
            awaitingSelectionTitle,
            awaitingSelectionSubtitle,
            openSelectionTitle,
            openSelectionSubtitle,
            eventInfoTitle,
            eventInfoSubtitle,
            faqTitle,
            faqs,
            contactEmailAddress,
            contactInstructionText,
            noOpportunitiesAwaitingEnrollmentTitle,
            noOpportunitiesAwaitingEnrollmentSubtitle,
            noOpportunitiesMissedEnrollmentTitle,
            noOpportunitiesMissedEnrollmentSubtitle,
            orientationTitle,
            orientationSubtitle,
            standbySelectionsTitle,
            standbySelectionsSubtitle,
            openOpportunitiesTitle,
            openOpportunitiesSubtitle,
          } = value.screenContent[englishIndex] || {};

          const {
            applicationReceivedTitle: applicationReceivedTitleSpanish,
            applicationReceivedSubtitle: applicationReceivedSubtitleSpanish,
            waiverAcceptedTitle: waiverAcceptedTitleSpanish,
            waiverAcceptedSubtitle: waiverAcceptedSubtitleSpanish,
            uploadPhotoTitle: uploadPhotoTitleSpanish,
            uploadPhotoSubtitle: uploadPhotoSubtitleSpanish,
            uploadPhotoRejectedText: uploadPhotoRejectedTextSpanish,
            uploadPhotoPendingText: uploadPhotoPendingTextSpanish,
            informationSessionTitle: informationSessionTitleSpanish,
            informationSessionSubtitle: informationSessionSubtitleSpanish,
            missedInformationSessionTitle: missedInformationSessionTitleSpanish,
            missedInformationSessionSubtitle:
              missedInformationSessionSubtitleSpanish,
            failedInformationSessionTitle: failedInformationSessionTitleSpanish,
            failedInformationSessionSubtitle:
              failedInformationSessionSubtitleSpanish,
            backgroundCheckTitle: backgroundCheckTitleSpanish,
            backgroundCheckSubtitle: backgroundCheckSubtitleSpanish,
            preSelectionTitle: preSelectionTitleSpanish,
            preSelectionSubtitle: preSelectionSubtitleSpanish,
            postSelectionTitle: postSelectionTitleSpanish,
            postSelectionSubtitle: postSelectionSubtitleSpanish,
            awaitingSelectionTitle: awaitingSelectionTitleSpanish,
            awaitingSelectionSubtitle: awaitingSelectionSubtitleSpanish,
            openSelectionTitle: openSelectionTitleSpanish,
            openSelectionSubtitle: openSelectionSubtitleSpanish,
            eventInfoTitle: eventInfoTitleSpanish,
            eventInfoSubtitle: eventInfoSubtitleSpanish,
            faqTitle: faqTitleSpanish,
            contactInstructionText: contactInstructionTextSpanish,
            noOpportunitiesAwaitingEnrollmentTitle:
              noOpportunitiesAwaitingEnrollmentTitleSpanish,
            noOpportunitiesAwaitingEnrollmentSubtitle:
              noOpportunitiesAwaitingEnrollmentSubtitleSpanish,
            orientationTitle: orientationTitleSpanish,
            orientationSubtitle: orientationSubtitleSpanish,
            noOpportunitiesMissedEnrollmentTitle:
              noOpportunitiesMissedEnrollmentTitleSpanish,
            noOpportunitiesMissedEnrollmentSubtitle:
              noOpportunitiesMissedEnrollmentSubtitleSpanish,
            standbySelectionsTitle: standbySelectionsTitleSpanish,
            standbySelectionsSubtitle: standbySelectionsSubtitleSpanish,
            openOpportunitiesTitle: openOpportunitiesTitleSpanish,
            openOpportunitiesSubtitle: openOpportunitiesSubtitleSpanish,
          } = value.screenContent[spanishIndex] || {};

          // set titles
          this.applicationReceivedTitle = applicationReceivedTitle || "";
          this.applicationReceivedTitleSpanish =
            applicationReceivedTitleSpanish || "";
          this.applicationReceivedSubtitle = applicationReceivedSubtitle || "";
          this.applicationReceivedSubtitleSpanish =
            applicationReceivedSubtitleSpanish || "";
          this.waiverAcceptedTitle = waiverAcceptedTitle || "";
          this.waiverAcceptedTitleSpanish = waiverAcceptedTitleSpanish || "";
          this.waiverAcceptedSubtitle = waiverAcceptedSubtitle || "";
          this.waiverAcceptedSubtitleSpanish =
            waiverAcceptedSubtitleSpanish || "";

          this.uploadPhotoTitle = uploadPhotoTitle || "";
          this.uploadPhotoTitleSpanish = uploadPhotoTitleSpanish || "";
          this.uploadPhotoSubtitle = uploadPhotoSubtitle || "";
          this.uploadPhotoSubtitleSpanish = uploadPhotoSubtitleSpanish || "";
          this.uploadPhotoRejectedText = uploadPhotoRejectedText || "";
          this.uploadPhotoRejectedTextSpanish =
            uploadPhotoRejectedTextSpanish || "";
          this.uploadPhotoPendingText = uploadPhotoPendingText || "";
          this.uploadPhotoPendingTextSpanish =
            uploadPhotoPendingTextSpanish || "";
          this.informationSessionTitle = informationSessionTitle || "";
          this.informationSessionTitleSpanish =
            informationSessionTitleSpanish || "";
          this.missedInformationSessionTitle =
            missedInformationSessionTitle || "";
          this.missedInformationSessionTitleSpanish =
            missedInformationSessionTitleSpanish || "";
          this.failedInformationSessionTitle =
            failedInformationSessionTitle || "";
          this.failedInformationSessionTitleSpanish =
            failedInformationSessionTitleSpanish || "";
          this.backgroundCheckTitle = backgroundCheckTitle || "";
          this.backgroundCheckTitleSpanish = backgroundCheckTitleSpanish || "";
          this.preSelectionTitle = preSelectionTitle || "";
          this.preSelectionTitleSpanish = preSelectionTitleSpanish || "";
          this.postSelectionTitle = postSelectionTitle || "";
          this.postSelectionTitleSpanish = postSelectionTitleSpanish || "";
          this.awaitingSelectionTitle = awaitingSelectionTitle || "";
          this.awaitingSelectionTitleSpanish =
            awaitingSelectionTitleSpanish || "";
          this.openSelectionTitle = openSelectionTitle || "";
          this.openSelectionTitleSpanish = openSelectionTitleSpanish || "";
          this.eventInfoTitle = eventInfoTitle || "";
          this.eventInfoTitleSpanish = eventInfoTitleSpanish || "";
          this.faqTitle = faqTitle || "";
          this.faqTitleSpanish = faqTitleSpanish || "";
          this.contactEmailAddress = contactEmailAddress || "";
          this.noOpportunitiesAwaitingEnrollmentTitle =
            noOpportunitiesAwaitingEnrollmentTitle || "";
          this.noOpportunitiesAwaitingEnrollmentTitleSpanish =
            noOpportunitiesAwaitingEnrollmentTitleSpanish || "";
          this.noOpportunitiesAwaitingEnrollmentSubtitle =
            noOpportunitiesAwaitingEnrollmentSubtitle || "";
          this.noOpportunitiesAwaitingEnrollmentSubtitleSpanish =
            noOpportunitiesAwaitingEnrollmentSubtitleSpanish || "";
          this.noOpportunitiesMissedEnrollmentTitle =
            noOpportunitiesMissedEnrollmentTitle || "";
          this.noOpportunitiesMissedEnrollmentTitleSpanish =
            noOpportunitiesMissedEnrollmentTitleSpanish || "";
          this.noOpportunitiesMissedEnrollmentSubtitle =
            noOpportunitiesMissedEnrollmentSubtitle || "";
          this.noOpportunitiesMissedEnrollmentSubtitleSpanish =
            noOpportunitiesMissedEnrollmentSubtitleSpanish || "";
          this.orientationTitle = orientationTitle || "";
          this.orientationTitleSpanish = orientationTitleSpanish || "";
          this.orientationSubtitle = orientationSubtitle || "";
          this.orientationSubtitleSpanish = orientationSubtitleSpanish || "";
          this.standbySelectionsTitle = standbySelectionsTitle || "";
          this.standbySelectionsTitleSpanish =
            standbySelectionsTitleSpanish || "";
          this.standbySelectionsSubtitle = standbySelectionsSubtitle || "";
          this.standbySelectionsSubtitleSpanish =
            standbySelectionsSubtitleSpanish || "";
          this.openOpportunitiesTitle = openOpportunitiesTitle || "";
          this.openOpportunitiesTitleSpanish =
            openOpportunitiesTitleSpanish || "";
          this.openOpportunitiesSubtitle = openOpportunitiesSubtitle || "";
          this.openOpportunitiesSubtitleSpanish =
            openOpportunitiesSubtitleSpanish || "";

          // set subtitles & text editor text
          this.informationSessionSubtitle = EditorUtilities.stateFromRawString(
            informationSessionSubtitle
          );
          this.informationSessionSubtitleSpanish =
            EditorUtilities.stateFromRawString(
              informationSessionSubtitleSpanish
            );
          this.missedInformationSessionSubtitle =
            EditorUtilities.stateFromRawString(
              missedInformationSessionSubtitle
            );
          this.missedInformationSessionSubtitleSpanish =
            EditorUtilities.stateFromRawString(
              missedInformationSessionSubtitleSpanish
            );
          this.failedInformationSessionSubtitle =
            EditorUtilities.stateFromRawString(
              failedInformationSessionSubtitle
            );
          this.failedInformationSessionSubtitleSpanish =
            EditorUtilities.stateFromRawString(
              failedInformationSessionSubtitleSpanish
            );
          this.backgroundCheckSubtitle = EditorUtilities.stateFromRawString(
            backgroundCheckSubtitle
          );
          this.backgroundCheckSubtitleSpanish =
            EditorUtilities.stateFromRawString(backgroundCheckSubtitleSpanish);
          this.preSelectionSubtitle =
            EditorUtilities.stateFromRawString(preSelectionSubtitle);
          this.preSelectionSubtitleSpanish = EditorUtilities.stateFromRawString(
            preSelectionSubtitleSpanish
          );
          this.postSelectionSubtitle = EditorUtilities.stateFromRawString(
            postSelectionSubtitle
          );
          this.postSelectionSubtitleSpanish =
            EditorUtilities.stateFromRawString(postSelectionSubtitleSpanish);
          this.awaitingSelectionSubtitle = EditorUtilities.stateFromRawString(
            awaitingSelectionSubtitle
          );
          this.awaitingSelectionSubtitleSpanish =
            EditorUtilities.stateFromRawString(
              awaitingSelectionSubtitleSpanish
            );
          this.openSelectionSubtitle = EditorUtilities.stateFromRawString(
            openSelectionSubtitle
          );
          this.openSelectionSubtitleSpanish =
            EditorUtilities.stateFromRawString(openSelectionSubtitleSpanish);
          this.eventInfoSubtitle =
            EditorUtilities.stateFromRawString(eventInfoSubtitle);
          this.eventInfoSubtitleSpanish = EditorUtilities.stateFromRawString(
            eventInfoSubtitleSpanish
          );
          this.contactInstructionText = EditorUtilities.stateFromRawString(
            contactInstructionText
          );
          this.contactInstructionTextSpanish =
            EditorUtilities.stateFromRawString(contactInstructionTextSpanish);
          this.noOpportunitiesAwaitingEnrollmentSubtitle =
            EditorUtilities.stateFromRawString(
              noOpportunitiesAwaitingEnrollmentSubtitle
            );
          this.noOpportunitiesAwaitingEnrollmentSubtitleSpanish =
            EditorUtilities.stateFromRawString(
              noOpportunitiesAwaitingEnrollmentSubtitleSpanish
            );
          this.noOpportunitiesMissedEnrollmentSubtitle =
            EditorUtilities.stateFromRawString(
              noOpportunitiesMissedEnrollmentSubtitle
            );
          this.noOpportunitiesMissedEnrollmentSubtitleSpanish =
            EditorUtilities.stateFromRawString(
              noOpportunitiesMissedEnrollmentSubtitleSpanish
            );
          this.orientationSubtitle =
            EditorUtilities.stateFromRawString(orientationSubtitle);
          this.orientationSubtitleSpanish = EditorUtilities.stateFromRawString(
            orientationSubtitleSpanish
          );
          this.standbySelectionsSubtitle = EditorUtilities.stateFromRawString(
            standbySelectionsSubtitle
          );
          this.standbySelectionsSubtitleSpanish =
            EditorUtilities.stateFromRawString(
              standbySelectionsSubtitleSpanish
            );
          this.openOpportunitiesSubtitle = EditorUtilities.stateFromRawString(
            openOpportunitiesSubtitle
          );
          this.openOpportunitiesSubtitleSpanish =
            EditorUtilities.stateFromRawString(
              openOpportunitiesSubtitleSpanish
            );
          this.applicationReceivedSubtitle = EditorUtilities.stateFromRawString(
            applicationReceivedSubtitle
          );
          this.applicationReceivedSubtitleSpanish =
            EditorUtilities.stateFromRawString(
              applicationReceivedSubtitleSpanish
            );
          this.waiverAcceptedSubtitle = EditorUtilities.stateFromRawString(
            waiverAcceptedSubtitle
          );
          this.waiverAcceptedSubtitleSpanish =
            EditorUtilities.stateFromRawString(waiverAcceptedSubtitleSpanish);

          this.uploadPhotoSubtitle =
            EditorUtilities.stateFromRawString(uploadPhotoSubtitle);
          this.uploadPhotoSubtitleSpanish = EditorUtilities.stateFromRawString(
            uploadPhotoSubtitleSpanish
          );

          this.uploadPhotoRejectedText = EditorUtilities.stateFromRawString(
            uploadPhotoRejectedText
          );
          this.uploadPhotoRejectedTextSpanish =
            EditorUtilities.stateFromRawString(uploadPhotoRejectedTextSpanish);

          this.uploadPhotoPendingText = EditorUtilities.stateFromRawString(
            uploadPhotoPendingText
          );
          this.uploadPhotoPendingTextSpanish =
            EditorUtilities.stateFromRawString(uploadPhotoPendingTextSpanish);

          // set faqs
          this.faqs = (faqs || []).map((f) => ({
            ...f,
            key: StringUtilities.random(),
          }));
        }

        if (value.waiver) {
          this.waiverText = EditorUtilities.stateFromRawString(
            value.waiver.text
          );
          this.waiverTextSpanish = EditorUtilities.stateFromRawString(
            value.waiver.textEs
          );
        } else {
          this.waiverText = this.defaults.waiverText;
          this.waiverTextSpanish = this.defaults.waiverTextSpanish;
        }

        const pdfRegex = /[0-9A-Z-_]+(\.pdf)$/i;
        const pdf = pdfRegex.exec(value.waiverRef);
        this.waiverFilename = pdf ? pdf[0] : this.defaults.waiverFilename;

        this.infoSessions = (value.infoSessions || []).map((isl) => ({
          ...isl,
          key: StringUtilities.random(),
          description: EditorUtilities.stateFromRawString(isl.description),
          infoSessionDates: isl.infoSessionDates.map((d) => ({
            ...d,
            key: StringUtilities.random(),
            startTime: !d.startTime
              ? null
              : DateUtilities.parseString(
                  `${d.date} ${d.startTime}`,
                  "YYYY-MM-DD HH:mm:00"
                ),
            endTime: !d.endTime
              ? null
              : DateUtilities.parseString(
                  `${d.date} ${d.endTime}`,
                  "YYYY-MM-DD HH:mm:00"
                ),
            date: DateUtilities.parseString(d.date),
          })),
        }));

        this.orientations = (value.orientations || []).map((o) => ({
          ...o,
          key: StringUtilities.random(),
          description: EditorUtilities.stateFromRawString(o.description),
          orientationDates: o.orientationDates.map((d) => ({
            ...d,
            key: StringUtilities.random(),
            startTime: !d.startTime
              ? null
              : DateUtilities.parseString(
                  `${d.date} ${d.startTime}`,
                  "YYYY-MM-DD HH:mm:00"
                ),
            endTime: !d.endTime
              ? null
              : DateUtilities.parseString(
                  `${d.date} ${d.endTime}`,
                  "YYYY-MM-DD HH:mm:00"
                ),
            date: DateUtilities.parseString(d.date),
          })),
        }));

        if (value.virtualInfoSession) {
          let deadlineDate = null;
          let deadlineTime = null;
          if (
            value.virtualInfoSession.deadlineDate &&
            value.virtualInfoSession.deadlineTime
          ) {
            deadlineDate = moment(
              value.virtualInfoSession.deadlineDate,
              "MM/DD/YYYY"
            );
            deadlineTime = moment(
              value.virtualInfoSession.deadlineTime,
              "h:mm a"
            );
          }
          this.virtualInfoSession = {
            ...value.virtualInfoSession,
            deadlineDate: deadlineDate ? deadlineDate.toDate() : null,
            deadlineTime: deadlineTime ? deadlineTime.toDate() : null,
            description: EditorUtilities.stateFromRawString(
              value.virtualInfoSession.description
            ),
            descriptionSpanish: EditorUtilities.stateFromRawString(
              value.virtualInfoSession.descriptionSpanish
            ),
          };
        }
      }),
      clearVolunteerScreensAttributes: action(() => {
        this.waiverText = this.defaults.waiverText;
        this.applicationReceivedTitle = this.defaults.applicationReceivedTitle;
        this.applicationReceivedTitleSpanish =
          this.defaults.applicationReceivedTitleSpanish;
        this.applicationReceivedSubtitle =
          this.defaults.applicationReceivedSubtitle;
        this.applicationReceivedSubtitleSpanish =
          this.defaults.applicationReceivedSubtitleSpanish;
        this.waiverAcceptedTitle = this.defaults.waiverAcceptedTitle;
        this.waiverAcceptedTitleSpanish =
          this.defaults.waiverAcceptedTitleSpanish;
        this.waiverAcceptedSubtitle = this.defaults.waiverAcceptedSubtitle;
        this.waiverAcceptedSubtitleSpanish =
          this.defaults.waiverAcceptedSubtitleSpanish;
        this.waiverText = this.defaults.waiverText;
        this.waiverTextSpanish = this.defaults.waiverTextSpanish;
        this.waiverFilename = this.defaults.waiverFilename;
        this.waiverRef = this.defaults.waiverRef;
        this.waiverPdf = this.defaults.waiverPdf;
        this.uploadPhotoTitle = this.defaults.uploadPhotoTitle;
        this.uploadPhotoTitleSpanish = this.defaults.uploadPhotoTitleSpanish;
        this.uploadPhotoSubtitle = this.defaults.uploadPhotoSubtitle;
        this.uploadPhotoSubtitleSpanish =
          this.defaults.uploadPhotoSubtitleSpanish;
        this.uploadPhotoRejectedText = this.defaults.uploadPhotoRejectedText;
        this.uploadPhotoRejectedTextSpanish =
          this.defaults.uploadPhotoRejectedTextSpanish;
        this.uploadPhotoPendingText = this.defaults.uploadPhotoPendingText;
        this.uploadPhotoPendingTextSpanish =
          this.defaults.uploadPhotoPendingTextSpanish;
        this.informationSessionTitle = this.defaults.informationSessionTitle;
        this.informationSessionTitleSpanish =
          this.defaults.informationSessionTitleSpanish;
        this.informationSessionSubtitle =
          this.defaults.informationSessionSubtitle;
        this.informationSessionSubtitleSpanish =
          this.defaults.informationSessionSubtitleSpanish;
        this.missedInformationSessionTitle =
          this.defaults.missedInformationSessionTitle;
        this.missedInformationSessionTitleSpanish =
          this.defaults.missedInformationSessionTitleSpanish;
        this.failedInformationSessionTitle =
          this.defaults.failedInformationSessionTitle;
        this.failedInformationSessionTitleSpanish =
          this.defaults.failedInformationSessionTitleSpanish;
        this.missedInformationSessionSubtitle =
          this.defaults.missedInformationSessionSubtitle;
        this.missedInformationSessionSubtitleSpanish =
          this.defaults.missedInformationSessionSubtitleSpanish;
        this.backgroundCheckTitle = this.defaults.backgroundCheckTitle;
        this.backgroundCheckTitleSpanish =
          this.defaults.backgroundCheckTitleSpanish;
        this.backgroundCheckSubtitle = this.defaults.backgroundCheckSubtitle;
        this.backgroundCheckSubtitleSpanish =
          this.defaults.backgroundCheckSubtitleSpanish;
        this.preSelectionTitle = this.defaults.preSelectionTitle;
        this.preSelectionTitleSpanish = this.defaults.preSelectionTitleSpanish;
        this.preSelectionSubtitle = this.defaults.preSelectionSubtitle;
        this.preSelectionSubtitleSpanish =
          this.defaults.preSelectionSubtitleSpanish;
        this.postSelectionTitle = this.defaults.postSelectionTitle;
        this.postSelectionTitleSpanish =
          this.defaults.postSelectionTitleSpanish;
        this.postSelectionSubtitle = this.defaults.postSelectionSubtitle;
        this.postSelectionSubtitleSpanish =
          this.defaults.postSelectionSubtitleSpanish;
        this.awaitingSelectionTitle = this.defaults.awaitingSelectionTitle;
        this.awaitingSelectionTitleSpanish =
          this.defaults.awaitingSelectionTitleSpanish;
        this.awaitingSelectionSubtitle =
          this.defaults.awaitingSelectionSubtitle;
        this.awaitingSelectionSubtitleSpanish =
          this.defaults.awaitingSelectionSubtitleSpanish;
        this.openSelectionTitle = this.defaults.openSelectionTitle;
        this.openSelectionTitleSpanish =
          this.defaults.openSelectionTitleSpanish;
        this.openSelectionSubtitle = this.defaults.openSelectionSubtitle;
        this.openSelectionSubtitleSpanish =
          this.defaults.openSelectionSubtitleSpanish;
        this.eventInfoTitle = this.defaults.eventInfoTitle;
        this.eventInfoTitleSpanish = this.defaults.eventInfoTitleSpanish;
        this.eventInfoSubtitle = this.defaults.eventInfoSubtitle;
        this.eventInfoSubtitleSpanish = this.defaults.eventInfoSubtitleSpanish;
        this.faqTitle = this.defaults.faqTitle;
        this.faqTitleSpanish = this.defaults.faqTitleSpanish;
        this.faqs = this.defaults.faqs;
        this.contactEmailAddress = this.defaults.contactEmailAddress;
        this.contactInstructionText = this.defaults.contactInstructionText;
        this.contactInstructionTextSpanish =
          this.defaults.contactInstructionTextSpanish;
        this.infoSessions = this.defaults.infoSessions;
        this.orientations = this.defaults.orientations;
        this.expanders = this.defaults.expanders;
        this.noOpportunitiesAwaitingEnrollmentTitle =
          this.defaults.noOpportunitiesAwaitingEnrollmentTitle;
        this.noOpportunitiesAwaitingEnrollmentTitleSpanish =
          this.defaults.noOpportunitiesAwaitingEnrollmentTitleSpanish;
        this.noOpportunitiesAwaitingEnrollmentSubtitle =
          this.defaults.noOpportunitiesAwaitingEnrollmentSubtitle;
        this.noOpportunitiesAwaitingEnrollmentSubtitleSpanish =
          this.defaults.noOpportunitiesAwaitingEnrollmentSubtitleSpanish;
        this.noOpportunitiesMissedEnrollmentTitle =
          this.defaults.noOpportunitiesMissedEnrollmentTitle;
        this.noOpportunitiesMissedEnrollmentTitleSpanish =
          this.defaults.noOpportunitiesMissedEnrollmentTitleSpanish;
        this.noOpportunitiesMissedEnrollmentSubtitle =
          this.defaults.noOpportunitiesMissedEnrollmentSubtitle;
        this.noOpportunitiesMissedEnrollmentSubtitleSpanish =
          this.defaults.noOpportunitiesMissedEnrollmentSubtitleSpanish;
        this.orientationTitle = this.defaults.orientationTitle;
        this.orientationTitleSpanish = this.defaults.orientationTitleSpanish;
        this.orientationSubtitle = this.defaults.orientationSubtitle;
        this.orientationSubtitleSpanish =
          this.defaults.orientationSubtitleSpanish;
        this.standbySelectionsTitle = this.defaults.standbySelectionsTitle;
        this.standbySelectionsTitleSpanish =
          this.defaults.standbySelectionsTitleSpanish;
        this.standbySelectionsSubtitle =
          this.defaults.standbySelectionsSubtitle;
        this.standbySelectionsSubtitleSpanish =
          this.defaults.standbySelectionsSubtitleSpanish;
        this.openOpportunitiesTitle = this.defaults.openOpportunitiesTitle;
        this.openOpportunitiesTitleSpanish =
          this.defaults.openOpportunitiesTitleSpanish;
        this.openOpportunitiesSubtitle =
          this.defaults.openOpportunitiesSubtitle;
        this.openOpportunitiesSubtitleSpanish =
          this.defaults.openOpportunitiesSubtitleSpanish;
        this.englishScreenContentId = this.defaults.englishScreenContentId;
        this.englishScreenContentCreatedTs =
          this.defaults.spanishScreenContentCreatedTs;
        this.englishScreenContentCreatedUser =
          this.defaults.spanishScreenContentCreatedUser;
        this.spanishScreenContentId = this.defaults.spanishScreenContentId;
        this.spanishScreenContentCreatedTs =
          this.defaults.spanishScreenContentCreatedTs;
        this.spanishScreenContentCreatedUser =
          this.defaults.spanishScreenContentCreatedUser;
      }),
      getVolunteerScreens: action(() => {
        const informationSessionsSubtitleState =
          this.informationSessionSubtitle.getCurrentContent();
        const informationSessionsSubtitleStateSpanish =
          this.informationSessionSubtitleSpanish.getCurrentContent();
        const missedInformationSessionSubtitleState =
          this.missedInformationSessionSubtitle.getCurrentContent();
        const missedInformationSessionSubtitleStateSpanish =
          this.missedInformationSessionSubtitleSpanish.getCurrentContent();
        const failedInformationSessionSubtitleState =
          this.failedInformationSessionSubtitle.getCurrentContent();
        const failedInformationSessionSubtitleStateSpanish =
          this.failedInformationSessionSubtitleSpanish.getCurrentContent();
        const backgroundCheckSubtitleState =
          this.backgroundCheckSubtitle.getCurrentContent();
        const backgroundCheckSubtitleStateSpanish =
          this.backgroundCheckSubtitleSpanish.getCurrentContent();
        const preSelectionSubtitleState =
          this.preSelectionSubtitle.getCurrentContent();
        const preSelectionSubtitleStateSpanish =
          this.preSelectionSubtitleSpanish.getCurrentContent();
        const postSelectionSubtitleState =
          this.postSelectionSubtitle.getCurrentContent();
        const postSelectionSubtitleStateSpanish =
          this.postSelectionSubtitleSpanish.getCurrentContent();
        const awaitingSelectionSubtitleState =
          this.awaitingSelectionSubtitle.getCurrentContent();
        const awaitingSelectionSubtitleStateSpanish =
          this.awaitingSelectionSubtitleSpanish.getCurrentContent();
        const openSelectionSubtitleState =
          this.openSelectionSubtitle.getCurrentContent();
        const openSelectionSubtitleStateSpanish =
          this.openSelectionSubtitleSpanish.getCurrentContent();
        const eventInfoSubtitleState =
          this.eventInfoSubtitle.getCurrentContent();
        const eventInfoSubtitleStateSpanish =
          this.eventInfoSubtitleSpanish.getCurrentContent();
        const contactInstructionTextState =
          this.contactInstructionText.getCurrentContent();
        const contactInstructionTextStateSpanish =
          this.contactInstructionTextSpanish.getCurrentContent();
        const noOpportunitiesAwaitingEnrollmentSubtitleState =
          this.noOpportunitiesAwaitingEnrollmentSubtitle.getCurrentContent();
        const noOpportunitiesAwaitingEnrollmentSubtitleStateSpanish =
          this.noOpportunitiesAwaitingEnrollmentSubtitleSpanish.getCurrentContent();
        const noOpportunitiesMissedEnrollmentSubtitleState =
          this.noOpportunitiesMissedEnrollmentSubtitle.getCurrentContent();
        const noOpportunitiesMissedEnrollmentSubtitleStateSpanish =
          this.noOpportunitiesMissedEnrollmentSubtitleSpanish.getCurrentContent();
        const orientationSubtitleState =
          this.orientationSubtitle.getCurrentContent();
        const orientationSubtitleStateSpanish =
          this.orientationSubtitleSpanish.getCurrentContent();
        const standbySelectionsSubtitleState =
          this.standbySelectionsSubtitle.getCurrentContent();
        const standbySelectionsSubtitleStateSpanish =
          this.standbySelectionsSubtitleSpanish.getCurrentContent();
        const openOpportunitiesSubtitleState =
          this.openOpportunitiesSubtitle.getCurrentContent();
        const openOpportunitiesSubtitleStateSpanish =
          this.openOpportunitiesSubtitleSpanish.getCurrentContent();
        const applicationReceivedSubtitleState =
          this.applicationReceivedSubtitle.getCurrentContent();
        const applicationReceivedSubtitleStateSpanish =
          this.applicationReceivedSubtitleSpanish.getCurrentContent();
        const waiverAcceptedSubtitleState =
          this.waiverAcceptedSubtitle.getCurrentContent();
        const waiverAcceptedSubtitleStateSpanish =
          this.waiverAcceptedSubtitleSpanish.getCurrentContent();
        const uploadPhotoSubtitleState =
          this.uploadPhotoSubtitle.getCurrentContent();
        const uploadPhotoSubtitleStateSpanish =
          this.uploadPhotoSubtitleSpanish.getCurrentContent();
        const uploadPhotoRejectedTextState =
          this.uploadPhotoRejectedText.getCurrentContent();
        const uploadPhotoRejectedTextStateSpanish =
          this.uploadPhotoRejectedTextSpanish.getCurrentContent();
        const uploadPhotoPendingTextState =
          this.uploadPhotoPendingText.getCurrentContent();
        const uploadPhotoPendingTextStateSpanish =
          this.uploadPhotoPendingTextSpanish.getCurrentContent();
        const virtualInfoSessionDescription =
          this.virtualInfoSession.description.getCurrentContent();
        const virtualInfoSessionDescriptionSpanish =
          this.virtualInfoSession.descriptionSpanish.getCurrentContent();          

        return {
          waiverFilename: this.waiverPdf ? this.waiverFilename : undefined,
          waiverRef: this.waiverPdf ? undefined : this.eventInfoStore.waiverRef,
          waiverFile: this.waiverPdf,
          screenContent: [
            // english
            {
              id: this.englishScreenContentId,
              applicationReceivedTitle: this.applicationReceivedTitle,
              applicationReceivedSubtitle:
                applicationReceivedSubtitleState.hasText()
                  ? EditorUtilities.stateToRawString(
                      applicationReceivedSubtitleState
                    )
                  : null,
              waiverAcceptedTitle: this.waiverAcceptedTitle,
              waiverAcceptedSubtitle: waiverAcceptedSubtitleState.hasText()
                ? EditorUtilities.stateToRawString(waiverAcceptedSubtitleState)
                : null,
              uploadPhotoTitle: this.uploadPhotoTitle,
              uploadPhotoSubtitle: uploadPhotoSubtitleState.hasText()
                ? EditorUtilities.stateToRawString(uploadPhotoSubtitleState)
                : null,
              uploadPhotoRejectedText: uploadPhotoRejectedTextState.hasText()
                ? EditorUtilities.stateToRawString(uploadPhotoRejectedTextState)
                : null,
              uploadPhotoPendingText: uploadPhotoPendingTextState.hasText()
                ? EditorUtilities.stateToRawString(uploadPhotoPendingTextState)
                : null,

              informationSessionTitle: this.informationSessionTitle,
              informationSessionSubtitle:
                informationSessionsSubtitleState.hasText()
                  ? EditorUtilities.stateToRawString(
                      informationSessionsSubtitleState
                    )
                  : null,
              missedInformationSessionTitle: this.missedInformationSessionTitle,
              missedInformationSessionSubtitle:
                missedInformationSessionSubtitleState.hasText()
                  ? EditorUtilities.stateToRawString(
                      missedInformationSessionSubtitleState
                    )
                  : null,
              failedInformationSessionTitle: this.failedInformationSessionTitle,
              failedInformationSessionSubtitle:
                failedInformationSessionSubtitleState.hasText()
                  ? EditorUtilities.stateToRawString(
                      failedInformationSessionSubtitleState
                    )
                  : null,
              backgroundCheckTitle: this.backgroundCheckTitle,
              backgroundCheckSubtitle: backgroundCheckSubtitleState.hasText()
                ? EditorUtilities.stateToRawString(backgroundCheckSubtitleState)
                : null,
              preSelectionTitle: this.preSelectionTitle,
              preSelectionSubtitle: preSelectionSubtitleState.hasText()
                ? EditorUtilities.stateToRawString(preSelectionSubtitleState)
                : null,
              postSelectionTitle: this.postSelectionTitle,
              postSelectionSubtitle: postSelectionSubtitleState.hasText()
                ? EditorUtilities.stateToRawString(postSelectionSubtitleState)
                : null,
              awaitingSelectionTitle: this.awaitingSelectionTitle,
              awaitingSelectionSubtitle:
                awaitingSelectionSubtitleState.hasText()
                  ? EditorUtilities.stateToRawString(
                      awaitingSelectionSubtitleState
                    )
                  : null,
              openSelectionTitle: this.openSelectionTitle,
              openSelectionSubtitle: openSelectionSubtitleState.hasText()
                ? EditorUtilities.stateToRawString(openSelectionSubtitleState)
                : null,
              eventInfoTitle: this.eventInfoTitle,
              eventInfoSubtitle: eventInfoSubtitleState.hasText()
                ? EditorUtilities.stateToRawString(eventInfoSubtitleState)
                : null,
              faqTitle: this.faqTitle,
              faqs: this.faqs.map((f) => ({
                ...f,
                key: StringUtilities.random(),
              })),
              contactEmailAddress: this.contactEmailAddress,
              contactInstructionText: contactInstructionTextState.hasText()
                ? EditorUtilities.stateToRawString(contactInstructionTextState)
                : null,
              createdTs: this.englishScreenContentCreatedTs,
              createdUser: this.englishScreenContentCreatedUser,
              locale: "en",
              noOpportunitiesAwaitingEnrollmentTitle:
                this.noOpportunitiesAwaitingEnrollmentTitle,
              noOpportunitiesAwaitingEnrollmentSubtitle:
                noOpportunitiesAwaitingEnrollmentSubtitleState.hasText()
                  ? EditorUtilities.stateToRawString(
                      noOpportunitiesAwaitingEnrollmentSubtitleState
                    )
                  : null,
              noOpportunitiesMissedEnrollmentTitle:
                this.noOpportunitiesMissedEnrollmentTitle,
              noOpportunitiesMissedEnrollmentSubtitle:
                noOpportunitiesMissedEnrollmentSubtitleState.hasText()
                  ? EditorUtilities.stateToRawString(
                      noOpportunitiesMissedEnrollmentSubtitleState
                    )
                  : null,
              orientationTitle: this.orientationTitle,
              orientationSubtitle: orientationSubtitleState.hasText()
                ? EditorUtilities.stateToRawString(orientationSubtitleState)
                : null,
              standbySelectionsTitle: this.standbySelectionsTitle,
              standbySelectionsSubtitle:
                standbySelectionsSubtitleState.hasText()
                  ? EditorUtilities.stateToRawString(
                      standbySelectionsSubtitleState
                    )
                  : null,
              openOpportunitiesTitle: this.openOpportunitiesTitle,
              openOpportunitiesSubtitle:
                openOpportunitiesSubtitleState.hasText()
                  ? EditorUtilities.stateToRawString(
                      openOpportunitiesSubtitleState
                    )
                  : null,
            },
            // spanish
            {
              id: this.spanishScreenContentId,
              applicationReceivedTitle: this.applicationReceivedTitleSpanish,
              applicationReceivedSubtitle:
                applicationReceivedSubtitleStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      applicationReceivedSubtitleStateSpanish
                    )
                  : null,
              waiverAcceptedTitle: this.waiverAcceptedTitleSpanish,
              waiverAcceptedSubtitle:
                waiverAcceptedSubtitleStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      waiverAcceptedSubtitleStateSpanish
                    )
                  : null,
              uploadPhotoTitle: this.uploadPhotoTitleSpanish,
              uploadPhotoSubtitle: uploadPhotoSubtitleStateSpanish.hasText()
                ? EditorUtilities.stateToRawString(
                    uploadPhotoSubtitleStateSpanish
                  )
                : null,
              uploadPhotoRejectedText:
                uploadPhotoRejectedTextStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      uploadPhotoRejectedTextStateSpanish
                    )
                  : null,
              uploadPhotoPendingText:
                uploadPhotoPendingTextStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      uploadPhotoPendingTextStateSpanish
                    )
                  : null,
              informationSessionTitle: this.informationSessionTitleSpanish,
              informationSessionSubtitle:
                informationSessionsSubtitleStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      informationSessionsSubtitleStateSpanish
                    )
                  : null,
              missedInformationSessionTitle:
                this.missedInformationSessionTitleSpanish,
              missedInformationSessionSubtitle:
                missedInformationSessionSubtitleStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      missedInformationSessionSubtitleStateSpanish
                    )
                  : null,
              failedInformationSessionTitle:
                this.failedInformationSessionTitleSpanish,
              failedInformationSessionSubtitle:
                failedInformationSessionSubtitleStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      failedInformationSessionSubtitleStateSpanish
                    )
                  : null,
              backgroundCheckTitle: this.backgroundCheckTitleSpanish,
              backgroundCheckSubtitle:
                backgroundCheckSubtitleStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      backgroundCheckSubtitleStateSpanish
                    )
                  : null,
              preSelectionTitle: this.preSelectionTitleSpanish,
              preSelectionSubtitle: preSelectionSubtitleStateSpanish.hasText()
                ? EditorUtilities.stateToRawString(
                    preSelectionSubtitleStateSpanish
                  )
                : null,
              postSelectionTitle: this.postSelectionTitleSpanish,
              postSelectionSubtitle: postSelectionSubtitleStateSpanish.hasText()
                ? EditorUtilities.stateToRawString(
                    postSelectionSubtitleStateSpanish
                  )
                : null,
              awaitingSelectionTitle: this.awaitingSelectionTitleSpanish,
              awaitingSelectionSubtitle:
                awaitingSelectionSubtitleStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      awaitingSelectionSubtitleStateSpanish
                    )
                  : null,
              openSelectionTitle: this.openSelectionTitleSpanish,
              openSelectionSubtitle: openSelectionSubtitleStateSpanish.hasText()
                ? EditorUtilities.stateToRawString(
                    openSelectionSubtitleStateSpanish
                  )
                : null,
              eventInfoTitle: this.eventInfoTitleSpanish,
              eventInfoSubtitle: eventInfoSubtitleStateSpanish.hasText()
                ? EditorUtilities.stateToRawString(
                    eventInfoSubtitleStateSpanish
                  )
                : null,
              faqTitle: this.faqTitleSpanish,
              faqs: [],
              contactEmailAddress: this.contactEmailAddress,
              contactInstructionText:
                contactInstructionTextStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      contactInstructionTextStateSpanish
                    )
                  : null,
              createdTs: this.spanishScreenContentCreatedTs,
              createdUser: this.spanishScreenContentCreatedUser,
              locale: "es",
              noOpportunitiesAwaitingEnrollmentTitle:
                this.noOpportunitiesAwaitingEnrollmentTitleSpanish,
              noOpportunitiesAwaitingEnrollmentSubtitle:
                noOpportunitiesAwaitingEnrollmentSubtitleStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      noOpportunitiesAwaitingEnrollmentSubtitleStateSpanish
                    )
                  : null,
              noOpportunitiesMissedEnrollmentTitle:
                this.noOpportunitiesMissedEnrollmentTitleSpanish,
              noOpportunitiesMissedEnrollmentSubtitle:
                noOpportunitiesMissedEnrollmentSubtitleStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      noOpportunitiesMissedEnrollmentSubtitleStateSpanish
                    )
                  : null,
              orientationTitle: this.orientationTitleSpanish,
              orientationSubtitle: orientationSubtitleStateSpanish.hasText()
                ? EditorUtilities.stateToRawString(
                    orientationSubtitleStateSpanish
                  )
                : null,
              standbySelectionsTitle: this.standbySelectionsTitleSpanish,
              standbySelectionsSubtitle:
                standbySelectionsSubtitleStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      standbySelectionsSubtitleStateSpanish
                    )
                  : null,
              openOpportunitiesTitle: this.openOpportunitiesTitleSpanish,
              openOpportunitiesSubtitle:
                openOpportunitiesSubtitleStateSpanish.hasText()
                  ? EditorUtilities.stateToRawString(
                      openOpportunitiesSubtitleStateSpanish
                    )
                  : null,
            },
          ],
          infoSessions: this.infoSessions.map((item) => {
            const descriptionState = item.description.getCurrentContent();
            return {
              ...item,
              description: descriptionState.hasText()
                ? EditorUtilities.stateToRawString(descriptionState)
                : null,
              infoSessionDates: item.infoSessionDates.map((d) => ({
                ...d,
                info_session_id: undefined,
                date: new Date(d.date),
                startTime: DateUtilities.timeToString(d.startTime),
                endTime: DateUtilities.timeToString(d.endTime),
              })),
            };
          }),
          virtualInfoSession: {
            ...this.virtualInfoSession,
            description: virtualInfoSessionDescription.hasText()
              ? EditorUtilities.stateToRawString(virtualInfoSessionDescription)
              : null,
              descriptionSpanish: virtualInfoSessionDescription.hasText()
              ? EditorUtilities.stateToRawString(virtualInfoSessionDescriptionSpanish)
              : null,
            deadlineDate:
              this.virtualInfoSession?.deadlineDate?.toLocaleDateString(),
            deadlineTime:
              this.virtualInfoSession?.deadlineTime?.toLocaleTimeString(),
            questions: this.virtualInfoSession?.questions?.length
              ? [...this.virtualInfoSession.questions]
              : [],
          },
          orientations: this.orientations.map((item) => {
            const descriptionState = item.description.getCurrentContent();
            return {
              ...item,
              description: descriptionState.hasText()
                ? EditorUtilities.stateToRawString(descriptionState)
                : null,
              orientationDates: item.orientationDates.map((d) => ({
                ...d,
                orientation_id: undefined,
                date: new Date(d.date),
                startTime: DateUtilities.timeToString(d.startTime),
                endTime: DateUtilities.timeToString(d.endTime),
              })),
            };
          }),
        };
      }),
    });
  }

  get infoSessionOptions() {
    return this.infoSessions.toJS().reduce(
      (p, i) =>
        p.concat(
          i.infoSessionDates.toJS().map((d) => {
            const timeInput =
              typeof d.startTime === "string" ? "HH:mm:00" : null;
            const start = DateUtilities.timeToString(
              d.startTime,
              "h:mm a",
              timeInput
            );
            const end = DateUtilities.timeToString(
              d.endTime,
              "h:mm a",
              timeInput
            );
            const date = DateUtilities.dateToString(d.date, "MM/DD/YYYY");
            return {
              key: d.id,
              name: `${i.name} - ${date} - ${start} - ${end}`,
              value: d,
            };
          })
        ),
      [
        {
          key: 0,
          name: "-- No Session Selected --",
          value: null,
        },
        {
          key: -1,
          name: "Online Info Session",
          value: {
            id: -1,
          },
        },
      ]
    );
  }

  get infoSessionFilterOptions() {
    return [
      {
        key: "selected",
        name: "Selected",
        value: "selected",
      },
      {
        key: "notSelected",
        name: "Not Selected",
        value: "notSelected",
      },
      ...this.infoSessionOptions.filter((d) => d.key !== 0),
    ];
  }

  get allNavBlocksExpanded() {
    return ScreenConstants.NAVIGATION.every(
      (k) => this.expanders[`${k}Expanded`]
    );
  }

  get allRegBlocksExpanded() {
    return ScreenConstants.REGISTRATION.every(
      (k) => this.expanders[`${k}Expanded`]
    );
  }

  get currentLanguages() {
    return [this.currentLanguage];
  }

  get availableLanguages() {
    return this.languageOptions.toJS();
  }

  get isContactEmailValid() {
    return FormConstants.REGEX.EMAIL.test(this.contactEmailAddress);
  }
}

decorate(VolunteerScreensStore, {
  infoSessionOptions: computed,
  infoSessionFilterOptions: computed,
  allNavBlocksExpanded: computed,
  allRegBlocksExpanded: computed,
  isContactEmailValid: computed,
});

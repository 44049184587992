import moment from "moment";
import { extendObservable, autorun, action } from "mobx";
import { FormConstants } from "../constants/FormConstants";
import { RouteConstants } from "../constants/RouteConstants";
import { StoreUtilities } from "../utilities/StoreUtilities";
import { DateUtilities } from "../utilities/DateUtilities";

export default class EventInfoStore {
  constructor(
    authStore,
    routerStore,
    programApi
  ) {
    this.authStore = authStore;
    this.routerStore = routerStore;
    this.programApi = programApi;
    this.fileReader = new FileReader();

    this.defaults = {
      eventTitle: "",
      eventTitleSpanish: "",
      eventDescription: "",
      eventDescriptionSpanish: "",
      eventStartDate: null,
      eventEndDate: null,
      dateIsRange: false,
      eventDates: [],
      eventProgramId: undefined,
      eventProgramName: "",
      eventLogo: null,
      eventSkin: null,
      eventCard: null,
      eventEmailHeader: null,
      eventLogoName: "",
      eventSkinName: "",
      eventCardName: "",
      eventLogoPreview: undefined,
      eventSkinPreview: undefined,
      eventCardPreview: undefined,
      eventEmailFooterPreview: undefined,
      eventEmailHeaderPreview: undefined,
      eventEmailFooterName: "",
      eventEmailFooter: null, 
      volunteerReportFilename: "",
      volunteerReportRef: undefined,
      volunteerReportPdf: null,
      dropdownPrograms: [],
      initialDropdownPrograms: [],
      loadingPrograms: false,
      languageOptions: FormConstants.LANGUAGES,
      language: FormConstants.LANGUAGES[0].name,
      currentLanguage: FormConstants.LANGUAGES[0],
      isLanguageOpen: false,
      waiverRef: undefined,
      eventStatus: ""
    };

    extendObservable(this, {
      ...StoreUtilities.initialize(this),

      setLanguage: action(event => {
        if (typeof event === "string") {
          this.language = event;
        } else {
          this.language = event.target.value;
        }
      }),
      setCurrentLanguage: action(value => {
        this.currentLanguage = value;
        this.setLanguage(this.currentLanguage.name);
      }),
      setEventTitle: action(event => {
        if (this.currentLanguage && this.currentLanguage.key === "es") {
          this.eventTitleSpanish = event.target.value;
        } else {
          this.eventTitle = event.target.value;
        }
      }),
      setEventDescription: action(event => {
        if (this.currentLanguage && this.currentLanguage.key === "es") {
          this.eventDescriptionSpanish = event.target.value;
        } else {
          this.eventDescription = event.target.value;
        }
      }),
      setEventStartDate: action(date => {
        this.eventStartDate = date;
        if (!this.dateIsRange) {
          this.eventEndDate = date;
        }
        this.calculateEventDates();
      }),
      setEventStartDateFromEvent: action((date, event) => {
        if (!(date instanceof Date)) {
          // onSelect from onChange passes in date and an event
          event = date;
        }

        if (!event) {
          this.eventStartDate = null;
        } else if (event.type === "click") {
          if (!this.dateIsRange) {
            this.eventEndDate = date;
          }
          this.eventStartDate = date;
        } else {
          const d = moment(event.currentTarget.value, "MM/DD/YYYY", true);
          if (d.isValid()) {
            this.eventStartDate = d.toDate();
          } else {
            this.eventStartDate = null;
          }
        }

        this.calculateEventDates();
      }),
      setEventEndDate: action(date => {
        this.eventEndDate = date;
        this.calculateEventDates();
      }),
      setEventEndDateFromEvent: action((date, event) => {
        // onSelect from onChange passes in date and an event
        if (!(date instanceof Date)) {
          event = date;
        }

        if (!event) {
          this.eventEndDate = null;
        } else if (event.type === "click") {
          this.eventEndDate = date;
        } else {
          const d = moment(event.currentTarget.value, "MM/DD/YYYY", true);
          this.eventEndDate = d.isValid() ? d.toDate() : null;
        }

        this.calculateEventDates();
      }),
      setDateIsRange: action(() => {
        this.dateIsRange = !this.dateIsRange;
        this.calculateEventDates();
      }),
      toggleEventStatus: action(() => {
        this.eventStatus = this.eventStatus === "ACTIVE" ?  "INACTIVE" : "ACTIVE";
      }),
      setEventProgramId: action(item => {
        this.eventProgramId = item.value;
      }),
      setEventProgramName: action(event => {
        if (typeof event === "string") {
          this.eventProgramName = event;
        } else {
          this.eventProgramName = event.target.value;

          if (this.eventProgramName) {
            this.loadingPrograms = true;
            this.searchPrograms(this.eventProgramName);
          } else {
            this.dropdownPrograms = this.initialDropdownPrograms;
          }
        }
      }),
      setEventLogo: action(files => {
        this.eventLogoName = files[0].name;
        this.eventLogoPreview = URL.createObjectURL(files[0]);
        this.fileReader.onloadend = () => {
          this.eventLogo = this.fileReader.result;
        };
        this.fileReader.readAsDataURL(files[0]);
      }),
      setEventSkin: action(files => {
        this.eventSkinName = files[0].name;
        this.eventSkinPreview = URL.createObjectURL(files[0]);
        this.fileReader.onloadend = () => {
          this.eventSkin = this.fileReader.result;
        };
        this.fileReader.readAsDataURL(files[0]);
      }),
      setEventEmailFooter: action(files => {
        this.eventEmailFooterName = files[0].name;
        this.eventEmailFooterPreview = URL.createObjectURL(files[0]);
        this.fileReader.onloadend = () => {
          this.eventEmailFooter = this.fileReader.result;
        };
        this.fileReader.readAsDataURL(files[0]);
      }),
      setEventEmailHeader: action(files => {
        this.eventEmailHeaderName = files[0].name;
        this.eventEmailHeaderPreview = URL.createObjectURL(files[0]);
        this.fileReader.onloadend = () => {
          this.eventEmailHeader = this.fileReader.result;
        };
        this.fileReader.readAsDataURL(files[0]);
      }),
      setEventCard: action(files => {
        this.eventCardName = files[0].name;
        this.eventCardPreview = URL.createObjectURL(files[0]);
        this.fileReader.onloadend = () => {
          this.eventCard = this.fileReader.result;
        };
        this.fileReader.readAsDataURL(files[0]);
      }),
      setVolunteerReport: action(files => {
        this.volunteerReportFilename = files[0].name;
        this.volunteerReportRef = URL.createObjectURL(files[0]);
        this.fileReader.onloadend = () => {
          this.volunteerReportPdf = this.fileReader.result;
        };
        this.fileReader.readAsDataURL(files[0]);
      }),
      clearImagePreviews: action(() => {
        // avoids memory leaks for image previews
        URL.revokeObjectURL(this.eventLogoPreview);
        URL.revokeObjectURL(this.eventSkinPreview);
        URL.revokeObjectURL(this.eventCardPreview);
        URL.revokeObjectURL(this.eventEmailFooterPreview);
        this.eventLogoPreview = this.defaults.eventLogoPreview;
        this.eventSkinPreview = this.defaults.eventSkinPreview;
        this.eventCardPreview = this.defaults.eventCardPreview;
        this.eventEmailFooterPreview = this.defaults.eventEmailFooterPreview;
        this.eventEmailHeaderPreview = this.defaults.eventEmailHeaderPreview;
      }),
      setEventInfoAttributes: action(value => {
        this.clearImagePreviews();
        this.eventEmailHeaderPreview = value.headerRef;
        this.eventEmailFooterPreview = value.footerRef;
        this.currentLanguage = this.defaults.currentLanguage;
        this.language = this.defaults.language;
        this.eventTitle = value.title;
        this.eventTitleSpanish = value.titleSpanish || "";
        this.eventDescription = value.description;
        this.eventDescriptionSpanish = value.descriptionSpanish || "";
        this.eventStartDate = value.startDate
          ? moment(value.startDate, "YYYY-MM-DD", true).toDate()
          : null;
        this.eventEndDate = value.endDate
          ? moment(value.endDate, "YYYY-MM-DD", true).toDate()
          : null;
        this.dateIsRange = Boolean(this.eventStartDate && this.eventEndDate);

        let eventDates = value.eventDates || [];
        if (eventDates.length === 0) {
          this.calculateEventDates();
        } else {
          this.eventDates = eventDates;
        }

        this.eventProgramId = value.programId;
        this.eventProgramName = value.programTitle;

        const regex = /[0-9A-Z-_]+(\.jpg|\.jpeg|\.png)$/i;
        const logo = regex.exec(value.logoRef);
        const skin = regex.exec(value.skinRef);
        const card = regex.exec(value.cardRef);
        const footer = regex.exec(value.footerRef);
        const header = regex.exec(value.headerRef);

        this.eventLogoPreview = value.logoRef;
        this.eventLogoName = logo ? logo[0] : this.defaults.eventLogoName;
        this.eventSkinPreview = value.skinRef;
        this.eventSkinName = skin ? skin[0] : this.defaults.eventSkinName;
        this.eventCardPreview = value.cardRef;
        this.eventCardName = card ? card[0] : this.defaults.eventCardName;
        this.volunteerReportRef = value.volunteerReportRef;
        this.eventEmailFooterPreview = value.footerRef;
        this.eventEmailFooterName = footer ? footer[0] : this.defaults.eventEmailFooterName;
        this.eventEmailHeaderPreview = value.headerRef;
        this.eventEmailHeaderName = header ? header[0] : this.defaults.eventEmailHeaderName;
        

        const htmlRegex =  /[0-9A-Z-_]+(\.html)$/i;
        const reportHtml = htmlRegex.exec(value.volunteerReportRef);
        this.volunteerReportFilename = reportHtml ?
          reportHtml[0] :
          this.defaults.volunteerReportFilename;
        this.waiverRef = value.waiverRef;
        this.eventStatus = value.status;
      }),
      clearEventInfoAttributes: action(() => {
        this.clearImagePreviews();
        this.language = this.defaults.language;
        this.currentLanguage = this.defaults.currentLanguage;
        this.eventTitle = this.defaults.eventTitle;
        this.eventTitleSpanish = this.defaults.eventTitleSpanish;
        this.eventDescription = this.defaults.eventDescription;
        this.eventDescriptionSpanish = this.defaults.eventDescriptionSpanish;
        this.eventStartDate = this.defaults.eventStartDate;
        this.eventEndDate = this.defaults.eventEndDate;
        this.dateIsRange = this.defaults.dateIsRange;
        this.eventProgramId = this.defaults.eventProgramId;
        this.eventProgramName = this.defaults.eventProgramName;
        this.eventLogo = this.defaults.eventLogo;
        this.eventSkin = this.defaults.eventSkin;
        this.eventCard = this.defaults.eventCard;
        this.eventFooter = this.defaults.eventFooter;
        this.eventHeader = this.defaults.eventHeader;
        this.eventLogoName = this.defaults.eventLogoName;
        this.eventSkinName = this.defaults.eventSkinName;
        this.eventCardName = this.defaults.eventCardName;
        this.waiverRef = this.defaults.waiverRef;
      }),
      getEventInfo: action(() => {
        return {
          title: this.eventTitle,
          titleSpanish: this.eventTitleSpanish,
          description: this.eventDescription,
          descriptionSpanish: this.eventDescriptionSpanish,
          startDate: this.eventStartDate,
          endDate: this.dateIsRange ? this.eventEndDate : null,
          eventDates: this.eventDates,
          programId: this.eventProgramId,
          logoImage: this.eventLogo,
          skinImage: this.eventSkin,
          cardImage: this.eventCard,
          headerImage: this.eventEmailHeader,
          footerImage: this.eventEmailFooter,
          logoRef: this.eventLogo ? undefined : this.eventLogoPreview,
          skinRef: this.eventSkin ? undefined : this.eventSkinPreview,
          cardRef: this.eventCard ? undefined : this.eventCardPreview,
          footerRef: this.eventEmailFooter ? undefined : this.eventEmailFooterPreview,
          headerRef: this.eventEmailHeader ? undefined : this.eventEmailHeaderPreview,
          logoFilename: this.eventLogo ? this.eventLogoName : undefined,
          skinFilename: this.eventSkin ? this.eventSkinName : undefined,
          cardFilename: this.eventCard ? this.eventCardName : undefined,
          headerFilename: this.eventEmailHeader ? this.eventEmailHeaderName : undefined,
          footerFilename: this.eventEmailFooter ? this.eventEmailFooterName : undefined, 
          volunteerReportFilename: this.volunteerReportFilename ?
            this.volunteerReportFilename : undefined,
          volunteerReportRef: this.volunteerReportPdf ? undefined : this.volunteerReportRef,
          volunteerReportFile: this.volunteerReportPdf,
          waiverRef: this.waiverRef,
          status: this.eventStatus,
        };
      }),
      calculateEventDates: action(() => {
        const eventDates = [];
        if (this.dateIsRange) {
          let date = this.eventStartDate;
          while (date <= this.eventEndDate) {
            let eventDate;
            this.eventDates.map(d => {
              if (d.date === DateUtilities.dateToString(date, "YYYY-MM-DD")) {
                eventDate = d;
              }
            });

            if (!eventDate) {
              eventDate = {
                eventId: this.eventStore.eventId,
                date: DateUtilities.dateToString(date, "YYYY-MM-DD")
              };
            }

            eventDates.push(eventDate);
            date = moment(date, "YYYY-MM-DD", true).add(1, "d").toDate();
          }
        } else {
          const matchingED = this.eventDates.find(ed =>
            ed.date === DateUtilities.dateToString(this.eventStartDate, "YYYY-MM-DD")
          );
          if (this.eventDates.length > 0 && matchingED) {
            eventDates.push({
              ...matchingED
            });
          } else {
            eventDates.push({
              date: this.eventStartDate,
              eventId: this.eventStore.eventId
            });
          }
        }

        if (this.eventDates.length > 0) {

          // Check the existing EventDates for any dates that are no longer selected and remove them
          let modEventDates = [];
          this.eventDates.map(ed => {
            if (eventDates.find(d => {
              return ed.date === d.date;
            })) {
              modEventDates.push(ed);
            }
          });
          this.eventDates = modEventDates;

          // Check the new dates and see if any are missing from the EventDates and add them
          eventDates.map(d => {
            if (!this.eventDates.find(ed => ed.date === d.date)) {
              this.eventDates.push(d);
            }
          });
        } else {
          this.eventDates = eventDates;
        }
      })
    });

    // load programs for dropdown
    autorun(() => {
      if (
        this.routerStore.isActive(RouteConstants.NEW_EVENT) &&
        this.authStore.currentUserId &&
        this.authStore.isAdmin
      ) {
        this.searchPrograms();
      }
    });
  }

  searchPrograms = query => {
    // TODO: make this function actually use query and limit
    this.programApi.loadPrograms().then(data => {
      this.loadingPrograms = false;
      this.dropdownPrograms = data ? data.programs : [];

      if (!query) {
        this.initialDropdownPrograms = this.dropdownPrograms;
        if (this.authStore.isProgramAdmin && this.dropdownPrograms.length === 1) {
          // initialize the default program. useful for program admins
          this.eventProgramId = data.programs[0].id;
          this.eventProgramName = data.programs[0].title;
        }
      } else {
        // TODO: remove this block once back end search implemented
        this.dropdownPrograms = this.dropdownPrograms.filter(
          item => {
            const regex = new RegExp(`.*${this.eventProgramName}.*`, "i");
            return regex.test(item.title);
          }
        );
      }
    }).catch(() => {
      this.loadingPrograms = false;
    });
  }

  get programSearchResults() {
    if (this.dropdownPrograms && this.dropdownPrograms.length) {
      return this.dropdownPrograms.map(program => ({
        key: program.id,
        name: program.title,
        value: program.id
      }));
    }
    return [];
  }

  get eventDateRange() {
    if (!this.eventStartDate && !this.eventEndDate) {
      return [];
    } else if (!this.eventEndDate) {
      return [moment(this.eventStartDate).format("YYYY-MM-DD")];
    }

    const start = moment(this.eventStartDate);
    const end = moment(this.eventEndDate);

    return [...Array(end.diff(start, "days") + 1).keys()].map(i =>
      moment(start).add(i, "days").format("YYYY-MM-DD")
    );
  }

  get currentLanguages() {
    return [this.currentLanguage];
  }

  get availableLanguages() {
    return this.languageOptions.toJS();
  }
}

import { syncHistoryWithStore } from "mobx-react-router";
import { createBrowserHistory } from "history";
import RouterStore from "./RouterStore";
import AuthStore from "./AuthStore";
import AuthApi from "../httpClients/AuthApi";
import ProgramStore from "./ProgramStore";
import EventStore from "./EventStore";
import EventInfoStore from "./EventInfoStore";
import VolunteerScreensStore from "./VolunteerScreensStore";
import ResetStore from "./ResetStore";
import LanguageStore from "./LanguageStore";
import NotificationStore from "./NotificationStore";
import ProgramApi from "../httpClients/ProgramApi";
import ImportApi from "../httpClients/ImportApi";
import i18n from "../translations/i18n";
import GridStore from "./GridStore";
import OktaStore from "./OktaStore";
import VolunteerStore from "./VolunteerStore";
import OpportunityStore from "./OpportunityStore";
import BlockingStore from "./BlockingStore";
import FlowStore from "./FlowStore";
import InfoStore from "./InfoStore";
import EmailsStore from "./EmailsStore";
import EmailApi from "../httpClients/EmailApi";
import SelectionStore from "./SelectionStore";
import SelectionApi from "../httpClients/SelectionApi";
import AccountStore from "./AccountStore";
import TrackerStore from "./TrackerStore";
import ReportsStore from "./ReportsStore";

export default class RootStore {
  constructor() {
    this.registerApis();
    this.registerStores();
  }

  registerApis = () => {
    this.authApi = new AuthApi();
    this.programApi = new ProgramApi();
    this.importApi = new ImportApi();
    this.emailApi = new EmailApi();
    this.selectionApi = new SelectionApi();
    this.confirmedApi = new SelectionApi();
  };

  registerStores = () => {
    const getUserConfirmation = () => {};

    this.routerStore = new RouterStore();
    this.history = syncHistoryWithStore(
      createBrowserHistory({ getUserConfirmation }),
      this.routerStore
    );

    this.gridStore = new GridStore();
    this.languageStore = new LanguageStore();
    this.notificationStore = new NotificationStore();
    this.blockingStore = new BlockingStore(this.history);
    this.resetStore = new ResetStore(
      this.authApi,
      this.notificationStore,
      i18n
    );
    this.authStore = new AuthStore(
      this.routerStore,
      this.authApi,
      this.notificationStore,
      this.history,
      i18n,
      this.resetStore
    );
    this.eventInfoStore = new EventInfoStore(
      this.authStore,
      this.routerStore,
      this.programApi
    );
    this.volunteerScreensStore = new VolunteerScreensStore(this.eventInfoStore);
    this.infoStore = new InfoStore(
      this.authApi,
      this.programApi,
      this.authStore,
      this.emailApi
    );
    this.programStore = new ProgramStore(
      this.authStore,
      this.programApi,
      this.authApi,
      this.routerStore,
      this.gridStore,
      this.notificationStore,
      this.blockingStore,
      this.history,
      i18n
    );
    this.eventStore = new EventStore(
      this.eventInfoStore,
      this.volunteerStore,
      this.importApi,
      this.volunteerScreensStore,
      this.authStore,
      this.programApi,
      this.emailApi,
      this.routerStore,
      this.gridStore,
      this.notificationStore,
      this.blockingStore,
      this.history,
      i18n
    );
    this.emailsStore = new EmailsStore(
      this.routerStore,
      this.gridStore,
      this.authStore,
      this.eventStore,
      this.volunteerScreensStore,
      this.notificationStore,
      this.blockingStore,
      this.programApi,
      this.emailApi
    );
    this.oktaStore = new OktaStore(
      this.routerStore,
      this.authStore,
      this.notificationStore,
      this.history
    );
    this.volunteerStore = new VolunteerStore(
      this.programApi,
      this.importApi,
      this.authApi,
      this.emailApi,
      this.routerStore,
      this.authStore,
      this.gridStore,
      this.notificationStore,
      this.eventStore,
      this.eventInfoStore,
      this.volunteerScreensStore,
      this.blockingStore,
      this.history,
      i18n
    );
    this.opportunityStore = new OpportunityStore(
      this.programApi,
      this.authApi,
      this.routerStore,
      this.authStore,
      this.eventStore,
      this.gridStore,
      this.notificationStore,
      this.blockingStore,
      i18n
    );
    this.flowStore = new FlowStore(
      this.programApi,
      this.authStore,
      this.eventStore,
      this.routerStore,
      this.notificationStore,
      this.volunteerStore,
      this.volunteerScreensStore,
      this.opportunityStore,
      this.confirmedApi,
      i18n
    );
    this.selectionStore = new SelectionStore(
      this.authStore,
      this.flowStore,
      this.eventStore,
      this.routerStore,
      this.selectionApi,
      this.programApi
    );
    this.trackerStore = new TrackerStore(
      this.authStore,
      this.eventStore,
      this.volunteerStore,
      this.opportunityStore,
      this.routerStore,
      this.programApi,
      new SelectionApi()
    );
    this.accountStore = new AccountStore(
      this.programApi,
      this.authApi,
      this.authStore,
      this.eventStore,
      this.flowStore,
      this.routerStore,
      this.notificationStore,
      this.resetStore,
      i18n
    );
    this.reportsStore = new ReportsStore(
      this.eventStore,
      this.volunteerStore,
      this.programApi,
      this.opportunityStore,
      this.notificationStore
    );
    this.eventStore.volunteerStore = this.volunteerStore;
    this.eventStore.opportunityStore = this.opportunityStore;
    this.flowStore.selectionStore = this.selectionStore;
    this.eventInfoStore.eventStore = this.eventStore;
  };
}

import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import decorator from "../components/elements/form/TextEditorDecorators";

export class EditorUtilities {
  static stateFromRawString = data => {
    let rawData;
    try {
      rawData = !data ? null : convertFromRaw(JSON.parse(data));
    } catch (e) {
      rawData = null;
    }

    const state = !data || !rawData
      ? EditorState.createEmpty(decorator)
      : EditorState.createWithContent(rawData, decorator);
    return state;
  };

  static stateToRawString = data => JSON.stringify(convertToRaw(data));
}

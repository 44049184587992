import { extendObservable, autorun, action } from "mobx";
import { StoreUtilities } from "../utilities/StoreUtilities";
import { RouteConstants } from "../constants/RouteConstants";
import decorator from "../components/elements/form/TextEditorDecorators";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { EditorUtilities } from "../utilities/EditorUtilities";
import { NotificationConstants } from "../constants/NotificationConstants";

export default class EmailsStore {
  constructor(
    routerStore,
    gridStore,
    authStore,
    eventStore,
    volunteerScreenStore,
    notificationStore,
    blockingStore,
    programApi,
    emailApi,
    eventInfoStore
  ) {
    this.routerStore = routerStore;
    this.gridStore = gridStore;
    this.authStore = authStore;
    this.eventStore = eventStore;
    this.notificationStore = notificationStore;
    this.blockingStore = blockingStore;
    this.programApi = programApi;
    this.emailApi = emailApi;
    this.volunteerScreenStore=volunteerScreenStore;
    this.eventInfoStore = eventInfoStore;

    this.defaults = {
      emails: [],
      sortFilters: {
        key: "created",
        direction: "ASC",
        initial: []
      },
      email: undefined,
      eventId: "",
      emailId: "",
      emailName: "",
      emailSubject: "",
      editorBody: EditorState.createEmpty(decorator),
      editorBodyString: "",
      htmlBody: "",
      // lastSent: "",
      createdTs: "",
      createdUser: "",
      searchEmails: "",
      emailFormDirty: false,
      emailsPendingDelete: [],
      isWarnOnDeleteOpen: false,
      isSendingEmail: false,
      emailFooterUrl: "",
      emailHeaderUrl: ""
    };

    extendObservable(this, {
      ...StoreUtilities.initialize(this),

      clearEmailAttributes: action(() => {
        this.email = this.defaults.email;
        this.eventId = this.defaults.eventId;
        this.emailId = this.defaults.emailId;
        this.emailName = this.defaults.emailName;
        this.emailSubject = this.defaults.emailSubject;
        this.editorBody = this.defaults.editorBody;
        this.editorBodyString = this.defaults.editorBodyString;
        this.htmlBody = this.defaults.htmlBody;
        this.createdTs = this.defaults.createdTs;
        this.createdUser = this.defaults.createdUser;
        this.searchEmails = this.defaults.searchEmails;
        this.sortFilters = this.defaults.sortFilters;
        this.emailFooterUrl = this.defaults.emailFooterUrl;
        this.emailHeaderUrl = this.defaults.emailHeaderUrl;
      }),

      setEmails: action(value => {
        this.emails = value;
      }),
      setEmailsSortDirection: action((col, direction) => {
        this.emailsSortFilters.key = col;
        this.emailsSortFilters.direction = direction;
      }),
      sortEmailsByColumn: action((col, override = "") => {
        const colIsKey = col === this.sortFilters.key;
        const direction = colIsKey ? this.sortFilters.direction : "NONE";
        this.sortFilters.key = col;
        this.sortFilters.direction =
          override || this.gridStore.changeDirection(direction);

        if (this.sortFilters.direction === "NONE") {
          this.emails = this.sortFilters.initial;
        } else {
          this.emails = this.gridStore.sortWithFilters(
            this.sortFilters,
            this.emails
          );
        }
      }),
      setEmailAttributes: action(value => {
        this.email = value;
        this.eventId = value.eventId;
        this.emailId = value.id;
        this.emailName = value.name;
        this.emailSubject = value.subject;
        this.editorBody = EditorUtilities.stateFromRawString(value.editorBody);
        this.htmlBody = value.htmlBody;
        this.createdTs = value.createdTs;
        this.createdUser = value.createdUser;
        // this.lastSent = value.lastSent;
      }),
      setSearchEmails: action(event => {
        this.searchEmails = event.target.value;

        if (!this.searchEmails) {
          this.emails = this.sortFilters.initial;
        } else {
          this.emails = this.sortFilters.initial.filter(email => {
            const regex = new RegExp(`.*${this.searchEmails}.*`, "i");
            return regex.test(email.name);
          });
        }
      }),
      showEmailDetails: action(() => {
        this.clearEmailAttributes();
        this.routerStore.pushWithQueryParams(RouteConstants.NEW_EMAIL, {
          eventId: this.eventStore.eventId
        });
      }),
      setEmailFormDirty: action(value => {
        this.emailFormDirty = value;
      }),
      setEmailName: action(event => {
        this.emailName = event.target.value;
      }),
      setEmailSubject: action(event => {
        this.emailSubject = event.target.value;
      }),
      setEmailBody: action(editorState => {
        this.editorBody = editorState;
      }),
      saveEmail: action((eventId, blockingCallback) => {
        this.createHTMLBody();

        const email = {
          eventId: this.eventId,
          id: this.emailId,
          name: this.emailName,
          subject: this.emailSubject,
          editorBody: this.editorBodyString,
          htmlBody: this.htmlBody,
          // lastSent: this.lastSent,
          createdTs: this.createdTs ? this.createdTs : "",
          createdUser: this.createdUser ? this.createdUser : ""
        };

        this.programApi
          .saveEmail(email)
          .then(() => {
            if (blockingCallback) {
              blockingCallback();
            } else {
              this.blockingStore.clearAttributes();
              this.routerStore.history.goBack();
            }

            this.notificationStore.setMessage("Email saved");

            const wasUpdate = !!this.emailId;
            if (!wasUpdate) {
              this.email = email;
              this.emails.push(this.email);
            } else {
              this.emails = this.emails.map(e => {
                if (e.id !== email.id) {
                  return e;
                } else {
                  let success = Object.assign({}, e, email);
                  return success;
                }
              });
            }

            this.sortFilters.initial = this.emails;
            this.clearEmailAttributes();
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.error(e);
            this.setValidationErrorMessages(e.message.split("|"));
          });
      }),
      showDeleteWarning: action(() => {
        this.isWarnOnDeleteOpen = true;
      }),
      hideDeleteWarning: action(() => {
        this.isWarnOnDeleteOpen = false;
      }),
      deleteEmail: action((e, blockingCallback) => {
        const promise = this.programApi.deleteEmail(this.email.id);

        promise.then(() => {
          if (blockingCallback) {
            blockingCallback();
          } else {
            this.blockingStore.clearAttributes();
          }

          this.notificationStore.setMessage("Email deleted");
          this.clearEmailAttributes();
          this.hideDeleteWarning();
        });
      }),
      sendContactUsEmail: action(() => {
        const payload = {
          toAddress: this.volunteerScreenStore.contactEmailAddress,
          fromAddress: this.authStore.userData.email,
          subject: this.eventStore.contactSubject,
          message: this.eventStore.contactUsMessage
        };

        this.isSendingEmail = true;
        this.emailApi
          .sendContactUsEmail(payload)
          .then(() => {
            this.isSendingEmail = false;
            this.eventStore.toggleContactModal();
            this.notificationStore.setMessage("Message sent");
          })
          .catch(() => {
            this.isSendingEmail = false;
            this.notificationStore.setMessage(
              "An error occurred. Try again later",
              NotificationConstants.ERROR
            );
          });
      })
    });

    autorun(() => {
      const { isActive, getPathParams, getQueryParams } = this.routerStore;
      const params = getQueryParams(RouteConstants.EDIT_EVENT).eventId
        ? getQueryParams(RouteConstants.EDIT_EVENT)
        : getPathParams(RouteConstants.EDIT_EVENT);
      this.eventId = params.eventId;

      if (
        (isActive(RouteConstants.EDIT_EVENT) || isActive(RouteConstants.EDIT_VOLUNTEER)) &&
        this.authStore.currentUserId &&
        this.authStore.isAdmin &&
        this.eventId
      ) {
        this.emailFormDirty = false;
        this.loadEmailsForEvent(this.eventId);
      }
    });
  }

  loadEmailsForEvent = eventId => {
    this.programApi.loadEmailsForEvent(eventId).then(data => {
      this.setEmails(data ? data : []);
      this.sortFilters.initial = this.emails;
    });
  };

  updateEmailBodyStyles = styles => (this.emailBodyStyles = styles);

  createHTMLBody = () => {
    const options = {
      inlineStyles: {}
    };

    for (let key in this.emailBodyStyles) {
      options.inlineStyles[key] = {
        style: this.emailBodyStyles[key]
      };
    }

    const editorState = this.editorBody.getCurrentContent();
    this.editorBodyString = editorState.hasText()
      ? EditorUtilities.stateToRawString(editorState)
      : null;
    this.htmlBody = stateToHTML(this.editorBody.getCurrentContent(), options);
    this.emailHeaderUrl = this.eventStore.eventInfoStore.eventEmailHeaderPreview;
    this.emailFooterUrl = this.eventStore.eventInfoStore.eventEmailFooterPreview;
  };

  previewEmail = () => {
    this.createHTMLBody();
    this.emailApi
      .previewEmail({
        htmlBody: this.htmlBody,
        emailHeaderUrl: this.emailHeaderUrl,
        emailFooterUrl: this.emailFooterUrl
      })
      .then(result => {
        const previewWindow = window.open();
        previewWindow.document.write(result.emailPreview);
      });
  };

  get emailsRows() {
    return this.emails.toJS();
  }

  get isEmailSaveDisabled() {
    return (
      !this.emailFormDirty ||
      !this.emailName ||
      !this.emailSubject ||
      !this.editorBody.getCurrentContent().hasText()
    );
  }

  get emailTemplatesForPhotoRejection() {
    return this.emails.map(e => {
      return {
        value: e.id,
        key: e.id,
        name: e.name
      };
    });
  }
}

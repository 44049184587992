export const RouteConstants = {
  BASE: "/",
  NOT_FOUND: "/404",
  EVENT_NAME: "/event/:eventName",
  EVENT_INFO: "/event/:eventName/info",
  EVENT_OPPORTUNITIES: "/event/:eventName/opportunities",
  LOGIN: "/login",
  PROGRAMS: "/programs",
  NEW_PROGRAM: "/programs/new",
  EDIT_PROGRAM: "/programs/:programId/edit",
  EVENTS: "/events",
  NEW_EVENT: "/events/new",
  EDIT_EVENT: "/events/:eventId/edit",
  TRACKER: "/events/:eventId/tracker",
  OKTA: "/okta",
  VOLUNTEERS: "/volunteers",
  NEW_VOLUNTEER: "/volunteers/new",
  EDIT_VOLUNTEER: "/volunteers/:volunteerId/edit",
  OPPORTUNITIES: "/opportunities",
  NEW_OPPORTUNITY: "/opportunities/new",
  EDIT_OPPORTUNITY: "/opportunities/:opportunityId/edit",
  EMAILS: "/emails",
  NEW_EMAIL: "/emails/new",
  EDIT_EMAIL: "/emails/:emailId/edit",
  ACCOUNT: "/account",
  LOGIN_CALLBACK: "/login/callback",
};

import { FormConstants } from "../constants/FormConstants";

export class BrowserUtilities {
  static isMobileBrowser = () => {
    return FormConstants.REGEX.MOBILE_BROWSER.test(
      navigator.userAgent || navigator.vendor || window.opera
    );
  };

  static scrollTop = () => {
    const elem = document.getElementById("vms-body");
    if (elem) {
      elem.scrollTop = 0;
    }
  };
}

import { decorate, extendObservable, action, autorun, computed } from "mobx";
import { AuthConstants } from "../constants/AuthConstants";
import { RouteConstants } from "../constants/RouteConstants";
import { ExceptionConstants } from "../constants/ExceptionConstants";
import { NotificationConstants } from "../constants/NotificationConstants";
import { StoreUtilities } from "../utilities/StoreUtilities";

export default class AuthStore {
  constructor(
    routerStore,
    authApi,
    notificationStore,
    history,
    i18n,
    resetStore
  ) {
    this.routerStore = routerStore;
    this.notificationStore = notificationStore;
    this.authApi = authApi;
    this.i18n = i18n;
    this.history = history;
    this.resetStore = resetStore;

    this.defaults = {
      mobileNavOpen: false,
      idToken: "",
      accessToken: "",
      refreshToken: "",
      loginError: false,
      resetPasswordError: false,
      isLoggingIn: false,
      isResettingPassword: false,
      refreshInProgress: false,
      confirmPassword: null,
      volunteerResetPassword: false,
      confirmEmail: null,
      userData: {},
      pendingRefreshes: [],
      authClient: {}
    };

    extendObservable(this, {
      ...StoreUtilities.initialize(this),

      // functions
      checkLocalStorage: action(() => {
        this.userData = localStorage.getItem(AuthConstants.KEYS.USER_DATA)
          ? JSON.parse(localStorage.getItem(AuthConstants.KEYS.USER_DATA))
          : this.defaults.userData;
        this.accessToken = localStorage.getItem(
          AuthConstants.KEYS.ACCESS_TOKEN
        );
        this.refreshToken = localStorage.getItem(
          AuthConstants.KEYS.REFRESH_TOKEN
        );
        this.idToken = localStorage.getItem(AuthConstants.KEYS.ID_TOKEN);
      }),
      setLoggedIn: action(value => {
        this.idToken = value.idToken;
        this.accessToken = value.accessToken;
        this.refreshToken = value.refreshToken;
      }),
      setLocalStorage: action(() => {
        if (this.userData) {
          localStorage.setItem(
            AuthConstants.KEYS.USER_DATA,
            JSON.stringify(this.userData)
          );
        }

        if (this.accessToken) {
          localStorage.setItem(
            AuthConstants.KEYS.ACCESS_TOKEN,
            this.accessToken
          );
        }
        if (this.refreshToken) {
          localStorage.setItem(
            AuthConstants.KEYS.REFRESH_TOKEN,
            this.refreshToken
          );
        }
        if (this.idToken) {
          localStorage.setItem(AuthConstants.KEYS.ID_TOKEN, this.idToken);
        }
      }),
      setUserData: action(data => {
        // NOTE: Setting localStorage here because login/reset password flow and Protected.js
        // don't work correctly if set userData without having userData in localStorage
        localStorage.setItem(
          AuthConstants.KEYS.USER_DATA,
          JSON.stringify(data)
        );
        this.userData = data;
      }),
      getUserInfo: action(() => {
        this.authApi
          .getUserInfo()
          .then(data => {
            this.isLoggingIn = false;
            if (data) {
              this.setLocalStorage();
              this.setUserData(data);

              this.isResettingPassword = false;
              this.confirmPassword = null;
              this.confirmEmail = null;
              // this.routerStore.history.push(
              //   this.isAdmin ? RouteConstants.EVENTS : RouteConstants.BASE
              // );
            }
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            if (e) console.error(e);
            this.isLoggingIn = false;

            // if you're on the okta page, redirect to login
            // if (this.routerStore.isActive(RouteConstants.OKTA)) {
            //   this.history.push(RouteConstants.LOGIN);
            // }

            this.notificationStore.setMessage(
              "Woops! Looks like an error on our end getting user info 😢",
              NotificationConstants.ERROR
            );
          });
      }),
      login: action((email, password) => {
        if (!this.isLoggingIn) {
          this.isLoggingIn = true;
          this.loginError = false;
          const body = {
            username: email,
            password: password
          };

          this.authApi
            .login(body)
            .then(data => {
              this.setLoggedIn(data);
              this.getUserInfo();
            })
            .catch(e => {
              if (
                e &&
                e.code === ExceptionConstants.CODE.CHANGE_TEMP_PASSWORD
              ) {
                this.confirmPassword = password;
                this.confirmEmail = email;
              }
              this.loginError = true;
              this.isLoggingIn = false;
            });
        }
      }),
      resetPassword: action((newPassword, currentPassword) => {
        if (!this.isResettingPassword) {
          this.isResettingPassword = true;
          this.resetPasswordError = false;
          const body = {
            username: this.confirmEmail,
            password: currentPassword ? currentPassword : this.confirmPassword,
            newPassword,
            tempPassword: !currentPassword
          };

          this.authApi
            .resetPassword(body)
            .then(data => {
              if (!currentPassword) {
                this.setLoggedIn(data);
                this.getUserInfo();
              }
              this.isResettingPassword = false;
              this.volunteerResetPassword = false;
              this.resetStore.clearResetAttributes();
              this.notificationStore.setMessage(
                this.i18n.t("login.notification.success"),
                NotificationConstants.SUCCESS
              );
            })
            .catch(() => {
              this.resetPasswordError = true;
              this.isResettingPassword = false;
              this.resetStore.confirmPassword = true;
              this.notificationStore.setMessage(
                this.i18n.t("account.notification.currentPasswordIncorrect"),
                NotificationConstants.ERROR
              );
            });
        }
      }),
      refresh: action(() => {
        if (this.refreshToken && !this.refreshInProgress) {
          this.refreshInProgress = true;
          const body = {
            refreshToken: this.refreshToken
          };

          const p = this.authApi.refresh(body);
          p.then(data => {
            if (data) {
              // have to manually set this here due to order of calls
              localStorage.setItem(
                AuthConstants.KEYS.ACCESS_TOKEN,
                data.accessToken
              );
              this.setLoggedIn(data);
              this.setLocalStorage();
            }
            this.refreshInProgress = false;
            this.pendingRefreshes.forEach(p => p());
            this.pendingRefreshes.length = 0;
          }).catch(() => {
            this.refreshInProgress = false;
          });
          return p;
        } else if (this.refreshInProgress) {
          let pResolve;
          const p = new Promise(resolve => {
            pResolve = resolve;
          });
          this.pendingRefreshes.push(pResolve);
          return p;
        }
      }),
      setMobileNavOpen: action(override => {
        this.mobileNavOpen =
          typeof override === typeof true ? override : !this.mobileNavOpen;
      }),
      toggleResetPasswordModal: action(() => {
        this.volunteerResetPassword = !this.volunteerResetPassword;
      })
    });

    autorun(() => {
      if (this.routerStore.isLoginPath && this.mobileNavOpen) {
        this.setMobileNavOpen();
      }
    });
  }

  serialize = obj => {
    const str = [];
    for (let p in obj)
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  logout = () => {
    localStorage.clear();
    this.accessToken = this.defaults.accessToken;
    this.userData = this.defaults.userData;
    location.href = `${location.origin}${RouteConstants.LOGIN}`;
  };

  get loggedIn() {
    return (
      !!this.accessToken &&
      this.accessToken.length > 0 &&
      !!this.userData &&
      !!this.userData.roles
    );
  }

  get isAdmin() {
    const roles = new Set(this.userData.roles);
    const { SUPER_ADMIN, PROGRAM_ADMIN,ETA_STAFF } = AuthConstants.KEYS.ROLES;
    return (
      this.loggedIn && (roles.has(SUPER_ADMIN) || roles.has(PROGRAM_ADMIN) || roles.has(ETA_STAFF))
    );
  }

  get isSuperAdmin() {
    const roles = new Set(this.userData.roles);
    return this.loggedIn && roles.has(AuthConstants.KEYS.ROLES.SUPER_ADMIN);
  }

  get isProgramAdmin() {
    const roles = new Set(this.userData.roles);
    return this.loggedIn && roles.has(AuthConstants.KEYS.ROLES.PROGRAM_ADMIN);
  }

  get isEtaStaff() {
    const roles = new Set(this.userData.roles);
    return this.loggedIn && roles.has(AuthConstants.KEYS.ROLES.ETA_STAFF);
  }

  get currentUserId() {
    return !!this.userData && !!this.userData.roles
      ? this.userData.email
      : null;
  }

  get isVolunteer() {
    return this.loggedIn && !this.isAdmin;
  }

  get currentUserRole() {
    const roles = new Set(this.userData.roles);
    const { SUPER_ADMIN, PROGRAM_ADMIN } = AuthConstants.KEYS.ROLES;
    if (!this.loggedIn) return null;

    if (roles.has(SUPER_ADMIN)) {
      return SUPER_ADMIN;
    }

    if (roles.has(PROGRAM_ADMIN)) {
      return PROGRAM_ADMIN;
    }

    return "Volunteer";
  }

  get isResetPasswordModalOpen() {
    return Boolean(this.confirmPassword) || this.volunteerResetPassword;
  }
}

decorate(AuthStore, {
  loggedIn: computed,
  isAdmin: computed,
  isSuperAdmin: computed,
  isProgramAdmin: computed,
  isVolunteer: computed,
  currentUserId: computed,
  currentUserRole: computed,
  isResetPasswordModalOpen: computed
});

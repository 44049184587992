import { action, autorun, computed, decorate, extendObservable } from "mobx";
import { NotificationConstants } from "../constants/NotificationConstants";
import { RouteConstants } from "../constants/RouteConstants";
import { StoreUtilities } from "../utilities/StoreUtilities";

export default class ProgramStore {
  constructor(
    authStore,
    programApi,
    authApi,
    routerStore,
    gridStore,
    notificationStore,
    blockingStore,
    history,
    i18n
  ) {
    this.authStore = authStore;
    this.programApi = programApi;
    this.authApi = authApi;
    this.routerStore = routerStore;
    this.gridStore = gridStore;
    this.notificationStore = notificationStore;
    this.blockingStore = blockingStore;
    this.history = history;
    this.i18n = i18n;

    this.defaults = {
      savingProgram: false,
      users: [],
      initialUsers: [],
      programs: [],
      programAdmins: [],
      programSortFilters: {
        key: "title",
        direction: "ASC"
      },
      program: undefined,
      programId: undefined,
      programTitle: "",
      programDescription: "",
      isWarnOnDeleteOpen: false,
      newAdminOpen: false,
      adminFirstName: "",
      adminLastName: "",
      adminEmail: "",
      userName: "",
      failedProgramLoad: false,
      validationErrorMessages: [],
      createUserInProgress: false,
      programDirty: false,// global program (program, opps, and vols forms)
      programFormDirty: false,// just the program form,
      loadingUsers: false
    };

    extendObservable(this, {
      // objects
      ...StoreUtilities.initialize(this),

      // functions
      setPrograms: action(value => {
        this.programs = value;
      }),
      setProgramsSortDirection: action((col, direction) => {
        this.programSortFilters.key = col;
        this.programSortFilters.direction = direction;
      }),
      setProgramTitle: action(event => {
        this.programTitle = event.target.value;
      }),
      setProgramDescription: action(event => {
        this.programDescription = event.target.value;
      }),
      setProgramAttributes: action(value => {
        this.program = value;
        this.programId = value.id;
        this.programTitle = value.title;
        this.programDescription = value.description;
        this.userName = this.defaults.userName;

        this.programAdmins = value.programAdmins.map(admin => ({
          name: admin.email,
          id: admin.userId,
          adminId: admin.id,
          createdTs: admin.createdTs,
          updatedTs: admin.updatedTs,
          createdUser: admin.createdUser,
          updatedUser: admin.updatedUser
        }));
      }),
      setProgramFormDirty: action(value => {
        this.programFormDirty = value;
      }),
      clearProgramAttributes: action(() => {
        this.program = this.defaults.program;
        this.programId = this.defaults.programId;
        this.programTitle = this.defaults.programTitle;
        this.programDescription = this.defaults.programDescription;
        this.failedProgramLoad = this.defaults.failedProgramLoad;
        this.programAdmins = this.defaults.programAdmins;
        this.userName = this.defaults.userName;
      }),
      clearNewAdminAttributes: action(() => {
        this.adminFirstName = this.defaults.adminFirstName;
        this.adminLastName = this.defaults.adminLastName;
        this.adminEmail = this.defaults.adminEmail;
      }),
      setNewAdminOpen: action((value = undefined) => {
        this.newAdminOpen =
          typeof value === "boolean" ? value : !this.newAdminOpen;
        if (!this.newAdminOpen) {
          this.clearNewAdminAttributes();
        }
      }),
      setAdminFirstName: action(event => {
        this.adminFirstName = event.target.value;
      }),
      setAdminLastName: action(event => {
        this.adminLastName = event.target.value;
      }),
      setAdminEmail: action(event => {
        this.adminEmail = event.target.value;
      }),
      showDeleteWarning: action(program => {
        this.isWarnOnDeleteOpen = true;
        this.setProgramAttributes(program);
      }),
      setValidationErrorMessages: action(messages => {
        this.validationErrorMessages = messages;
      }),
      clearValidationErrorMessages: action(() => {
        this.validationErrorMessages = [];
      }),
      hideDeleteWarning: action(() => {
        this.isWarnOnDeleteOpen = false;
      }),
      setProgramAdmins: action(value => {
        this.programAdmins = this.programAdmins.concat([value]);
      }),
      removeProgramAdmin: action(item => {
        this.programAdmins = this.programAdmins.filter(val => (
          val.id !== item.key
        ));
      }),
      setUserName: action(event => {
        if (typeof event === "string") {
          this.userName = event;
        } else {
          this.userName = event.target.value;

          if (this.userName) {
            this.loadingUsers = true;
            this.searchUsers(this.userName);
          } else {
            this.users = this.initialUsers;
          }
        }
      }),
      loadProgram: action(programId => {
        this.programApi.loadProgram(programId).then(program => {
          this.failedProgramLoad = false;
          this.setProgramAttributes(program);
        }).catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          this.failedProgramLoad = true;
        });
      }),
      saveProgram: action(blockingCallback => {
        const program = {
          id: this.programId,
          title: this.programTitle,
          description: this.programDescription,
          programAdmins: this.programAdmins.map(admin => ({
            id: admin.adminId,
            email: admin.name,
            userId: admin.id,
            programId: this.programId,
            createdTs: admin.createdTs || null,
            updatedTs: admin.updatedTs || null,
            createdUser: admin.createdUser || null,
            updatedUser: admin.updatedUser || null
          }))
        };

        const promise = this.programId
          ? this.programApi.updateProgram(program)
          : this.programApi.createProgram(program);

        this.savingProgram = true;
        promise.then(() => {
          this.savingProgram = false;
          this.programTitle = this.defaults.programTitle;
          this.programDescription = this.defaults.programDescription;
          if (blockingCallback) {
            blockingCallback();
          } else {
            this.blockingStore.clearAttributes();// allow push to go through
            this.history.push(RouteConstants.PROGRAMS);
          }
          this.notificationStore.setMessage(
            this.i18n.t("program.notification.saved")
          );
        }).catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          this.savingProgram = false;
          this.setValidationErrorMessages(e.message.split("|"));
        });
      }),
      deleteProgram: action(() => {
        this.programApi.deleteProgram(this.programId).then(() => {
          this.hideDeleteWarning();
          this.blockingStore.clearAttributes();// allow push to go through
          this.history.push(RouteConstants.PROGRAMS);
          this.notificationStore.setMessage(
            this.i18n.t("program.notification.deleted")
          );
          this.loadAllPrograms();
        }).catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          this.notificationStore.setMessage(
            this.i18n.t("program.notification.notDeleted"),
            NotificationConstants.ERROR
          );
        });
      }),
      createUser: action((userDetails) => {
        if (!this.createUserInProgress) {
          this.createUserInProgress = true;

          const createUserRequest = {
            email: userDetails.email,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            roles: ["PROGRAM_ADMIN"]
          };

          this.authApi.createUser(createUserRequest).then(result => {
            this.createUserInProgress = false;
            this.notificationStore.setMessage(this.i18n.t("program.notification.user.saved"));
            this.programAdmins.push({ id: result.userId, name: result.email });
            this.setProgramFormDirty(true);
            this.setNewAdminOpen(false);
          }).catch(() => {
            this.createUserInProgress = false;
            this.notificationStore.setMessage(
              this.i18n.t("program.notification.user.notSaved"),
              NotificationConstants.ERROR
            );
          });
        }
      })
    });

    // load programs autorun
    autorun(() => {
      if (
        this.routerStore.isProgramsPath &&
        this.authStore.currentUserId &&
        this.authStore.isAdmin
      ) {
        this.setProgramFormDirty(false);// should start not dirty
        this.loadAllPrograms();
      }
    });

    // load users for dropdown
    autorun(() => {
      if (this.authStore.currentUserId && this.authStore.isAdmin) {
        // TODO: These 2 may be different searches at some point
        if (this.routerStore.isActive(RouteConstants.NEW_PROGRAM)) {
          this.searchUsers(null, 10);
        } else if (this.routerStore.isActive(RouteConstants.EDIT_PROGRAM)) {
          this.searchUsers(null, 10);
        }
      }
    });
  }

  searchUsers = (search, limit) => {
    this.authApi.searchUsers(search || "", limit || 0).then(data => {
      this.users = !data ? [] : data.result.map(user => ({
        id: user.userId,
        name: user.email
      }));

      if (!search) {
        this.initialUsers = this.users;
      }
      this.loadingUsers = false;
    }).catch(() => {
      this.users = [];
      this.loadingUsers = false;
    });
  }

  loadAllPrograms = () => {
    this.programApi.loadPrograms().then(data => {
      this.setPrograms(data ? data.programs : []);
    });
  }

  get allPrograms() {
    return this.programs.toJS();
  }

  get programData() {
    return this.gridStore.sortWithFilters(this.programSortFilters, this.programs.toJS());
  }

  get userNameSearchResults() {
    return (this.users || []).map(user => Object.assign({}, user, {
      key: user.id
    }));
  }

  get selectedUsers() {
    if (this.programAdmins && this.programAdmins.length > 0) {
      return this.programAdmins.map(programAdmin => (
        Object.assign({}, programAdmin, { key: programAdmin.id })
      ));
    }
    return [];
  }
}

decorate(ProgramStore, {
  allPrograms: computed,
  programData: computed
});

import _ from "lodash";
import { action, autorun, computed, decorate, extendObservable } from "mobx";
import { StoreUtilities } from "../utilities/StoreUtilities";
import { DateUtilities } from "../utilities/DateUtilities";
import { RouteConstants } from "../constants/RouteConstants";
import { NotificationConstants } from "../constants/NotificationConstants";
import { FlowConstants } from "../constants/FlowConstants";
import { BrowserUtilities } from "../utilities/BrowserUtilities";
import moment from "moment";

export default class FlowStore {
  constructor(
    programApi,
    auth,
    event,
    router,
    notification,
    volunteerStore,
    volunteerScreensStore,
    opportunityStore,
    confirmedApi,
    i18n
  ) {
    this.programApi = programApi;
    this.authStore = auth;
    this.eventStore = event;
    this.routerStore = router;
    this.notificationStore = notification;
    this.volunteerStore = volunteerStore;
    this.volunteerScreensStore = volunteerScreensStore;
    this.opportunityStore = opportunityStore;
    this.confirmedApi = confirmedApi;
    this.updatesPending = 0;
    this.i18n = i18n;

    this.defaults = {
      gettingTime: true,
      serverTime: null,
      systemTime: null,
      timeSubscription: null,
      loading: true,
      initializing: false,
      runTransition: true,
      volunteer: {},
      signature: "",
      signingWaiver: false,
      selectingInfoSession: false,
      signatureBoxClicked: false,
      photo: "",
      infoSessionModalOpen: false,
      selectOpportunityBoxClicked: false,
      standbySelections: {},
      dateToSelect: null,
      initializedCxn: false,
      confirmedSelections: [],
      confirmedSelectionsExpanders: {},
      changingSelections: false,
      showChangeSelectionsButton: true,
      reloadingVolunteer: false,
      reloadAttempts: 0,
      isPhotoCamModalOpen: false,
      steps: [
        {
          key: FlowConstants.APP_RECEIVED,
          name: "flow.steps.application",
          complete: false,
          ready: false,
          active: false,
        },
        {
          key: FlowConstants.ACCEPT_WAIVER,
          name: "flow.steps.waiver",
          complete: false,
          ready: false,
          active: false,
        },
        {
          key: FlowConstants.UPLOAD_PHOTO,
          name: "flow.steps.uploadPhoto",
          complete: false,
          ready: false,
          active: false,
        },
        {
          key: FlowConstants.INFO_SESSION,
          name: "flow.steps.infoSession",
          complete: false,
          ready: false,
          active: false,
        },
        {
          key: FlowConstants.BACKGROUND_CHECK,
          name: "flow.steps.background",
          complete: false,
          ready: false,
          active: false,
        },
        {
          key: FlowConstants.SELECT_OPPORTUNITIES,
          name: "flow.steps.opportunities",
          complete: false,
          ready: false,
          active: false,
        },
        {
          key: FlowConstants.ORIENTATION,
          name: "flow.steps.orientation",
          complete: false,
          ready: false,
          active: false,
        },
      ],
    };

    extendObservable(this, {
      ...StoreUtilities.initialize(this),

      updateServerTime: action((response) => {
        const data = JSON.parse(response.body);
        const startTimeStamp = data.startTime
          ? typeof data.startTime === "string"
            ? data.startTime
            : data.startTime.epochSecond * 1000
          : null;

        if (startTimeStamp) {
          this.gettingTime = false;
          this.systemTime = new Date();
          this.serverTime = new Date(startTimeStamp);
          this.confirmedApi.disconnect();
        }
        this.loading = this.selectionStore.savingSelections;
      }),
      clearTransition: action(() => {
        this.runTransition = false;
      }),
      clearOldFlowStatus: action(() => {
        StoreUtilities.resetDefaults(this);
      }),
      clickNextStepWaiver: action(() => {
        this.steps.find(
          (step) => step.key === FlowConstants.APP_RECEIVED
        ).complete = true;
        this.setActiveStep(
          FlowConstants.ACCEPT_WAIVER,
          FlowConstants.APP_RECEIVED
        );

        BrowserUtilities.scrollTop();
      }),
      clickSignatureBox: action(() => {
        this.signatureBoxClicked = !this.signatureBoxClicked;
      }),
      clickSelectOpportunitiesBox: action(() => {
        this.selectOpportunityBoxClicked = !this.selectOpportunityBoxClicked;
      }),
      toggleInfoSessionModal: action((dateToSelect = null) => {
        this.infoSessionModalOpen = !this.infoSessionModalOpen;
        this.dateToSelect = dateToSelect;
      }),
      signWaiver: action(() => {
        const eventId = this.eventStore.event.id;
        this.signingWaiver = true;
        this.programApi
          .signWaiver(this.volunteer.id, eventId)
          .then((data) => {
            BrowserUtilities.scrollTop();
            this.signingWaiver = false;
            this.volunteer = data;
            this.setActiveStep(
              FlowConstants.UPLOAD_PHOTO,
              FlowConstants.ACCEPT_WAIVER
            );
            this.steps.find(
              (step) => step.key === FlowConstants.ACCEPT_WAIVER
            ).complete = true;

            if (this.volunteer.priorExp) {
              this.steps.find(
                (step) => step.key === FlowConstants.INFO_SESSION
              ).complete = true;
            }
          })
          .catch(() => {
            this.signingWaiver = false;
          });
      }),
      setActiveStep: action((activeKey, completeKey) => {
        this.steps.forEach((step) => {
          step.active = step.key === activeKey;
          if (step.key === completeKey) {
            step.complete = true;
          }
        });
      }),
      rewindSteps: action((stepsToRewind, stepToActivate) => {
        this.steps.forEach((step) => {
          step.active = step.key === stepToActivate;
          if (step.key === stepToActivate) {
            step.complete = false;
          }
          if (stepsToRewind.includes(step.key)) {
            step.complete = false;
            step.ready = false;
          }
        });
      }),
      setPhoto: action((photo) => {
        this.photo = photo;
      }),
      submitPhoto: action(() => {
        this.programApi
          .submitVolunteerPhoto(this.volunteer.id, this.photo)
          .then(() => {
            this.volunteer.photoStatus = "PENDING";
            this.notificationStore.setMessage(
              this.i18n.t("photo.photoCamModal.initialUploadSuccess"),
              NotificationConstants.SUCCESS
            );
            this.volunteer.priorExp
              ? this.setActiveStep(
                  FlowConstants.BACKGROUND_CHECK,
                  FlowConstants.UPLOAD_PHOTO
                )
              : this.setActiveStep(
                  FlowConstants.INFO_SESSION,
                  FlowConstants.UPLOAD_PHOTO
                );
          })
          .catch(() => {
            this.notificationStore.setMessage(
              this.i18n.t("photo.photoCamModal.uploadFail"),
              NotificationConstants.ERROR
            );
          });
      }),
      reSubmitPhoto: action(() => {
        this.programApi
          .submitVolunteerPhoto(this.volunteer.id, this.photo)
          .then(() => {
            this.volunteer.photoStatus = "PENDING";
            this.isPhotoCamModalOpen = false;
            this.notificationStore.setMessage(
              this.i18n.t("photo.photoCamModal.uploadSuccess"),
              NotificationConstants.SUCCESS
            );
          })
          .catch(() => {
            this.isPhotoCamModalOpen = false;
            this.notificationStore.setMessage(
              this.i18n.t("photo.photoCamModal.uploadFail"),
              NotificationConstants.ERROR
            );
          });
      }),
      selectInfoSession: action((date) => {
        this.selectingInfoSession = true;
        const eventId = this.eventStore.event.id;
        this.programApi
          .selectInfoSession(eventId, this.volunteer.id, date.id)
          .then((data) => {
            this.volunteer = data;
            this.infoSessionModalOpen = false;
            this.selectingInfoSession = false;
            BrowserUtilities.scrollTop();
          })
          .catch(() => {
            this.selectingInfoSession = false;
          });
      }),
      submitQuestionnaire: action((result) => {
        this.setInfoSessionQuestionnaireResult(result);
        if (result) {
          this.setActiveStep(
            FlowConstants.BACKGROUND_CHECK,
            FlowConstants.INFO_SESSION
          );
        } else {
          // Show the failed screen
        }
      }),
      getServerTime: action(() => {
        this.confirmedApi.getServerTime();
      }),
      initializeConfirmedSelections: action((selections) => {
        let confirmedOpps = [];
        selections.forEach((selection) => {
          confirmedOpps.push(
            this.opportunityStore.getOpportunityForSelection(selection)
          );
          this.confirmedSelectionsExpanders[selection.id] = false;
        });
        this.confirmedSelections = _.sortBy(confirmedOpps, [
          "date",
          "startTime",
        ]);
        this.selectionStore.savingSelections = false;
        this.setUpSteps();
      }),
      toggleSelectionExpanded: action((selectionId) => {
        this.confirmedSelectionsExpanders[selectionId] =
          !this.confirmedSelectionsExpanders[selectionId];
        this.confirmedSelections = this.confirmedSelections.map((sel) => sel); // force a re-render
      }),
      togglePhotoCamModal: action(() => {
        this.isPhotoCamModalOpen = !this.isPhotoCamModalOpen;
      }),
      confirmOpportunitySelections: action(() => {
        this.setActiveStep(
          FlowConstants.ORIENTATION,
          FlowConstants.SELECT_OPPORTUNITIES
        );
      }),
      setStandbyTime: action((date, tod, selectAll) => {
        let val = null;
        if (selectAll) {
          val = this.standbySelections[date] || {};
          val.standbySaving = tod;
          if (val.morning && val.afternoon && val.evening) {
            this.standbySelections = {
              ...this.standbySelections,
              [date]: {
                ...val,
                morning: false,
                afternoon: false,
                evening: false,
              },
            };
          } else {
            this.standbySelections = {
              ...this.standbySelections,
              [date]: { ...val, morning: true, afternoon: true, evening: true },
            };
          }
        } else {
          val = this.standbySelections[date] || {};
          val[tod] = !val[tod];
          val.standbySaving = tod;

          this.standbySelections = { ...this.standbySelections, [date]: val };
        }

        this.volunteer.standbySelections = Object.keys(
          this.standbySelections
        ).map((k) => ({ ...this.standbySelections[k], date: k }));
        if (this.volunteer.selectedInfoSessionDate) {
          this.volunteer.selectedInfoSessionDate.info_session_id = null;
        }
        if (!this.volunteer.phoneNumbers) {
          this.volunteer.phoneNumbers = [];
        }

        ++this.updatesPending;
        return this.programApi
          .updateVolunteer(this.volunteer, this.eventStore.event.id)
          .then((volunteer) => {
            volunteer.standbySelections.forEach((ss) => {
              const d = moment(ss.date).format("YYYY-MM-DD");
              if (d === date) {
                val = ss;
                val.standbySaved = true;
                val.standbySaving = undefined;
                this.standbySelections[d] = val;
              } else {
                this.standbySelections[d] = ss;
              }
            });
            this.standbySelections = { ...this.standbySelections };
            setTimeout(() => {
              --this.updatesPending;
              if (this.updatesPending === 0) {
                val.standbySaved = false;
                this.standbySelections = {
                  ...this.standbySelections,
                  [date]: val,
                };
              }
            }, 3000);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e);

            val.standbySaving = undefined;
            val.standbySaved = false;
            this.standbySelections = Object.assign({}, this.standbySelections, {
              [date]: val,
            });
          });
      }),
      reopenOpportunitySelection: action(() => {
        this.changingSelections = true;
        this.selectionStore.collapseAllLiveCounts();
        this.confirmedSelections = this.defaults.confirmedSelections;
        this.confirmedSelectionsExpanders =
          this.defaults.confirmedSelectionsExpanders;
        this.rewindSteps(
          [FlowConstants.ORIENTATION],
          FlowConstants.SELECT_OPPORTUNITIES
        );
      }),
      setCompletedInfoSessionVideo: action((result) => {
        this.volunteer.completedInfoSessionVideo = result;
        this.programApi.completedInfoSessionVideo(
          this.eventStore.eventId,
          this.volunteer.id
        );
      }),
      setInfoSessionQuestionnaireResult: action((result) => {
        const questions = [];
        result.forEach((q) => {
          questions.push({ question: q.question, answer: q.answer });
        });
        const payload = {
          id: this.volunteer.id,
          volunteerName: `${this.volunteer.firstName} ${this.volunteer.lastName}`,
          volunteerEmail: this.volunteer.email,
          questionAnswers: questions,
        };
        this.volunteer.passedInfoSessionQuestionnaire = result;
        this.programApi.submitQuestionnaireResult(
          this.eventStore.eventId,
          this.volunteer.id,
          payload
        );
      }),
    });

    // load specific volunteer info (not admin)
    autorun(() => {
      const { event } = this.eventStore;
      const { currentUserId, isAdmin } = this.authStore;

      if (
        currentUserId &&
        !isAdmin &&
        !this.volunteer.id &&
        !this.initializing
      ) {
        // get the event from the path
        const params = this.routerStore.getPathParams(
          RouteConstants.EVENT_NAME
        );

        if (params.eventName) {
          this.initializing = true;
          if (!event) {
            this.loadEvent(params.eventName);
          } else {
            this.loadVolunteerAndOpportunities(currentUserId, event);
          }
        }
      }
    });

    autorun(() => {
      if (
        this.volunteer &&
        this.isStepComplete(FlowConstants.BACKGROUND_CHECK)
      ) {
        this.getTimes();
        if (
          this.volunteer.selections &&
          this.volunteer.selections.length > 0 &&
          this.opportunityStore.opportunities.length > 0
        ) {
          this.initializeConfirmedSelections(this.volunteer.selections);
        }
      } else {
        this.loading = false;
      }
    });
  }

  loadEvent = (eventName) => {
    const requests = [
      this.programApi.findEvent(eventName),
      this.programApi.loadAllEvents(),
    ];

    Promise.all(requests)
      .then((data) => {
        const [eventInfo, allEvents] = data;
        const event = eventInfo ? eventInfo.events[0] || null : null;
        const userEvents = allEvents ? allEvents.events : [];

        if (event) {
          this.loadVolunteerAndOpportunities(
            this.authStore.currentUserId,
            event,
            userEvents
          );
        } else {
          this.initializing = false;
          this.routerStore.history.push(RouteConstants.NOT_FOUND);
        }
      })
      .catch(() => {
        this.loading = false;
        this.initializing = false;
      });
  };

  reloadVolunteer = () => {
    const { event } = this.eventStore;
    this.reloadingVolunteer = true;
    this.programApi
      .findVolunteers({
        email: this.authStore.currentUserId,
        eventId: event.id,
      })
      .then((data) => {
        this.volunteer = data ? data.volunteers[0] : null;
        if (
          (!this.volunteer ||
            !this.volunteer.selections ||
            this.volunteer.selections.length !==
              event.numRequiredOpportunities) &&
          this.reloadAttempts < 4
        ) {
          setTimeout(() => {
            this.reloadVolunteer();
            ++this.reloadAttempts;
          }, 2000);
        } else {
          this.reloadingVolunteer = false;
          this.reloadAttempts = 0;
        }
      });
  };

  // loads the volunteer + opportunities
  loadVolunteerAndOpportunities = (email, event, allEvents = null) => {
    this.programApi
      .findVolunteers({ email, eventId: event.id })
      .then((data) => {
        const volunteer = data ? data.volunteers[0] : null;
        if (volunteer) {
          this.volunteer = volunteer;
          this.standbySelections = {};
          this.volunteer.standbySelections.forEach((ss) => {
            this.standbySelections[moment(ss.date).format("YYYY-MM-DD")] = ss;
          });
          (volunteer.phoneNumbers || []).forEach((phone) => {
            if (phone.type === "Home") {
              this.homePhone = phone.number;
            } else if (phone.type === "Mobile") {
              this.mobilePhone = phone.number;
            }
          });

          // setup event + opportunities
          if (allEvents) {
            this.eventStore.setUserEvents(allEvents);
          }
          this.eventStore.setEventAttributes(event);
          this.setUpSteps();
        } else {
          // somehow, this user isn't a volunteer for the event. redirect
          this.notificationStore.setMessage(
            "Sorry, that event is unavailable",
            NotificationConstants.ERROR
          );
          this.routerStore.history.push(RouteConstants.BASE);
        }
      })
      .catch(() => {
        this.loading = false;
        this.initializing = false;
      });

    // the opportunities get loaded in the background
    this.programApi.loadOpportunitiesForEvent(event.id).then((data) => {
      const opportunities = (data ? data.opportunities : []) || [];
      this.opportunityStore.opportunities = _.sortBy(
        opportunities.map((o) => {
          const dates = o.dates.map((d) => DateUtilities.parseString(d.date));
          return {
            ...o,
            justOutside: o.zones.every((z) => z.outside),
            justInside: o.zones.every((z) => !z.outside),
            startDate: _.min(dates),
            endDate: _.max(dates),
          };
        }),
        "endDate"
      );
    });
  };

  setUpSteps = (index = 0) => {
    let activeFound = false;
    this.steps.slice(index).forEach((step) => {
      step.complete = this.isStepComplete(step.key);
      step.ready = this.isStepReady(step.key);
      if (!step.complete && step.ready && !activeFound) {
        step.active = true;
        activeFound = true;
      } else {
        step.active = false;
      }
    });
  };

  isStepComplete = (key) => {
    switch (key) {
      case FlowConstants.APP_RECEIVED:
        return Boolean(this.volunteer.acceptedWaiverDate);
      case FlowConstants.ACCEPT_WAIVER:
        return Boolean(this.volunteer.acceptedWaiverDate);
      case FlowConstants.UPLOAD_PHOTO:
        return Boolean(this.volunteer.photoUrl);
      case FlowConstants.INFO_SESSION:
        return (
          this.volunteer.priorExp ||
          (this.volunteer.interviewScore !== null &&
            Boolean(
              this.volunteer.attendedInPersonSessionDate ||
                this.volunteer.attendedOnlineSessionDate
            )) ||
          (this.volunteer.completedInfoSessionVideo &&
            this.volunteer.passedInfoSessionQuestionnaire &&
            !this.volunteer.failedReason)
        );
      case FlowConstants.BACKGROUND_CHECK:
        return Boolean(this.volunteer.backgroundCheckConsentDate);
      case FlowConstants.SELECT_OPPORTUNITIES:
        return (
          this.volunteer.selections && this.volunteer.selections.length > 0
        );
      default:
        return false;
    }
  };

  isStepReady = (key) => {
    const {
      priorExp,
      acceptedWaiverDate,
      photoUrl,
      attendedInPersonSessionDate,
      attendedOnlineSessionDate,
      backgroundCheckConsentDate,
      interviewScore,
      completedInfoSessionVideo,
      passedInfoSessionQuestionnaire,
      failedReason,
    } = this.volunteer;

    switch (key) {
      case FlowConstants.APP_RECEIVED:
        return !acceptedWaiverDate;
      case FlowConstants.ACCEPT_WAIVER:
        return !acceptedWaiverDate;
      case FlowConstants.UPLOAD_PHOTO:
        return !photoUrl && acceptedWaiverDate;
      case FlowConstants.INFO_SESSION:
        return (
          ((!attendedInPersonSessionDate && !attendedOnlineSessionDate) ||
            interviewScore === null) &&
          acceptedWaiverDate &&
          photoUrl
        );
      case FlowConstants.BACKGROUND_CHECK:
        return (
          priorExp ||
          (acceptedWaiverDate &&
            !backgroundCheckConsentDate &&
            (attendedInPersonSessionDate || attendedOnlineSessionDate) &&
            interviewScore !== null) ||
          (completedInfoSessionVideo &&
            passedInfoSessionQuestionnaire &&
            !failedReason)
        );
      case FlowConstants.SELECT_OPPORTUNITIES:
        return (
          backgroundCheckConsentDate &&
          (attendedInPersonSessionDate ||
            attendedOnlineSessionDate ||
            priorExp ||
            (completedInfoSessionVideo && passedInfoSessionQuestionnaire)) &&
          acceptedWaiverDate
        );
      case FlowConstants.ORIENTATION:
        return (
          backgroundCheckConsentDate &&
          (attendedInPersonSessionDate ||
            attendedOnlineSessionDate ||
            priorExp ||
            (completedInfoSessionVideo && passedInfoSessionQuestionnaire)) &&
          acceptedWaiverDate &&
          this.volunteer.selections &&
          this.volunteer.selections.length > 0
        );
    }
  };

  compare = (obj, now) => {
    const { date, endTime } = obj;
    const dateTime = DateUtilities.parseString(
      `${date} ${endTime}`,
      "YYYY-MM-DD HH:mm:00"
    );

    return now >= dateTime;
  };

  get activeStep() {
    return this.steps.find((step) => step.active);
  }

  get agreementValid() {
    return this.signature && this.signatureBoxClicked;
  }

  get missedInfoSession() {
    // if the volunteer has a selected info session, just
    // check that info session. else, if ANY session has
    // an end dateTime > now, you have NOT missed ALL info sessions
    const now = new Date();
    const { event } = this.eventStore;
    const isVirtual =
      event && event.virtualInfoSession && event.virtualInfoSession.allow;

    if (isVirtual) {
      const infoSession = event.virtualInfoSession;
      const dateTime = moment(
        `${infoSession.deadlineDate} ${infoSession.deadlineTime}`,
        "M/D/YYYY hh:mm:ss a"
      );
      return moment().isSameOrAfter(dateTime);
    }

    if (this.volunteer.selectedInfoSessionDate) {
      return this.compare(this.volunteer.selectedInfoSessionDate, now);
    } else {
      return this.volunteerScreensStore.infoSessions
        .toJS()
        .every((s) =>
          s.infoSessionDates.toJS().every((d) => this.compare(d, now))
        );
    }
  }

  get failedInfoSession() {
    return this.volunteer.failedReason;
  }

  get isPhotoRejected() {
    return this.volunteer.photoStatus &&
      this.volunteer.photoStatus.toUpperCase() === "REJECTED"
      ? true
      : false;
  }

  get isPhotoPending() {
    return this.volunteer.photoStatus &&
      this.volunteer.photoStatus.toUpperCase() === "PENDING"
      ? true
      : false;
  }

  getTimes() {
    const connected =
      this.confirmedApi.client && this.confirmedApi.client.connected;
    const { id: volunteerId } = this.volunteer;

    if (!connected && volunteerId) {
      this.initializedCxn = true;

      this.confirmedApi.connect(() => {
        this.confirmedApi.client.subscribe(
          "/status/time",
          this.updateServerTime
        );

        this.getServerTime();
      });
    }
  }
}

decorate(FlowStore, {
  activeStep: computed,
  agreementValid: computed,
  missedInfoSession: computed,
  isPhotoRejected: computed,
  isPhotoPending: computed,
});

import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const LoadingContext = createContext({ isLoading: false, setLoading: null });

const LoadingContextProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  return (
    <LoadingContext.Provider value={{ isLoading, setLoading }}>{children}</LoadingContext.Provider>
  );
};

const useLoading = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error("useLoading must be used within a AuthProvider");
  }
  return context;
};

LoadingContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ])
};

export { LoadingContextProvider, useLoading };

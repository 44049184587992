export const ScreenConstants = {
  NAVIGATION: [
    "preSelection",
    "postSelection",
    "eventInfo",
    "contact",
    "standbySelections",
    "openOpportunities"
  ],
  OPPORTUNITIES_NAVIGATION: [
    "preSelection",
    "postSelection",
    "standbySelections",
    "openOpportunities"
  ],
  REGISTRATION: [
    "applicationReceived",
    "waiver",
    "uploadPhoto",
    "infoSession",
    "missedInfo",
    "backgroundCheck",
    "awaitingSelection",
    "openSelection",
    "noOpportunitiesAwaitingEnrollment",
    "noOpportunitiesMissedEnrollment",
    "orientation"
  ]
};

import { action, autorun, computed, decorate, extendObservable } from "mobx";
import { DateUtilities } from "../utilities/DateUtilities";
import { StringUtilities } from "../utilities/StringUtilities";
import { StoreUtilities } from "../utilities/StoreUtilities";
import { FlowConstants } from "../constants/FlowConstants";
import { BrowserUtilities } from "../utilities/BrowserUtilities";
import { RouteConstants } from "../constants/RouteConstants";
import _ from "lodash";

export default class SelectionStore {
  constructor(auth, flow, event, router, selectionApi, programApi) {
    this.authStore = auth;
    this.flowStore = flow;
    this.eventStore = event;
    this.routerStore = router;
    this.selectionApi = selectionApi;
    this.programApi = programApi;
    this.defaults = {
      initializedCxn: false,
      showExpiredModal: false,
      showChangedSelectionModal: false,
      savingSelections: false,
      selectionStarted: false,
      initializedCounts: false,
      selectionBoxClicked: false,
      currentOpenEnrollment: null,
      missedAllSelections: false,
      selectionTime: null,
      timeToSelection: {
        days: 0,
        hours: 0,
        mins: 0,
        secs: 0,
      },
      timeForSelection: {
        days: 0,
        hours: 0,
        mins: 0,
        secs: 0,
      },
      endTime: null,
      systemTime: null,
      serverTime: null,
      selections: [],
      opportunityMap: {},
      liveCountDateMap: {},
      shiftDateSelectedMap: {},
      opportunitySubscription: null,
      linkedShiftsModalOpen: false,
      pendingShiftSelected: {},
      linkedShifts: [],
      selectedLinkedShifts: {},
      selectedLinkedShiftsConflict: false,
      preSelectionInterval: null,
    };

    extendObservable(this, {
      ...StoreUtilities.initialize(this),

      // socket function requests go here
      getLiveCounts: action((eventId, tierId) => {
        this.selectionApi.updateLiveCounts(eventId, tierId);
      }),
      getSelectedShifts: action((volunteerId) => {
        this.selectionApi.getSelectedShifts(volunteerId);
      }),
      getServerTime: action(() => {
        this.selectionApi.getServerTime();
      }),
      getStartTime: action((volunteerId) => {
        this.selectionApi.getStartTime(volunteerId);
      }),
      getOpportunities: action((eventId) => {
        this.selectionApi.getOpportunities(eventId);
      }),
      deleteSelectedShift: action((selectionId) => {
        const { id: volunteerId } = this.flowStore.volunteer;
        const oldSelection = this.selections.find((s) => s.id === selectionId);

        // NOTE: if this becomes an issue, will actually listen to a delete
        if (oldSelection) {
          // check to make sure selection is not linked
          if (this.isSelectionLinked(oldSelection)) {
            // delete all selected selections for this opp that are linked
            const selections = this.selections.filter(
              (s) =>
                s.opportunityId === oldSelection.opportunityId &&
                this.isSelectionLinked(s)
            );
            const selectionIds = new Set(selections.map((s) => s.id));
            this.selectionApi.deleteMultiple(volunteerId, {
              selectionIds: [...selectionIds],
            });
            this.selections = this.selections.filter(
              (s) => !selectionIds.has(s.id)
            );
            selections.forEach((s) => this.setIsShiftSelected(false, s));
          } else {
            this.selectionApi.deleteSelectedShift(volunteerId, selectionId);
            this.selections = this.selections.filter(
              (s) => s.id !== selectionId
            );
            this.setIsShiftSelected(false, oldSelection);
          }

          // Reset the volunteer's timer if they don't have any opportunities selected
          if (this.selections.length === 0) {
            this.selectionApi.deleteVolunteerTimer(volunteerId);
            this.endTime = null;
          }
        }
      }),
      selectShift: action((shiftDate, name) => {
        const { numRequiredOpportunities } = this.eventStore.event;

        this.setIsShiftLoading(true, shiftDate);

        const payload = {
          volunteerId: this.flowStore.volunteer.id,
          dateTimeId: shiftDate.dateTimeId,
          eventId: (this.eventStore.event || {}).id,
          tierId: this.flowStore.volunteer.assignedTierId,
          opportunityId: shiftDate.opportunityId,
          opportunityShiftId: shiftDate.opportunityShiftId,
          outside: shiftDate.outside,
          opportunityName: name,
          date: shiftDate.date,
          startTime: shiftDate.startTime,
          endTime: shiftDate.endTime,
        };

        if (shiftDate.linked && numRequiredOpportunities > 1) {
          this.pendingShiftSelected = payload;
          this.getLinkedShifts();
          if (this.linkedShifts.length > 0) {
            this.toggleLinkedShiftsModal();
          } else {
            this.selectionApi.selectShift(payload.volunteerId, payload);
          }
        } else {
          this.selectionApi.selectShift(payload.volunteerId, payload);
        }
      }),
      selectMultiple: action(() => {
        // instance variables
        const { id: volunteerId, assignedTierId: tierId } =
          this.flowStore.volunteer;
        const { id: eventId } = this.eventStore.event || {};
        const linkedShifts = Object.values(this.selectedLinkedShifts);

        // create payload && send request && close modal
        this.selectionApi.selectMultiple(volunteerId, {
          selections: [this.pendingShiftSelected].concat(
            linkedShifts.map((shift) => ({
              volunteerId,
              tierId,
              eventId,
              dateTimeId: shift.dateTimeId,
              opportunityShiftId: shift.opportunityShiftId,
              opportunityId: shift.opportunityId,
              outside: shift.outside,
              opportunityName: this.pendingShiftSelected.opportunityName,
              date: shift.date,
              startTime: shift.startTime,
              endTime: shift.endTime,
            }))
          ),
        });
        this.toggleLinkedShiftsModal();
      }),
      saveSelections: action(() => {
        this.savingSelections = true;
        const { id: eventId } = this.eventStore.event;
        const { id: volunteerId, assignedTierId: tierId } =
          this.flowStore.volunteer;
        this.selectionApi.persistSelections(eventId, tierId, volunteerId);
      }),

      // socket function responses go here
      updateServerTime: action((response) => {
        const data = JSON.parse(response.body);
        const startTimeStamp = data.startTime
          ? typeof data.startTime === "string"
            ? data.startTime
            : data.startTime.epochSecond * 1000
          : null;
        this.serverTime = startTimeStamp ? new Date(startTimeStamp) : null;
        if (startTimeStamp) {
          this.systemTime = new Date();
        }
      }),
      updateEndTime: action((response) => {
        // user's startTime
        const data = JSON.parse(response.body);
        const startTimeStamp = data.startTime
          ? typeof data.startTime === "string"
            ? data.startTime
            : data.startTime * 1000
          : null;
        const startTime = startTimeStamp ? new Date(startTimeStamp) : null;

        if (startTime) {
          if (this.preSelectionInterval) {
            window.clearInterval(this.preSelectionInterval);
            this.preSelectionInterval = null;
          }
          startTime.setSeconds(startTime.getSeconds() + 900);

          // event tier selection endTime
          const { tiers } = this.eventStore.event || {};
          const { assignedTierId: tierId } = this.flowStore.volunteer;
          const tier = (tiers || []).find((t) => t.id === tierId);

          const { date, endDate, endTime: end } = tier || {};
          const endTime = tier
            ? DateUtilities.parseTZString(`${endDate || date} ${end}`)
            : startTime;

          this.getServerTime();
          this.endTime = endTime < startTime ? endTime : startTime;
        }
      }),
      updateSelectedShifts: action((response) => {
        const data = JSON.parse(response.body);
        if (data.selections) {
          this.clearSelected(this.selections);
          this.selections = data.selections.map((s) => {
            const { startTime, endTime } = s;
            const date = DateUtilities.redisDateToString(s.date);
            s.date = date;
            this.setIsShiftSelected(true, s);
            this.setIsShiftLoading(false, s);
            return {
              ...s,
              key: StringUtilities.random(),
              startObj: DateUtilities.parseString(
                `${date} ${startTime}`,
                "YYYY-MM-DD HH:mm:00"
              ),
              endObj: DateUtilities.parseString(
                `${date} ${endTime}`,
                "YYYY-MM-DD HH:mm:00"
              ),
            };
          });
        } else if (data.selection) {
          const { startTime, endTime } = data.selection;
          const date = DateUtilities.redisDateToString(data.selection.date);
          data.selection.date = date;
          this.selections.push({
            ...data.selection,
            key: StringUtilities.random(),
            startObj: DateUtilities.parseString(
              `${date} ${startTime}`,
              "YYYY-MM-DD HH:mm:00"
            ),
            endObj: DateUtilities.parseString(
              `${date} ${endTime}`,
              "YYYY-MM-DD HH:mm:00"
            ),
          });

          this.setIsShiftSelected(true, data.selection);
          this.setIsShiftLoading(false, data.selection);
        } else {
          // the result is null, so make sure loading is reset
          this.clearLoading();
        }
      }),
      updateLiveCounts: action((response) => {
        const data = JSON.parse(response.body);
        if (!this.initializedCounts) {
          this.initializeLiveCounts(data.liveCounts);
        } else {
          data.liveCounts.forEach((liveCount) => {
            const { opportunityId, dateTimeId, outside, total, current } =
              liveCount;

            // make sure date is a string
            const date = DateUtilities.redisDateToString(liveCount.date);

            // update remaining
            const key = outside ? "outsideShiftDates" : "insideShiftDates";
            this.liveCountDateMap[date].opportunities[opportunityId][key][
              dateTimeId
            ].remaining = Math.max(total - current, 0);
          });
        }
      }),
      initializeOpportunities: action((response) => {
        const data = JSON.parse(response.body);
        const opportunityMap = {};
        data.opportunities.forEach((opportunity) => {
          opportunityMap[opportunity.opportunityId] = opportunity;
        });

        this.opportunityMap = opportunityMap;
        this.opportunitySubscription.unsubscribe();
        this.opportunitySubscription = this.defaults.opportunitySubscription;
      }),
      initializeLiveCounts: action((liveCounts) => {
        const liveCountDateMap = {};
        liveCounts.forEach((liveCount) => {
          const { dateTimeId, opportunityId, outside, total, current } =
            liveCount;

          // make sure date and time are strings
          const date = DateUtilities.redisDateToString(liveCount.date);
          liveCount.date = date;
          liveCount.startTime = DateUtilities.redisTimeToString(
            liveCount.startTime
          );
          liveCount.endTime = DateUtilities.redisTimeToString(
            liveCount.endTime
          );

          // do the rest
          const oldLiveCountObject = liveCountDateMap[date] || {};
          const oldOpportunitiesByDate = oldLiveCountObject.opportunities || {};
          const oldOpportunity = oldOpportunitiesByDate[opportunityId] || {};
          const insideShiftDates = oldOpportunity.insideShiftDates || {};
          const outsideShiftDates = oldOpportunity.outsideShiftDates || {};

          if (outside) {
            outsideShiftDates[dateTimeId] = {
              ...liveCount,
              loading: false,
              selected: this.getIsShiftSelected(liveCount),
              remaining: Math.max(total - current, 0),
            };
          } else {
            insideShiftDates[dateTimeId] = {
              ...liveCount,
              loading: false,
              selected: this.getIsShiftSelected(liveCount),
              remaining: Math.max(total - current, 0),
            };
          }

          liveCountDateMap[date] = {
            ...oldLiveCountObject,
            expanded: false,
            opportunities: {
              ...oldOpportunitiesByDate,
              [opportunityId]: {
                id: opportunityId,
                expanded: false,
                insideShiftDates,
                outsideShiftDates,
              },
            },
          };
        });
        this.initializedCounts = true;
        this.liveCountDateMap = liveCountDateMap;
      }),
      persistSelections: action((response) => {
        const data = JSON.parse(response.body);
        const { id: eventId } = this.eventStore.event;
        const { id: volunteerId } = this.flowStore.volunteer;

        if (data.selections) {
          setTimeout(() => {
            this.loadSelection(eventId, volunteerId);
          }, 2000);
        }
      }),
      loadSelection: action((eventId, volunteerId) => {
        this.programApi
          .fetchSelections(eventId, volunteerId)
          .then((result) => {
            if (result?.selections.length === 0) {
              setTimeout(() => {
                this.loadSelection(eventId, volunteerId);
              }, 2000);
            } else {
              this.flowStore.volunteer.selections = result?.selections;
              this.goToOpportunities();
            }
          })
          .catch((e) => {
            console.log(e);
            this.flowStore.volunteer.selections = null;
            this.goToOpportunities();
          });
      }),
      collapseAllLiveCounts: action(() => {
        this.liveCountsByDate.forEach((liveCount) => {
          liveCount.expanded = false;
        });
      }),

      // misc
      closeExpiredModal: action(() => {
        this.showExpiredModal = this.defaults.showExpiredModal;
        this.selectionBoxClicked = this.defaults.selectionBoxClicked;
        this.endTime = this.defaults.endTime;
        this.serverTime = this.defaults.serverTime;
        this.systemTime = this.defaults.systemTime;
        this.clearSelected(this.selections);
        this.selections = this.defaults.selections;
        BrowserUtilities.scrollTop();
      }),
      displayExpiredModal: action(() => {
        this.showExpiredModal = true;
      }),
      toggleChangedSelectionModal: action(() => {
        this.showChangedSelectionModal = !this.showChangedSelectionModal;
      }),
      clickSelectionBox: action(() => {
        this.selectionBoxClicked = true;
        const { id: volunteerId } = this.flowStore.volunteer;
        this.selectionApi.getStartTime(volunteerId);

        this.getTimeToSelection();
        this.preSelectionInterval = window.setInterval(
          this.getTimeToSelection,
          1000
        );
      }),
      toggleLinkedShiftsModal: action(() => {
        this.linkedShiftsModalOpen = !this.linkedShiftsModalOpen;
        if (!this.linkedShiftsModalOpen) {
          this.linkedShifts = [];
          this.selectedLinkedShifts = {};
          this.clearLoading();
          this.pendingShiftSelected = {};
        }
      }),
      getLinkedShifts: action(() => {
        Object.values(this.liveCountsByDate).map((date) => {
          date.opportunities.map((opp) => {
            if (opp.id === this.pendingShiftSelected.opportunityId) {
              [...opp.insideShiftDates, ...opp.outsideShiftDates].forEach(
                (shiftDate) => {
                  const shiftDateTime =
                    shiftDate.date + shiftDate.startTime + shiftDate.endTime;
                  const pendingShiftDatetime =
                    this.pendingShiftSelected.date +
                    this.pendingShiftSelected.startTime +
                    this.pendingShiftSelected.endTime;
                  if (
                    shiftDate.remaining > 0 &&
                    shiftDateTime !== pendingShiftDatetime &&
                    !this.doLiveCountsConflict(
                      shiftDate,
                      this.pendingShiftSelected
                    )
                  ) {
                    shiftDate.checked = false;
                    this.linkedShifts.push(shiftDate);
                  }
                }
              );
            }
          });
        });
      }),
      toggleLinkedShift: action((id) => {
        this.linkedShifts = this.linkedShifts.map((shift) => {
          if (shift.id === id) {
            shift.checked = !shift.checked;
            if (shift.checked) {
              this.selectedLinkedShifts[shift.id] = shift;
            } else {
              delete this.selectedLinkedShifts[shift.id];
            }
            this.checkSelectedLinkedShiftsForConflicts();
          }
          return shift;
        });
      }),
      checkSelectedLinkedShiftsForConflicts: action(() => {
        this.selectedLinkedShiftsConflict = false;
        this.linkedShifts.forEach((shift) => (shift.conflict = false));
        Object.values(this.selectedLinkedShifts).forEach((shift1) => {
          Object.values(this.selectedLinkedShifts).forEach((shift2) => {
            if (shift1.id !== shift2.id) {
              if (this.doLiveCountsConflict(shift1, shift2)) {
                this.selectedLinkedShiftsConflict = true;
                shift1.conflict = shift2.conflict = true;
              }
            }
          });
        });
      }),
      toggleDateExpander: action((dateStr) => {
        this.liveCountDateMap[dateStr].expanded =
          !this.liveCountDateMap[dateStr].expanded;
      }),
      toggleOpportunityExpander: action((dateStr, opportunityId) => {
        this.liveCountDateMap[dateStr].opportunities[opportunityId].expanded =
          !this.liveCountDateMap[dateStr].opportunities[opportunityId].expanded;
      }),
      getSelectionTime: action(() => {
        const { assignedTierId } = this.flowStore.volunteer;
        const { tiers: tiersArr } = this.eventStore.event || {};
        const tiers = tiersArr || [];
        const volunteerTier = tiers.find((t) => t.id === assignedTierId);
        if (
          !this.selectionTime &&
          (volunteerTier || this.currentOpenEnrollment)
        ) {
          let startsAt = null;
          let endsAt = null;
          if (this.currentOpenEnrollment) {
            // start
            startsAt = this.getOpenEnrollmentDateTime(
              this.currentOpenEnrollment,
              "date",
              "startTime"
            );
            // end
            endsAt = this.getOpenEnrollmentDateTime(
              this.currentOpenEnrollment,
              "endDate",
              "endTime"
            );
          } else {
            startsAt = `${volunteerTier.date} ${volunteerTier.startTime}`;
            endsAt = `${volunteerTier.endDate || volunteerTier.date} ${
              volunteerTier.endTime
            }`;
          }

          this.selectionTime = DateUtilities.parseTZString(startsAt);
          this.selectionEndTime = DateUtilities.parseTZString(endsAt);
        }
        return this.selectionTime;
      }),
      getOpenEnrollment: action(() => {
        // This sorts open enrollments by date asc, and return first one that hasn't ended yet or null if all are past
        if (this.eventStore.openEnrollments) {
          const openEnrollments = Object.keys(this.eventStore.openEnrollments)
            .map((key) => {
              return this.eventStore.openEnrollments[key];
            })
            .filter((oe) => oe.id && oe.id > 0);
          const availableOpenEnrollments = openEnrollments.filter((oe) => {
            const endsAt = this.getOpenEnrollmentDateTime(
              oe,
              "endDate",
              "endTime"
            );
            const endTime = DateUtilities.parseString(
              endsAt,
              "YYYY-MM-DD HH:mm:00"
            );
            return endTime - new Date() > 0;
          });
          if (availableOpenEnrollments && availableOpenEnrollments.length > 0) {
            availableOpenEnrollments.sort((a, b) => {
              const aDate = DateUtilities.parseString(
                this.getOpenEnrollmentDateTime(a, "date", "startTime"),
                "YYYY-MM-DD HH:mm:00"
              );
              const bDate = DateUtilities.parseString(
                this.getOpenEnrollmentDateTime(b, "date", "startTime"),
                "YYYY-MM-DD HH:mm:00"
              );
              return aDate - bDate;
            });
            return availableOpenEnrollments[0];
          }
        }
        return null;
      }),
      getTimeToSelection: action(() => {
        const { serverTime, systemTime } = this.flowStore;
        if (serverTime && systemTime) {
          const duration = new Date() - systemTime;
          const now = new Date(serverTime.getTime() + duration);

          let diff = this.getSelectionTime() - now;
          if (diff > 0) {
            const { days, hours, mins, secs } = this.calculateTimeParts(diff);
            let daysFloor = Math.floor(days);
            let hoursFloor = Math.floor(hours);
            let minsFloor = Math.floor(mins);
            this.timeToSelection = this.padTime(
              daysFloor,
              hoursFloor,
              minsFloor,
              secs
            );
            this.selectionStarted = false;
          } else {
            this.selectionStarted = true;
            diff = this.selectionEndTime - now;
            if (diff > 0) {
              const { days, hours, mins, secs } = this.calculateTimeParts(diff);
              let daysFloor = Math.floor(days);
              let hoursFloor = Math.floor(hours);
              let minsFloor = Math.floor(mins);
              this.timeForSelection = this.padTime(
                daysFloor,
                hoursFloor,
                minsFloor,
                secs
              );
            } else {
              this.selectionStarted = false;
              this.currentOpenEnrollment = this.getOpenEnrollment();
              if (!this.currentOpenEnrollment) {
                this.missedAllSelections = true;
                this.selectionBoxClicked = this.defaults.selectionBoxClicked;
              }
            }

            // at this point, open a socket and connect to the server
            if (!this.initializedCxn) {
              this.connect();
            }
          }
        }
      }),
      reopenOpportunitySelection: action(() => {
        const { id: volunteerId, assignedTierId } = this.flowStore.volunteer;
        const { id: eventId, title } = this.eventStore.event;
        this.showChangedSelectionModal = false;
        this.programApi
          .deleteVolunteerSelections(eventId, volunteerId)
          .then(() => {
            BrowserUtilities.scrollTop();
            this.flowStore.reopenOpportunitySelection();
            this.selectionBoxClicked = true;
            this.routerStore.pushWithPathParams(RouteConstants.EVENT_NAME, {
              eventName: title.toLowerCase(),
            });

            // reset timer and set confirmed to null
            const params = [eventId, assignedTierId, volunteerId];
            this.selectionApi.resetSelectionTimer(...params);
            this.selectionApi.clearSelections(...params);
          });
      }),
      goToOpportunities: action(() => {
        const { title } = this.eventStore.event;
        this.savingSelections = false;
        this.collapseAllLiveCounts();
        this.routerStore.pushWithPathParams(
          RouteConstants.EVENT_OPPORTUNITIES,
          { eventName: title.toLowerCase() }
        );
        this.flowStore.changingSelections = false;
        this.flowStore.confirmOpportunitySelections();
        BrowserUtilities.scrollTop();
      }),
    });

    autorun(() => {
      const { key } = this.flowStore.activeStep || {};
      const { serverTime, systemTime } = this.flowStore;
      const isSelectionTime = key === FlowConstants.SELECT_OPPORTUNITIES;
      const selectionsMade = key === FlowConstants.ORIENTATION;
      // reset these so they're correct when we switch events
      this.currentOpenEnrollment = this.defaults.currentOpenEnrollment;
      this.missedAllSelections = this.defaults.missedAllSelections;

      // when the page loads and you're at selection time, get the selection time
      if (
        this.authStore.isVolunteer &&
        (isSelectionTime ||
          (selectionsMade && serverTime && systemTime) ||
          (event &&
            this.flowStore.isStepComplete(FlowConstants.BACKGROUND_CHECK)))
      ) {
        this.getSelectionTime();
        this.getTimeToSelection();
      }
    });
  }

  connect = () => {
    // if you're a volunteer and you've reached opportunity selection,
    // try and connect if you haven't already

    const { id: volunteerId, assignedTierId: tierId } =
      this.flowStore.volunteer;
    const { id: eventId } = this.eventStore.event || {};
    const connected =
      this.selectionApi.client && this.selectionApi.client.connected;

    if (!connected && eventId && tierId && volunteerId) {
      this.initializedCxn = true;
      this.selectionApi.connect(() => {
        /** add all subscriptions here */
        this.opportunitySubscription = this.selectionApi.client.subscribe(
          `/event/${eventId}/opportunities`,
          this.initializeOpportunities
        );
        this.selectionApi.client.subscribe(
          `/event/${eventId}/tier/${tierId}/shifts`,
          this.updateLiveCounts
        );
        this.selectionApi.client.subscribe(
          `/volunteer/${volunteerId}/selectShift`,
          this.updateSelectedShifts
        );
        this.selectionApi.client.subscribe(
          `/volunteer/${volunteerId}/selectMultiple`,
          this.updateSelectedShifts
        );
        this.selectionApi.client.subscribe(
          `/volunteer/${volunteerId}/shifts`,
          this.updateSelectedShifts
        );
        this.selectionApi.client.subscribe(
          `/volunteer/${volunteerId}/time`,
          this.updateEndTime
        );
        this.selectionApi.client.subscribe(
          `/volunteer/${volunteerId}/persistSelections`,
          this.persistSelections
        );
        this.selectionApi.client.subscribe(
          "/status/time",
          this.updateServerTime
        );

        /** run initializers here */
        this.getOpportunities(eventId);
        this.getLiveCounts(eventId, tierId);
        this.getSelectedShifts(volunteerId);
        this.getStartTime(volunteerId);
      });
    }
  };

  disconnect = () => this.selectionApi.disconnect();

  getOpenEnrollmentDateTime = (oe, dateProperty, timeProperty) => {
    const hours = oe[timeProperty].getHours().toString().padStart(2, "0");
    const minutes = oe[timeProperty].getMinutes().toString().padStart(2, "0");

    const date = oe[dateProperty] || oe.date;
    return `${DateUtilities.dateToString(
      date,
      "YYYY-MM-DD"
    )} ${hours}:${minutes}:00`;
  };

  isSelectedInMap = (selection, date) => {
    const { opportunityId, dateTimeId, outside } = selection;
    const oldLiveCountObject = this.liveCountDateMap[date] || {};
    const oldOpportunities = oldLiveCountObject.opportunities || {};
    const oldOpportunity = oldOpportunities[opportunityId] || {};
    const insideShiftDates = oldOpportunity.insideShiftDates || {};
    const outsideShiftDates = oldOpportunity.outsideShiftDates || {};
    return outside
      ? Boolean(outsideShiftDates[dateTimeId])
      : Boolean(insideShiftDates[dateTimeId]);
  };

  getIsShiftSelected = (liveCount) => {
    const { date, dateTimeId, opportunityId, outside } = liveCount;
    const oldLiveCountObject = this.liveCountDateMap[date] || {};
    const oldOpportunities = oldLiveCountObject.opportunities || {};
    const oldOpportunity = oldOpportunities[opportunityId] || {};

    const key = outside ? "outsideShiftDates" : "insideShiftDates";
    const oldShiftDates = oldOpportunity[key] || {};
    const shiftDate = oldShiftDates[dateTimeId] || { selected: false };

    return shiftDate.selected;
  };

  isSelectionLinked = (selection) => {
    const { date, dateTimeId, opportunityId, outside } = selection;
    const oldLiveCountObject = this.liveCountDateMap[date] || {};
    const oldOpportunities = oldLiveCountObject.opportunities || {};
    const oldOpportunity = oldOpportunities[opportunityId] || {};

    const key = outside ? "outsideShiftDates" : "insideShiftDates";
    const oldShiftDates = oldOpportunity[key] || {};
    const shiftDate = oldShiftDates[dateTimeId] || { linked: false };

    return shiftDate.linked;
  };

  clearSelected = (selections) => {
    selections.forEach((selection) => {
      const { date, opportunityId, dateTimeId, outside } = selection;
      const dateStr = DateUtilities.redisDateToString(date);

      const key = outside ? "outsideShiftDates" : "insideShiftDates";
      this.liveCountDateMap[dateStr].opportunities[opportunityId][key][
        dateTimeId
      ].selected = false;
    });
  };

  clearSelectionLoading = (selection) => {
    const { date, opportunityId, dateTimeId, outside } = selection;
    const dateStr = DateUtilities.redisDateToString(date);

    const key = outside ? "outsideShiftDates" : "insideShiftDates";
    this.liveCountDateMap[dateStr].opportunities[opportunityId][key][
      dateTimeId
    ].loading = false;
  };

  clearLoading = () => {
    this.selections.forEach((selection) => {
      this.clearSelectionLoading(selection);
    });
    if (this.pendingShiftSelected && this.pendingShiftSelected.date) {
      this.clearSelectionLoading(this.pendingShiftSelected);
    }
  };

  setIsShiftSelected = (value, selection) =>
    this.setShiftValue("selected", value, selection);

  setIsShiftLoading = (value, selection) =>
    this.setShiftValue("loading", value, selection);

  setShiftValue = (key, value, selection) => {
    const { date, opportunityId, dateTimeId, outside } = selection;
    const dateStr = DateUtilities.redisDateToString(date);

    // now update the liveCount map
    if (this.isSelectedInMap(selection, dateStr)) {
      // the map is initialized, so just update the record
      const flag = outside ? "outsideShiftDates" : "insideShiftDates";
      this.liveCountDateMap[dateStr].opportunities[opportunityId][flag][
        dateTimeId
      ][key] = value;
    } else {
      // if you're initializing the selection, the entire map
      // has to be updated
      const oldLiveCountObject = this.liveCountDateMap[dateStr] || {};
      const oldOpportunities = oldLiveCountObject.opportunities || {};
      const oldOpportunity = oldOpportunities[opportunityId] || {};
      const insideShiftDates = oldOpportunity.insideShiftDates || {};
      const outsideShiftDates = oldOpportunity.outsideShiftDates || {};

      if (outside) {
        const oldShiftDate = outsideShiftDates[dateTimeId] || {};
        outsideShiftDates[dateTimeId] = {
          ...oldShiftDate,
          [key]: value,
        };
      } else {
        const oldShiftDate = insideShiftDates[dateTimeId] || {};
        insideShiftDates[dateTimeId] = {
          ...oldShiftDate,
          [key]: value,
        };
      }

      this.liveCountDateMap[dateStr] = {
        ...oldLiveCountObject,
        opportunities: {
          ...oldOpportunities,
          [opportunityId]: {
            ...oldOpportunity,
            insideShiftDates,
            outsideShiftDates,
          },
        },
      };
    }
  };

  calculateTimeParts = (date) => {
    const days = date / (1000 * 60 * 60 * 24);
    const hours = (days - Math.floor(days)) * 24;
    const mins = (hours - Math.floor(hours)) * 60;
    const secs = Math.floor((mins - Math.floor(mins)) * 60);

    return { days, hours, mins, secs };
  };

  filterShiftDates = (sd) => sd.selected || sd.remaining;

  filterOpportunities = (o) =>
    Object.values(o.insideShiftDates).filter(this.filterShiftDates).length +
    Object.values(o.outsideShiftDates).filter(this.filterShiftDates).length;

  sortOpportunities = (a, b) => (a.id > b.id ? 1 : -1);

  sortShiftDates = (a, b) => (a.startTime > b.startTime ? 1 : -1);

  doLiveCountsConflict = (lc1, lc2) => {
    const startTime1 = lc1.startTime,
      startTime2 = lc2.startTime,
      endTime1 = lc1.endTime,
      endTime2 = lc2.endTime;

    return (
      !(
        (startTime1 < startTime2 && endTime1 === startTime2) ||
        (startTime1 === endTime2 && endTime1 > endTime2) ||
        (startTime1 < startTime2 && endTime1 < startTime2) ||
        (startTime1 > endTime2 && endTime1 > endTime2)
      ) && lc1.date === lc2.date
    );
  };

  padTime = (days, hours, mins, secs) => {
    return {
      days: days.toString().padStart(2, "0"),
      hours: hours.toString().padStart(2, "0"),
      mins: mins.toString().padStart(2, "0"),
      secs: secs.toString().padStart(2, "0"),
    };
  };

  get timer() {
    return this.selectionStarted ? this.timeForSelection : this.timeToSelection;
  }

  get selectedShifts() {
    const { numRequiredOpportunities: size, numMaxOpportunitiesPerDay } =
      this.eventStore.event || {};
    const ranges = this.selections.map((s) => ({
      id: s.id,
      start: s.startObj,
      end: s.endObj,
      date: s.date,
    }));

    const selections = this.selections.map((s) => {
      const sStartStamp = s.startObj.getTime();
      const sEndStamp = s.endObj.getTime();
      const range = ranges.find((r) => {
        const rStartStamp = r.start.getTime();
        const rEndStamp = r.end.getTime();
        return (
          r.id !== s.id &&
          !(
            (sStartStamp < rStartStamp && sEndStamp === rStartStamp) ||
            (sStartStamp === rEndStamp && sEndStamp > rEndStamp) ||
            (sStartStamp < rStartStamp && sEndStamp < rStartStamp) ||
            (sStartStamp > rEndStamp && sEndStamp > rEndStamp)
          )
        );
      });

      const selectedDates = ranges.map((r) => r.date);
      const selectedDatesCounts = _.countBy(selectedDates);
      let exceedMaxSelectionPerDay = false;
      for (const date in selectedDatesCounts) {
        if (selectedDatesCounts[date] > numMaxOpportunitiesPerDay) {
          exceedMaxSelectionPerDay = true;
          break;
        }
      }

      return {
        ...s,
        conflicted: Boolean(range),
        exceedMaxSelectionPerDay: exceedMaxSelectionPerDay,
      };
    });

    return [...Array(size || 0).keys()].map((_, i) =>
      i < selections.length
        ? { ...selections[i] }
        : { key: StringUtilities.random() }
    );
  }

  get canConfirmSelections() {
    const { numRequiredOpportunities: size } = this.eventStore.event || {};
    return (
      this.selections.length === (size || 0) &&
      !this.selectedShifts.some((s) => s.conflicted) &&
      !this.selectedShifts.some((s) => s.exceedMaxSelectionPerDay)
    );
  }

  get liveCountsByDate() {
    return Object.keys(this.liveCountDateMap)
      .sort()
      .map((dateStr) => {
        const { expanded, opportunities } = this.liveCountDateMap[dateStr];

        return {
          expanded,
          date: dateStr,
          opportunities: Object.values(opportunities)
            .filter(this.filterOpportunities)
            .sort(this.sortOpportunities)
            .map((o) => {
              const opportunityData = this.opportunityMap[o.id] || {};
              return {
                ...o,
                name: opportunityData.name,
                description: opportunityData.description,
                reportingLocation: opportunityData.location,
                address: opportunityData.locationAddress,
                insideShiftDates: Object.values(o.insideShiftDates)
                  .filter(this.filterShiftDates)
                  .sort(this.sortShiftDates)
                  .map((isd) => {
                    return {
                      ...isd,
                      name: opportunityData.name,
                    };
                  }),
                outsideShiftDates: Object.values(o.outsideShiftDates)
                  .filter(this.filterShiftDates)
                  .sort(this.sortShiftDates)
                  .map((osd) => {
                    return {
                      ...osd,
                      name: opportunityData.name,
                    };
                  }),
              };
            }),
          remaining: Object.values(opportunities).reduce((pv, o) => {
            const inTotal = Object.values(o.insideShiftDates).reduce(
              (pv, sd) => pv + sd.remaining,
              0
            );
            const outTotal = Object.values(o.outsideShiftDates).reduce(
              (pv, sd) => pv + sd.remaining,
              0
            );
            return pv + inTotal + outTotal;
          }, 0),
        };
      });
  }

  get opportunitiesRemaining() {
    return this.liveCountsByDate.reduce((pv, lc) => pv + lc.remaining, 0);
  }

  isSubsetValid = (subset) => {
    let valid = true;
    if (subset.length > 1) {
      // any set of size one is automatically valid
      subset.forEach((shift) => {
        if (valid) {
          // only proceed checking if the set is still valid
          subset.forEach((shift2) => {
            if (
              valid &&
              shift.id !== shift2.id &&
              this.doLiveCountsConflict(shift, shift2)
            ) {
              valid = false;
            }
          });
        }
      });
    }
    return valid;
  };

  get numberOfRequiredLinkedShiftSelections() {
    const { numRequiredOpportunities } = this.eventStore.event;

    let numRequired;

    const originalNum =
      this.linkedShifts.length < numRequiredOpportunities - 1
        ? this.linkedShifts.length
        : numRequiredOpportunities - 1;

    if (originalNum !== 1) {
      // First we get all the possible combinations of selections
      const allSubsets = this.linkedShifts.reduce(
        (subsets, value) =>
          subsets.concat(subsets.map((set) => [value, ...set])),
        [[]]
      );
      const validSubsets = [];
      let largestValidSubset = 0;
      // Then we check each combination to determine if
      // any of the shifts in it conflict with each other.
      allSubsets.forEach((subset) => {
        if (subset.length > 0 && this.isSubsetValid(subset)) {
          validSubsets.push(subset);
          // If this combination has the most valid options, and that number
          // is less than or equal to the maximum number of valid selections,
          // then that is the number of shifts they must select.
          if (
            subset.length > largestValidSubset &&
            subset.length <= originalNum
          ) {
            largestValidSubset = subset.length;
          }
        }
      });
      numRequired = largestValidSubset;
    } else {
      numRequired = originalNum;
    }

    return numRequired;
  }

  get remainingTimeInTier() {
    if (this.systemTime && this.serverTime) {
      let minutes = "0";
      let seconds = "00";
      const { tiers } = this.eventStore.event || {};
      const { assignedTierId: tierId } = this.flowStore.volunteer;
      const tier = (tiers || []).find((t) => t.id === tierId);

      const { date, endDate, endTime: end } = tier || {};
      const endTime = DateUtilities.parseTZString(`${endDate || date} ${end}`);

      if (endTime && endTime - this.serverTime) {
        const duration = new Date() - this.systemTime;
        const diff = endTime - new Date(this.serverTime.getTime() + duration);
        if (diff > 0) {
          const m = diff / (1000 * 60);
          const s = Math.floor((m - Math.floor(m)) * 60);
          const min = Math.min(Math.floor(m), 15);
          const sec = min === 15 ? 0 : Math.min(s, 59);

          minutes = min.toString();
          seconds = sec.toString().padStart(2, "0");
        }
      }
      return { minutes: minutes, seconds: seconds };
    } else {
      return null;
    }
  }
}

decorate(SelectionStore, {
  timer: computed,
  selectedShifts: computed,
  canConfirmSelections: computed,
  liveCountsByDate: computed,
  opportunitiesRemaining: computed,
  numberOfRequiredLinkedShiftSelections: computed,
  remainingTimeInTier: computed,
});

import _ from "lodash";
import { extendObservable, autorun, action, decorate, computed } from "mobx";
import { StoreUtilities } from "../utilities/StoreUtilities";
import { StringUtilities } from "../utilities/StringUtilities";

export default class AccountStore {
  constructor(
    programApi,
    authApi,
    auth,
    event,
    flow,
    router,
    notification,
    reset,
    i18n
  ) {
    this.programApi = programApi;
    this.authApi = authApi;
    this.authStore = auth;
    this.eventStore = event;
    this.flowStore = flow;
    this.routerStore = router;
    this.notificationStore = notification;
    this.resetStore = reset;
    this.i18n = i18n;

    this.defaults = {
      eventId: null,
      eventName: "",
      initialInfo: { phoneNumbers: [] },
      userInfo: { phoneNumbers: [] },
      volunteerId: null,
      isWarnOnLeaveOpen: false,
      updatingUserInfo: false,
      leavingEvent: false,
      historyOfUserEvents: []
    };

    extendObservable(this, {
      ...StoreUtilities.initialize(this),

      addPhoneNumber: action(() => {
        this.userInfo.phoneNumbers.push({
          key: StringUtilities.random(),
          type: "",
          number: ""
        });
      }),
      removePhoneNumber: action(idx => {
        this.userInfo.phoneNumbers.splice(idx, 1);
        if (!this.userInfo.phoneNumbers.length) {
          this.addPhoneNumber();
        }
      }),
      setPhoneType: action((idx, item) => {
        this.userInfo.phoneNumbers[idx].type = item.name;
      }),
      setPhoneNumber: action((idx, event) => {
        this.userInfo.phoneNumbers[idx].number = event.target.value;
      }),
      toggleLeaveEventModal: action(() => {
        this.isWarnOnLeaveOpen = !this.isWarnOnLeaveOpen;
      }),
      loadUserInfo: action(email => {
        this.authApi.getUserProfile(email).then(data => {
          // set user attributes
          const userInfo = data || { phoneNumbers: [] };
          userInfo.phoneNumbers = _.sortBy(userInfo.phoneNumbers, "id");
          this.userInfo = {
            ...userInfo,
            phoneNumbers: userInfo.phoneNumbers.map(p => ({
              ...p,
              key: StringUtilities.random()
            }))
          };

          if (!this.userInfo.phoneNumbers.length) {
            this.addPhoneNumber();
          }

          this.initialInfo = { ...userInfo };
        });
      }),
      loadEventsForUser: action(email => {
        this.programApi.loadHistoricalEventsForUser(encodeURIComponent(email)).then(data => {
          this.historyOfUserEvents = data ? data.events : [];
        });
      }),
      prepareToLeaveEvent: action(event => {
        this.eventId = event.id;
        this.eventName = event.title;
        this.volunteerId = event.volunteerIdForEvent;
        this.isWarnOnLeaveOpen = true;
      }),
      leaveEvent: action(() => {
        // persist eventId and name
        const eventId = this.eventId;
        const eventName = this.eventName;

        // leave event
        this.leavingEvent = true;
        this.programApi
          .leaveEvent(this.volunteerId, this.eventId)
          .then(() => {
            StoreUtilities.resetDefaults(this);
            this.notificationStore.setMessage(
              `${this.i18n.t("volunteer.notification.left")} ${eventName}`
            );
            this.loadUserInfo(this.authStore.userData.email);
            this.loadEventsForUser(this.authStore.currentUserId);

            // clear curr event if you just left
            const { id } = this.eventStore.event || {};
            if (id === eventId) {
              this.eventStore.clearEventAttributes();
              this.flowStore.clearOldFlowStatus();
            }

            // reload user-events
            this.eventStore.loadUserEvents();
          })
          .catch(() => {
            this.leavingEvent = false;
          });
      }),
      updateUser: action(() => {
        this.updatingUserInfo = true;
        this.authApi
          .updateUser(this.userInfo)
          .then(() => {
            this.notificationStore.setMessage("Saved");
            this.updatingUserInfo = false;
            this.loadUserInfo(this.authStore.userData.email);
          })
          .catch(() => {
            this.updatingUserInfo = false;
          });
      }),
      resetPassword: action(() => {
        this.authStore.confirmEmail = this.userInfo.email;
        this.resetStore.confirmPassword = true;
        this.authStore.volunteerResetPassword = true;
      })
    });

    // load user info && history of events for user
    autorun(() => {
      const { currentUserId, isAdmin, userData } = this.authStore;
      if (currentUserId && !isAdmin && this.routerStore.isAccountPath) {
        this.loadUserInfo(userData.email);
        this.loadEventsForUser(currentUserId);
        if (!this.eventStore.userEvents.length) {
          this.programApi.loadEventsForUser().then(data => {
            // this prevents infinite loop
            const events = data ? data.events : [];
            if (events.length) {
              this.eventStore.setUserEvents(events);
            }
          });
        }
      }
    });
  }

  get changesMade() {
    const prevInfo = this.initialInfo;
    const currInfo = this.userInfo;
    if (prevInfo.phoneNumbers.length !== currInfo.phoneNumbers.length) {
      return true;
    }

    for (let i = 0; i < prevInfo.phoneNumbers.length; i++) {
      const p = prevInfo.phoneNumbers[i];
      const c = currInfo.phoneNumbers[i];

      if (p.type !== c.type) return true;
      if (p.number !== c.number) return true;
    }

    return false;
  }

  get isValid() {
    return this.userInfo.phoneNumbers.reduce(
      (pv, c) => pv && Boolean(c.type && c.number),
      true
    );
  }
}

decorate(AccountStore, {
  changesMade: computed,
  isValid: computed
});

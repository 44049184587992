import { extendObservable, autorun } from "mobx";
import { StoreUtilities } from "../utilities/StoreUtilities";

export default class InfoStore {
  constructor(authApi, programApi, authStore, emailApi) {
    this.authApi = authApi;
    this.programApi = programApi;
    this.authStore = authStore;
    this.emailApi = emailApi;

    this.defaults = {
      projectInfo: null
    };

    extendObservable(this, {
      ...StoreUtilities.initialize(this)
    });

    autorun(() => {
      if (this.authStore.isAdmin && !this.projectInfo) {
        const req = [
          this.programApi.loadInfo(),
          this.authApi.loadInfo(),
          this.emailApi.loadInfo()
        ];
        this.executeAll(req);
      }
    });
  }

  executeAll = async promises => {
    const results = await Promise.all(promises.map(p => p.catch(e => e)));
    const r = results.filter(result => !(result instanceof Error));
    const [program, user, email] = r;
    const unavailable = { branch: "UNAVAILABLE", commit: { id: "UNAVAILABLE" } };
    this.projectInfo = {
      program: program ? program.git : unavailable,
      user: user ? user.git : unavailable,
      email: email ? email.git : unavailable
    };
  };
}

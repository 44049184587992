import { action, extendObservable } from "mobx";
import i18n from "../translations/i18n";
import Cookies from "js-cookie";

export default class LanguageStore {
  constructor() {
    this.defaults = {
      language: Cookies.get("language") || "en"
    };

    // NOTE: i18n sets the default language to the browser default,
    // so the language must be overriden by the cookie
    i18n.changeLanguage(this.defaults.language);

    extendObservable(this, {
      language: this.defaults.language,
      setLanguage: action(value => {
        Cookies.set("language", value);
        i18n.changeLanguage(value);
        this.language = value;
      })
    });
  }
}

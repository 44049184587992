import moment from "moment";
import momentTZ from "moment-timezone";

const format = (arg, output, input) => {
  if (!arg) return null;

  const m = input ? moment(arg, input) : moment(arg);
  return m.format(output);
};

export class DateUtilities {
  static utcString = () => moment.utc().format();

  static parseString = (arg, format = "YYYY-MM-DD", strict = true) => {
    return arg ? moment(arg, format, strict).toDate() : null;
  };

  static dateTimeToString = (arg, output = "HH:mm:ss") =>
    format(arg, output);

  static timeToString = (arg, output = "HH:mm:ss", input = "h:mm a") =>
    format(arg, output, input);

  static dateToString = (arg, output = "MM/DD/YYYY", input = null) =>
    format(arg, output, input);

  static dateRange = (
    startDate,
    endDate,
    output = "MM/DD/YYYY",
    input = null
  ) => {
    const start = this.dateToString(startDate, output, input);
    const end = this.dateToString(endDate, output, input);

    return !end || start === end ? start : `${start} - ${end}`;
  };

  static timeRange = (
    startTime,
    endTime,
    {
      outputStart = "h:mm a",
      outputEnd = "h:mm a",
      inputStart = "HH:mm:00",
      inputEnd = "HH:mm:00",
    }
  ) => {
    const start = this.timeToString(startTime, outputStart, inputStart);
    const end = this.timeToString(endTime, outputEnd, inputEnd);

    return !end || start === end ? start : `${start} - ${end}`;
  };

  static redisDateToString = (dateObj) => {
    if (typeof dateObj === "string") {
      return dateObj;
    }

    // This update is due to the dateObj being sent as an array ex: [2023, 5, 2]
    if (Array.isArray(dateObj) && dateObj.length === 3) {
      let month = dateObj[1].toString();
      if (month.length === 1) {
        month = "0" + month;
      }

      let day = dateObj[2].toString();
      if (day.length === 1) {
        day = "0" + day;
      }
      return dateObj[0] + "-" + month + "-" + day;
    }

    const { year, monthValue: month, dayOfMonth: day } = dateObj;
    return this.dateToString(new Date(year, month - 1, day), "YYYY-MM-DD");
  };

  static redisTimeToString = (timeObj) => {
    if (typeof timeObj === "string") {
      return timeObj;
    }

    const { hour, minute } = timeObj;
    const dateTime = new Date();
    dateTime.setHours(hour);
    dateTime.setMinutes(minute);
    return this.timeToString(dateTime, "HH:mm:00", null);
  };

  static parseTZString = (dateTimeString) =>
    momentTZ.tz(dateTimeString, "America/New_York").toDate();

  static changeTimezone(date, timezone) {
    const invdate = new Date(
      date.toLocaleString("en-US", {
        timeZone: timezone,
      })
    );

    let diff = date.getTime() - invdate.getTime();
    if (diff < 0) {
      diff *= -1;
    }
    return new Date(date.getTime() - diff);
  }
}

/*
  This contains the environment variables needed for the application
  in the local environment
*/

const overrides = (process.env.OVERRIDES || "").toLowerCase().split(",");

export const environment = {
  ambassador: false,
  useLocalPorts: true,
  elbURL: "https://ambassador-dev.npd.aws-us-east-1.infra.mlbinfra.net",
  baseURL: "http://localhost",
  REACT_APP_OKTA_REDIRECT_URI: "http://localhost:3000",
  REACT_APP_OKTA_REDIRECT_URI_VOL: "http://localhost:3000",
  REACT_APP_OKTA_POST_LOGOUT_REDIRECT_URI: "http://localhost:3000/admin",
  REACT_APP_OKTA_POST_LOGOUT_REDIRECT_URI_VOL: "http://localhost:3000",
  superAdminRole: "SUPER_ADMIN",
  REACT_APP_OKTA_BASE_URL: "https://mlbtest.okta.com",
  REACT_APP_OKTA_CLIENT_ID: "0oa94hkpha2XiQCQP1t7",
  REACT_APP_OKTA_ISSUER: "https://mlbtest.okta.com/oauth2/ausa3295vsxer28Br1t7",

  REACT_APP_OKTA_BASE_URL_VOL: "https://qa-ids.mlb.com",
  REACT_APP_OKTA_CLIENT_ID_VOL: "0oa1k6srkm04rhdUV0h8",
  REACT_APP_OKTA_ISSUER_VOL: "https://qa-ids.mlb.com/oauth2/ausg0y0s07iKGqVKv0h7",
  services: {
    // NOTE: These should match ambassador prefix
    user: {
      isOverridden: overrides.includes("user"),
      url: `http://localhost:${process.env.USER_PORT}`,
    },
    program: {
      isOverridden: overrides.includes("program"),
      url: `http://localhost:${process.env.PROGRAM_PORT}`,
    },
    email: {
      isOverridden: overrides.includes("email"),
      url: `http://localhost:${process.env.EMAIL_PORT}`,
    },
    // TODO: does this get run in a local env? if not we can remove this
    selection: {
      isOverridden: overrides.includes("selection"),
      url: `http://localhost:${process.env.SELECTION_PORT}`,
    },
    login: {
      isOverridden: overrides.includes("login"),
      url: `http://localhost:${process.env.AUTH_PORT}`,
    },
    import: {
      isOverridden: overrides.includes("import"),
      url: `http://localhost:${process.env.IMPORT_PORT}`,
    },
  },
};

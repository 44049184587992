import { action } from "mobx";
import { EditorState } from "draft-js";

export class StoreUtilities {
  static initialize = (self, translatedProperties, currentLanguageProperty) => {
    // define common defaults
    const defaults = {
      validationErrorMessages: []
    };

    // this defines some common setters
    const setters = {
      setValidationErrorMessages: action(messages => {
        self.validationErrorMessages = messages;
      }),
      clearValidationErrorMessages: action(() => {
        self.validationErrorMessages = [];
      })
    };

    for (const key in self.defaults) {
      if (Object.prototype.hasOwnProperty.call(self.defaults, key)) {
        if (typeof self.defaults[key] === "string") {
          const name = `set${key.charAt(0).toUpperCase()}${key.substr(1)}`;
          setters[name] = action(event => {
            if (
              translatedProperties &&
              translatedProperties.has(key) &&
              self[currentLanguageProperty] &&
              self[currentLanguageProperty].key === "es"
            ) {
              self[key + "Spanish"] = event.target.value;
            } else {
              self[key] = event.target.value;
            }
          });
        } else if (self.defaults[key] instanceof EditorState) {
          const name = `set${key.charAt(0).toUpperCase()}${key.substr(1)}`;
          setters[name] = action(editorState => {
            if (
              translatedProperties &&
              translatedProperties.has(key) &&
              self[currentLanguageProperty] &&
              self[currentLanguageProperty].key === "es"
            ) {
              self[key + "Spanish"] = editorState;
            } else {
              self[key] = editorState;
            }
          });
        }
      }
    }

    const result = Object.assign({}, defaults, setters);
    return Object.assign({}, self.defaults, result);
  };

  static resetDefaults = self => {
    for (const key in self.defaults) {
      if (Object.prototype.hasOwnProperty.call(self.defaults, key)) {
        self[key] = self.defaults[key];
      }
    }
  };
}

import { environment } from "../../configs/environment";
import * as axios from "axios";

const SERVICE_URL = `${environment.baseURL}`;

const doRequest = (prefix, endpointUrl, method, body, headers, options, localPort) => {
  const useLocalPorts = environment.useLocalPorts;
  let serviceUrl = SERVICE_URL;
  if(useLocalPorts === true){
    serviceUrl += ":" + localPort;
  }

  const url = `${serviceUrl}/${prefix}/${endpointUrl}`;

  return axios({
    url,
    method,
    data: body || undefined,
    headers: headers || {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    ...(options || {})
  });
};

export default class AbstractApi {
  constructor() {
    if (new.target === AbstractApi) {
      throw new TypeError("Cannot construct Abstract instances directly");
    }

    if (
      this.getAmbassadorPrefix === undefined ||
      typeof this.getAmbassadorPrefix !== "function"
    ) {
      throw new TypeError("Must override method getAmbassadorPrefix");
    }

    if (
      this.getLocalPort === undefined ||
      typeof this.getLocalPort !== "function"
    ) {
      throw new TypeError("Must override method getLocalPort");
    }
  }

  get = (url, headers, options) =>
    doRequest(this.getAmbassadorPrefix(), url, "get", null, headers, options, this.getLocalPort());

  post = (url, body, headers, options) =>
    doRequest(this.getAmbassadorPrefix(), url, "post", body, headers, options, this.getLocalPort());

  put = (url, body, headers) =>
    doRequest(this.getAmbassadorPrefix(), url, "put", body, headers, null, this.getLocalPort());

  patch = (url, body, headers) =>
    doRequest(this.getAmbassadorPrefix(), url, "patch", body, headers, null, this.getLocalPort());

  delete = (url, headers) =>
    doRequest(this.getAmbassadorPrefix(), url, "delete", null, headers, null, this.getLocalPort());
}

const Theme = {
  "dark-grey": "#333333",
  "slate-grey": "#666666",
  "almost-black": "#121212",
  "almost-white": "#f2f3f4",
  "grey": "#d2d2d2",
  "gray": "#d2d2d2",
  "off-white": "#f7f7f7",
  "white": "#ffffff",
  "black": "#000000",
  "green": "#00a651",
  "mlb-navy": "#041e42",
  "light-blue": "#4c8cee",
  "light-grey": "#e6e6e6",
  "dark-blue": "#003882",
  "mlb-red": "#bf0d3e",
  "dev-banner": "#bf0d3e",
  "local-banner": "#007bff",
  "stg-banner": "#ffc107",
  "link-blue" : "#2b77eb",

  icon: {
    color: "#007bff",
    hover: "#002e6d",
    size: "1.1rem"
  },
  link: {
    color: "#007bff",
    hover: "#041e42"
  }
};

export default Theme;

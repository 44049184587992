import { autorun } from "mobx";

class OktaStore {
  constructor(routerStore, authStore, notificationStore, history) {
    this.routerStore = routerStore;
    this.authStore = authStore;
    this.notificationStore = notificationStore;
    this.history = history;

    autorun(() => {
      // if (this.routerStore.isOktaPath) {
      //   const oktaResult = window.location.href.split("#").slice(1)[0];

      //   if (!oktaResult || oktaResult.startsWith("error")) {
      //     decodeURIComponent(oktaResult).split("&").forEach(error => {
      //       const e = error.split("=");
      //       // eslint-disable-next-line no-console
      //       console.error(`${e[0]}: ${e[1].replace(/\+/g, " ")}`);
      //     });

      //     this.history.push(RouteConstants.LOGIN);
      //     this.notificationStore.setMessage(
      //       "Woops! Looks like an error on our end 😢",
      //       NotificationConstants.ERROR
      //     );

      //     // TODO: clear any okta data
      //     return;
      //   }

      //   const vars = oktaResult.split("&");
      //   this.authStore.setLoggedIn({
      //     idToken: vars[1].split("=")[1],
      //     accessToken: vars[0].split("=")[1],
      //     expiresAt: vars[3].split("=")[1]
      //   });
      //   this.authStore.getUserInfo();
      // }
    });
  }
}

export default OktaStore;

import { environment } from "../../configs/environment";

export const AuthConstants = {
  KEYS: {
    USER_DATA: "user_data",
    ACCESS_TOKEN: "access_token",
    REFRESH_TOKEN: "refresh_token",
    ID_TOKEN: "id_token",
    AUTHORITIES: "authorities",
    ROLES: {
      SUPER_ADMIN: environment.superAdminRole,
      PROGRAM_ADMIN: environment.programAdminRole,
      ETA_STAFF:environment.etaStaffRole
    }
  }
};

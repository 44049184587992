import { extendObservable, action } from "mobx";
import { StoreUtilities } from "../utilities/StoreUtilities";
import { DateUtilities } from "../utilities/DateUtilities";
import { NotificationConstants } from "../constants/NotificationConstants";

class ReportsStore {
  constructor(
    eventStore,
    volunteerStore,
    programApi,
    opportunityStore,
    notificationStore
  ) {
    this.eventStore = eventStore;
    this.volunteerStore = volunteerStore;
    this.programApi = programApi;
    this.opportunityStore = opportunityStore;
    this.notificationStore = notificationStore;

    this.defaults = {
      generating: false,
      reportType: null
    };

    extendObservable(this, {
      ...StoreUtilities.initialize(this),
      setReportType: action(reportType => {
        this.reportType = reportType;
      }),
      generateReport: action(() => {
        if (this.reportType.value === "nonUSVolunteers") {
          this.exportNonUSVolunteers();
        } else if (this.reportType.value === "opportunity") {
          this.exportOpportunityVolunteers();
        } else if (this.reportType.value === "volunteerSelections") {
          this.exportVolunteerSelection();
        } else if (this.reportType.value === "alumniCertificates") {
          this.exportAlumniCertificates();
        }
      })
    });
  }

  exportNonUSVolunteers = () => {
    const { id: eventId } = this.eventStore.event || {};
    const standby = new Set(["all", "none", "selected"]);
    const filters = this.volunteerStore.searchFilters();
    const { volunteerFilters } = this.volunteerStore;
    filters.onlyNonUS = true;
    // create standby query params
    filters.standby = null;

    if(volunteerFilters.standby.value){
      if(standby.has(volunteerFilters.standby.value)) {
        filters.standbyDate = DateUtilities.dateToString(new Date(), "YYYY-MM-DD");
        filters.standbyTod = volunteerFilters.standby.value;
      } else {
        filters.standbyDate = volunteerFilters.standby.value.date;
        filters.standbyTod =volunteerFilters.standby.value.tod;
      }
    }
    const params = Object.keys(filters)
      .filter(k => filters[k] !== null && filters[k] !== undefined)
      .map(k => `${k}=${filters[k]}`)
      .join("&").concat(`&eventId=${eventId}`);

    this.generating = true;
    this.programApi.exportVolunteers(eventId, params).then(binary => {
      this.generating = false;
      const date = DateUtilities.utcString();
      const formattedDate = date.replace("+00:00", "").replace(/:+/g, ".");

      const blob = new Blob([binary], { type: "application/vnd.ms-excel" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.id = "volunteer-download";
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = `volunteerExport_${formattedDate}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      const m = document.getElementById("volunteer-download");
      m.remove();
    }).catch(() => {
      this.generating = false;
    });
  };

  exportVolunteerSelection = () => {
    const { id: eventId } = this.eventStore.event || {};
    this.generating=true;
    this.programApi.exportVolunteerSelections(eventId).then(() => {
      this.generating=false;
      this.notificationStore.setMessage(
        "Report is being generated.",
        NotificationConstants.SUCCESS
      );
    });
  }

  exportOpportunityVolunteers = () => {
    const { id } = this.eventStore.event || {};
    const payload ={
      opportunities: [],
      dates: [],
      locations:[],
      reportingLocations:[]
    };

    const {
      opportunityReportFilters,
      opportunityReportFilterOptions,
      opportunityReportDatesFilterOptions,
      opportunityReportLocationsFilterOptions,
      opportunityReportReportingLocationsFilterOptions
    } = this.opportunityStore;

    const getPayloadFilter = (filter, filterOptions) => {
      const isAll = filter.find(o => o.key === "all");
      if(isAll) {
        return filterOptions.map(o => o.value)
          .filter(oid=> oid !== "all");
      } else {
        return filter.map(o=>o.value);
      }
    };

    payload["opportunities"] = getPayloadFilter(
      opportunityReportFilters["opportunities"],
      opportunityReportFilterOptions
    );

    payload["dates"] = getPayloadFilter(
      opportunityReportFilters["dates"],
      opportunityReportDatesFilterOptions
    );

    payload["locations"] = getPayloadFilter(
      opportunityReportFilters["locations"],
      opportunityReportLocationsFilterOptions
    );

    payload["reportingLocations"] = getPayloadFilter(
      opportunityReportFilters["reportingLocations"],
      opportunityReportReportingLocationsFilterOptions

    );

    this.generating=true;
    this.programApi.exportVolunteersOpportunity(id, payload).then(binary => {
    this.generating = false;
    const date = DateUtilities.utcString();
    const formattedDate = date.replace("+00:00", "").replace(/:+/g, ".");

    const blob = new Blob([binary], { type: "application/vnd.ms-excel" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.id = "volunteer-download";
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = `volunteerOpportunityExport_${formattedDate}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
    const m = document.getElementById("volunteer-download");
    m.remove();
    }).catch(() => {
      this.generating = false;
    });
  };

  exportAlumniCertificates = () => {
    const { id: eventId } = this.eventStore.event || {};
    this.programApi.exportAlumniCertificates(eventId)            
      .then((binary) => {
        const blob = new Blob([binary], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        const id = "alumni-certificates-download";
        a.id = id;
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = `${eventId}__AlumniCertificates.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        const m = document.getElementById(id);
        m.remove();
        this.setDownloadingReport(false);
      });
  };
}

export default ReportsStore;
